import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper";
import BOGraveListItem from "./BOGraveListItem";

const BOGraveList = (props) => {

  const { t } = useTranslation();

  const graves = props.items;

  let itemList = "";
  if (graves.length > 0) {
    itemList = graves.map((item) => (
      <BOGraveListItem id={item.id} key={item.id} item={item} />
    ));
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                <th>{t("bo_grave_list_graveName")}</th>
                <th className="rl-table-border-l">{t("bo_grave_list_date")}</th>
                <th className="rl-table-border-l">{t("bo_grave_list_verse")}</th>
                <th className="rl-table-border-l">{t("bo_grave_list_isPaid")}</th>
                <th className="rl-table-border-l">{t("bo_grave_list_graveType")}</th>
                <th className="rl-table-border-l">{t("bo_grave_list_name")}</th>
                <th className="rl-table-border-l">{t("bo_grave_list_email")}</th>
                <th className="rl-table-border-l">{t("bo_grave_list_kycStatus")}</th>
                <th className="text-right"></th>
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default BOGraveList;
