import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import RLCheckBox from "../../UI/RLCheckBox";
import RLTable from "../../UI/RLTable";
import Wrapper from "../../UI/Wrapper";
import PanelAdminListItem from "./PanelAdminListItem";

const PanelAdminList = (props) => {
  const { t } = useTranslation();

  const [admins, setAdmins] = useState();
  if (admins != props.items) {
    setAdmins(props.items);
  }

  let listCheckboxes_ = [];

  const [listCheckboxes, setListCheckboxes] = useState();
  // const listCheckboxesRef = useRef();
  // listCheckboxesRef.current = listCheckboxes;
  const [itemList, setItemList] = useState();

  const handleListCheckboxClick = (event) => {
    event.preventDefault();
    let isChange = false;
    let checkboxList = {};

    checkboxList = listCheckboxes_;
    // checkboxList = listCheckboxes;

    for (const key in checkboxList) {
      if (
        key === event.target.value &&
        checkboxList[key].isChecked != event.target.checked
      ) {
        checkboxList[key].isChecked = event.target.checked;
        isChange = true;
      }
    }

    if (isChange) {
      listCheckboxes_ = checkboxList;
      setListAndItem(checkboxList);
    }
  };

  const adminRemoveHandler = () => {
    props.adminRemoveHandler();
  };

  useEffect(() => {

    let checkboxList = {};
    if (admins != undefined) {
      if (admins.length > 0) {
        admins.forEach((admin) => {
          let isChecked = false;
          if (listCheckboxes != undefined) {
            isChecked = listCheckboxes[admin?.id]?.isChecked;
          }

          checkboxList[admin.id] = {
            value: admin.id,
            isChecked: isChecked,
            onClick: handleListCheckboxClick,
          };
        });
      }
    }

    if (listCheckboxes_ == undefined) {
      listCheckboxes_ = checkboxList;
      setListAndItem(checkboxList);
    } else if (JSON.stringify(checkboxList) != "{}") {
      listCheckboxes_ = checkboxList;
      setListAndItem(checkboxList);
    }
  }, [admins, listCheckboxes]);

  const setListAndItem = (checkboxList) => {

    if (JSON.stringify(listCheckboxes) != JSON.stringify(checkboxList)) {
      setListCheckboxes(checkboxList);
    }

    let itemList_ = [];
    if (checkboxList != undefined && admins != undefined) {
      admins.forEach((admin) => {
        const item = {
          value: admin,
          checkbox: checkboxList[admin.id],
        };

        itemList_.push(
          <PanelAdminListItem
            adminRemoveHandler={adminRemoveHandler}
            id={admin.id}
            key={admin.id}
            item={item}
          />
        );
      });

      if (itemList_ != itemList) {
        setItemList(itemList_);
      }
    }
  };

  const tableHeaders = [
    // { className: "", text: "", type: "headerCheckbox" },
    {
      className: "",
      text: t("general_status"),
      type: "header",
    },
    {
      className: "rl-table-border-l",
      text: t("general_name"),
      type: "header",
    },
    {
      className: "rl-table-border-l",
      text: t("general_email"),
      type: "header",
    },
    { className: "w-40 text-right", text: "", type: "headerRemove" },
  ];

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                <th className="">{t("general_status")}</th>
                <th className="rl-table-border-l">{t("general_name")}</th>
                <th className="rl-table-border-l">{t("general_email")}</th>
                <th className="w-40 text-right">
                  {/* <a className="rl-table-button disabled">
                    {t("general_remove")}
                  </a> */}
                </th>
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default PanelAdminList;
