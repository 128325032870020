import CongratsAdmin from "../components/Congrats/CongratsAdmin";
import CongratsOwner from "../components/Congrats/CongratsOwner";
import CongratsPurchaser from "../components/Congrats/CongratsPurchaser";
import Wrapper from "../components/UI/Wrapper";
import Helpers from "../utils/Helpers";

const CongratsPage = (props) => {
  const onboardType = Helpers.getOnboardType();
  let content = null;

  if (onboardType == "OWNER") {
    content = <CongratsOwner />;
  } else if (onboardType == "PURCHASER") {
    content = <CongratsPurchaser />;
  } else if (onboardType == "ADMIN") {
    content = <CongratsAdmin />;
  } else if (onboardType == "GUARDIAN") {
    content = <CongratsAdmin />;
  }
  // }else if(onboardType=="MEMBER") {
  //     content = (
  //         <CongratsMember />
  //     );
  // }

  return <Wrapper>{content}</Wrapper>;
};

export default CongratsPage;
