import { useEffect, useState } from "react";
import Wrapper from "../UI/Wrapper"
import useAxios from "../../hooks/use-axios";
import PanelSideBar from "./PanelSideBar/PanelSideBar";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import PanelNavigation from "./PanelNavigation";
import Card from "../UI/Card";
import Helpers from "../../utils/Helpers";
import { useTranslation } from "react-i18next";

const PanelWrapper = props => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    const paths = location.pathname.split("/");

    const [rlSideBar, setRLSideBar] = useState({
        selectedGrave: {},
        selectedGraveType: {},
        sideBar: {},
        graveList: [],
        changeGraveType: handleChangeGraveType,
        changeGrave: handleChangeGrave,
        reloadSideBar: handleReloadSideBar,
        funnelMoveForward: handleFunnelMoveForward
    })
    const [graveList, setGraaveList] = useState();

    useEffect(() => {
        getGraveList();
    }, []);

    function getGraveList() {
        const url = "grave/list";

        request({
            method: "POST",
            url: url,
        });
    }

    function getSideBar() {
        const url = "panel/sideMenu2";

        request({
            method: "GET",
            url: url,
        });
    }

    const { response, loading, error, request, responseType } = useAxios();

    useEffect(() => {
        if (response !== null && typeof response !== "undefined") {
            if (responseType == "grave/list") {

                // setGraveList(response.data);
                setRLSideBar({ ...rlSideBar, graveList: response.data })
                setGraaveList(response.data);

                getSideBar();
            } else if (responseType == "panel/sideMenu2") {

                let sidebar_ = response;

                // TODO: OCK sevis FOR_YOU ve FOR_BE_LOVED dondugunde kaldir.
                // if (sidebar_.FOR_YOU == undefined) {
                //     sidebar_.FOR_YOU = [];
                // }

                // if (sidebar_.FOR_BE_LOVED == undefined) {
                //     sidebar_.FOR_BE_LOVED = [];
                // }

                setRLSideBar({ ...rlSideBar, sideBar: sidebar_ })
            }
        }
    }, [response]);

    useEffect(() => {

        if (Object.keys(rlSideBar.sideBar).length > 0) {
            findSelectedGrave();
        }

    }, [rlSideBar.sideBar]);

    function findSelectedGrave() {

        const graveStatus = findGraveStatus2();
    }

    function findGraveStatus2() {
        var filteredGraves = {};
        if (paths.length >= 2) {
            if (paths[1] == "panel") {
                filteredGraves = hasGraveWithSubs(true);
            } else if (paths[1] == "funnel") {
                filteredGraves = hasGraveWithSubs(false);
            }

            if (filteredGraves.length > 0) {
                if (paths[2] != undefined) {
                    var filteredGraves_ = filteredGraves.filter((value) => {

                        if (value.id == paths[2]) {
                            return true;
                        }
                    })


                    if (filteredGraves_.length > 0) {
                        if (paths[3] != undefined) {

                            let _selectedGrave = Helpers.getGraveFromSideBar(rlSideBar.sideBar, paths[2])
                            let _selectedGraveType = Helpers.getGraveTypeFromSideBar(rlSideBar.sideBar, paths[2])

                            if (_selectedGraveType == "FOR_YOU") {

                                if(_selectedGrave.hasSubscription) {
                                    if (paths[3] == "testament" || paths[3] == "guardian" || paths[3] == "verse" || paths[3] == "content" || paths[3] == "diary") {

                                    } else {
                                        history.replace("/access-denied");
                                        return;
                                    }
                                } else {
                                    if (paths[3] == "testament" || paths[3] == "guardian") {

                                    } else {
                                        history.replace("/access-denied");
                                        return;
                                    }
                                }
                            } else if (_selectedGraveType == "FOR_BE_LOVED") {
                                if (paths[3] == "admin" || paths[3] == "verse" || paths[3] == "content") {
                                    
                                } else {
                                    history.replace("/access-denied");
                                }

                                if(_selectedGrave.hasSubscription) {
                                    if (paths[3] == "admin" || paths[3] == "verse" || paths[3] == "content") {

                                    } else {
                                        history.replace("/access-denied");
                                        return;
                                    }
                                } else {
                                    if (paths[3] == "admin") {

                                    } else {
                                        history.replace("/access-denied");
                                        return;
                                    }
                                }
                            } else if (_selectedGraveType == "ADMIN") {
                                if (paths[3] != "content") {
                                    history.replace("/access-denied");
                                    return;
                                }
                            } else {
                                // history.replace("/access-denied");
                            }

                            history.replace(`/${paths[1]}/${paths[2]}/${paths[3]}`);

                            
                            setRLSideBar({ ...rlSideBar, selectedGrave: _selectedGrave, selectedGraveType: _selectedGraveType })
                        } else {
                            findPage(rlSideBar, filteredGraves_[0]);
                        }

                    } else {

                    }
                } else {
                    findPage(rlSideBar, filteredGraves[0]);
                }
            }
        }
    }

    function hasGraveWithSubs(hasSubs) {
        var filter = graveList.filter((value) => {

            if (value.hasSubscription == hasSubs) {
                return true;
            }
        })

        if (Object.keys(filter).length > 0) {
            return filter;
        }
        return false;
    }

    function findPage(_rlSideBar, selectedGrave) {

        let _selectedGrave = Helpers.getGraveFromSideBar(_rlSideBar.sideBar, selectedGrave.id)
        let _selectedGraveType = Helpers.getGraveTypeFromSideBar(_rlSideBar.sideBar, _selectedGrave.id)

        const path = window.location.pathname;
        const newPath = _selectedGrave.hasSubscription ? "panel" : "funnel";

        if (!_selectedGrave.hasSubscription) {

            for (let i = 0; i < _selectedGrave.graveFunnelSteps.length; i++) {

                if (_selectedGrave.graveFunnelSteps[i].selected) {
                    if (_selectedGrave.graveFunnelSteps[i].status == "COMPLETED" || _selectedGrave.graveFunnelSteps[i].status == "IN_PROGRESS") {
                        if (path != `/${newPath}/` + _selectedGrave.id + "/" + _selectedGrave.graveFunnelSteps[i].funnelStep.stepKey.toLowerCase()) {

                            history.replace(`/${newPath}/` + _selectedGrave.id + "/" + _selectedGrave.graveFunnelSteps[i].funnelStep.stepKey.toLowerCase());

                        }
                    }
                }
            }

        } else {
            if (_selectedGraveType == "ADMIN" && paths[3] != "CONTENT") {
                history.replace(`/${newPath}/` + _selectedGrave.id + "/content");

            } else if (_selectedGraveType == "FOR_YOU" && paths[3] != "TESTAMENT") {
                history.replace(`/${newPath}/` + _selectedGrave.id + "/testament");

            } else if (_selectedGraveType == "FOR_BE_LOVED" && paths[3] != "ADMIN") {
                history.replace(`/${newPath}/` + _selectedGrave.id + "/admin");

            } else {
            }

        }

        setRLSideBar({ ..._rlSideBar, selectedGrave: _selectedGrave, selectedGraveType: _selectedGraveType, sideBar: _rlSideBar.sideBar })
    }

    function handleChangeGraveType(_rlSideBar, selectedGraveType) {

        var filteredGraves = [];

        for (let verseIndex in _rlSideBar.sideBar[selectedGraveType]) {
            for (let graveIndex in _rlSideBar.sideBar[selectedGraveType][verseIndex]?.graves) {
                console.log(_rlSideBar.sideBar[selectedGraveType][verseIndex].graves[graveIndex])

                let grave = _rlSideBar.sideBar[selectedGraveType][verseIndex].graves[graveIndex];
                filteredGraves.push(grave);
            }
        }

        // if (selectedGraveType == "ADMIN") {

        //     for (let verseIndex in _rlSideBar.sideBar["ADMIN"]) {
        //         for (let graveIndex in _rlSideBar.sideBar["ADMIN"][verseIndex]?.graves) {
        //             console.log(_rlSideBar.sideBar["ADMIN"][verseIndex].graves[graveIndex])

        //             let grave = _rlSideBar.sideBar["ADMIN"][verseIndex].graves[graveIndex];
        //             filteredGraves.push(grave);
        //         }
        //     }

        // } else {

        //     filteredGraves = _rlSideBar.graveList.filter((value) => {
        //         if (value.graveType == selectedGraveType && value.hasSubscription == true) {
        //             return true;
        //         }
        //     })

        //     if (filteredGraves.length == 0) {
        //         filteredGraves = _rlSideBar.graveList.filter((value) => {
        //             if (value.graveType == selectedGraveType && value.hasSubscription == false) {
        //                 return true;
        //             }
        //         })
        //     }

        //     // if(_rlSideBar.sideBar[selectedGraveType].length == 0) {
        //     //     return true;
        //     // }
        // }


        let _selectedGrave = Helpers.getGraveFromSideBar(_rlSideBar.sideBar, filteredGraves[0]?.id)
        let _selectedGraveType = Helpers.getGraveTypeFromSideBar(_rlSideBar.sideBar, filteredGraves[0]?.id)

        if (filteredGraves.length == 0 || _rlSideBar.sideBar[selectedGraveType].length == 0) {
            setRLSideBar({ ..._rlSideBar, selectedGraveType: selectedGraveType })
        } else {
            if (_selectedGrave != undefined) {
                findPage(_rlSideBar, _selectedGrave);
            } else {

            }
            setRLSideBar({ ..._rlSideBar, selectedGrave: _selectedGrave, selectedGraveType: _selectedGraveType })
        }
    }

    function handleChangeGrave(_rlSideBar, selectedGrave) {
        console.log(selectedGrave);

        let _selectedGrave = Helpers.getGraveFromSideBar(_rlSideBar.sideBar, selectedGrave?.id)

        if (_rlSideBar.selectedGrave.id != _selectedGrave.id) {
            findPage(_rlSideBar, _selectedGrave);
        }

    }

    function handleChangePage(page) {
        console.log(page);

        const path = window.location.pathname;
        const newPath = rlSideBar.selectedGrave.hasSubscription ? "panel" : "funnel";

        history.replace(`/${newPath}/` + rlSideBar.selectedGrave.id + "/" + page.toLowerCase());
    }

    function handleFunnelMoveForward(_rlSideBar, grave) {
        // console.log(grave);
        let _selectedGrave = Helpers.getGraveFromSideBar(_rlSideBar.sideBar, grave?.id)
        _selectedGrave.graveFunnelSteps = grave.graveFunnelSteps;
        setRLSideBar({ ..._rlSideBar, selectedGrave: _selectedGrave })
    }

    function handleReloadSideBar(_rlSideBar) {
        // setRLSideBar({ ..._rlSideBar})
        // getSideBar();
        getGraveList();
    }

    const handleHome = event => {
        event.preventDefault();

        history.replace("/");
        setTimeout(() => {
            window.location.reload(false);
        }, 0)
    }

    return (
        <Wrapper>
            {(Object.keys(rlSideBar.selectedGrave).length > 0 && Object.keys(rlSideBar.sideBar).length > 0) && (

                <Wrapper>

                    <PanelSideBar rlSideBar={rlSideBar} />

                    {/* {!rlSideBar.selectedGrave.hasSubscription && (
                        <Wrapper>
                            <div className="rl-panel-wizard-overlay"></div>
                            <a href="" onClick={handleHome} className="rl-panel-wizard-home-btn">{t("general_home")}</a>
                        </Wrapper>
                    )} */}

                    {(paths.length == 4) && (
                        <Card className={`${"rl-admin-panel-content"}`}>
                            <PanelNavigation page={paths[3]} rlSideBar={rlSideBar} handleChangePage={handleChangePage} />
                        </Card>
                    )}

                </Wrapper>
            )}
        </Wrapper>
    );

}

export default PanelWrapper;