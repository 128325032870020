import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import RLEmptyListCard from "../../UI/RLModal/RLEmptyListCard";
import Wrapper from "../../UI/Wrapper";
import PanelDiaryList from "./PanelDiaryList";
import PanelDiaryForm from "./PanelDiaryForm";
import AppContext from "../../../utils/app-context";
import PanelContentTop from "../PanelContentTop";

const PanelDiary = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const params = useParams();
  let graveId = params["graveId"];

  const [diaries, setDiaries] = useState([]);
  const [diariesFilter, setDiariesFilter] = useState([]);
  const [selectedDiariesFilter, setSelectedDiariesFilter] = useState();
  const [diariesEdit, setDiariesEdit] = useState(undefined);
  const [isEdit, setIsEdit] = useState(false);

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (graveId != undefined) {
      getDiaryList();
    }
  }, [selectedGrave]);

  useEffect(() => {

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/diary/list") {
        const loadedDiaries = [];

        const reponseDiaries = response.data.diaryList;

        if (reponseDiaries.length > 0) {
          for (const key in reponseDiaries) {
            loadedDiaries[key] = reponseDiaries[key];
          }
        }

        setDiaries(loadedDiaries);

        if (response.data.diaryDateList.length > 0 && diariesFilter.length == 0) {
          setDiariesFilter(response.data.diaryDateList)
        }

        appCtx.hideLoading();
      }
    }
  }, [response, error, reload, graveId]);

  function getDiaryList(filter = "") {

    appCtx.showLoading();

    const url = "grave/" + graveId + "/diary/list";
    if (endpoint == "first") {
      setEndpoint(url);
    }

    let data = {};

    if (filter != "") {
      data = filter
    }

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  let editDiaryId = undefined;
  const saveDiaryHandler = (diary) => {
    getDiaryList(selectedDiariesFilter);
    setDiariesEdit(undefined);
    setIsEdit(false);
    editDiaryId = undefined;
  };

  const diaryRemoveHandler = (diary) => {
    getDiaryList(selectedDiariesFilter);
  };

  const handleDiaryDateFilter = (filter) => {
    getDiaryList(filter);
    setSelectedDiariesFilter(filter);
  }

  function handleEdit(diary) {
    setDiariesEdit(diary);
    setIsEdit(true);
    editDiaryId = diary;
  }

  return (
    <Wrapper>
      <section className="rl-admin-content">

        <PanelContentTop rlSideBar={rlSideBar} />

        
        <PanelDiaryForm saveDiary={saveDiaryHandler} rlSideBar={rlSideBar} isEdit={isEdit} diariesEdit={diariesEdit} count={diaries.length} />
        
        {diaries.length > 0 ? (
          <PanelDiaryList
            diaryRemoveHandler={diaryRemoveHandler}
            handleDiaryDateFilter={handleDiaryDateFilter}
            items={diaries}
            diariesFilter={diariesFilter}
            handleEdit={handleEdit}
            rlSideBar={rlSideBar}
          />
        ) : (
          // <RLEmptyListCard />
          <div></div>
        )}
      </section>
    </Wrapper >
  );
};

export default PanelDiary;
