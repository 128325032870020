let instance;
let globalState = {
    user: [],
    sideMenu: []
};

class StateUtility {
  constructor() {
    if (instance) {
      throw new Error("New instance cannot be created!!");
    }

    instance = this;
  }

  getPropertyByName(propertyName) {
    return globalState[propertyName];
  }

  setPropertyValue(propertyName, propertyValue) {
    globalState[propertyName] = propertyValue;
  }

  getUserPropertyValue(propertyName) {
    return globalState["user"][propertyName];
  }

  getForType() {

    const selectedForTypeIndex = globalState["sideMenu"]["selectedFor"]
    if(!selectedForTypeIndex) {
        selectedForTypeIndex = 0;
    }

    return selectedForTypeIndex;
  }
}

let stateUtilityInstance = Object.freeze(new StateUtility());

export default stateUtilityInstance;