import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RLInputSelect from "../../UI/RLInputSelect";
import RLEmptyListCard from "../../UI/RLModal/RLEmptyListCard";
import Wrapper from "../../UI/Wrapper";
import PanelTestamentListItem from "./PanelTestamentListItem";

const PanelTestamentList = (props) => {
  const { t } = useTranslation();

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave
  const _testaments = props.testaments.items;

  const [testaments, setTestaments] = useState({});
  const [listFilters, setlistFilter] = useState({
    testament: null,
    mail: null,
  });


  const [itemList, setItemList] = useState("");
  useEffect(() => {

    if (_testaments != testaments) {
      setTestaments(_testaments)
    }

  }, [_testaments]);

  useEffect(() => {
    let _itemList = "";

    if (testaments != undefined) {
      if (testaments.length > 0) {
        _itemList = testaments.map((item) => (
          <PanelTestamentListItem
            id={item.id + "#" + item.name}
            key={item.id + "#" + item.name}
            item={item}
            reloadList={props.reloadList}
            rlSideBar={rlSideBar}
          />
        ));

        setItemList(_itemList);
      }
    }

  }, [testaments]);

  function testamentFilterHandler(value) {
    setlistFilter({
      testament: value,
      mail: listFilters.mail,
    });
    props.onListFilterChange({ testament: value, mail: listFilters.type });
  }

  function mailFilterHandler(value) {
    setlistFilter({
      testament: listFilters.testament,
      mail: value,
    });
    props.onListFilterChange({ testament: listFilters.testament, mail: value });
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <form
          action=""
          className="rl-bo-users-search-form rl-table-admin-bottom-filters"
        >
          <div className="column">
            <div className="column-2">

              <div className="rl-form-group">
                <div className="rl-form-group-material">

                </div>
              </div>

              <div className="rl-form-group">
                <div className="rl-form-group-material">

                </div>
              </div>

            </div>
          </div>
          <div className="column"></div>
          <div className="column"></div>
        </form>

        {testaments?.length > 0 ? (
          <div className="rl-table-outer rl-custom-scroll">
            <table className="rl-table-admin-bottom">
              <thead>
                <tr>
                  <th className="">
                    {t("testement_form_name")}
                  </th>
                  <th className="w-30 rl-table-border-l">
                    {t("testement_list_share")}
                  </th>
                  <th className="w-20 rl-table-border-l">
                    {t("general_file")}
                  </th>
                  <th className="w-40 text-right">
                  </th>
                </tr>
              </thead>
              <tbody>{itemList}</tbody>
            </table>
          </div>
        ) : (
          <RLEmptyListCard />
        )}
      </div>
    </Wrapper>
  );
};

export default PanelTestamentList;
