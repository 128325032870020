import PaymentCrateGrave from "../components/Payment/PaymentCreateGrave";
import Wrapper from "../components/UI/Wrapper";
import PanelHeader from "../components/Panel/PanelHeader";

// import "./../css-panel/style.css";

const PaymentPage = (props) => {
    return (
        <Wrapper>
            {/* <PanelHeader /> */}
            <PaymentCrateGrave />
        </Wrapper>
    );
}

export default PaymentPage;