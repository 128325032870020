import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppContext from "../../utils/app-context";
import Wrapper from "../UI/Wrapper";
import Helpers from "../../utils/Helpers";

const CongratsOwner = (props) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();

  const homepageHandler = (event) => {
    event.preventDefault();

    if (!appCtx.isLoggedIn) {
      history.replace("/");
    } else {
      // appCtx.onboardStatus = "COMPLETED";
      localStorage.setItem("onboardStatus", "COMPLETED");
      history.replace("/panel");
    }
  };

  return (
    <Wrapper>
      <div className="rl-welcome">
        <img
          src="img/icon-heart-check.svg"
          alt="TEBRİKLER"
          className="img-heart"
        />
        <h2>{t("congrats_owner_congrats")}</h2>
        <p>{t("congrats_owner_message")}</p>
        <a onClick={homepageHandler}>{Helpers.capitalizeAll(t('homepage'))}</a>
      </div>
    </Wrapper>
  );
};

export default CongratsOwner;
