import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import Helpers from "./Helpers";
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.

// const resources = {
//   en: {
//     translation: {
//       app_name: "Rememberland",
//     },
//   },
//   tr: {
//     translation: {
//       app_name: "Rememberland TR",
//     },
//   },
// };
i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    // resources,
    lng: Helpers.getLang(),
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;