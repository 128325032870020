import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import Wrapper from "../Wrapper";

import classes from "./RLModal.module.css";
import Card from "../Card";
import RLModalLogin from "./RLModalLogin";
import RLModalRegister from "./RLModalRegister";
import RLModalCreateGrave from "./RLModalCreateGrave";
import RLModalPasswordChange from "./RLModalPasswordChange";
import RLModalForgotPassword from "./RLModalForgotPassword";
import RLModalCheckEmail from "./RLModalCheckEmail";
import RLModal3DSecure from "./RLModal3DSecure";

const RLModal = (props) => {
  // console.log("RLModal " + props.type + " " + props.show);

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <Wrapper>
        {/* <div className={classes.backdrop} onClick={props.onClose} /> */}
        <Card className={classes.blocker} onClick={props.onClose}>
          <Card className={`${classes.modal} ${"rememberland-modal"}`}>
            <div className="rememberland-modal-body w-100">
              {props.type == "login" && (
                <RLModalLogin
                  onClose={props.onClose}
                  openModal={props.openModal}
                  // openOtherModal={props.openOtherModal}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {props.type == "register" && (
                <RLModalRegister
                  onClose={props.onClose}
                  openModal={props.openModal}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {props.type == "forgotPassword" && (
                <RLModalForgotPassword
                  onClose={props.onClose}
                  openModal={props.openModal}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {props.type == "checkEmail" && (
                <RLModalCheckEmail
                  onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                  onConfirm={props.onConfirm != undefined ? props.onConfirm : undefined}
                />
              )}

              {props.type == "createGrave" && (
                <RLModalCreateGrave
                  onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {props.type == "createGraveForYou" && (
                <RLModalCreateGrave
                  onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                  graveType="FOR_YOU"
                />
              )}

              {props.type == "createGraveForBeloved" && (
                <RLModalCreateGrave
                  onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                  graveType="FOR_BE_LOVED"
                />
              )}

              {props.type == "changePassword" && (
                <RLModalPasswordChange
                  onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                />
              )}

            {props.type == "3dSecure" && (
                <RLModal3DSecure
                  onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                  content={props.content}
                  graveId={props.graveId}
                />
              )}
            </div>
          </Card> 
        </Card>
      </Wrapper>
      {/* </div> */}
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default RLModal;
