import Wrapper from "../components/UI/Wrapper";

var Helpers = {
  capitalizeFirst: function (str) {
    return str.charAt(0).toLocaleUpperCase(this.getLang()) + str.slice(1).toLocaleLowerCase(this.getLang());
  },

  capitalizeAllFirst: function (str) {
    const words = str.split(" ");
    var sentence = "";

    if (str != "") {
      sentence = words
        .map((word) => {
          // console.log(word[0].toUpperCase() + word.substring(1));

          return word.charAt(0).toLocaleUpperCase(this.getLang()) + word.substring(1);
        })
        .join(" ");
    }
    return sentence;
  },

  capitalizeAll: function (str) {
    return str.toLocaleUpperCase(this.getLang());
  },

  formatDate(date) {
    let dateStr = "";
    const date_ = new Date(date);
    const day = date_.getDate();
    const month = date_.getMonth() + 1;
    const year = date_.getFullYear();
    dateStr = day + "." + month + "." + year;

    return dateStr;
  },

  formatDateMY(date) {
    let dateStr = "";
    const date_ = new Date(date);
    // const day = date_.getDate();

    let locale = "en-EN";
    const lang = this.getLang()
    if (lang == "tr") {
      locale = "tr-TR"
    }

    const month = date_.toLocaleString(locale, { month: "long" });
    const year = date_.getFullYear();
    dateStr = month + " " + year;

    return dateStr;
  },

  formatDateMD(date) {
    let dateStr = "";
    const date_ = new Date(date);
    // const day = date_.getDate();

    let locale = "en-EN";
    const lang = this.getLang()
    if (lang == "tr") {
      locale = "tr-TR"
    }

    const month = date_.toLocaleString(locale, { month: "long" });
    const day = date_.getDate();

    dateStr = month + " " + day;
    if (this.getLang() == "tr") {
      dateStr = day + " " + month;
    } else if (this.getLang() == "en") {
      dateStr = month + " " + day + this.nth(day);
    }

    return dateStr;
  },

  nth(d) {
    const dString = String(d);
    const last = +dString.slice(-2);
    if (last > 3 && last < 21) return 'th';
    switch (last % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  },

  minutesBetweenDates(date1, date2 = new Date()) {
    // Convert both dates to milliseconds
    const date1Ms = new Date(date1);
    const date2Ms = new Date(date2);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date2Ms - date1Ms);

    // Convert back to minutes and return
    console.log(Math.ceil(differenceMs / (1000 * 60)))
    return Math.ceil(differenceMs / (1000 * 60));
  },

  hasRole: function (userRole, roles) {
    let hasRole = false;
    if (roles != undefined) {
      const foundedRole = roles.find((role) => role == userRole);

      if (foundedRole) {
        hasRole = true;
      }
    }

    return hasRole;
  },

  hasGraveRole: function (userRole, roles) {
    let hasRole = false;
    if (roles != undefined) {
      const foundedRole = roles.find((role) => role == userRole);

      if (foundedRole) {
        hasRole = true;
      }
    }

    return hasRole;
  },

  getUser: function () {
    return JSON.parse(localStorage.getItem("user")) ?? {};
  },
  setUser: function (data) {
    let user = this.getUser();
    user = { ...user, ...data };
    localStorage.setItem("user", JSON.stringify(user));

    return this.getUser();
  },
  
  setProfile: function (data) {
    localStorage.setItem("onboardStatus", data.onboardStatus);
    localStorage.setItem("onboardType", data.onboardType);

    localStorage.setItem("user", JSON.stringify(data));

    const updatedIsAdmin = data.roles.includes("ROLE_ADMIN");
    localStorage.setItem("isAdmin", updatedIsAdmin);
  },

  getUserGraveRoles: function () {
    let user = this.getUser()
    return user.graveRoles;
  },

  getOnboardStatus: function () {
    return localStorage.getItem("onboardStatus");
  },
  setOnboardStatus: function (onboardStatus) {
    localStorage.setItem("onboardStatus", onboardStatus);
    return this.getOnboardStatus();
  },
  getOnboardType: function () {
    return localStorage.getItem("onboardType");
  },
  setOnboardType: function (onboardType) {
    localStorage.setItem("onboardType", onboardType);
    return this.getOnboardType();
  },

  removeStorage: function () {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("onboardStatus");
    localStorage.removeItem("onboardType");
    localStorage.removeItem("user");
    localStorage.removeItem("topMenu");
    localStorage.removeItem("graveType");
    localStorage.removeItem("graveStatus");
    localStorage.removeItem("hasFunnel");
    // localStorage.removeItem("testamentStep");
  },

  setTopMenu: function (value) {
    localStorage.setItem("topMenu", JSON.stringify(value));
  },
  getTopMenu: function () {
    return JSON.parse(localStorage.getItem("topMenu")) ?? {};
  },
  findTopMenu: function (type) {
    const topMenu = JSON.parse(localStorage.getItem("topMenu")) ?? undefined;
    if (topMenu != undefined) {
      return topMenu.includes(type);
    }
  },

  setGraveType: function (value) {
    localStorage.setItem("graveType", JSON.stringify(value));
  },
  getGraveType: function () {
    if (localStorage.getItem("graveType") != 'undefined') {
      return JSON.parse(localStorage.getItem("graveType")) ?? {};
    }

  },
  getNormalizedGraveType: function (value) {

    let graveTypeVerse = {}

    if(value.includes("_")) {
      graveTypeVerse = {
        "FOR_YOU": "forYouVerses",
        "FOR_BE_LOVED": "forBeLovedVerses",
        "ADMIN": "adminVerses",
        "GUARDIAN": "guardianVerses",
      }
    } else {
      graveTypeVerse = {
        "forYouVerses": "FOR_YOU",
        "forBeLovedVerses": "FOR_BE_LOVED",
        "adminVerses": "ADMIN",
        "guardianVerses": "GUARDIAN",
      }
    }

    return graveTypeVerse[value];
  },
  getGraveTypeFromSideBar: function (sideBar, graveId) {
    for (let graveType in sideBar) {
      for (let verseIndex in sideBar[graveType]) {
        for (let graveIndex in sideBar[graveType][verseIndex]?.graves) {
          if (sideBar[graveType][verseIndex].graves[graveIndex].id == graveId) {
            return graveType;
          }
        }
      }
    }

    return "";
  },
  getGraveFromSideBar: function (sideBar, graveId) {
    for (let graveType in sideBar) {
      for (let verseIndex in sideBar[graveType]) {
        for (let graveIndex in sideBar[graveType][verseIndex]?.graves) {
          if (sideBar[graveType][verseIndex].graves[graveIndex].id == graveId) {
            return sideBar[graveType][verseIndex].graves[graveIndex];
          }
        }
      }
    }

    return {};
  },
  hasSubscriptedGraveFromSideBar: function (sideBar) {
    for (let graveType in sideBar) {
      for (let verseIndex in sideBar[graveType]) {
        for (let graveIndex in sideBar[graveType][verseIndex]?.graves) {
          if (sideBar[graveType][verseIndex].graves[graveIndex].hasSubscription) {
            return true;
          }
        }
      }
    }

    return false;
  },

  setGraveStatus: function (value) {
    localStorage.setItem("graveStatus", JSON.stringify(value));
  },
  getGraveStatus: function () {
    return JSON.parse(localStorage.getItem("graveStatus")) ?? {};
  },

  setHasFunnelThroughGraveList: function (graveList) {

    let hasFunnel = {};
    for (let graveIndex in graveList) {
      if (!graveList[graveIndex].hasSubscription) {
        hasFunnel[graveList[graveIndex].graveType] = true;
      }
    }

    this.setHasFunnel(hasFunnel);
  },
  setHasFunnel: function (hasFunnel) {
    localStorage.setItem("hasFunnel", JSON.stringify(hasFunnel));
    // localStorage.setItem("hasFunnel", hasFunnel);
  },
  getHasFunnel: function () {
    return JSON.parse(localStorage.getItem("hasFunnel")) ?? {};
  },
  getHasFunnelThroughType: function (graveType = "") {

    let hasFunnel = JSON.parse(localStorage.getItem("hasFunnel")) ?? {}

    let _graveType = graveType;
    if (_graveType == "") {
      _graveType = this.getGraveType();
    }

    if (hasFunnel[_graveType] == undefined) {
      return false;
    } else {
      return hasFunnel[graveType];
    }

  },
  setTestamentStep: function (value) {

    let testamentStep = JSON.parse(localStorage.getItem("testamentStep")) ?? {};

    let userId = Helpers.getUser().id;

    if(Object.keys(testamentStep).length == 0) {
      var obj = {userId:{}}
      obj[userId] = value;
      localStorage.setItem("testamentStep", JSON.stringify(obj));
    } else {
      let userId = this.getUser().id;
      // return (testamentStep[userId] ?? {});
      if(!testamentStep.hasOwnProperty(userId)) {
        testamentStep[userId] = value
      } else {
  
        Object.assign(testamentStep[userId], value);
      }

      localStorage.setItem("testamentStep", JSON.stringify(testamentStep));

    }

    
  },
  getTestamentStep: function () {

    let testamentStep = JSON.parse(localStorage.getItem("testamentStep")) ?? {};
    if(Object.keys(testamentStep).length == 0) {
      return {};
    } else {
      let userId = this.getUser().id;
      return (testamentStep[userId] ?? {});
    }
    
  },
  removeTesamentStep: function (selectedGraveId) {
    let testamentStep = this.getTestamentStep();

    let userId = Helpers.getUser().id;
    let testamentStep_ = JSON.parse(localStorage.getItem("testamentStep")) ?? {};

    if (testamentStep[selectedGraveId] != undefined) {
      delete testamentStep[selectedGraveId];
      testamentStep_[userId] = testamentStep;
      localStorage.setItem("testamentStep", JSON.stringify(testamentStep_));
    }
  },

  // setFunnel: function (value) {
  //   localStorage.setItem("funnel", JSON.stringify(value));
  // },
  // getFunnel: function () {
  //   return JSON.parse(localStorage.getItem("funnel")) ?? false;
  // },
  isFunnel: function (location) {

    let isFunnel = false;

    if (location != undefined) {
      const paths = location.pathname.split("/");
      if (paths[1] == "panel") {
        isFunnel = false;
      } else if (paths[1] == "funnel") {
        isFunnel = true;
      }
    }

    return isFunnel;
  },

  // setFunnelStep: function (value) {
  //   localStorage.setItem("funnelStep", value);
  // },
  // getFunnelStep: function () {
  //   return localStorage.getItem("funnelStep") ?? {};
  // },

  passwordVerification: function (value, t) {

    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*"'+/()=_<>|£½{}[\]\\€`:;~¨.,-])/;

    const minLengthRegExp = /.{6,}/;
    const passwordLength = value.length;
    const uppercasePassword = uppercaseRegExp.test(value);
    const lowercasePassword = lowercaseRegExp.test(value);
    const digitsPassword = digitsRegExp.test(value);
    const specialCharPassword = specialCharRegExp.test(value);
    const minLengthPassword = minLengthRegExp.test(value);
    let errMsg = "";
    if (passwordLength === 0) {
      errMsg = t("form_validation_notempty");
    } else if (!uppercasePassword) {
      errMsg = t("validation_password_uppercase");
    } else if (!lowercasePassword) {
      errMsg = t("validation_password_lowercase");
    } else if (!digitsPassword) {
      errMsg = t("validation_password_digit");
    } else if (!specialCharPassword) {
      errMsg = t("validation_password_specialchar");
    } else if (!minLengthPassword) {
      errMsg = t("validation_password_mineight");
    } else {
      errMsg = "";
    }

    return errMsg;
  },

  creditCardVerification: function (value, t) {
    const creditCardRegex = /^\d{14,}$/;

    const isValid = creditCardRegex.test(value);
    let errMsg = "";

    if (!isValid) {
      errMsg = t("validation_creditcard_number");
    }

    return errMsg;
  },

  monthVerification: function (value, t) {
    const monthRegex = /^(0[1-9]|1[0-2])$/; // 01, 02, ... 11, 12

    const isValid = monthRegex.test(value);
    let errMsg = "";

    if (!isValid) {
      errMsg = t("validation_creditcard_month");
    }

    return errMsg;
  },

  cvcVerification: function (value, t) {
    const cvcRegex = /^\d{3,4}$/; // 01, 02, ... 11, 12

    const isValid = cvcRegex.test(value);
    let errMsg = "";

    if (!isValid) {
      errMsg = t("validation_creditcard_cvc");
    }

    return errMsg;
  },

  setLang: function (value) {
    const lang = this.getLang();

    if (lang !== value) {
      localStorage.setItem("lang", JSON.stringify(value));
    }
  },
  getLang: function () {
    let lang = JSON.parse(localStorage.getItem("lang")) ?? {};

    if (Object.keys(lang).length === 0) {
      lang = "en";
      localStorage.setItem("lang", JSON.stringify(lang));
    }

    return lang;
  },

  getNow: function () {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = dd + "." + mm + "." + yyyy;
    return today;
  },

  formatCreditCardNumber(creditCard, type = "") {
    let content = "";

    if (creditCard.binNumber && creditCard.lastFourDigits) {
      if (type === "span") {
        content = (
          <Wrapper>
            <span>{creditCard.binNumber.slice(0, 4)}</span> <span>● ● ● ●</span>{" "}
            <span>● ● ● ●</span> <span>{creditCard.lastFourDigits}</span>
          </Wrapper>
        );
      } else if (type === "div") {
        content = (
          <Wrapper>
            <div className="no">{creditCard.binNumber.slice(0, 4)}</div>
            <div>● ● ● ●</div> <div>● ● ● ●</div>
            <div className="no">{creditCard.lastFourDigits}</div>
          </Wrapper>
        );
      } else {
        content =
          creditCard.binNumber.slice(0, 4) +
          " **** **** " +
          creditCard.lastFourDigits;
      }
    }

    return content;
  },

  getFileIcon(type) {
    if (type == "TEXT" || type == "text/plain" || type == "application/pdf") {
      return (
        <div className="rl-content-icon text">
          <img src="/img/icon-type.svg" alt="text" />
        </div>
      );
    } else if (type == "IMAGE" || type == "image/png" || type == "image/jpeg") {
      return (
        <div className="rl-content-icon">
          <img src="/img/icon-file-img.png" alt="text" />
        </div>
      );
    } else if (type == "AUDIO" || type == "audio/mpeg") {
      return (
        <div className="rl-content-icon music">
          <img src="/img/icon-music-note.svg" alt="music" />
        </div>
      );
    } else if (type == "VIDEO" || type == "video/mp4" || type == "LINK") {
      return (
        <div className="rl-content-icon video">
          <img src="/img/icon-play-circle.svg" alt="video" />
        </div>
      );
    } else {
      return (
        // <div className="rl-content-icon">
        //   <img src="/img/icon-file-02.svg" alt="file" />
        // </div>
        <div className="rl-content-icon text">
          <img src="/img/icon-type.svg" alt="text" />
        </div>
      );
    }
  },

  hashCode(str) {
    var hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },

  fileHashCode(file) {
    const str = file.name + "#" + file.timeStamp;
    return this.hashCode(str);
  },

  removeBackSlashes(str) {
    return str.replace(/\n/gi, " ");
  }
};

export default Helpers;
