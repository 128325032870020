import PanelPaymentHeader from "../components/PanelPayment/PanelPaymentHeader";
import PanelPaymentMain from "../components/PanelPayment/PanelPaymentMain";
import Wrapper from "../components/UI/Wrapper";

const PanelPaymentPage = (props) => {
  return (
    <Wrapper>
      <body className="bg-white">
        <PanelPaymentHeader />
        <PanelPaymentMain />
      </body>
    </Wrapper>
  );
};

export default PanelPaymentPage;
