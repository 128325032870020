import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper";
import PanelSubsListItem from "./PanelSubsListItem";

const PanelSubsList = (props) => {
  const { t } = useTranslation();

  const subsList = props.items
  const paymentMethod = props.paymentMethod
  const graveId = props.graveId

  let itemList = "";
  if (subsList.length > 0 && paymentMethod) {
    let first = true;
    itemList = subsList.map((item) => (
      <PanelSubsListItem
        id={item.id}
        key={item.id}
        item={item}
        graveId={graveId}
        paymentMethod={paymentMethod}
      />
    ));
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                {/* <th>
                  <input type="checkbox" className="form-checkbox" />
                </th> */}
                <th className="">{t("panelSub_list_plan")}</th>
                <th className="rl-table-border-l">
                  {t("general_status")}
                </th>
                <th className="w-20 rl-table-border-l">
                  {t("panelSub_list_price")}
                </th>
                <th className="rl-table-border-l">
                  {t("panelSub_list_paymentDue")}
                </th>
                <th className="rl-table-border-l">{t("general_card")}</th>
                {/* <th className="w-40 text-right">
                  <a
                    href=""
                    className="rl-table-download-btn disabled"
                  >
                    <img
                      src="/img/icon-download.svg"
                      alt="Download"
                      width="20"
                      height="20"
                    />{" "}
                    {t("general_download")}
                  </a>
                </th> */}
              </tr>
            </thead>
            <tbody>
                { itemList }
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default PanelSubsList;
