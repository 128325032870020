import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLButton from "../RLButton";
import RLInputText from "../RLInputText";
import Wrapper from "../Wrapper";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";

const RLModalRegister = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const appCtx = useContext(AppContext);

  const modal = props.modal;
  const params = modal.params;

  const [name, setName] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleNameChange,
  });
  const [surname, setSurname] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleSurnameChange,
  });
  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "email"],
    onChange: handleEmailChange,
  });
  const [password, setPassword] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handlePasswordChange,
  });
  const [passwordConfirm, setPasswordConfirm] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handlePasswordConfirmChange,
  });
  const [agreement, setAgreement] = useState({
    value: false,
    isValid: true,
    validation: ["empty"],
    onChange: handleAgreementChange,
  });

  const [isPasswordsMatch, setIsPasswordsMatch] = useState(true);

  function handleNameChange(value, isValid = false) {
    setName({ ...name, value: value, isValid: isValid });
  }

  function handleSurnameChange(value, isValid = false) {
    setSurname({ ...surname, value: value, isValid: isValid });
  }

  function handleEmailChange(value, isValid = false) {
    setEmail({ ...email, value: value, isValid: isValid });
  }

  function handlePasswordChange(value, isValid = false) {
    setPassword({ ...password, value: value, isValid: isValid });
  }

  function handlePasswordConfirmChange(value, isValid = false) {
    setPasswordConfirm({ ...passwordConfirm, value: value, isValid: isValid });
  }

  function handleAgreementChange() {
    setAgreement({
      ...agreement,
      value: !agreement.value,
      isValid: !agreement.value,
    });
  }

  function isValidForm() {
    let isValidFormName = name.isValid;
    if (isValidFormName && name.validation.includes("empty")) {
      isValidFormName = name.value == "" ? false : true;
      setName({ ...name, isValid: isValidFormName });
    }

    let isValidFormSurname = surname.isValid;
    if (isValidFormSurname && surname.validation.includes("empty")) {
      isValidFormSurname = surname.value == "" ? false : true;
      setSurname({ ...surname, isValid: isValidFormSurname });
    }

    let isValidFormEmail = email.isValid;
    if (isValidFormEmail && email.validation.includes("empty")) {
      isValidFormEmail = email.value == "" ? false : true;
      setEmail({ ...email, isValid: isValidFormEmail });
    }

    let isValidFormPassword = password.isValid;
    if (isValidFormPassword && password.validation.includes("empty")) {
      isValidFormPassword = password.value == "" ? false : true;
      setPassword({ ...password, isValid: isValidFormPassword });
    }

    let isValidFormPasswordConfirm = passwordConfirm.isValid;
    if (
      isValidFormPasswordConfirm &&
      passwordConfirm.validation.includes("empty")
    ) {
      isValidFormPasswordConfirm = passwordConfirm.value == "" ? false : true;
      setPasswordConfirm({
        ...passwordConfirm,
        isValid: isValidFormPasswordConfirm,
      });
    }

    let isPasswordsMatch_ = isPasswordsMatch;
    if (isValidFormPassword && isValidFormPasswordConfirm) {
      if (password.value === passwordConfirm.value) {
        isPasswordsMatch_ = true;
      } else {
        isPasswordsMatch_ = false;
      }
    }

    let isValidFormAggreement = agreement.isValid;
    if (isValidFormAggreement && agreement.validation.includes("empty")) {
      isValidFormAggreement = agreement.value === false ? false : true;
      setAgreement({ ...agreement, isValid: isValidFormAggreement });
    }

    return (
      isValidFormName &&
      isValidFormSurname &&
      isValidFormEmail &&
      isValidFormPassword &&
      isValidFormPasswordConfirm &&
      isPasswordsMatch_ &&
      isValidFormAggreement
    );
  }

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {
    
    if (response == undefined && error == "") {
      if (password.isValid && passwordConfirm.isValid) {
        if (password.value === passwordConfirm.value) {
          setIsPasswordsMatch(true);
        } else {
          setIsPasswordsMatch(false);
        }
      }
    }

    if (response !== null && typeof response !== "undefined") {
      // let event = { target: "event", currentTarget: "event" };

      if (responseType == "auth/loginWithFacebook" ||
        responseType == "auth/loginWithGoogle" || 
        responseType == "auth/loginWithAppleId") {

        let token = response.data.token;
        let profile = response.data.profile;
        // appCtx.setUser(response.data.user, response.data.roles);

        if (profile.roles.includes("ROLE_ADMIN")) {
          appCtx.hideLoading();
          modal.onClose();
        } else {
          appCtx.login(token, profile);

          appCtx.hideLoading();

          if (profile.onboardStatus == "WAITING") {
            params.onLoginSuccess("/payment");
            modal.onClose();
          } else if (profile.onboardStatus == "COMPLETED") {
            appCtx.hideLoading();
            getGraveList(token);
            // modal.onClose();
          }
        }

        if (profile.locale === null) {
          const lang = Helpers.getLang();
          i18n.changeLanguage(lang);
          postProfileUpdateLocale(lang, response.data.token);
        } else {
          let lang = "";
          if (profile.locale === "en" || profile.locale === "EN") {
            lang = "en";
          } else if (profile.locale === "tr" || profile.locale === "TR") {
            lang = "tr";
          }

          i18n.changeLanguage(lang);
          Helpers.setLang(lang);
        }

      } else if (responseType == "auth/register") {
        modal.onClose();
        appCtx.hideLoading();

        const checkMailModal = {
          type: "checkEmail",
          show: true,
        };
        appCtx.showModal(checkMailModal);

        const path = window.location.pathname;
        window.history.pushState(null, `${path}`, `${path}success`)

      } else if (responseType == "grave/list") {

        if (response.data.length > 0) {

          for (var grave in response.data) {
            if (response.data[grave].hasSubscription) {
              params.onLoginSuccess("/panel");
              modal.onClose();
              return
            }
          }

          postGraveListFunnelGraves();

          appCtx.hideLoading();
        } else {

          modal.onClose();
          appCtx.hideLoading();
        }

      } else if (responseType == "grave/list-funnel-graves") {

        const onboardStatus = Helpers.getOnboardStatus();

        if (response.data.length == 0) {
          if (onboardStatus == "WAITING") {
            params.onLoginSuccess("/payment");
            modal.onClose();

          } else if (onboardStatus == "COMPLETED") {
            params.onLoginSuccess("/panel");
            modal.onClose();
          }
        } else if (response.data.length == 1) {
          params.onLoginSuccess("/funnel/" + response.data[0].id);
          modal.onClose();

        } else if (response.data.length == 2) {

          params.onLoginSuccess("", true, response.data);
          // modal.onClose();
        }
      }
    }
  }, [response, error, password, passwordConfirm]);

  function getGraveList(token) {
    appCtx.showLoading();
    const url = "grave/list";
    const data = {};

    request({
      method: "POST",
      url: url,
      headers: {
        Authorization: "Bearer " + token,
      },
    },
      () => { },
      false
    );
  }

  function postGraveListFunnelGraves() {

    appCtx.showLoading();

    const url = "grave/list-funnel-graves";

    const data = {};

    request(
      {
        method: "POST",
        url: url,
        data,
      },
    );
  }

  const registerHandler = async (event) => {
    event.preventDefault();

    if (isValidForm() && agreement) {
      const data = {
        email: email.value,
        name: name.value,
        surname: surname.value,
        password: password.value,
        onboardType: "USER",
      };

      appCtx.showLoading();

      request(
        {
          method: "POST",
          url: "auth/register",
          data,
        },
        () => { },
        false
      );
    }
  };

  const handleClose = (event) => {
    event.preventDefault();
    modal.onClose();
  };

  const handleLogin = (event) => {
    modal.onClose();
    const verseDetailModal = {
      type: "login",
      show: true,
      params: {
        onLoginSuccess: params.onLoginSuccess,
      },
    };
    appCtx.showModal(verseDetailModal);
  };

  const responseFacebook = (response) => {
    console.log("responseFacebook => " + JSON.stringify(response));

    if (response.status == "unknown") {
      appCtx.hideLoading();
    }

    if (response.accessToken) {
      const token = response.accessToken;

      const data = {
        access_token: token,
      };
      const url = "auth/loginWithFacebook";

      request(
        {
          method: "POST",
          url: url,
          data,
        },
        () => { },
        false
      );
    }
  };

  const responseApple = (response) => {
    // event.preventDefault();
    console.log("responseApple => " + JSON.stringify(response));

    if (response.error != undefined) {
      appCtx.hideLoading();
      return;
    }

    const data = {
      code: response.authorization.code,
      idToken: response.authorization.id_token,
      firstName: response.user == undefined ? null : response.user.name.firstName,
      lastName: response.user == undefined ? null : response.user.name.lastName,
      email: response.user == undefined ? null : response.user.email,
    };
    const url = "auth/loginWithAppleId";

    request(
      {
        method: "POST",
        url: url,
        data,
      },
      () => { },
      false
    );
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      if (tokenResponse.access_token) {
        const token = tokenResponse.access_token;

        const data = {
          access_token: token,
        };
        const url = "auth/loginWithGoogle";

        request(
          {
            method: "POST",
            url: url,
            data,
          },
          () => { },
          false
        );
      }
    },
    onError: (tokenResponse) => {
      console.log(tokenResponse);
      appCtx.hideLoading();
    },
    onNonOAuthError: (tokenResponse) => {
      appCtx.hideLoading();
    },
  });

  function postProfileUpdateLocale(lang, token) {
    const url = "profile/update";
    // setEndpoint(url);

    const data = {
      locale: lang,
    };

    request(
      {
        method: "POST",
        url: url,
        data,
        headers: {
          Authorization: "Bearer " + token,
        },
      },
      () => { },
      false
    );
  }

  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">
          {Helpers.capitalizeFirst(t("register_title"))}
        </h1>
        <div className="modal-header-desc">{t("register_description")}</div>
      </div>
      <form
        onSubmit={registerHandler}
        className="sign-in-up-form"
      >
        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_name"))} data={name} />
          </div>
        </div>
        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_surname"))} data={surname} />
          </div>
        </div>

        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_email"))} data={email} autoComplete={false} />
          </div>
        </div>

        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText
              inputType="text"
              inputFeature="password"
              label={t("password")}
              data={password}
              autoComplete={false}
            />
          </div>
        </div>

        <div className="rl-form-group">
          <div className="rl-form-group-material error">
            <RLInputText
              inputType="text"
              inputFeature="password"
              label={t("password")}
              data={passwordConfirm}
              autoComplete={false}
            />
          </div>
          {!isPasswordsMatch && (
            <div className="error-line">
              <img
                src="img/icon-circle-info.svg"
                alt="Wrong Password"
                width="24"
              />{" "}
              {Helpers.capitalizeAllFirst(t("password_error"))}
            </div>
          )}
        </div>

        <div className="rl-form-remember-forgot">
          <div className="left form-checkbox-outer">
            <label htmlFor="14">
              <input
                onChange={handleAgreementChange}
                type="checkbox"
                id="14"
                className="form-checkbox"
              />
              <a
                href={t("register_agreement_doc")}
                target="_blank"
                rel="noreferrer"
                style={{ color: "black" }}
              >
                {t("register_agree_terms")}
              </a>
            </label>
          </div>

          <div className="right">
            <div className="rl-form-group">
              {!agreement.isValid && (
                <div className="error-line">
                  <img
                    src="img/icon-circle-info.svg"
                    alt="must not empty"
                    width="24"
                  />{" "}
                  {Helpers.capitalizeAllFirst(t("register_agree_error"))}
                </div>
              )}
            </div>
          </div>
        </div>

        <button className="rl-form-button">{t("register")}</button>

        <div className="rl-form-or">
          <span>{t("general_or")}</span>
        </div>

        <div className="rl-form-social-btns" style={{ marginBottom: "8px" }}>
          {/* <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            fields="name,first_name,last_name,email,picture"
            scope="public_profile,email"
            // autoLoad
            isMobile={false}
            redirectUri={process.env.REACT_APP_URL}
            disableMobileRedirect={true}
            callback={responseFacebook}
            render={(renderProps) => (
              <a
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  appCtx.showLoading();
                  renderProps.onClick();
                }}
                className="rl-form-social-btns-facebook"
                title="Continue with Facebook"
              >
                <img src="img/icon-facebook-btn.svg" alt="Google" />
                {t("login_social_facebook")}
              </a>
            )}
          /> */}

          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              appCtx.showLoading();
              handleGoogleLogin();
            }}
            className="rl-form-social-btns-google"
            title="Continue with Google"
          >
            <img src="img/icon-google-btn.png" alt="Google" />
            {t("login_social_google")}
          </a>
        </div>

        {/* <div className="rl-form-social-btns" style={{ display: "flex", justifyContent: "center" }}>
              <AppleLogin clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
                redirectURI={process.env.REACT_APP_URL}
                usePopup={true}
                responseMode="form_post"
                responseType="code id_token"
                scope="name email"
                callback={responseApple}
                render={(renderProps) => (
                  <a
                    href=""
                    onClick={(event) => {
                      event.preventDefault();
                      appCtx.showLoading();
                      renderProps.onClick();
                    }}
                    className="rl-form-social-btns-apple"
                    title=""
                  >
                    <img src="img/icon-apple-btn.svg" alt="Google" />
                    {t("login_social_apple")}
                  </a>
                )}
              />
            </div> */}

        <div className="rl-sign-up-or-in">
          {t("register_signin")}{" "}
          <RLButton
            text={Helpers.capitalizeAll(t("signin"))}
            onClick={handleLogin}
            buttonType="a"
          />
        </div>
      </form>

      <a href="" className={props.closeClassName} onClick={handleClose}>
        x
      </a>
    </Wrapper>
  );
};

export default RLModalRegister;
