import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import RLInputSelect from "../../UI/RLInputSelect";
import Wrapper from "../../UI/Wrapper";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const PanelVerseListItem = (props) => {
  const appCtx = useContext(AppContext);
  const params = useParams();
  const { t } = useTranslation();
  const location = useLocation();

  const rlSideBar = props.rlSideBar
  const graveVerse = props.graveVerse;

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {
    if (error) {
    }
    if (response !== null && typeof response !== "undefined") {
      //   setData(response);

      if (
        responseType ==
        "grave/" + params["graveId"] + "/verse/delete"
      ) {
        props.reloadList();
      } else if (
        responseType ==
        "grave/" + params["graveId"] + "/verse/change"
      ) {
        props.reloadList();
        appCtx.addToast(t("verse_list_item_changed"), "green");
      }
    }
  }, [response, error]);

  const handleChange = (event) => {
    event.preventDefault();

    const verseDetailModal = {
      type: "verseChange",
      show: true,
      params: {
        graveId: params["graveId"],
        verseId: graveVerse.verseId,
        reloadList: props.reloadList,
        verses: props.verses,
        location: location,
        varseName: graveVerse.verse.name,
        rlSideBar: rlSideBar
      },
      className: 'rl-modal-change-verse',
      
    };
    appCtx.showModal(verseDetailModal);
  };

  const handleRemove = event => {
    event.preventDefault();

    const verseDetailModal = {
      type: "confirmationModal",
      show: true,
      params: {
        title: t("confirmation_remove_title"),
        description: t("confirmation_remove_desc"),
        buttonName: "Remove"
      },
      onConfirm: handleRemoveConfirm
    };
    appCtx.showModal(verseDetailModal);
  }

  const postRemove = (event) => {
    event.preventDefault();

     let data = [graveVerse.id];

    request({
      method: "DELETE",
      url: "grave/" + params["graveId"] + "/verse/delete",
      data: data,
    });
  };

  const handleRemoveConfirm = event => {
    postRemove(event);
  }
  
  const percent =
    (
      (graveVerse.displayedMemberCount * 100) /
      graveVerse.verse.capacity
    ).toString() + "%";

  return (
    <Wrapper>
      <tr>
        <td className="fw-600">{t("general_enum_"+graveVerse.verse.verseStatus)}</td>

        <td className="rl-table-border-l w-30 fw-600">
          {graveVerse.verse.name}
        </td>

        <td className="w-30 rl-table-border-l">
          <div className="rl-progress-bar">
            <div className="rl-progress-bar-fill" style={{width: percent, backgroundColor: graveVerse.verse.color}}></div>
          </div>
        </td>

        <td className="w-40 text-right">
          {/* <a href="" className="rl-table-button blue" onClick={handleChange}>
            {t("verse_list_item_change")}
          </a> */}

          <a href="" className="rl-table-button red" onClick={handleRemove}>
            {t("general_remove")}
          </a>
        </td>
      </tr>
    </Wrapper>
  );
};

export default PanelVerseListItem;
