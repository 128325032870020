import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";

const PanelSideBarGraveListItem = (props) => {
  // const appCtx = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const grave = props.grave;
  const rlSideBar = props.rlSideBar;

  const handleGraveClick = (event) => {
    event.preventDefault();

    // if(!rlSideBar.isAccount){
      rlSideBar.changeGrave(rlSideBar, grave);
    // }
    
    appCtx.setMobileSideBar(false)
  }

  return (
    <Wrapper>
      <li>
        <a className={(grave.id == rlSideBar.selectedGrave.id && !rlSideBar.isAccount) ? "active" : ""} onClick={handleGraveClick}>
          {grave.name}
          {grave.graveStatus == "HELD" && (
            <img
              src="/img/icon-circle-info.svg"
              alt={Helpers.capitalizeAllFirst(t("general_email_error"))}
              width="24"
            />
          )}
        </a>
      </li>
    </Wrapper>
  );
};

export default PanelSideBarGraveListItem;
