import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useAxios from "../../hooks/use-axios";
import { useContext, useEffect } from "react";
import AppContext from "../../utils/app-context";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const Guardians = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const history = useHistory();
    const location = useLocation();

  const handleLoginSuccess = (url) => {
    history.replace(url);
  }
  const handleLogin = event => {

      event.preventDefault();

      const verseDetailModal = {
          type: "register",
          show: true,
          params: {
              onLoginSuccess: handleLoginSuccess
          }
      };
      appCtx.showModal(verseDetailModal);
  }

function handleCreateGrave(event, type) {

    if (event != "") {
        event.preventDefault();
    }

    const checkMailModal = {
        type: "createGrave",
        show: true,
        params: { graveType: type, location: location, history: history },
    };
    appCtx.showModal(checkMailModal);
}

  const handleStartLife = (event) => {

    if (event != "") {
        event.preventDefault();
    }

    const type = "FOR_YOU"

    if (type == "FOR_YOU" || type == "FOR_BE_LOVED") {
        if (appCtx.isLoggedIn) {

            postGraveListFunnelGraves();
        } else {
            handleLogin(event)
        }
    }
}

const { response, loading, error, request, responseType } = useAxios();
    useEffect(() => {
        if (response !== null && typeof response !== "undefined") {
            if (responseType == "grave/list-funnel-graves") {
                if (response.data.length == 0) {
                    handleCreateGrave("", "FOR_YOU")
                } else {
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].graveType == "FOR_YOU") {
                            history.replace("/funnel/" + response.data[i].id);
                            appCtx.hideLoading();
                            return;
                        }
                    }

                    handleCreateGrave("", "FOR_YOU")
                }

                appCtx.hideLoading();
            }
        }
    }, [response]);

    function postGraveListFunnelGraves() {
        appCtx.showLoading();
        const url = "grave/list-funnel-graves";

        const data = {};

        request(
            {
                method: "POST",
                url: url,
                data,
            },
        );
    }

  return (
    <section className="rememberland-landing-guardians" id="rememberland-landing-guardians">
      {/* <div className="line-1">{t("landing_guardians_line1").toUpperCase()}</div> */}
      <div className="line-2">{Helpers.capitalizeAll(t("landing_guardians_line2"))}</div>
      <div className="line-3">{t("landing_guardians_line3")}</div>

      <Swiper
        className="swiper rl-guardians-carousel"
        // install Swiper modules
        modules={[Navigation, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
        breakpoints={{
          1024: {
            slidesPerView: 3,
            spaceBetween: 80,
            centeredSlides: true,
            // loop: true,
          },
        }}
      >
        <SwiperSlide>
          <a onClick={(event) => event.preventDefault()}>
            {/* <img src={t("landing_guardians_icon_deer")} alt="" /> */}
            <LazyLoadImage style={{width: "400px", marginBottom: "20px"}}
              src={t("landing_guardians_icon_deer")}
            />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a onClick={(event) => event.preventDefault()}>
            {/* <img src={t("landing_guardians_icon_lion")} alt="" /> */}
            <LazyLoadImage style={{width: "400px"}}
              src={t("landing_guardians_icon_lion")}
            />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a onClick={(event) => event.preventDefault()}>
            {/* <img src={t("landing_guardians_icon_wolf")} alt="" /> */}
            <LazyLoadImage style={{width: "400px"}}
              src={t("landing_guardians_icon_wolf")}
            />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a onClick={(event) => event.preventDefault()}>
            {/* <img src={t("landing_guardians_icon_eagle")} alt="" /> */}
            <LazyLoadImage style={{width: "400px"}}
              src={t("landing_guardians_icon_eagle")}
            />
          </a>
        </SwiperSlide>
      </Swiper>

      <div style={{height: "40px"}}></div>
      <a href="" className="rl-animated-btn slide-vertical" onClick={event => handleStartLife(event, "FOR_YOU")} data-splitting>{Helpers.capitalizeAll(t("landing_guardians_button"))}</a>
    </section>
  );
};

export default Guardians;
