import { Fragment } from "react";
import Wrapper from "../UI/Wrapper";
import Header from "../Landing/Header";

const Layout = (props) => {
  return (
    <Fragment>

      <main>{props.children}</main>

    </Fragment>
  );
};

export default Layout;
