import { useTranslation } from "react-i18next";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper"
import RLInputText from "../../UI/RLInputText";
import { useContext, useEffect, useRef, useState } from "react";
import RLDragAndDrop from "../../UI/RLDragAndDrop";
import { useIndexedDB } from 'react-indexed-db';
import AppContext from "../../../utils/app-context";

const PanelTestamentStepTwo = props => {

    const { getByID, update, getByIndex } = useIndexedDB('testament_step_files');
    const db = useIndexedDB('testament_step_files');
    const { t } = useTranslation();
    const appCtx = useContext(AppContext);

    const rlSideBar = props.rlSideBar
    const selectedGrave = rlSideBar.selectedGrave
    const availableDiskSize = props.availableDiskSize

    const [file, setFile] = useState([]);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isValid, setIsValid] = useState(true);

    var testamentStep = props.testamentStep;

    var filesLoaded = 0;

    function handleSetFile(file_) {
        if(JSON.stringify(file) != JSON.stringify(file_)) {
            setFile(file_);
        }
    }
    function handleFileFormatWrong(error) {
        console.log(error);

        appCtx.addToast(error, "red")
    }

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        isValidForm();
    }, [file]);

    useEffect(() => {
        const _testamentStep = Helpers.getTestamentStep();

        var _files = [];

        let userId = Helpers.getUser().id;
        let graveId = selectedGrave.id;
        let id = userId + "_" + graveId;

        getByIndex('rlId', id).then(
            testamentStepFiles => {

                if(testamentStepFiles != undefined) {
                    let _files_db = JSON.parse(testamentStepFiles.files);

                    for (let i = 0; i < _files_db.length; i++) {

                        var base64 = _files_db[i].base;
                        var base64Parts = base64.split(",");
                        var fileFormat = base64Parts[0].split(";")[1];
                        var fileContent = base64Parts[1];
                        var file = new File([fileContent], _files_db[i].name, { type: _files_db[i].type });
                        _files.push(file);
                    }

                    setFile(_files);
                }
                
            },
            error => {
                console.log(error);
            }
        );

    }, [selectedGrave]);

    function isValidForm() {
        const _testamentStep = Helpers.getTestamentStep();
        let isValidFormTestamentName = (file.length == 0 && (_testamentStep[selectedGrave.id]["files"] == undefined || _testamentStep[selectedGrave.id]["files"] == 0)) ? false : true;

        setIsValid(isValidFormTestamentName);

        return isValidFormTestamentName;
    }

    const handleStepTwoSubmit = async (event) => {
        event.preventDefault();

        const _testamentStep = Helpers.getTestamentStep();

        if (isValidForm()) {

            if(file.length > 0) {
                for (let i = 0; i < file.length; i++) {
    
                    try {
                        const result = await toBase64(file[i]);
    
                        handleFileLoad(result, i, _testamentStep)
                     } catch(error) {
                        console.error(error);
                        return;
                     }
                }
            } else {
                _testamentStep[selectedGrave.id]["step"] = 3;
                _testamentStep[selectedGrave.id]["stepTwoStatus"] = "completed";
                _testamentStep[selectedGrave.id]["stepThreeStatus"] = "pending";
                // _testamentStep[selectedGrave.id]["file"] = "";
                Helpers.setTestamentStep(_testamentStep);
                props.updateStep();
            }
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    

    var _files = [];
    function handleFileLoad(base64, index, _testamentStep) {

        _testamentStep[selectedGrave.id]["step"] = 3;
        // _testamentStep[selectedGrave.id]["file"][index] = base64.currentTarget.result;

        // _files.push({base: base64.currentTarget.result, name: file[index].name, type: file[index].type})
        _files.push({base: base64, name: file[index].name, type: file[index].type})

        if (++filesLoaded === file.length) {

            let userId = Helpers.getUser().id;
            let graveId = selectedGrave.id;
            let id = userId + "_" + graveId;

            getByIndex('rlId', id).then(
                testamentStepFiles => {

                    if(testamentStepFiles == undefined) {
                        update({rlId: id, files: JSON.stringify(_files) }).then(
                            event => {
                                console.log('ID Generated: ', event);

                                _testamentStep[selectedGrave.id]["step"] = 3;
                                _testamentStep[selectedGrave.id]["stepTwoStatus"] = "completed";
                                _testamentStep[selectedGrave.id]["stepThreeStatus"] = "pending";
                                // _testamentStep[selectedGrave.id]["file"] = "";
                                Helpers.setTestamentStep(_testamentStep);
                                props.updateStep();
                            },
                            error => {
                                console.log(error);
                            }
                        );
                    } else {
                        update({ id: testamentStepFiles.id, rlId: id, files: JSON.stringify(_files) }).then(
                            event => {
                                console.log('ID Generated: ', event);

                                _testamentStep[selectedGrave.id]["step"] = 3;
                                _testamentStep[selectedGrave.id]["stepTwoStatus"] = "completed";
                                _testamentStep[selectedGrave.id]["stepThreeStatus"] = "pending";
                                // _testamentStep[selectedGrave.id]["file"] = "";
                                Helpers.setTestamentStep(_testamentStep);
                                props.updateStep();
                            },
                            error => {
                                console.log(error);
                            }
                        );
                    }
                    
                },
                error => {
                    console.log(error);
                }
            );
        }
    }

    const handleTestamentCancel = (event) => {
        event.preventDefault();

        props.cancelStep(event);
    }

    const _testamentStep = Helpers.getTestamentStep();
    return (
        <Wrapper>
            <div className="rl-admin-content-box">
                {availableDiskSize && (
                    <div className="rl-upload-storage-left">
                        {t("content_form_remainingsize")}{" "}
                        <span>
                            {(availableDiskSize / (1024 * 1024 * 1024)).toFixed(2)} GB
                        </span>
                    </div>
                )}

                <RLDragAndDrop file={file} recordedFiles={testamentStep["files"]} selectedGraveId={selectedGrave.id} isUploaded={isUploaded} onFileChange={(file) => handleSetFile(file)} onFileFormatWrong={handleFileFormatWrong} multiple={true} formatCheck={true} />

                {!isValid && (
                    <div className="rl-form-group">
                    <div className="error-line">
                        <img src="/img/icon-circle-info.svg" width="24" />
                        <span>{t("testement_form_validationMessage_steptwo")}</span>
                    </div>
                    </div>
                )}

                <div class="text-center">
                    <a href="" onClick={handleStepTwoSubmit} class="rl-form-button rl-add-new-test-btn">{t("general_next")}</a>
                    {props.testaments.items.length > 0 && (
                        <a href="" onClick={handleTestamentCancel} class="rl-form-button rl-add-new-test-btn" style={{marginLeft:"16px"}}>{Helpers.capitalizeFirst(t("general_cancel"))}</a>
                    )}
                </div>
            </div>
        </Wrapper>
    )
}

export default PanelTestamentStepTwo;