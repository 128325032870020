import { useContext } from "react";
import Wrapper from "../Wrapper"
import AppContext from "../../../utils/app-context";
import { useTranslation } from "react-i18next";

const RLModalFunnelChoose = props => {
    const appCtx = useContext(AppContext);
  const { t } = useTranslation();

  const defaultBody = {
    title: t(""),
    description: t(""),
    funnel1: t(""),
    funnel2: t(""),
  };

  const modal = props.modal;
  const params = modal.params;

  const handleClose = (event, funnel) => {
    event.preventDefault();

    appCtx.hideModal();
  };

  const handleConfirm = (event, funnel) => {
    event.preventDefault();

    modal.onClose();
    modal.onConfirm(funnel);
    
  };

  const funnel1Name = () => {

    if(params.button1Name != undefined) {
      return params.button1Name
    } else {
      if(params.funnel1 != undefined) {
        if(params.funnel1.graveType == "FOR_YOU") {
          return t("modal_funnelChoose_button_foryou")
        } else if(params.funnel1.graveType == "FOR_BE_LOVED") {
          return t("modal_funnelChoose_button_forbeloved")
        }
      }
    }
    
    return ""
  }

  const funnel2Name = () => {

    if(params.button2Name != undefined) {
      return params.button2Name
    } else {
      if(params.funnel2 != undefined) {
        if(params.funnel2.graveType == "FOR_YOU") {
          return t("modal_funnelChoose_button_foryou")
        } else if(params.funnel2.graveType == "FOR_BE_LOVED") {
          return t("modal_funnelChoose_button_forbeloved")
        }
      }
    }
    
    return ""
  }

  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">
          {params.title != undefined ? params.title : defaultBody.title}
        </h1>

        <div className="modal-header-desc">
          {" "}
          {params.description != undefined
            ? params.description
            : defaultBody.description}{" "}
        </div>

        <div className="modal-header-desc"></div>

        <div className="modal-2-buttons">
        <a href="" onClick={(event) => handleConfirm(event, params.funnel1)} className="rl-btn-filled black">
            {" "}
            {funnel1Name()}{" "}
          </a>

          <a href="" onClick={(event) => handleConfirm(event, params.funnel2)} className="rl-btn-filled black">
            {" "}
            {funnel2Name()}{" "}
          </a>
        </div>
      </div>
      <a href="" className={props.closeClassName} onClick={handleClose}>
          x
        </a>
        </Wrapper>
    )

}

export default RLModalFunnelChoose;