import { style } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import Wrapper from "../../UI/Wrapper";
import { useAxios } from "../../../hooks/use-axios";
import PanelSideBarVerseListItem from "./PanelSideBarVerseListItem";
// import AuthContext from "../../../utils/auth-context";
import globals from "../../../utils/globals";
import AppContext from "../../../utils/app-context";
import { useParams } from "react-router-dom";
import PanelSideBarGraveList from "./PanelSideBarGraveList";
import Helpers from "../../../utils/Helpers";

const PanelSideBarVerseList = (props) => {

  const rlSideBar = props.rlSideBar;

  let itemList = "";
  const selectedList = rlSideBar?.sideBar[Helpers.getGraveTypeFromSideBar(rlSideBar.sideBar, rlSideBar.selectedGrave.id)];

  itemList = (
    <ul style={{ cursor: "pointer" }}>
      {selectedList?.map((verse) =>
        <PanelSideBarVerseListItem
          id={verse.id}
          key={verse.id}
          verse={verse}
          rlSideBar={rlSideBar}
        />
        // )
      )}
    </ul>
  );

  // let maxHeight = "";
  // // maxHeight = "calc(100vh - 550px)"
  // if ((Helpers.findTopMenu("OWNER") ||
  // Helpers.findTopMenu("PURCHASER")) && 
  //   Helpers.getHasFunnelThroughType()) {
  //     maxHeight = "calc(100vh - 550px)"
  //   }

  return (
    <Wrapper>
      <ul className="rl-accordion rl-custom-scroll"
      // style={{maxHeight: maxHeight}}
      >{itemList}</ul>
    </Wrapper>
  );
};

export default PanelSideBarVerseList;
