const TranslationJson = props => {
    return (
        {
            "app_name": "Rememberland",
            "general_empty_list": "Please fill the area above to add {{page}}",
            "general_status": "STATUS",
            "general_name": "NAME",
            "general_surname": "Surname",
            "general_email": "EMAIL",
            "general_email_error": "Email error!",
            "password": "Password",
            "passwordNew": "New Password",
            "passwordNewAgain": "New Password Again",
            "password_error": "Password error!",  
            "general_link": "Link",
            "general_file": "FILE",
            "general_file_type": "FILE",
            "general_card": "CARD",
            
            "general_resend": "Resend",
            "general_resent": "Resent",
            "general_remove": "Remove",
            "general_removed": "Removed",
            "general_edit": "Edit",
            "general_edited": "Edited",
            "general_duplicate": "Duplicate",
            "general_duplicated": "Duplicated",
            "general_cancel": "Cancel",
            "general_save": "Save",
            "general_add": "ADD",
            "general_or":"OR",
            "general_download":"Download",
            "general_aprove":"Aprove",
            "general_reject":"Reject",
            "general_change":"Change",
            "general_homepage":"HOMEPAGE",
          
            "general_active": "Active",
            "general_passive": "Passive",
            "general_deleted": "Deleted",
          
            "general_owner": "Owner",
            "general_purchaser": "Purchaser",
            "general_guardian": "Guardian",
            "general_admin": "Admin",
            "general_continue": "Continue",
          
            "payment_create_grave": "Create Grave",
            "payment_create_newGrave": "CREATE A NEW GRAVE",
            "payment_create_graveDesc1": "Maybe you want to keep one photo forever. Maybe a unique image you want to see over and over… Maybe you want something special for your beloved, somewhere - or you just want your favorite guitar solo to stay there. One thing is sure, memories live on forever.",
            "payment_create_graveDesc2": "Some say “you only live as long as the last person to remember you”. Be the member and let people remember. So, welcome to eternity!",
            "payment_create_fixBeloved": "FIX YOUR SPACE FOR YOUR BELOVED",
            "payment_create_fixYou": "FIX YOUR SPACE FOR YOURSELF",
            "panel_sidebar_foryou": "FOR YOU",
            "panel_sidebar_forbeloved": "FOR MY BELOVED",
            
            "content_form_remainingsize": "Remaining Content Space",
            "content_form_dragdrop": "Drag and drop files here to upload",
            "content_form_dragdropsuccess": "Uploaded Successfully",
            "content_form_dragdropnew": "UPLOAD NEW FILE",
            "content_form_dragdropupload": "Select files to upload",
            "content_form_selectverse": "Select Verse",
            "content_form_selectfiletype": "Select File Type",
            "content_form_orlink": "Or link from web",
            "content_form_uploadedFiles": "Uploaded Files",
            "content_form_validationMessage": "En az bir adet verse secmelisiniz. Dosya ve link eklemelisiniz.",
            "content_list_item_duplicated":"Content duplicated",
            "content_list_item_updated":"Content updated",
          
            "landing_readmore": "READ MORE",
            "landing_mydashboard": "MY DASHBOARD",
            "landing_header_whatisrememberland": "What is Rememberland?",
            "landing_hero_sound":"SOUND",
            "landing_forwhom_title": "This is your very own digital memorial. Maybe you want to keep one photo forever. Maybe a unique image you want to see over and over… Maybe you want something special for your beloved, somewhere or you just want your favorite guitar solo to stay there. One thing is sure, memories live on forever.",
            "landing_forwhom_desc": "Some say “you only live as long as the last person to remember you”. Be the member and let people remember.",
            "landing_forwhom_beloved": "FOR BELOVED",
            "landing_forwhom_foryou": "FOR YOU",
            "landing_graveyards_title": "Verses",
            "landing_graveyards_discover": "DISCOVER",
            "landing_guardians_line1": "meet your",
            "landing_guardians_line2": "guardıans",
            "landing_guardians_line3": "Here is your Guardians If you had eternity, who would you share it with? Who would guard your memories? Who else could it be but the people you trust most, your loved ones! Identify your guardians and entrust your digital memorial to them.",
            "landing_social_title": "social endorsement page",
            "landing_gotopayment": "GO TO PAYMENT",
            "landing_footer_contactus": "CONTACT US",
          
            "panel_navigation_admins": "admins",
            "panel_navigation_guardians": "guardians",
            "panel_navigation_contents": "contents",
            "panel_navigation_testaments": "testaments",
            "panel_navigation_verses": "verses",
            "panel_navigation_subsInfo": "subs Info",
            "panel_navigation_kyc": "kyc",
          
            "admin_form_message": "Message",
            "admin_form_defaultMessage": "Congratulations on your first step into eternity on Rememberland. Rememberland is where black is the color and none is the number. There are no rules. So, you can design your digital memorials however you want.",
            "admin_chart_desc": "NUMBER OF ADMINS",
            "admin_list_add_guardian": "Add to Guardian",
            "admin_list_add_guardian_message": "Added to Guardian",
            "admin_modal_assign_title": "Assign Admin",
            "admin_modal_assign_desc": "Do you want to assign {{name}} as an Admin?",
            "admin_modal_assign_button": "Assign",
            "admin_modal_remove_title": "Remove Admin",
            "admin_modal_remove_desc": "Do you want to remove {{name}} from your admins?",
            "admin_modal_remove_button": "Remove from Admins",
            
            "guardian_chart_desc": "NUMBER OF GUARDIANS",
            "guardian_list_add_admin": "Add to Admin",
            "guardian_list_add_admin_message": "Added to Admin",
            "guardian_modal_assign_title": "Assign Admin",
            "guardian_modal_assign_desc": "Do you want to assign {{name}} as an Admin?",
            "guardian_modal_assign_button": "Assign",
            "guardian_modal_remove_title": "Remove Admin",
            "guardian_modal_remove_desc": "Do you want to remove {{name}} from your admins?",
            "guardian_modal_remove_button": "Remove from Admins",
            
            "testement_form_name": "Testament Name",
            "testement_form_share": "Share With Email",
            "testement_list_share": "Shared With",
            "testement_form_validationMessage": "Dosya eklemeli, isim yazmali ve en az 1 tane email secmelisiniz.",
          
            "verse_form_popular": "Most Popular Verses",
            "verse_form_add": "Add Verse",
            "verse_form_select": "Select Verse",
            "verse_form_selected": "Selected Verse",
            "verse_form_change": "Change Verse",
            "verse_form_all": "All Verses",
            "verse_form_newVerse": "New Verse Name",
            "verse_form_VerseName": "Verse Name",
            "verse_list_item_change": "Change",
            "verse_list_item_changed": "Changed",
            "verse_change_title": "Please select a new verse to change your selection ‘’{{verseName}}’’ ",
            "verse_change_desc": "Verse change costs $200",
          
            "panelSub_paymentMethod":"Grave payment methods",
            "panelSub_paymentMethod_graveName":"GRAVE NAME",
            "panelSub_paymentMethod_payment":"PAYMENT",
            "panelSub_paymentMethod_remainingPayment":"Remaining Payment",
            "panelSub_paymentMethod_changeCard":"Change Card",
            "panelSub_paymentMethod_unRegistered":"Paid With Unregistered Card",
            "panelSub_list_plan":"PLAN",
            "panelSub_list_price":"PRICE",
            "panelSub_list_paymentDue":"PAYMENT DUE",
          
            "kyc_status": "KYC Status:",
            "kyc_form_document": "Document",
            "kyc_form_documentError": "Dosya ve dokuman tipi secmeleisiniz.",
            "kyc_isApproved": "IS APROVED",
          
          
            "form_validation_notempty": "Can not be empty!",
            "list_no_record": "There is no record.",
            "list_search_complete": "Search completed with {{count}} records.",
            "register_title": "register a new account",
            "register_agreement_doc": "/docs/agreement-en.pdf",
            "signup": "SIGN UP",
            "signin": "SIGN IN",
            "login": "Login",
            "logout": "Log out",
            
            "login_description": "Please login with your username and password to step into eternity in Rememberland.",
            "login_rememberme": "Remember Me",
            "login_forgotPassword": "Forgot Password",
            "login_social_facebook": "Continue with Facebook",
            "login_social_google": "Continue with Google",
            "login_signup": "DONT HAVE AN ACOOUNT? ",
            "forgatpassword_resetPassword": "Reset Password",
            "forgatpassword_desc": "Enter the email associated with your account and we’ll send an email with instructions to reset your password",
            "register": "Register",
            "register_description": "You have taken your first step to eternity. Please fill the required information below to register.",
            "register_description_invitation": "Guardian/Admin olarak eklendiniz hesabinizi olusturun.",
            "register_agree_terms": "I agree with terms and privacy",
            "register_agree_error": "Terms and privacy must be confirm",
            "register_signin": "ALREADY HAVE AN ACOOUNT? ",
            
            
            "bo_sidebar_users": "Users",
            "bo_sidebar_tags": "Tags",
            "bo_sidebar_graves": "Graves",
            "bo_sidebar_changePassword": "Change Password",
            "bo_user_form_user": "User",
            "bo_user_form_register_mindate": "Register Date",
            "bo_user_form_status": "Status",
            "bo_user_form_type": "Type",
            "bo_grave_form_grave": "Grave",
            "bo_grave_form_create_mindate": "Create Date",
            "bo_grave_form_gravename": "Graveyard Name",
            "bo_grave_form_versename": "Verse Name",
            "bo_grave_form_ispaod": "Is Paid?",
            "bo_grave_form_kyc": "KYC Status",
            "bo_grave_form_kyc_waiting": "waiting",
            "bo_grave_form_kyc_approved": "approved",
            "bo_grave_form_kyc_declined": "declined",
            "bo_grave_form_type": "Type",
            "bo_grave_list_graveName": "GRAVE NAME",
            "bo_grave_list_date": "DATE",
            "bo_grave_list_verse": "VERSE",
            "bo_grave_list_isPaid": "IS PAID",
            "bo_grave_list_graveType": "GRAVE TYPE",
            "bo_grave_list_name": "NAME",
            "bo_grave_list_email": "EMAIL",
            "bo_grave_list_kycStatus": "KYC STATUS",
            "bo_grave_changeGrave": "Change Grave",
            "bo_grave_detail": "Detail",
            "bo_grave_sub_filterDate_label": "Payment Period",
            "bo_grave_sub_filterDate_LAST_SIX_MONTH": "LAST_SIX_MONTH",
            "bo_grave_sub_filterDate_LAST_YEAR": "LAST_YEAR",
            "bo_grave_sub_filterPaymentMethod_label": "Payment Method",
            "bo_grave_sub_filterPaymentMethod_ONE_TIME": "ONE_TIME",
            "bo_grave_sub_filterPaymentMethod_INSTALLMENT": "INSTALLMENT",
            "bo_grave_sub_filterStatus_label": "Status",
            "bo_grave_sub_filterStatus_COMPLETED": "COMPLETED",
            "bo_grave_sub_filterStatus_PENDING": "PENDING",
            "bo_grave_sub_filterStatus_FAILED": "FAILED",
            "bo_grave_sub_filterStatus_CANCELLED": "CANCELLED",
            "bo_grave_sub_filterCustomer_label": "Customer",
            "bo_grave_sub_filterSearch": "Search",
            "bo_grave_sub_list_method": "METHOD",
            "bo_grave_sub_list_status": "STATUS",
            "bo_grave_sub_list_price": "PRICE",
            "bo_grave_sub_list_due": "PAYMENT DUE",
            "bo_grave_sub_list_customer": "CUSTOMER",
            "bo_grave_sub_list_invoice": "Invoice",
            "bo_grave_kyc_list_fileType": "File Type",
            "bo_grave_kyc_list_isapproved": "Is Approved",
            
            "accountSettings": "Account Settings",
            "accountSettings_updatedMessage": "Updated",
            "accountSettings_deletedMessage": "Deleted",
            "accountSettings_payment_paymentMethods": "Payment Methods",
            "accountSettings_payment_relatedGraves": "Related Graves",
            "accountSettings_payment_changeCard": "Change Card",
            "accountSettings_payment_addCard": "ADD NEW CARD",
            "accountSettings_payment_cancelSubs": "Cancel My Subscription",
            "accountSettings_payment_cancelSubs_header": "Delete Account",
            "accountSettings_payment_cancelSubs_desc": "When you delete your account all your grave yards will be lost. Do you still wish to continue?",
            "accountSettings_payment_cancelSubs_delete": "Delete Account",
            "accountSettings_payment_cancelSubs_cancel": "Cancel",
            "accountSettings_payment_newCard_add": "Add New Credit Card",
            "accountSettings_payment_newCard_save": "Save New Card",
            "accountSettings_payment_changeCard_cardName": "Card",
            "accountSettings_payment_changeCard_desc": "You can select the desired credit card for payment",
            "accountSettings_payment_changeCard_selectCard": "Select Card",
            "accountSettings_payment_cancelCard": "Cancel Credit Card",
            "accountSettings_payment_cancelCard_number": "Cancel Credit Card {{cardNumber}}",
            "accountSettings_payment_cancelCard_desc": "The card you want to cancel is for Space Verse",
            "accountSettings_payment_cancelCard_descBold": "You can change payment method of the grave below",
            "accountSettings_subs_cancel": "Delete Account",
            "accountSettings_subs_desc": "When you delete your account all your grave yards will be lost. Do you still wish to continue?",
            "panelPayment_membership_options": "Payment Options",
            "panelPayment_membership_options_discount": "20% discount on one time payments",
            "panelPayment_banner_cash": "Peşin ödemelerde",
            "panelPayment_banner_dıscount": "20% discount",
            "panelPayment_banner_gain": "kazanırsınız",
            "panelPayment_annual": "ANNUAL",
            "panelPayment_monthly": "MONTHLY",
            "panelPayment_sale": "SALE",
            "panelPayment_month": "Month",
            "panelPayment_paymentMethod": "Payment Method",
            "panelPayment_registerCard": "Registered Card",
            "panelPayment_registerCard_error": "Choose Card",
            "panelPayment_newCard": "New Card",
            "panelPayment_cardHolderName": "Card Holders Name",
            "panelPayment_cardNumber": "Card Number",
            "panelPayment_cardExpirationDate": "Expiration Date",
            "panelPayment_year": "Year",
            "panelPayment_cvc": "CVC",
            "panelPayment_payWith3d": "Pay using 3d secure",
            "panelPayment_saveCard": "Save credit card",
            "congrats_owner_congrats": "CONGRATULATIONS",
            "congrats_owner_message": "Congratulations. Welcome to eternity. Now you have a place here too. You can shape eternity by changing your page as you wish. Wishing you a good time in eternity!",
            "congrats_admin_message": "Congratulations on your first step into eternity on Rememberland. Rememberland is where black is the color and none is the number. There are no rules. So, you can design your own digital memorial however you want.",
            "congrats_admin_specialOffer": "SPECIAL OFFER",
            "congrats_admin_specialOffer_message": "Now you are an Admin, you can buy your own graveyard for 20% discount!",
            "congrats_admin_backToMain": "BACK TO MAIN PAGE",
            "createGrave_title":"Enter Grave Name",
            "createGrave_graveName":"Grave Name",
            "welcome-ownerPurchaser-congrats":"TEBRIKLER",
            "welcome-ownerPurchaser-desc":"Congratulations. Welcome to eternity. Now you have a place here too. You can shape eternity by changing your page as you wish. Wishing you a good time in eternity!",
            "generalWelcome_accountCancel_title": "Account Cancellation",
            "generalWelcome_accountCancel_desc": "Account Cancellation Desc",
            "generalWelcome_emailVerificationError_title": "Email Verification Error",
            "generalWelcome_emailVerificationError_desc": "Email Verification Error Desc",
            
            "newPasswordPage_title": "Create New Password",
            "newPasswordPage_desc": "Enter the email associated with your account and we’ll send an email with instructions to reset your password",
            "newPasswordPage_change": "Change Password",
            "confirmation_title": "Confirm",
            "confirmation_desc": "Are you sure?",
            "confirmation_confirmButton": "Confirm",
            "confirmation_cancelButton": "Cancel"
          
          }
          
    )
}

export default TranslationJson;