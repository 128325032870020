import Footer from "../components/Landing/Footer";
import ForWhom from "../components/Landing/ForWhom";
import Gravyeards from "../components/Landing/Gravyeards";
import Guardians from "../components/Landing/Guardians";
import Header from "../components/Landing/Header";
import Hero from "../components/Landing/Hero";
import Social from "../components/Landing/Social";
import Card from "../components/UI/Card";
import Wrapper from "../components/UI/Wrapper";
// import "./../css-landing/style.css";
import { useTranslation } from "react-i18next";
import AppContext from "../utils/app-context";
import { useContext, useEffect, useRef, useState } from "react";
import Helpers from "../utils/Helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeroNew from "../components/Landing/HeroNew";
import WhatIs from "../components/Landing/WhatIs";
import StartLife from "../components/Landing/StartLife";
import DetailsForLife from "../components/Landing/DetailsForLife";

// import "splitting/dist/splitting.css";
// import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

const LandingPage = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const [detailForLifeType, setDeetailForLifeType] = useState();

  if (appCtx.isLoggedIn && !appCtx.isAdmin && Helpers.getOnboardStatus() == "WAITING") {

    history.replace("/payment");
  }

  const handleClickToScroll = (id) => {

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };


  const handleDetailForLifeType = (type, navigate = false) => {

    setDeetailForLifeType(type);

    if(navigate) {
      handleClickToScroll("rememberland-landing-details-for-life")
    }
  }

  const splitRef = useRef(null);
  useEffect(() => {
    if (splitRef) {
      let split_res = Splitting({
        by: "chars",
      });
    }
  }, [splitRef]);

  // useEffect(() => {
  //   window.$chatwoot.toggleBubbleVisibility('show')
  // }, []);

  return (
    <Wrapper>

      <Header handleDetailForLifeType={handleDetailForLifeType}/>
      {/* <Hero /> */}
      <HeroNew handleDetailForLifeType={handleDetailForLifeType} />
      <Card className="rememberland-landing-body">
        <WhatIs />
        {/* <ForWhom /> */}
        <StartLife />
        <Gravyeards />
        <DetailsForLife detailForLifeType={detailForLifeType} handleDetailForLifeType={handleDetailForLifeType} />
        <Guardians />
        <Social />
      </Card>
      <Footer />

    </Wrapper>
  );
};

export default LandingPage;
