export const DBConfig = {
    name: 'RemeberlandDB',
    version: 1,
    objectStoresMeta: [
      {
        store: 'testament_step_files',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'rlId', keypath: 'rlId', options: { unique: false } },
          { name: 'files', keypath: 'files', options: { unique: false } },
          { name: 'graveId', keypath: 'graveId', options: { unique: false } },
          { name: 'userId', keypath: 'userId', options: { unique: false } }
        ]
      }
    ]
  };
  