import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../../hooks/use-axios";
import RLInputSelect from "../../../UI/RLInputSelect";
import RLInputText from "../../../UI/RLInputText";
import Wrapper from "../../../UI/Wrapper";
import BOGraveDetailSubsItem from "./BOGraveDetailSubsItem";

const BOGraveDetailSubs = (props) => {
  const { t } = useTranslation();
  const params = useParams();

  const [filters, setFilters] = useState({
    dateFilterTypes: [],
    paymentMethods: [],
    orderStatuses: [],
  });

  const [subList, setSubList] = useState([]);
  const [itemList, setItemList] = useState("");

  const [dateFilter, setDateFilter] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleDateChange,
    options: [],
    selected: null,
  });
  const [methodFilter, setMethodFilter] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleMethodChange,
    options: [],
    selected: null,
  });
  const [statusFilter, setStatusFilter] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleStatusChange,
    options: [],
    selected: null,
  });

  const [name, setName] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleNameChange,
  });

  function handleDateChange(value, isValid_ = false) {
    setDateFilter({
      ...dateFilter,
      options: filters.dateFilterTypes,
      selected: value,
    });
  }
  function handleMethodChange(value, isValid_ = false) {
    setMethodFilter({
      ...methodFilter,
      options: filters.paymentMethods,
      selected: value,
    });
  }
  function handleStatusChange(value, isValid_ = false) {
    setStatusFilter({
      ...statusFilter,
      options: filters.orderStatuses,
      selected: value,
    });
  }
  function handleNameChange(value, isValid = false) {
    setName({ ...name, value: value, isValid: isValid });
  }

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getSubFilters();
      postSubList();
    }

    if (response !== null && typeof response !== "undefined") {
      if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/sub/filter"
      ) {
        initFilters(response.data);
      } else if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/sub/list"
      ) {
        if (response.data.orders != undefined) {
          if (response.data.orders.length > 0) {
            let itemList_ = "";
            itemList_ = response.data.orders.map((item) => (
              <BOGraveDetailSubsItem
                id={item.id + "#" + item.testamentFileId}
                key={item.id + "#" + item.testamentFileId}
                item={item}
              />
            ));

            setItemList(itemList_);
          } else {
            setItemList("");
          }
        } else {
          setItemList("");
        }
      }
    }
  }, [response, error]);

  function getSubFilters() {
    const url = "backOffice/grave/" + params["graveId"] + "/sub/filter";
    setEndpoint(url);

    request({
      method: "GET",
      url: url,
    });
  }

  function initFilters(data) {
    let dateFilterTypes = [];
    let paymentMethods = [];
    let orderStatuses = [];

    if (data.dateFilterTypes) {
      data.dateFilterTypes.forEach((dateFilter) => {
        dateFilterTypes.push({
          id: dateFilter,
          name: t("bo_grave_sub_filterDate_" + dateFilter),
        });
      });

      setDateFilter({
        ...dateFilter,
        options: dateFilterTypes,
        selected: {},
      });
    }

    if (data.paymentMethods) {
      data.paymentMethods.forEach((paymentMethod) => {
        paymentMethods.push({
          id: paymentMethod,
          name: t("bo_grave_sub_filterPaymentMethod_" + paymentMethod),
        });
      });

      setMethodFilter({
        ...methodFilter,
        options: paymentMethods,
        selected: {},
      });
    }

    if (data.orderStatuses) {
      data.orderStatuses.forEach((orderStatus) => {
        orderStatuses.push({
          id: orderStatus,
          name: t("bo_grave_sub_filterStatus_" + orderStatus),
        });
      });

      setStatusFilter({
        ...statusFilter,
        options: orderStatuses,
        selected: {},
      });
    }

    setFilters({
      dateFilterTypes: dateFilterTypes,
      paymentMethods: paymentMethods,
      orderStatuses: orderStatuses,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    let data = {};

    // if (JSON.stringify(dateFilter.selected) !== null) {
    if (dateFilter.selected !== null) {
      data["dateFilterType"] = dateFilter.selected.id;
    }
    if (methodFilter.selected !== null) {
      data["paymentMethod"] = methodFilter.selected.id;
    }
    if (statusFilter.selected !== null) {
      data["orderStatus"] = statusFilter.selected.id;
    }
    if (name.value !== "") {
      data["customerName"] = name.value;
    }

    postSubList(data);
  };

  function postSubList(data = {}) {
    const url = "backOffice/grave/" + params["graveId"] + "/sub/list";
    request({
      method: "POST",
      url: url,
      data,
    });
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <form className="rl-bo-users-search-form">
          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("bo_grave_sub_filterDate_label")}
                  standart={true}
                  onChange={dateFilter.onChange}
                  defaultValue={dateFilter.selected}
                  options={filters.dateFilterTypes}
                />
              </div>
            </div>

            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("bo_grave_sub_filterPaymentMethod_label")}
                  standart={true}
                  onChange={methodFilter.onChange}
                  defaultValue={methodFilter.selected}
                  options={filters.paymentMethods}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  label={t("bo_grave_sub_filterCustomer_label")}
                  data={name}
                />
              </div>
            </div>

            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("bo_grave_sub_filterStatus_label")}
                  standart={true}
                  onChange={statusFilter.onChange}
                  defaultValue={statusFilter.selected}
                  options={filters.orderStatuses}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <button onClick={handleSubmit} className="rl-form-button">
              {t("bo_grave_sub_filterSearch")}
            </button>
          </div>
        </form>
      </div>

      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                <th>{t("bo_grave_sub_list_method")}</th>
                <th className="rl-table-border-l">
                  {t("bo_grave_sub_list_status")}
                </th>
                <th className="rl-table-border-l">
                  {t("bo_grave_sub_list_price")}
                </th>
                <th className="rl-table-border-l">
                  {t("bo_grave_sub_list_due")}
                </th>
                <th className="rl-table-border-l">
                  {t("bo_grave_sub_list_customer")}
                </th>
                <th className="w-30 text-right">
                  {/* <a href="" className="rl-table-download-btn disabled">
                    <img
                      src="/img/icon-download.svg"
                      alt="Download"
                      width="20"
                      height="20"
                    />{" "}
                    {t("general_download")}
                  </a> */}
                </th>
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default BOGraveDetailSubs;
