import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/use-axios";
import AppContext from "../../utils/app-context";
import RLModal from "../UI/RLModal/RLModal";
import Wrapper from "../UI/Wrapper";
import PanelPaymentCard from "./PanelPaymentCard";
import PanelPaymentPlan from "./PanelPaymentPlan";
import { useHistory } from "react-router-dom";
import Helpers from "../../utils/Helpers";

const PanelPaymentMain = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const [checkout, setCheckout] = useState([]);

  const [plan, setPlan] = useState("MONTHLY");
  const [selectedCard, setSelectedCard] = useState(undefined);
  const [cardType, setCardType] = useState(undefined);

  const [tdContent, setTdContent] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  const queryParams = new URLSearchParams(window.location.search);
  const graveName_ = queryParams.get("graveName");
  const graveType_ = queryParams.get("graveType");

  const discountCode_ = queryParams.get("discountCode");
  const subscriptionId_ = queryParams.get("subscriptionId");
  const graveId_ = queryParams.get("graveId");

  var discountDesc = t("panelPayment_membership_options_y_discount");

  const closeModalHandler = (event) => {
    if (event.target === event.currentTarget) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (endpoint == "first") {
      getSideBar();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "checkout") {
        setCheckout(response.data);

      } else if (responseType == "checkout/grave" || responseType.startsWith("checkout/grave")) {
        // console.log(response.data)

        if (cardType == "NEW" && selectedCard.payWith3d) {
          setTdContent(response.data);
          setShowModal(true);
        } else {
          getProfile();
          // history.replace("/payment-welcome");
        }

        appCtx.hideLoading();

      } else if (responseType == "profile") {
        const profile = response.data;
        const user = Helpers.setUser(profile);
        const onboardStatus = Helpers.setOnboardStatus(profile.onboardStatus);
        const onboardType = Helpers.setOnboardType(profile.onboardType);

        appCtx.login(
          localStorage.getItem("token"),
          profile
        );

        history.replace("/payment-welcome?graveId=" + graveId_);
      }

      appCtx.hideLoading();
    }
  }, [response, error]);

  function getSideBar() {
    let url = "checkout";
    let queryString = "";

    const params = {};

    if (discountCode_ != "" && discountCode_ != null)
      params["discountCode"] = discountCode_;

    // queryString += "&discountCode=" + discountCode_;

    if (subscriptionId_ != "" && subscriptionId_ != null)
      params["subscriptionId"] = subscriptionId_;

    if (graveId_ != "" && graveId_ != null)
      params["graveId"] = graveId_;

    // queryString += "subscriptionId=" + subscriptionId_;

    // if(queryString != "")
    //   url += "?" + queryString;
    // else
    //   url += "/";

    params["languageCode"] = navigator.language;

    setEndpoint(url);
    appCtx.showLoading();

    request({
      method: "GET",
      url: url,
      params
    });
  }

  function getProfile() {
    const url = "profile";

    request({
      method: "GET",
      url: url,
    });
  }

  function handleSelectedCard(selectedCard_, cardType_) {
    setSelectedCard(selectedCard_);
    setCardType(cardType_);
  }

  function handlePayment(selectedCard_, cardType_, cvc_) {
    setSelectedCard(selectedCard_);
    setCardType(cardType_);

    const url = "checkout/grave";
    console.log(plan, selectedCard, cardType_);

    const selectedPlan = checkout.planDTOS.filter(function (plan_) {
      return plan_.installmentPeriod == plan;
    });

    let payWith3d = false;
    let creditCard = {};
    if (cardType_ == "REGISTERED") {
      const selectedStoredCard = checkout.storedCards.filter(function (plan) {
        return plan.cardToken == selectedCard_;
      });

      creditCard = {
        cardToken: selectedStoredCard[0].cardToken,
        cvc: cvc_
      };
    } else if (cardType_ == "NEW") {
      creditCard = selectedCard_;
      payWith3d = selectedCard_.payWith3d;
    }

    const data = {
      planGuid: selectedPlan[0].planGuid,
      creditCard: creditCard,
      payWith3d: payWith3d,
      graveName: graveName_,
      graveType: graveType_,
      // discountCode: discountCode_,
      // subscriptionId: subscriptionId_,
      graveId: graveId_
    };

    appCtx.showLoading();
    setEndpoint(url);

    request({
      method: "POST",
      url: url,
      data: data,
    });
  }

  function getDiscoutDescription() {
    const planMonthly = checkout.planDTOS.filter(function (plan) {
      return plan.installmentPeriod == "MONTHLY";
    });

    if (planMonthly[0].discount) {
      return t("panelPayment_membership_options_my_discount");
    }

    return t("panelPayment_membership_options_y_discount");
  }

  return (
    <Wrapper>
      <section className="rl-payment-guardian">
        <div className="rl-payment-guardian-left">
          {/* <img src="img/payment-left-img.jpg" alt="" /> */}
          <ul class="rl-payment-slideshow">
            <li> <span>Image One</span> </li>
            <li> <span>Image Two</span> </li>
            <li> <span>Image Three</span> </li>
          </ul>
        </div>

        <div className="rl-payment-guardian-right">
          {/* <h2>{t("panelPayment_membership_options")}</h2> */}
          <h2>{graveName_}</h2>

          <p style={{ marginTop: "1.5rem" }}>{t("panelPayment_membership_publish_desc")}</p>

          {checkout.length != 0 && (
            <div className="discount-text">
              <img
                src="/img/icon-star.svg"
              />
              <span>
                {getDiscoutDescription()}
              </span>
            </div>
          )}


          {"planDTOS" in checkout && (
            <PanelPaymentPlan
              items={checkout.planDTOS}
              setPlan={(value) => setPlan(value)}
            />
          )}

          {"storedCards" in checkout && (
            <PanelPaymentCard
              items={checkout.storedCards}
              handleSelectedCard={handleSelectedCard}
              handlePayment={handlePayment}
              paymentPlan={plan}
            />
          )}
        </div>
      </section>

      <RLModal
        title="My Modal"
        onClose={closeModalHandler}
        show={showModal}
        type={"3dSecure"}
        content={tdContent}
        graveId={graveId_}
      />

      {/* <RLModal3DSecure show={showModal} content={tdContent} onClose={closeModalHandler}/> */}
    </Wrapper>
  );
};

export default PanelPaymentMain;
