import { useContext } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";
import AccountSettingsPaymentMethodItemGrave from "./AccountSettingsPaymentMethodItemGrave";

const AccountSettingsPaymentMethodItem = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const creditCard = props.item.creditCard;
  const graves = props.item.graves;

  const { response, loading, error, request, responseType } = useAxios();

  const handleRemoveCard = (event) => {
    event.preventDefault();

    if (graves.length == 0) {

      const cancelCardModal = {
        type: "cancelCard",
        show: true,
        params: {
          cardNumber: Helpers.formatCreditCardNumber(creditCard),
          userCardId: creditCard.userCardId
        },
      };
      appCtx.showModal(cancelCardModal);
    } else {

      const graveId = Object.keys(graves[0])[0];

      const changeCardModal = {
        type: "changeCard",
        show: true,
        params: {
          cardNumber: Helpers.formatCreditCardNumber(creditCard),
          userCardId: creditCard.userCardId,
          type: 'cancel'
        },
      };
      appCtx.showModal(changeCardModal);
    }
  };

  const onConfirmChangeCard = (event) => {
    event.preventDefault();

    appCtx.hideModal();
  };

  let itemList = "";
  if (graves.length > 0) {
    itemList = graves.map((item) => (
      <AccountSettingsPaymentMethodItemGrave
        id={creditCard.userCardId}
        key={creditCard.userCardId}
        item={item}
        itemCount={props.itemCount}
      />
    ));
  }

  let backgroundColor = "";
  if (props.itemIndex % 2 == 0) {
    backgroundColor = { backgroundColor: "#F2C9F3" };
  } else {
    backgroundColor = { backgroundColor: "#F3D6C9" };
  }

  function cardAssociationImage () {

    if(creditCard.cardAssociation == "VISA") {
      return <img src="/img/card-visa.svg" alt="" />

    } else if(creditCard.cardAssociation == "MASTER_CARD") {
      return <img src="/img/card-mastercard.svg" alt="" />

    } else if(creditCard.cardAssociation == "AMEX") {
      return <img src="/img/card-amex.svg" alt="" />

    } else if(creditCard.cardAssociation == "TROY") {
      return <div style={{height:"64px", width:"64px", display:"flex", alignItems: "center",
      justifyContent: "center"}}>
        <img src="/img/card-troy.png" alt=""/>
      </div>

    } else {
      return <div style={{height:"64px", width:"64px", display:"flex", alignItems: "center",
      justifyContent: "center"}}>
        <img src="/img/card-default.png" alt=""/>
      </div>
    }
  }

  return (
    <Wrapper>
      <div className="rl-account-cards-grid-item">
        <div className="rl-account-card" style={backgroundColor}>
          <header>
            <div className="card-holder">{creditCard.cardHolderName}</div>
            {props.itemCount > 1 && (<div className="header-right">
              <a
                href=""
                onClick={handleRemoveCard}
                rel="modal:open"
                className="del-btn"
              >
                <img
                  src="/img/icon-trash.svg"
                  alt="Delete"
                  width="20"
                  height="20"
                />
              </a>
            </div>)}
          </header>

          <div className="card-numbers">
            {Helpers.formatCreditCardNumber(creditCard, 'div')}
          </div>

          <footer>
            <div className="expire-date"></div>

            {cardAssociationImage()}

          </footer>
        </div>

        {graves.length > 0 && (
          <Wrapper>
            <div className="card-related-text">
              {t("accountSettings_payment_relatedGraves")}
            </div>

            {itemList}
          </Wrapper>
        )}
      </div>
    </Wrapper>
  );
};

export default AccountSettingsPaymentMethodItem;
