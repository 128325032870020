import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Waypoint } from "react-waypoint";
import Wrapper from "../UI/Wrapper"
import { isMobile } from 'react-device-detect';
import Helpers from "../../utils/Helpers";
import RenderIfVisible from 'react-render-if-visible'

const WhatIs = props => {

    const { t } = useTranslation();

    const elementRef = useRef(null);

    const _handleWaypointEnter = event => {
        elementRef.current.play();
    }
    const _handleWaypointLeave = event => {
        elementRef.current.pause();
    }

    useEffect(() => {
        elementRef.current.pause();
    }, []);

    return (
        <Wrapper>
            <section className="rememberland-what-is" id="rememberland-what-is">
                <div className="rememberland-what-is-wrapper">
                    <div className="left">
                        <h2>{Helpers.capitalizeAll(t("landing_whatIs_title"))}</h2>
                        <p>{t("landing_whatIs_desc1")}</p>
                        <p className="mb-0">{t("landing_whatIs_desc2")}</p>
                        <p className="mb-0">{t("landing_whatIs_desc3")}</p>
                        <p className="mb-0">{t("landing_whatIs_desc4")}</p>
                    </div>
                    {/* <RenderIfVisible> */}
                        <div className="right">
                            <Waypoint
                                onEnter={_handleWaypointEnter}
                                onLeave={_handleWaypointLeave}>
                                <div className="video-wrapper">

                                    <video preload="none" muted playsInline loop controls ref={elementRef}>
                                        <source src={`${process.env.REACT_APP_CDN_URL}${isMobile ? t("landing_hero_video") : t("landing_hero_video_webm")}`} type={isMobile ? "video/mp4" : "video/webm"} />

                                    </video>
                                </div>
                            </Waypoint>
                        </div>
                    {/* </RenderIfVisible> */}

                </div>
            </section>
        </Wrapper>
    )
}

export default WhatIs;