import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AppContext from "../../utils/app-context";
import Helpers from "../../utils/Helpers";
import RLButton from "../UI/RLButton";
import Wrapper from "../UI/Wrapper";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const handleClickToScroll = (event, id) => {
    event.preventDefault();

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const confirmChooseFunnel = (funnel) => {
    history.replace("/funnel/" + funnel.id);
  }
  const handleLoginSuccess = (url, isFUnnel = false, funnels = []) => {
    history.replace(url);
    if (!isFUnnel) {
      history.replace(url);
    } else {

      let funnel1 = funnels[0]
      let funnel2 = funnels[1]

      const funenlChooseModal = {
        type: "funnelChoose",
        show: true,
        params: {
          title: t("modal_funnelChoose_title"),
          description: t("modal_funnelChoose_desc1"),
          funnel1: funnel1,
          funnel2: funnel2,
        },
        onConfirm: confirmChooseFunnel,
      };
      appCtx.showModal(funenlChooseModal);
    }
  }
  const handleLogin = event => {
    event.preventDefault()
    const verseDetailModal = {
      type: "login",
      show: true,
      params: {
        onLoginSuccess: handleLoginSuccess
      }
    };
    appCtx.showModal(verseDetailModal);
  }

  return (
    <Wrapper>
      <footer className="rememberland-footer">
        <div className="rememberland-footer-left">
          <img src="img/logo-black.svg" alt="" className="logo" />
          <a
            href="mailto:support@rememberland.io"
            // onClick={(e) => {
            //   window.location.href = "mailto:support@rememberland.io";
            //   e.preventDefault();
            // }}
          >
            {t("landing_footer_contactus")}
          </a>
        </div>
        
        <div className="rememberland-footer-right" >

            {/* {!appCtx.isLoggedIn && (
              <div className="top">
              <a href="" onClick={handleLogin} className="button slide-vertical" data-splitting>
                {Helpers.capitalizeAll(t("login"))}
              </a>
              </div>
            )} */}
          

          <div className="bottom" style={{ display: "inline", paddingRight: "8px", marginTop: "10px" }}>
            <ul >
              <li>
                <a
                  href={process.env.REACT_APP_DISCORD_REDIRECT_URL}
                  target="_blank"
                  title="Discord"
                >
                  <img src="img/icon-discord.svg" alt="" style={{ width: "24px", height: "24px"}} />
                </a>
              </li>
              <li>
                <a
                  href={process.env.REACT_APP_FACEBOOK_REDIRECT_URL}
                  target="_blank"
                  title="Facebook"
                >
                  <img src="img/icon-fb.svg" alt="" style={{ width: "24px", height: "24px"}} />
                </a>
              </li>
              <li>
                <a
                  href={process.env.REACT_APP_TWITTER_REDIRECT_URL}
                  target="_blank"
                  title="Twitter"
                >
                  <img src="img/icon-tw-x.svg" alt="" style={{ width: "14px", height: "24px"}} />
                </a>
              </li>
              <li>
                <a
                  href={process.env.REACT_APP_INSTAGRAM_REDIRECT_URL}
                  target="_blank" title="Instagram">
                  <img src="img/icon-ins.svg" alt="" style={{ width: "24px", height: "24px"}} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <footer className="rememberland-footer-mobile">
        <img src="img/logo-black.svg" alt="REMEMBERLAND" className="logo" />

        {!appCtx.isLoggedIn && (
          <a href="" onClick={handleLogin} className="login">{Helpers.capitalizeAll(t("login"))}</a>
        )}

        <ul className="bottom">
          <li>
            <a
              href={process.env.REACT_APP_DISCORD_REDIRECT_URL}
              target="_blank"
              title="Discord"
            >
              <img src="img/icon-discord.svg" alt="" style={{ width: "24px", height: "24px"}} />
            </a>
          </li>
          <li>
            <a
              href={process.env.REACT_APP_FACEBOOK_REDIRECT_URL}
              target="_blank"
              title="Facebook"
            >
              <img src="img/icon-fb.svg" alt="" style={{ width: "24px", height: "24px"}} />
            </a>
          </li>
          <li>
            <a
              href={process.env.REACT_APP_TWITTER_REDIRECT_URL}
              target="_blank"
              title="Twitter"
            >
              <img src="img/icon-tw-x.svg" alt="" style={{ width: "24px", height: "24px"}} />
            </a>
          </li>
          <li>
            <a
              href={process.env.REACT_APP_INSTAGRAM_REDIRECT_URL}
              target="_blank" title="Instagram">
              <img src="img/icon-ins.svg" alt="" style={{ width: "24px", height: "24px"}} />
            </a>
          </li>
        </ul>
        <a
          href="mailto:support@rememberland.io"
          className="contact-us"
        >
          {t("landing_footer_contactus")}
        </a>
      </footer>
    </Wrapper>
  );
};

export default Footer;
