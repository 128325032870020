import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../../../hooks/use-axios";
import Helpers from "../../../../utils/Helpers";
import Wrapper from "../../../UI/Wrapper";
import BOGraveDetailVerseItem from "./BOGraveDetailVerseItem";

const BOGraveDetailVerse = (props) => {

  const { t } = useTranslation();
  const params = useParams();
  const [verses, setVerses] = useState([]);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
        getVerseList();
    }

    if (response !== null && typeof response !== "undefined") {
      if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/verse/list"
      ) {
        setVerses(response.data);
      }
    }
  }, [response, error]);

  function getVerseList() {
    const url = "backOffice/grave/" + params["graveId"] + "/verse/list";
    setEndpoint(url);

    const data = {};

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  let itemList = "";
  if (verses != undefined) {
    if (verses.length > 0) {
      itemList = verses.map((item) => (
        <BOGraveDetailVerseItem
          id={item.verseId}
          key={item.verseId}
          item={item}
        //   reloadList={reloadList}
        />
      ));
    }
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                <th>{Helpers.capitalizeAll(t("general_status"))}</th>
                <th className="rl-table-border-l">{Helpers.capitalizeAll(t("bo_grave_form_versename"))}</th>
                <th className="rl-table-border-l">{Helpers.capitalizeAll(t("bo_grave_verse_occupancyRate"))}</th>
              </tr>
            </thead>
            <tbody>
              {itemList}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default BOGraveDetailVerse;
