import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper";

import { useState } from "react";
import RLInputSelect from "../../UI/RLInputSelect";
import RLInputDate from "../../UI/RLInputDate";
import RLInputText from "../../UI/RLInputText";
import Helpers from "../../../utils/Helpers";

const BOGraveForm = (props) => {

  const { t } = useTranslation();

  const [registerDate, setRegisterDate] = useState({
    value: undefined,
    onChange: handleRegisterDate,
    inputProps: {max: new Date().toISOString().slice(0, 10)}
  });
  const [registerMaxDate, setRegisterMaxDate] = useState({
    value: undefined,
    onChange: handleRegisterMaxDate,
    inputProps: {max: new Date().toISOString().slice(0, 10)}
  });
  const [graveName, setGraveName] = useState({
    value: "",
    isValid: true,
    onChange: handleGraveChange,
  });
  const [verseName, setVerseName] = useState({
    value: "",
    isValid: true,
    onChange: handleVerseChange,
  });
  const [isPaid, setIsPaid] = useState(null);
  const [kyc, setKyc] = useState(null);
  const [type, setType] = useState(null);
  const [name, setName] = useState({
    value: "",
    isValid: true,
    onChange: handleNameChange,
  });
  const [surname, setSurname] = useState({
    value: "",
    isValid: true,
    onChange: handleSurnameChange,
  });
  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    onChange: handleEmailChange,
  });

  let startDate_ = registerDate.value
  let endDate_ = registerMaxDate.value

  function handleRegisterDate(value) {
    startDate_ = value

    if(startDate_ > endDate_) {
      endDate_ = undefined
    }

    setRegisterDate({ ...registerDate, value: value });
    setRegisterMaxDate({ ...registerMaxDate, value: endDate_, inputProps: {max: new Date().toISOString().slice(0, 10), min: value} });
  }

  function handleRegisterMaxDate(value) {
    endDate_ = value

    setRegisterMaxDate({ ...registerMaxDate, value: value, inputProps: {max: new Date().toISOString().slice(0, 10), min: startDate_}  });
    setRegisterDate({ ...registerDate, value: startDate_, inputProps: {max: value} });
  }

  function handleGraveChange(value) {
    setGraveName({ ...graveName, value: value });
  }

  function handleVerseChange(value) {
    setVerseName({ ...verseName, value: value });
  }

  function handleNameChange(value, isValid) {
    setName({ ...name, value: value });
  }

  function handleSurnameChange(value, isValid) {
    setSurname({ ...surname, value: value });
  }

  function handleEmailChange(value, isValid) {
    setEmail({ ...email, value: value });
  }

  function isValidForm() {
    if (email.value != "" && !email.isValid) {
      return false;
    } else {
      return true;
    }
  }

  const handleSerach = (event) => {
    event.preventDefault();

    if (isValidForm()) {
      let data = {};

      if (registerDate.value !== undefined) {
        data["startDate"] = registerDate.value;
      }

      if (registerMaxDate.value !== undefined) {
        data["endDate"] = registerMaxDate.value;
      }

      if (type !== null) {
        data["graveType"] = type.id;
      }

      if (graveName.value !== "") {
        data["graveName"] = graveName.value;
      }

      if (verseName.value !== "") {
        data["verseName"] = verseName.value;
      }

      if (name.value !== "") {
        data["name"] = name.value;
      }

      if (surname.value !== "") {
        data["surname"] = surname.value;
      }

      if (email.value !== "") {
        data["email"] = email.value;
      }

      if (isPaid !== null) {
        data["isPaid"] = isPaid.id;
      }

      if (kyc !== null) {
        data["kycStatus"] = kyc.id;
      }

      props.handleSearch(data);
    }
  };

  const YesNo = [
    { id: true, name: "Yes" },
    { id: false, name: "No" },
  ];
  
  const KYCStatus = [
    { id: "WAITING", name: t("bo_grave_form_kyc_waiting") },
    { id: "APPROVED", name: t("bo_grave_form_kyc_approved") },
    { id: "DECLINED", name: t("bo_grave_form_kyc_declined") },
  ];
  
  const GraveType = [
    { id: "FOR_YOU", name: Helpers.capitalizeAllFirst(t("landing_forwhom_foryou")) },
    { id: "FOR_BE_LOVED", name: Helpers.capitalizeAllFirst(t("landing_forwhom_beloved")) },
  ];

  return (
    <Wrapper>
      <div className="rl-admin-content-box">
        <h1>{t("bo_grave_form_grave")}</h1>

        <form className="rl-bo-users-search-form">
          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputDate
                  inputType="text"
                  label={t("bo_grave_form_create_mindate")}
                  data={registerDate}
                  standart={true}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  label={t("bo_grave_form_gravename")}
                  data={graveName}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  label={t("bo_grave_form_versename")}
                  data={verseName}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("bo_grave_form_ispaod")}
                  defaultValue={isPaid}
                  standart={true}
                  onChange={(newValue) => {
                    setIsPaid(newValue);
                  }}
                  options={YesNo}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("bo_grave_form_kyc")}
                  defaultValue={kyc}
                  standart={true}
                  onChange={(newValue) => {
                    setKyc(newValue);
                  }}
                  options={KYCStatus}
                />
              </div>
            </div>
          </div>
          <div className="column">
          <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputDate
                  inputType="text"
                  label={t("bo_grave_form_create_maxdate")}
                  data={registerMaxDate}
                  standart={true}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("bo_grave_form_type")}
                  defaultValue={type}
                  standart={true}
                  onChange={(newValue) => {
                    setType(newValue);
                  }}
                  options={GraveType}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  label={Helpers.capitalizeFirst(t("general_name"))}
                  data={name}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  label={Helpers.capitalizeFirst(t("general_surname"))}
                  data={surname}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  label={t("general_email")}
                  data={email}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <button onClick={handleSerach} className="rl-form-button">
              {t("bo_grave_sub_filterSearch")}
            </button>
          </div>
        </form>
      </div>
    </Wrapper>
  );

  
};

export default BOGraveForm;


