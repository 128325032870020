import Wrapper from "../../UI/Wrapper";

const BOTag = props => {
    return (
        <Wrapper>
            BOTag
        </Wrapper>
    );
}

export default BOTag;