import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useParams } from "react-router-dom";
import { useParams } from "react-router";
import useAxios from "../../../hooks/use-axios";
import RLInput from "../../UI/RLInput";
import RLInputSelect from "../../UI/RLInputSelect";
import RLInputText from "../../UI/RLInputText";
import Wrapper from "../../UI/Wrapper";
import PanelVerseFormItem from "./PanelVerseFormItem";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import Helpers from "../../../utils/Helpers";
import { useContext } from "react";
import AppContext from "../../../utils/app-context";

const PanelVerseForm = (props) => {

  const { t } = useTranslation();
  const params = useParams();

  // const [isValid, setIsValid] = useState(true);
  const [ownVerse, setOwnVerse] = useState({
    value: "",
    isValid: true,
    validation: [],
    onChange: handleOwnVerse,
  });

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {

    if (!error && response !== null && typeof response !== "undefined") {

      if (responseType == "verse/create") {
        setOwnVerse({ ...ownVerse, value: "", isValid: true });
        props.reloadList();

      }

    } else {
      if (error) {

      }

    }
  }, [response, error]);

  function isValidForm() {

    let isValidFormVerseName = ownVerse.value.trim().length >= 2;

    if (!isValidFormVerseName) {
      setOwnVerse({ ...ownVerse, value: ownVerse.value, isValid: isValidFormVerseName });
    }

    return isValidFormVerseName;
  }

  function handleOwnVerse(value, isValid = false) {
    setOwnVerse({ ...ownVerse, value: value, isValid: isValid });

  }

  function handleOwnVerseAdd(event) {
    event.preventDefault();

    const url = "verse/create";

    if (isValidForm()) {
      let data = {
        verseName: ownVerse.value,
        graveId: params["graveId"],
      };

      request({
        method: "POST",
        url: url,
        data: data,
      });
    }

  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <h3 className="rl-verses-titles">{t("verse_form_title")} </h3>

        <form action="" className="rl-add-new-verse-form">

          <div className="rl-form-group">
            <div className="rl-form-group-material">
              {/* <input type="text" className="rl-material-input" value="" />
                <label className="rl-material-label">{t("verse_form_own_verse")}</label> */}
              <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("verse_form_own_verse"))} data={ownVerse} />
            </div>
          </div>

          <button onClick={handleOwnVerseAdd} className="rl-form-button">{t("general_add")}</button>
        </form>

        {!ownVerse.isValid && (
          <div className="rl-form-group">
            <div className="error-line">
              <img src="/img/icon-circle-info.svg" width="24" />
              <span>{t("form_validation_minChar")}</span>
            </div>
          </div>
        )}

      </div>
    </Wrapper>
  );
};

export default PanelVerseForm;
