import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import RLInputText from "../../UI/RLInputText";
import RLInputSelect from "../../UI/RLInputSelect";
import Wrapper from "../../UI/Wrapper";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import PanelContentFormFileItem from "./PanelContentFormFileItem";
import Helpers from "../../../utils/Helpers";
import RLDragAndDrop from "../../UI/RLDragAndDrop";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const PanelContentForm = (props) => {
  const params = useParams();
  let graveId = params["graveId"];

  const appCtx = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  // let nextPage = "";
  const [nextPage, setNextPage] = useState("");

  let btnRef = useRef();
  const [disableNextPrev, setDisableNextPrev] = useState({ next: false, prev: false });

  const isFunnel = Helpers.isFunnel(location);
  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave
  let selectedGraveFunnelSteps = undefined;
  const contentCount = props.count;

  const [file, setFile] = useState([]);
  const [verses, setVerses] = useState({ value: [], isValid: true });
  const [link, setLink] = useState({
    value: "",
    isValid: true,
    disabled: false,
    onChange: linkHandler,
  });
  const [isValid, setIsValid] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  // const [nextPage, setNext]

  function versesHandler(value) {
    setVerses({ ...verses, value: value });
    btnRef.current.removeAttribute("disabled");
  }

  function linkHandler(value) {
    setLink({ ...link, value: value });
    btnRef.current.removeAttribute("disabled");

    if(value != "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }

  function isValidForm() {
    if (verses.value.length > 0) {
      if (file.length > 0 && link.value != "") {
      } else if (file.length > 0 || link.value != "") {
        setIsValid(true);
        return true;
      }
    }

    setIsValid(false);
    setIsUploaded(false);
    return false;
  }

  const addContentHandler = (event) => {
    event.preventDefault();

    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }

    if (isValidForm()) {
      let graveVerseIds = verses.value.map((verse, index) => verse.id);

      let data = undefined;

      if (file.length > 0) {
        data = {
          files: file,
          graveVerseIds: graveVerseIds,
        };
      }

      if (link.value != "") {
        data = {
          link: link.value,
          graveVerseIds: graveVerseIds,
        };
      }

      if (data != undefined && graveVerseIds.length > 0) {
        postContentCreate(data);
      }
    }
  };

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (!error && response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/content/create") {
        props.reloadList();

        setIsUploaded(true);
        setTimeout(() => setIsUploaded(false), 3000);

        setLink({ ...link, value: "", disabled: false});
        setVerses({ ...verses, value: [] });
        setFile([]);
        btnRef.current.removeAttribute("disabled");
        setIsDisabled(false)
        appCtx.hideLoading();

      } else if (responseType == "funnel/move-forward-funnel") {
        if (selectedGrave.graveType == "FOR_YOU") {
          // history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/diary");
          setNextPage("/diary");
          postSelectFunnelStep("DIARY");
        } else if (selectedGrave.graveType == "FOR_BE_LOVED") {
          history.replace("/panelPayment?graveId=" + graveId + "&graveName=" + selectedGrave.name + "&graveType=" + selectedGrave.graveType);
        }

        rlSideBar.funnelMoveForward(rlSideBar, response.data);

      } else if (responseType == "funnel/select-funnel-step") {

        appCtx.hideLoading();
        history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + nextPage);

        rlSideBar.funnelMoveForward(rlSideBar, response.data);

        // appCtx.hideLoading();
      }
    } else {
      if (error) {
        btnRef.current.removeAttribute("disabled");
      }
    }

  }, [response, error]);

  const postContentCreate = (data) => {

    appCtx.showLoading();
    request(
      {
        method: "POST",
        url: "grave/" + graveId + "/content/create",
        data,
      },
      () => { },
      false,
      true
    );
  };

  for (let i = 0; i < selectedGrave.graveFunnelSteps.length; i++) {
    if (selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == "CONTENT") {
      selectedGraveFunnelSteps = selectedGrave.graveFunnelSteps[i]
    }
  }

  const handleBack = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, prev: true });

    // history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/verse");
    // nextPage = "/verse"
    setNextPage("/verse");
    postSelectFunnelStep("VERSE")
  }
  const handleSkipForNow = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, next: true });

    postFunnelMoveForward();
  }
  function postFunnelMoveForward() {

    if (isFunnel) {

      let graveId = -1;
      let graveFunnelStepId = -1;
      let skipped = contentCount > 0 ? false : true;

      graveId = selectedGraveFunnelSteps.graveId
      graveFunnelStepId = selectedGraveFunnelSteps.id

      if (selectedGrave.graveType == "FOR_BE_LOVED") {
        history.replace("/panelPayment?graveId=" + graveId + "&graveName=" + selectedGrave.name + "&graveType=" + selectedGrave.graveType);
        return
      }

      if (selectedGraveFunnelSteps.status == "COMPLETED") {
        if (selectedGrave.graveType == "FOR_YOU") {
          // history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/diary");
          // nextPage = "/diary"
          setNextPage("/diary");
          postSelectFunnelStep("DIARY");
        } else if (selectedGrave.graveType == "FOR_BE_LOVED") {
          history.replace("/panelPayment?graveId=" + graveId + "&graveName=" + selectedGrave.name + "&graveType=" + selectedGrave.graveType);
        }
        return;
      }

      appCtx.showLoading();

      const data = {
        graveId: graveId,
        graveFunnelStepId: graveFunnelStepId,
        skipped: skipped,
      };

      request({
        method: "POST",
        url: "funnel/move-forward-funnel",
        data,
      });
    }
  }

  function postSelectFunnelStep(type) {
    if (isFunnel) {
      appCtx.showLoading();
      let graveId = -1;
      let graveFunnelStepId = -1;

      for (let i = 0; i < selectedGrave.graveFunnelSteps.length; i++) {
        if (selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == type) {
          graveId = selectedGrave.graveFunnelSteps[i].graveId
          graveFunnelStepId = selectedGrave.graveFunnelSteps[i].id
        }
      }

      const data = {
        graveId: graveId,
        graveFunnelStepId: graveFunnelStepId,
      };

      request({
        method: "POST",
        url: "funnel/select-funnel-step",
        data,
      });
    }
  }
  const handleCompleteGraveYard = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, next: true });

    postFunnelMoveForward();
  }
  const handleFunnelNextButton = () => {
    if (selectedGrave.graveType == "FOR_YOU") {
      let nextButton = "";
      if (isFunnel) {
        if (selectedGraveFunnelSteps.status == "IN_PROGRESS" && (contentCount == undefined || contentCount == 0)) {
          nextButton = "general_skipfornow"
        } else {
          nextButton = "general_next"
        }
      }

      return (
        <div className="column">
          <a href="" onClick={handleSkipForNow} className="rl-panel-wizard-btn-skip w-icon" style={{ fontWeight: "bold", pointerEvents: disableNextPrev.next ? 'none' : 'auto' }}>{t(nextButton)} <img src="/img/icon-chevron-right.svg" alt="" /></a>
        </div>
      )
    } else if (selectedGrave.graveType == "FOR_BE_LOVED") {
      return (
        <div className="column">
          <a href="" onClick={handleCompleteGraveYard} className="rl-complete-graveyard-button" style={{ pointerEvents: disableNextPrev.next ? 'none' : 'auto' }}>{t("panel_navigation_kyc_complete")}</a>
        </div>
      )
    }
  }

  function handleSetFile(file) {
    setFile(file)
    btnRef.current.removeAttribute("disabled");

    if(file.length > 0) {
      setLink({ ...link, disabled: true });
    } else {
      setLink({ ...link, disabled: false });
    }
  }

  return (
    <Wrapper>

      <RLDragAndDrop file={file} isUploaded={isUploaded} isDisabled={isDisabled} onFileChange={(file) => handleSetFile(file)} multiple={true} />

      <form onSubmit={addContentHandler} className={`${"rl-bo-users-search-form"} ${isFunnel ? "rl-w-skip-prev" : ""}`}>

        {isFunnel && (
          <div className="column">
            <a href="" onClick={handleBack} class="rl-panel-wizard-btn-skip w-icon left" style={{ fontWeight: "bold", pointerEvents: disableNextPrev.prev ? 'none' : 'auto' }}>
              <img src="/img/icon-chevron-right.svg" alt="Testaments" /> {Helpers.capitalizeAllFirst(t("panel_navigation_verses"))}
            </a>
          </div>
        )}

        <div className="column">
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputSelect
                label={t("content_form_selectverse")}
                defaultValue={verses.value}
                standart={true}
                onChange={versesHandler}
                options={props.RLVerses}
                isMultiple={true}
              />
            </div>
          </div>
        </div>

        <div className="column">
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="text"
                label={t("content_form_orlink")}
                data={link}
              />
            </div>
          </div>
        </div>

        <div className="column">
          <button ref={btnRef} className="rl-form-button">{t("general_add")}</button>
        </div>

        {isFunnel && (

          handleFunnelNextButton()
        )}


      </form>
      {!isValid && (
        <div className="rl-form-group">
          <div className="error-line">
            <img src="/img/icon-circle-info.svg" width="24" />
            <span>{t("content_form_validationMessage")}</span>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default PanelContentForm;
