import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Helpers from "../../../../utils/Helpers";
import Wrapper from "../../../UI/Wrapper";
import useAxios from "../../../../hooks/use-axios";

const BOGraveDetailSubsItem = (props) => {

    const { t } = useTranslation();
    const params = useParams();

    const sub = props.item
    
    const { response, loading, error, request, responseType } = useAxios();

    useEffect(() => {
      if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/sub/invoice/" + sub.id
      ) {
        const type = "application/octet-stream";
        const blob = new Blob([response], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "invoice.pdf";
        link.click();
      }
    }, [response, error]);

    const handleContentOpen = (event) => {
      event.preventDefault();
  
      request({
        method: "GET",
        url: "backOffice/grave/" + params["graveId"] + "/sub/invoice/" + sub.id,
        responseType: "blob",
      });
    };

  return (
    <Wrapper>
      <tr>
        <td className="fw-600">{t("bo_grave_sub_filterPaymentMethod_" + sub.paymentMethod)}</td>
        <td className="rl-table-border-l fw-600">{t("bo_grave_sub_filterStatus_" + sub.orderStatus)}</td>
        <td className="rl-table-border-l fw-600">${sub.productFinalAmount}</td>
        <td className="rl-table-border-l fw-600">{Helpers.formatDate(sub.orderDate)}</td>
        <td className="rl-table-border-l fw-600">{sub.customerName}</td>
        <td className="w-30 text-right">
          <a href="" onClick={handleContentOpen} className="rl-link-black">
            <img
              src="/img/icon-download.svg"
              alt="Download"
              width="20"
              height="20"
            />{" "}
            {t("general_download")}
          </a>
        </td>
      </tr>
    </Wrapper>
  );
};

export default BOGraveDetailSubsItem;
