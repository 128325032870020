import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper";

import RLInputSelect from "../../UI/RLInputSelect";
import RLInputDate from "../../UI/RLInputDate";
import Helpers from "../../../utils/Helpers";
import RLInputText from "../../UI/RLInputText";
import { useState } from "react";

const BOUserForm = (props) => {
  const { t } = useTranslation();

  const [registerDate, setRegisterDate] = useState({
    value: undefined,
    onChange: handleRegisterDate,
    inputProps: {max: new Date().toISOString().slice(0, 10)}
  });
  const [endDate, setEndDate] = useState({
    value: undefined,
    onChange: handleEndDate,
    inputProps: {max: new Date().toISOString().slice(0, 10)}
  });
  
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");

  const [name, setName] = useState({
    value: "",
    isValid: true,
    onChange: handleNameChange,
  });
  const [surname, setSurname] = useState({
    value: "",
    isValid: true,
    onChange: handleSurnameChange,
  });
  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    onChange: handleEmailChange,
  });

  let startDate_ = registerDate.value
  let endDate_ = endDate.value

  function handleRegisterDate(value) {
    startDate_ = value

    if(startDate_ > endDate_) {
      endDate_ = undefined
    }

    setRegisterDate({ ...registerDate, value: value });
    setEndDate({ ...endDate, value: endDate_, inputProps: {max: new Date().toISOString().slice(0, 10), min: value} });
  }

  function handleEndDate(value) {
    // console.log(value)
    endDate_ = value
    setEndDate({ ...endDate, value: value, inputProps: {max: new Date().toISOString().slice(0, 10), min: startDate_}  });
    setRegisterDate({ ...registerDate, value: startDate_, inputProps: {max: value} });
  }

  function handleNameChange(value) {
    setName({ ...name, value: value });
  }

  function handleSurnameChange(value) {
    setSurname({ ...surname, value: value });
  }

  function handleEmailChange(value) {
    setEmail({ ...email, value: value });
  }

  function isValidForm() {
    if (email.value != "" && !email.isValid) {
      return false;
    } else {
      return true;
    }
  }

  const handleSerach = (event) => {
    event.preventDefault();

    if (isValidForm()) {
      let data = {};

      if (registerDate.value !== undefined) {
        data["startDate"] = registerDate.value;
      }

      if (endDate.value !== undefined) {
        data["endDate"] = endDate.value;
      }

      if (name.value !== "") {
        data["name"] = name.value;
      }

      if (surname.value !== "") {
        data["surname"] = surname.value;
      }

      if (email.value !== "") {
        data["email"] = email.value;
      }

      if (type !== null) {
        data["userType"] = type.id;
      }

      if (status !== null) {
        data["userStatus"] = status.id;
      }

      props.handleSearch(data);
    }
  };

  return (
    <Wrapper>
      <div className="rl-admin-content-box">
        <h1>{t("bo_user_form_user")}</h1>

        <form className="rl-bo-users-search-form">
          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputDate
                  inputType="text"
                  label={t("bo_user_form_register_mindate")}
                  data={registerDate}
                  standart={true}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputDate
                  inputType="text"
                  label={t("bo_user_form_register_maxdate")}
                  data={endDate}
                  standart={true}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("bo_user_form_status")}
                  defaultValue={status}
                  standart={true}
                  onChange={(newValue) => {
                    setStatus(newValue);
                  }}
                  options={UserStatus}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("bo_user_form_type")}
                  defaultValue={type}
                  standart={true}
                  onChange={(newValue) => {
                    setType(newValue);
                  }}
                  options={UserType}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_name"))} data={name} />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  label={Helpers.capitalizeFirst(t("general_surname"))}
                  data={surname}
                />
              </div>
            </div>
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_email"))} data={email} />
              </div>
            </div>
          </div>
          <div className="column">
            <button onClick={handleSerach} className="rl-form-button">
              Search
            </button>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default BOUserForm;

const UserStatus = [
  { id: "ACTIVE", name: "Active" },
  { id: "CANCELED", name: "Passive" },
  // { id: "DELETED", name: "Deleted" },
];

const UserType = [
  { id: "OWNER", name: "Owner" },
  { id: "PURCHASER", name: "Purchase" },
  { id: "GUARDIAN", name: "Guardian" },
  { id: "ADMIN", name: "Admin" },
];
