import React, { useState, useTransition } from "react";
import { createPortal } from "react-dom";
// import styled from "styled-components";
// import { useTransition } from "react-spring";
import Toast from "./Toast";
import Wrapper from "../Wrapper";
import { CSSTransition } from "react-transition-group";

// import Toast from "../Toast";

// const Wrapper = styled.div`
//   position: absolute;
//   right: 0;
//   top: 0;
//   z-index: 1;
// `;

const ToastContainer = ({ toasts }) => {
  // const transitions = useTransition(toasts, (toast) => toast.id, {
  //   from: { right: "-100%" },
  //   enter: { right: "0%" },
  //   leave: { right: "-100%" },
  // });

  const [isShow, setIsShow] = useState(false);

  let itemList = "";
  if (toasts.length > 0) {
    // itemList = toasts.map((item) => (
    //   <Toast id={item.id} key={item.id} item={item} type={item.type} />
    // ));

    let item = toasts[0];
    itemList = (
      <Toast id={item.id} key={item.id} item={item} type={item.type} />
    );
  }

  return createPortal(
    <CSSTransition in={true} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <Wrapper>{itemList}</Wrapper>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default ToastContainer;
