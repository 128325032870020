import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLInputSelect from "../../UI/RLInputSelect";
import Wrapper from "../../UI/Wrapper";

const PanelContentListItem = (props) => {
  const appCtx = useContext(AppContext);
  const params = useParams();
  const { t } = useTranslation();

  const content = props.item;

  let defaultValue = {};
  if (content.graveVerseId != null) {
    defaultValue = { id: content.graveVerseId, name: content.verseName };
  }

  const [selectedVerse, setSelectedVerse] = useState(defaultValue);
  const [selectedVerse_, setSelectedVerse_] = useState(defaultValue);
  const [edit, setEdit] = useState(false);

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + params["graveId"] + "/content/delete") {
        props.removeContent();
      } else if (
        responseType ==
        "grave/" + params["graveId"] + "/content/update/" + content.id + "/"
      ) {
        props.removeContent();
        setEdit(!edit);
        appCtx.addToast(t("content_list_item_updated"), "green");
      } else if (
        responseType ==
        "grave/" + params["graveId"] + "/content/duplicate/" + content.id + "/"
      ) {
        props.removeContent();
        appCtx.addToast(t("content_list_item_duplicated"), "green");
      } else if (
        responseType ==
        "grave/" +
          params["graveId"] +
          "/content/view/" +
          content.content.guid +
          "/"
      ) {
        window.open("data:image/png;" + response, "_blank", "noreferrer");
      }
    }
  }, [response, error]);

  const postRemoveContent = (event) => {
    event.preventDefault();

    const data = [content.id];

    request({
      method: "DELETE",
      url: "grave/" + params["graveId"] + "/content/delete",
      data,
    });
  };

  const handleRemoveConfirm = event => {
    postRemoveContent(event);
  }

  const handleRemove = event => {
    event.preventDefault();

    const verseDetailModal = {
      type: "confirmationModal",
      show: true,
      params: {
        title: t("confirmation_remove_title"),
        description: t("confirmation_remove_desc"),
        buttonName: "Remove"
      },
      onConfirm: handleRemoveConfirm
    };
    appCtx.showModal(verseDetailModal);
  }

  const postDuplicateContent = (event) => {
    event.preventDefault();

    request({
      method: "POST",
      url:
        "grave/" + params["graveId"] + "/content/duplicate/" + content.id + "/",
    });
  };

  const versesChangeHandler = (event) => {
    if(event != null) {
      defaultValue = event;
      setSelectedVerse(event);
    }
  };

  const postEditContent = (event) => {
    event.preventDefault();

    const data = {
      graveVerseId: selectedVerse.id,
    };

    request({
      method: "POST",
      url: "grave/" + params["graveId"] + "/content/update/" + content.id + "/",
      data,
    });
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setEdit(!edit);
  };

  const handleCancelEdit = (event) => {
    event.preventDefault();
    setEdit(!edit);
    setSelectedVerse(selectedVerse_)
  }

  const handleSaveEdit = (event) => {
    event.preventDefault();

    const data = {
      graveVerseId: selectedVerse.id,
    };

    request({
      method: "POST",
      url: "grave/" + params["graveId"] + "/content/update/" + content.id + "/",
      data,
    });
  }

  const handleContentOpen = (event) => {
    event.preventDefault();

    let link = "";
    if (content.content.link!= undefined) {
      link = content.content.link;

      if(!content.content.link.startsWith("http")) {
        link = "https://"+content.content.link;
      }

    } else {
      link = content.content.file.url;
    }

    window.open(link, "_blank", "noreferrer");
  };

  const contentTypeIcon = "";

  return (
    <Wrapper>
      <tr>
        {/* <td>
          <input type="checkbox" className="form-checkbox" />
        </td> */}

        <td className="fw-600">{t("general_enum_"+content.content.contentStatus)}</td>

        <td className="rl-table-border-l w-30 fw-600">
          {edit && (
            <RLInputSelect
              label={t("content_form_selectverse")}
              defaultValue={selectedVerse}
              standart={true}
              onChange={versesChangeHandler}
              options={props.RLVerses}
            />
          )}
          {!edit && selectedVerse.name}
        </td>

        <td className="rl-table-border-l">
          <a
            href=""
            onClick={handleContentOpen}
            className="rl-content-link"
          >
            {Helpers.getFileIcon(content.content.contentType)}{" "}
            {content.content.link != undefined
              ? content.content.link
              : content.content.file.originalFileName}
          </a>
        </td>

        <td className="w-40 text-right">
          {!edit && (
            <Wrapper>
              {/* <a
                href=""
                className="rl-link-black"
                rel="modal:open"
                onClick={postDuplicateContent}
              >
                <img src="/img/icon-copy.svg" alt="Add to Guardian" /> {t("general_duplicate")}
              </a> */}

              <a
                href=""
                className="rl-table-button blue"
                // onClick={postEditContent}
                onClick={handleEdit}
              >
                {t("general_edit")}
              </a>

              <a
                href=""
                className="rl-table-button red"
                rel="modal:open"
                onClick={handleRemove}
              >
                {t("general_remove")}
              </a>
            </Wrapper>
          )}
          {edit && (
            <Wrapper>
              <a
                href=""
                className="rl-table-button blue"
                onClick={handleSaveEdit}
              >
                {t("general_save")}
              </a>
              <a
                href=""
                className="rl-table-button red"
                rel="modal:open"
                onClick={handleCancelEdit}
              >
                {t("general_cancel")}
              </a>
            </Wrapper>
          )}
        </td>
      </tr>
    </Wrapper>
  );
};

export default PanelContentListItem;
