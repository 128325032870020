import { useEffect, useState } from "react";
import AccountMain from "../components/Account/AccountMain";
import AccountSideBar from "../components/Account/AccountSideBar";
import PanelHeader from "../components/Panel/PanelHeader";
import PanelSideBar from "../components/Panel/PanelSideBar/PanelSideBar";
import Card from "../components/UI/Card";
import Wrapper from "../components/UI/Wrapper";
import useAxios from "../hooks/use-axios";
import Helpers from "../utils/Helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const AccountPage = (props) => {
  const history = useHistory();
  const [data, setData] = useState([]);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  const [rlSideBar, setRLSideBar] = useState({
    selectedGrave: {},
    selectedGraveType: {},
    sideBar: {},
    graveList: [],
    isAccount: true,
    changeGraveType: handleChangeGraveType,
    changeGrave: handleChangeGrave,
    // reloadSideBar: handleReloadSideBar,
    // funnelMoveForward: handleFunnelMoveForward
})

  function handleChangeGraveType(_rlSideBar, graveType) {
    // setRLSideBar({ ..._rlSideBar, selectGrave: {}, selectedGraveType: graveType })

    // selectGrave();
    selectGraveByType(_rlSideBar, graveType)
  }

  function handleChangeGrave(_rlSideBar, selectedGrave) {
    let _selectedGrave = Helpers.getGraveFromSideBar(_rlSideBar.sideBar, selectedGrave.id)

    const path = window.location.pathname;
    const newPath = _selectedGrave.hasSubscription ? "panel" : "funnel";

    history.replace(`/${newPath}/` + _selectedGrave.id);
  }

  useEffect(() => {
    if (endpoint == "first" ) {
      getSideBar();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "panel/sideMenu2") {
        // setData(response.data);
        setRLSideBar({ ...rlSideBar, sideBar: response })
      }
    }
  }, [response, error]);

  function getSideBar() {
    const url = "panel/sideMenu2";
    setEndpoint(url)
    request({
      method: "GET",
      url: url,
    });
  }

  useEffect(() => {
    if(Object.keys(rlSideBar.selectedGrave).length == 0) {
      selectGrave()
    }
    
  }, [rlSideBar.sideBar]);

  // useEffect(() => {
  //   if(Object.keys(rlSideBar.selectedGrave).length > 0) {
  //     selectGraveByType()
  //   }
    
  // }, [rlSideBar.selectedGraveType]);

  function selectGrave() {
    let selectedGrave_ = {};
    if (Object.keys(rlSideBar.sideBar).length > 0) {
      if(rlSideBar.sideBar?.FOR_YOU.length > 0 && rlSideBar.sideBar.FOR_YOU[0].graves.length > 0) {

        selectedGrave_ = rlSideBar.sideBar.FOR_YOU[0].graves[0];
      } else if(rlSideBar.sideBar?.FOR_BE_LOVED.length > 0 && rlSideBar.sideBar.FOR_BE_LOVED[0].graves.length > 0) {

        selectedGrave_ = rlSideBar.sideBar.FOR_BE_LOVED[0].graves[0];
      }
    }

    if (Object.keys(selectedGrave_).length > 0) {
      setRLSideBar({ ...rlSideBar, selectedGrave: selectedGrave_, selectedGraveType: selectedGrave_.graveType })
    
    }
  }

  function selectGraveByType(_rlSideBar, graveType) {
    let selectedGrave_ = {};

    if (Object.keys(_rlSideBar.sideBar).length > 0) {
      if(_rlSideBar.sideBar[graveType].length > 0 && _rlSideBar.sideBar[graveType][0].graves.length > 0) {
        selectedGrave_ = _rlSideBar.sideBar[graveType][0].graves[0];
      }
    }

    if (Object.keys(selectedGrave_).length > 0) {
      setRLSideBar({ ..._rlSideBar, selectedGrave: selectedGrave_, selectedGraveType: selectedGrave_.graveType })
    
    }
  }

  return (
    <Wrapper>
      {/* <PanelHea */}
      <PanelHeader />
      {/* <AccountSideBar /> */}
      {(Object.keys(rlSideBar.selectedGrave).length > 0 && Object.keys(rlSideBar.sideBar).length > 0) && (
        <PanelSideBar rlSideBar={rlSideBar} type={'account'}/>
      )}
      
      <Card className="rl-admin-panel-content">
        <AccountMain />
      </Card>
    </Wrapper>
  );
};

export default AccountPage;
