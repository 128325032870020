import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/use-axios";
import RLInputSelect from "../UI/RLInputSelect";
import RLInputSelectStandart from "../UI/RLInputSelectStandart";
import RLInputText from "../UI/RLInputText";
import Wrapper from "../UI/Wrapper";

const PanelPaymentCardItemNew = (props) => {
  const { t } = useTranslation();

  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i.toString());
  }

  // cosnt a = Date()
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= 10; i++) {
    years.push((currentYear + i).toString());
  }

  const [name, setName] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleNameChange,
  });

  const [number, setNumber] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "cardNumber"],
    onChange: handleNumberChange,
  });

  const [month, setMonth] = useState({
    value: [""],
    isValid: true,
    validation: ["empty"],
    onChange: handleMonthChange,
    options: months,
  });

  const [year, setYear] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleYearChange,
    options: years,
  });

  const [cvc, setCvc] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "cvc"],
    onChange: handleCvcChange,
  });

  const [is3d, setIs3d] = useState(false);
  const [isSave, setIsSave] = useState(false);

  function handleNameChange(value, isValid = false) {
    setName({ ...name, value: value, isValid: isValid });
  }

  function handleNumberChange(value, isValid = false) {
    setNumber({ ...number, value: value, isValid: isValid });
  }

  function handleMonthChange(value, isValid = false) {
    setMonth({ ...month, value: value, isValid: isValid });
  }

  function handleYearChange(value, isValid = false) {
    setYear({ ...year, value: value, isValid: isValid });
  }

  function handleCvcChange(value, isValid = false) {
    setCvc({ ...cvc, value: value, isValid: isValid });
  }

  function isValidForm() {
    let isValidFormName = name.isValid;
    if (isValidFormName && name.validation.includes("empty")) {
      isValidFormName = name.value == "" ? false : true;
      setName({ ...name, isValid: isValidFormName });
    }

    let isValidFormNumber = number.isValid;
    if (isValidFormNumber && number.validation.includes("empty")) {
      isValidFormNumber = number.value == "" ? false : true;
      setNumber({ ...number, isValid: isValidFormNumber });
    }

    let isValidFormMonth = month.isValid;
    if (isValidFormMonth && month.validation.includes("empty")) {
      isValidFormMonth = month.value == "" ? false : true;
      setMonth({ ...month, isValid: isValidFormMonth });
    }

    let isValidFormYear = year.isValid;
    if (isValidFormYear && year.validation.includes("empty")) {
      isValidFormYear = year.value == "" ? false : true;
      setYear({ ...year, isValid: isValidFormYear });
    }

    let isValidFormCvc = cvc.isValid;
    if (isValidFormCvc && cvc.validation.includes("empty")) {
      isValidFormCvc = cvc.value == "" ? false : true;
      setCvc({ ...cvc, isValid: isValidFormCvc });
    }

    return (
      isValidFormName &&
      isValidFormNumber &&
      isValidFormMonth &&
      isValidFormYear &&
      isValidFormCvc
    );
  }

  const { response, loading, error, request } = useAxios();

  useEffect(() => {
    if (!error && response !== null && typeof response !== "undefined") {
      if (response.data) {
        props.assignAdmin(response.data);
      }
    }
  }, [response, error]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isValidForm()) {
      const creditCard = {
        // cardUserKey: "string",
        cardHolderName: name.value,
        cardNumber: number.value,
        expireYear: year.value,
        expireMonth: month.value,
        cvc: cvc.value,
        storeCard: isSave,
        payWith3d: is3d,
      };

      // props.handleSelectedCard(creditCard, "NEW");
      props.handleSubmit(creditCard, "NEW");
    }
  };

  return (
    <Wrapper>
      <div className="rl-new-card-content">
        <form>
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="text"
                label={t("panelPayment_cardHolderName")}
                data={name}
              />
            </div>
          </div>
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="text"
                inputFeature="number"
                label={t("panelPayment_cardNumber")}
                data={number}
              />
            </div>
          </div>
          <div className="rl-form-group">
            <h4>{t("panelPayment_cardExpirationDate")}</h4>

            <div className="rl-card-expire-info">
              <div className="rl-form-group-material" style={{minWidth:"120px"}}>
                <RLInputSelectStandart
                  label={t("panelPayment_month")}
                  standart={true}
                  onChange={(newValue) => {
                    // setIsPaid(newValue);
                  }}
                  data={month}
                />
              </div>

              <div className="rl-form-group-material" style={{minWidth:"120px"}}>
                {/* <RLInputText
                  inputType="text"
                  label={t("panelPayment_year")}
                  data={year}
                /> */}
                <RLInputSelectStandart
                  label={t("panelPayment_year")}
                  standart={true}
                  onChange={(newValue) => {
                    // setIsPaid(newValue);
                  }}
                  data={year}
                />
              </div>

              <div className="rl-form-group-material">
                <div style={{minWidth:"100px"}}>
                  <RLInputText
                    inputType="text"
                    label={t("panelPayment_cvc")}
                    data={cvc}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="rl-form-group">
            <label htmlFor="12" className="form-checkbox-label">
              <input
                type="checkbox"
                id="12"
                className="form-checkbox"
                checked={is3d}
                onChange={() => setIs3d(!is3d)}
              />
              {t("panelPayment_payWith3d")}
            </label>
          </div>

          {props.paymentPlan == "ONE_TIME" ? (
            <div className="rl-form-group">
              <label htmlFor="13" className="form-checkbox-label">
                <input
                  type="checkbox"
                  id="13"
                  className="form-checkbox"
                  checked={isSave}
                  onChange={() => setIsSave(!isSave)}
                />
                {t("panelPayment_saveCard")}
              </label>
            </div>
          ) : (
            <div class="rl-save-card-info">
              <img
                src="img/icon-circle-info.svg"
                alt=""
                width="16"
                height="16"
              />{" "}
              {t("panelPayment_saveCard_info")}
            </div>
          )}

          <div className="text-center">
            <button
              onClick={handleSubmit}
              type="button"
              className="rl-form-button"
            >
              {t("general_submit")}
            </button>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default PanelPaymentCardItemNew;

const YesNo = [
  { id: true, name: "Yes" },
  { id: false, name: "No" },
];
