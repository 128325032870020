import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import Wrapper from "./Wrapper";
import isEmail from "validator/lib/isEmail";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Helpers from "../../utils/Helpers";
import { useTranslation } from "react-i18next";
import RLTextField from "./RLTextField";
import RLButton from "./RLButton";
import RLTextArea from "./RLTextArea";
import { TextareaAutosize, TextField } from "@mui/material";

const RLInputText = (props) => {
  const { t } = useTranslation();
  const defaultValidation = t("validation_error");

  const [input, setInput] = useState({
    value: props.data.value,
    isValid: props.data.isValid,
    disabled: props.data.disabled,
    validation: props.data.validation,
    validationMessage: defaultValidation,
    onChange: props.data.onChange,
    onSaveSetting: props.data.onSaveSetting,
  });

  const input_ = props.data;

  const [checkValidation, setCheckValidation] = useState(false);
  const [inputType, setInputType] = useState(props.inputType);
  const [showPassword, setShowPassword] = useState(false);
  const [showEdit, setShowEdit] = useState(true);

  function findValidity(val) {
    let isValidVar = true;
    let validMsg = defaultValidation;

    if (input.validation) {
      input.validation.map((validator) => {
        if (validator == "empty") {
          isValidVar = val.trim() !== "";

          if (!isValidVar) {
            validMsg = t("form_validation_notempty");
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }

        if (validator == "min") {
          const charCount = val.trim().length

          if (charCount < 2) {
            isValidVar = false
            validMsg = t("form_validation_minChar");
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }

        if (validator == "max30") {
          const charCount = val.trim().length

          if (charCount > 30) {
            isValidVar = false
            validMsg = t("form_validation_max30Char");
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }

        if (validator == "max") {
          const charCount = val.trim().length

          if (charCount > 60) {
            isValidVar = false
            validMsg = t("form_validation_maxChar");
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }

        if (validator == "email") {
          if (
            isEmail(val) ||
            (val.trim() === "" && !input.validation.includes("empty"))
          ) {
            isValidVar = true;
          } else {
            isValidVar = false;
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }

        if (validator == "password") {
          let errMsg = Helpers.passwordVerification(val, t);

          if (errMsg === "") {
            isValidVar = true;
          } else {
            isValidVar = false;
            validMsg = errMsg;
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }

        if (validator == "cardNumber") {
          let errMsg = Helpers.creditCardVerification(val, t);

          if (errMsg === "") {
            isValidVar = true;
          } else {
            isValidVar = false;
            validMsg = errMsg;
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }

        if (validator == "month") {
          let errMsg = Helpers.monthVerification(val, t);

          if (errMsg === "") {
            isValidVar = true;
          } else {
            isValidVar = false;
            validMsg = errMsg;
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }

        if (validator == "cvc") {
          let errMsg = Helpers.cvcVerification(val, t);

          if (errMsg === "") {
            isValidVar = true;
          } else {
            isValidVar = false;
            validMsg = errMsg;
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }
      });
    }

    return { isValidVar: isValidVar, validMsg: validMsg };
  }

  function handleChange(event) {
    const val = event.target.value;

    const isValid = findValidity(val);

    setInput({
      ...input,
      value: val,
      isValid: isValid.isValidVar,
      validationMessage: isValid.validMsg,
    });

    input.onChange(val, isValid.isValidVar);
  }

  if (input_.isValid && input_.value == "" && checkValidation) {
    setCheckValidation(false);
  }

  if (!input_.isValid && !checkValidation) {
    setCheckValidation(true);

    const isValid = findValidity(input_.value);

    setInput({
      ...input,
      isValid: isValid.isValidVar,
      validationMessage: isValid.validMsg,
    });
  }

  function handleInputType() {
    if (props.inputType == "password" && showPassword) {
      setInputType("text");
    } else {
      setInputType(props.inputType);
    }
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);

    // handleInputType();
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickEdit = (event) => {
    event.preventDefault();

    if (props.inputFeature == "accountSettingsPassword") {
      props.openModal();
    } else {
      if (input.isValid) {
        if (!showEdit) {
          input.onSaveSetting(props.data.value);
        }

        setShowEdit(!showEdit);
      }
    }
  };

  const handleClickResend = (event) => {
    event.preventDefault();

    if (props.inputFeature == "accountSettingsResend") {
      props.resendEmail();
    }
  };

  var autoCompleteProps = {};
  if (props.autoComplete == "off") {
    autoCompleteProps = {
      id: Date.now().toString()
    };
  }

  var inputLabelProps = {};
  if (props.standart) {
    inputLabelProps = {
      InputLabelProps: {
        shrink: true,
      },
    };
  }

  var multilineProps = {};
  if (props.multiline) {
    multilineProps = {
      multiline: true,
      // rows:props.rows,
      // maxRows:props.rows
    };
  }

  let disableProps = {};
  if (
    showEdit &&
    (props.inputFeature === "accountSettings" ||
      props.inputFeature === "accountSettingsPassword")
  ) {
    disableProps = {
      disabled: true,
    };
  } else if (input_.disabled) {
    disableProps = {
      disabled: true,
    };
  }

  let inputAdornment = "";
  if (props.inputFeature === "password") {
    inputAdornment = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {!showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  } else if (props.inputFeature === "accountSettingsResend") {
    inputAdornment = (
      <InputAdornment position="end">
        <RLButton
          text={t("general_resend")}
          buttonType="accountSettings"
          onClick={handleClickResend}
        />
      </InputAdornment>
    );
  } else if (
    props.inputFeature === "accountSettings" ||
    props.inputFeature === "accountSettingsPassword"
  ) {
    inputAdornment = (
      <InputAdornment position="end">
        {showEdit ? (
          <a onClick={handleClickEdit}>
            <img
              src="/img/icon-change.svg"
              alt="Change"
              class="rl-account-change-icon"
            ></img>
          </a>
        ) : (
          <RLButton
            text={t("general_save")}
            buttonType="accountSettings"
            onClick={handleClickEdit}
          />
        )}
      </InputAdornment>
    );
  }

  let defValue = null;
  if (input_.value != undefined) {
    defValue = input_.value;
  }

  let type = props.inputType;
  if (props.inputFeature != undefined) {
    if (props.inputFeature == "number") {
      type = "number";
    } else if (!showPassword && props.inputFeature == "password") {
      type = "password";
    }
  }

  return (
    <Wrapper>
      {props.inputType == "textArea" && (
        <RLTextArea
          style={{ height: 180 }}
          label={Helpers.capitalizeAllFirst(t(props.label))}
          variant="filled"
          fullWidth
          multiline
          value={defValue}
          onChange={handleChange}
          rows={6}
        />
      )}
      {(props.inputType == "text" || props.inputType == "password") && (
        <RLTextField
          label={Helpers.capitalizeAllFirst(t(props.label))}
          type={type}
          autoComplete="off"
          variant="filled"
          fullWidth
          value={defValue}
          onChange={handleChange}
          InputProps={{
            disableUnderline: true,
            endAdornment: inputAdornment,
          }}
          {...inputLabelProps}
          {...multilineProps}
          {...disableProps}
          {...autoCompleteProps}
        />
      )}
      {!input.isValid && (
        <div className="error-line">
          <img
            src="/img/icon-circle-info.svg"
            alt={Helpers.capitalizeAllFirst(t("general_email_error"))}
            width="24"
          />
          <span>{input.validationMessage}</span>
        </div>
      )}
    </Wrapper>
  );
};

export default RLInputText;
