import Card from "./Card";

const Image = (props) => {
  return (
    <Card className="">
      <img className={props.className} src={props.src} alt={props.alt} />
    </Card>
  );
};

export default Image;
