import { useState } from "react";
import RLModal from "./RLModal/RLModal";
import { SplittingJS } from "./Splitting";
import Wrapper from "./Wrapper";

const RLButton = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [secondModalType, setSecondModalType] = useState("");

  const onClickHandler = (event) => {
    // event.preventDefault();
    // if (event.target === event.currentTarget) {
      if (props.modalType) {
        setShowModal(true);
      } else if (props.buttonType == "accountSettings") {
        props.onClick();
      } else if (props.onClick) {
        props.onClick();
      }
    // }
  };

  const closeModalHandler = (event) => {
    if (event.target === event.currentTarget) {
      if(event.target != "event") {
        event.preventDefault();
      }
      setShowModal(false);
      setShowSecondModal(false);
    }
  };

  function openModal(type = "first", secondModal = "") {
    if (type == "first") {
      setShowModal(true);
      setShowSecondModal(false);
    } else if (type == "second") {
      setSecondModalType(secondModal)
      setShowModal(false);
      setShowSecondModal(true);
    } else if (type == "closeAll") {
      setShowModal(false);
      setShowSecondModal(false);
    }
  }

  return (
    <Wrapper>
      {props.buttonType == "splitting" && (
        <SplittingJS
          onClick={props.onClick}
          className={props.className}
          text={props.text}
          styleClass={props.styleClass}
        />
      )}

      {props.buttonType == "a" && (
        <a
          style={{ cursor: "pointer" }}
          className={props.className}
          onClick={(props.showSecondModal || props.showThirdModal) ? props.onClick : onClickHandler}
          rel="modal:open"
        >
          {props.img && <img src={props.img} />}
          {props.text}
        </a>
      )}

      {props.buttonType == "accountSettings" && (
        <a
          style={{ cursor: "pointer" }}
          // className={props.className}
          className={"rl-account-details-form-rl-account-save-input-btn"}
          onClick={props.onClick}
          // rel="modal:open"
        >
          {props.text}
        </a>
      )}

      {!props.buttonType && (
        <button
          onClick={props.showSecondModal ? props.onClick : onClickHandler}
          className={"rl-form-button " + props.className}
        >
          {props.text}
        </button>
      )}

      {props.modalType && (
        <Wrapper>
          <RLModal
            title="My Modal"
            openModal={openModal}
            onClose={closeModalHandler}
            show={showModal}
            type={props.modalType}
          />
          <RLModal
            title="My Modal"
            // openModal={() => openModal("first")}
            openModal={openModal}
            onClose={closeModalHandler}
            show={showSecondModal}
            type={secondModalType}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default RLButton;
