import { useState } from "react";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import Wrapper from "../UI/Wrapper";
import PanelPaymentCardItem from "./PanelPaymentCardItem";
import PanelPaymentCardItemNew from "./PanelPaymentCardItemNew";
import RLInputText from "../UI/RLInputText";

const PanelPaymentCard = (props) => {
  const { t } = useTranslation();

  const cards = props.items;
  const cardType_ = cards.length > 0 ? "REGISTERED" : "NEW";

  const [selectedCard, setSelectedCard] = useState();
  const [cardType, setCardType] = useState(cardType_);

  const [cvc, setCvc] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "cvc"],
    onChange: handleCvcChange,
  });

  function handleCvcChange(value, isValid = false) {
    setCvc({ ...cvc, value: value, isValid: isValid });
  }

  function handleCardType(event, value) {
    event.preventDefault();

    if (value == "REGISTERED") {
      if (cards.length > 0) {
        setCardType(value);
      }
    } else {
      setCardType(value);
    }

  }

  let itemList = "";
  if (cards.length > 0) {
    itemList = cards.map((item) => (
      <PanelPaymentCardItem
        id={item.cardToken}
        key={item.cardToken}
        item={item}
        isSelected={selectedCard == item.cardToken ? true : false}
        onChange={handleSelectedCardRegistered}
      />
    ));
  }

  const handleContinue = event => {
    if (event != undefined) {
      event.preventDefault();
    }

    let isValidFormCvc = cvc.isValid;
    if (isValidFormCvc && cvc.validation.includes("empty")) {
      isValidFormCvc = cvc.value == "" ? false : true;
      setCvc({ ...cvc, isValid: isValidFormCvc });
    }

    if (selectedCard != undefined && isValidFormCvc) {
      props.handlePayment(selectedCard, cardType, cvc.value);
    }

  }

  function handleSubmit(selectedCard_, cardType_) {

    props.handlePayment(selectedCard_, cardType_);
  }

  function handleSelectedCard(selectedCard_, cardType_) {
    if (selectedCard_ != undefined) {
      setSelectedCard(selectedCard_)
      props.setSelectedCard(selectedCard_, cardType_);
      props.handleSelectedCard(selectedCard_, cardType_);
    }
  }

  function handleSelectedCardRegistered(selectedCard_) {
    setSelectedCard(selectedCard_);
    props.handleSelectedCard(selectedCard_, cardType);
  }

  return (
    <Wrapper>
      <div className="rl-payment-guardian-method">
        <h2>{t("panelPayment_paymentMethod")}</h2>
        <div className="rl-payment-guardian-method-options">
          <a
            href=""
            onClick={(event) => handleCardType(event, "REGISTERED")}
            className={`${"rl-registered-card"}
            ${cardType == "REGISTERED" ? "active" : ""}
            ${cards.length == 0 ? "disabled" : ""}`}
          >
            {t("panelPayment_registerCard")}
          </a>
          <a
            href=""
            onClick={(event) => handleCardType(event, "NEW")}
            className={`${"rl-new-card"} ${cardType == "NEW" ? "active" : ""}`}
          >
            {t("panelPayment_newCard")}
          </a>
        </div>

        <div className="rl-payment-guardian-method-options-content">
          {(cardType == "REGISTERED" && cards.length > 0) && (
            <div className="rl-registered-card-content">
              {itemList}

              {(selectedCard == undefined) && (
                <div className="rl-form-group">
                  <div className="error-line">
                    <img
                      src="img/icon-circle-info.svg"
                      alt="Wrong Password"
                      width="24"
                    />{" "}
                    {Helpers.capitalizeAllFirst(t("panelPayment_registerCard_error"))}
                  </div>
                </div>
              )}

              <div className="rl-form-group">
                <div className="rl-form-group-material" style={{marginTop: "20px"}}>
                  <RLInputText
                    inputType="text"
                    label={t("panelPayment_cvc")}
                    data={cvc}
                  />
                </div>
              </div>

              <div className="text-center">
                <button
                  type="button"
                  className="rl-form-button"
                  onClick={handleContinue}
                >
                  {t("general_continue")}
                </button>
              </div>
            </div>
          )}

          {cardType == "NEW" && <PanelPaymentCardItemNew handleSelectedCard={handleSelectedCard} handleSubmit={handleSubmit} paymentPlan={props.paymentPlan} />}

          {/* <PanelPaymentCardItemNew /> */}

        </div>
      </div>
    </Wrapper>
  );
};

export default PanelPaymentCard;
