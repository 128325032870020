import { useContext } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../utils/app-context";
import Wrapper from "../../UI/Wrapper";
import PanelSideBarGraveListItem from "./PanelSideBarGraveListItem";
import Helpers from "../../../utils/Helpers";

const PanelSideBarGraveList = (props) => {
  
  const verse = props.verse;
  const rlSideBar = props.rlSideBar;


  function handleGraveStatusChange(graveStatus) {
    // props.handleGraveStatusChange(graveStatus);
  }

  const selectedList = rlSideBar.sideBar[rlSideBar.selectedGrave.graveType];

  let itemList = "";
  if (verse.graves.length > 0) {
    itemList = verse.graves.map((grave) => (
      <PanelSideBarGraveListItem
        id={grave.id}
        key={grave.id}
        grave={grave}
        rlSideBar={rlSideBar}
        // hasVerse={props.hasVerse}
        handleGraveStatusChange={handleGraveStatusChange}
      />
    ));
  }

  return (
    <Wrapper>
      <ul>{itemList}</ul>
    </Wrapper>
  );
};

export default PanelSideBarGraveList;
