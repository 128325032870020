import { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../utils/app-context";
import Wrapper from "../Wrapper";

const RLModalConfirmationModal = (props) => {
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();

  const defaultBody = {
    title: t("confirmation_title"),
    description: t("confirmation_desc"),
    confirmButton: t("confirmation_confirmButton"),
    cancelButton: t("confirmation_cancelButton"),
  };

  const modal = props.modal;
  const params = modal.params;

  const handleClose = (event) => {
    event.preventDefault();

    appCtx.hideModal();
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    modal.onConfirm(event);
    modal.onClose();
  };

  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">
          {params.title != undefined ? params.title : defaultBody.title}
        </h1>

        <div className="modal-header-desc">
          {" "}
          {params.description != undefined
            ? params.description
            : defaultBody.description}{" "}
        </div>

        <div className="modal-header-desc"></div>

        <div className="modal-2-buttons">
          <a href="" onClick={handleClose} className="rl-btn-border black">
            {" "}
            {params.cancelButton != undefined
              ? params.cancelButton
              : defaultBody.cancelButton}{" "}
          </a>

          <a href="" onClick={handleConfirm} className="rl-btn-filled black">
            {" "}
            {params.confirmButton != undefined
              ? params.confirmButton
              : defaultBody.confirmButton}{" "}
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default RLModalConfirmationModal;
