import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";
import { useContext } from "react";
import AppContext from "../../../utils/app-context";

const PanelSubsPaymentMethod = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const appCtx = useContext(AppContext)

  const paymentMethod = props.item;
  const orders = props.orders;
  const order = orders.find(
    (order) => order.paymentMethod === "INSTALLMENT"
  );
  const graveId = props.graveId;
  const graveStatus = Helpers.getGraveStatus();

  // const handleCardChange = (event) => {
  //   event.preventDefault();
  //   history.replace("/account");
  // };

  const handleCardChange = (event) => {
    event.preventDefault();

    const changeCardModal = {
      type: "changeCard",
      show: true,
      params: { userCardId: props.id, graveId: graveId },
    };
    appCtx.showModal(changeCardModal);
  };

  const handlePayNow = (event) => {
    event.preventDefault();
    history.replace(
      "/panelPayment?graveName=" +
        paymentMethod.graveName +
        "&graveType=" +
        Helpers.getGraveType() +
        "&subscriptionId=" +
        paymentMethod.subscriptionId
    );
  };

  return (
    <Wrapper>
      <div className="rl-admin-content-box">
        <h1>{t("panelSub_paymentMethod")}</h1>

        {paymentMethod.afk ? (
          <div className="rl-subs-info-top">
            <div className="rl-subs-info-top-left">
              <div className="column">
                {/* <div className="top">{t("panelSub_paymentMethod_graveName")}</div> */}
                <div className="bottom">
                  {t("panelSub_paymentMethod_unRegistered")}
                </div>
              </div>
            </div>

            {graveStatus == "HELD" && (
              <div className="rl-subs-info-top-right">
                <a href="" onClick={handlePayNow} className="rl-link-black">
                  <img
                    src="/img/icon-credit-card.svg"
                    alt="Change Card"
                    width="20"
                    height="20"
                  />{" "}
                  {t("panelSub_paymentMethod_payNow")}
                </a>
              </div>
            )}
          </div>
        ) : (
          <div className="rl-subs-info-top">
            <div className="rl-subs-info-top-left">
              <div className="column">
                <div className="top">
                  {t("panelSub_paymentMethod_graveName")}
                </div>
                <div className="bottom">{paymentMethod.graveName}</div>
              </div>

              <div className="column">
                <div className="top">{t("panelSub_paymentMethod_payment")}</div>
                <div className="bottom">{paymentMethod.cardHolderName}</div>
              </div>

              <div className="column">
                <div className="top">{t("general_card")}</div>
                <div className="bottom">
                  {Helpers.formatCreditCardNumber(paymentMethod, "span")}
                </div>
              </div>

              {/* {paymentMethod.remainingInstallments != -1 && (<div className="column">
                <div className="top">
                  {t("panelSub_paymentMethod_remainingPayment")}
                </div>
                <div className="bottom">
                  {paymentMethod.remainingInstallments}
                </div>
              </div>)} */}
            </div>

            {graveStatus == "HELD" && (
              <div className="rl-subs-info-top-right">
                <a href="" onClick={handlePayNow} className="rl-link-black">
                  <img
                    src="/img/icon-credit-card.svg"
                    alt="Change Card"
                    width="20"
                    height="20"
                  />{" "}
                  {t("panelSub_paymentMethod_payNow")}
                </a>
              </div>
            )}

            {order && graveStatus != "HELD" && (
              <div className="rl-subs-info-top-right">
                <a href="" onClick={handleCardChange} className="rl-link-black">
                  <img
                    src="/img/icon-credit-card.svg"
                    alt="Change Card"
                    width="20"
                    height="20"
                  />{" "}
                  {t("panelSub_paymentMethod_changeCard")}
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default PanelSubsPaymentMethod;
