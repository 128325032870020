import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import Wrapper from "../Wrapper";

const RLModalCancelCard = (props) => {

    const { t } = useTranslation();

    const modal = props.modal;
    const params = modal.params

    const { response, loading, error, request, responseType } = useAxios();

    useEffect(() => {
    
        if (!error && response !== null && typeof response !== "undefined") {
          if (responseType == "payment/card/" + params.userCardId + "/delete") {
            // modal.onClose();
            window.location.reload(false);
          }
        }
    }, [response]);

    const handleSubmit = (e) => {
      e.preventDefault();

      const url = "payment/card/" + params.userCardId + "/delete";

      request({
        method: "DELETE",
        url: url,
      });
    };

  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">{t("accountSettings_payment_cancelCard")}</h1>
        <div className="modal-header-desc">{t("accountSettings_payment_cancelCard_number",{ cardNumber: modal.params.cardNumber })}</div>
        <form onSubmit={handleSubmit} action="" className="sign-in-up-form">
          <button className="rl-form-button reset-pass-btn">{t("accountSettings_payment_cancelCard")}</button>
        </form>
      </div>
      <a
        rel="modal:close"
        className={props.closeClassName}
        onClick={modal.onClose}
      >
        x
      </a>
    </Wrapper>
  );
};

export default RLModalCancelCard;
