import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import PanelVerseForm from "../../Panel/PanelVerse/PanelVerseForm";
import Wrapper from "../Wrapper";
import AppContext from "../../../utils/app-context";

const RLModalVerseChange = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const modal = props.modal;
  const params = modal.params;

  const [versePopular, setVersePopular] = useState([]);
  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  const [selectedVerse, setSelectedVerse] = useState();

  useEffect(() => {
    if (endpoint == "first") {
      getVersePopular();
    }
    if (response !== null && typeof response !== "undefined") {
      //   setData(response);

      if (responseType == "/verse/popular") {
        if (response.data.length > 0) {
          setVersePopular(response.data);

          var selectedVerse_ = response.data.filter(function (f) {
            return f.id == props.verseId;
          });

          setSelectedVerse(selectedVerse_);
        }
      } else if (responseType == "/grave/" + params.graveId + "/verse/change/") {
        params.reloadList()
        modal.onClose();

        if(response.message) {
          appCtx.addToast(response.message, "green")
        }
      }

      //   setData({ contents: contents, RLVerses: RLVerses });
    }
  }, [response, error]);

  const getVersePopular = (data) => {
    const url = "/verse/popular";
    setEndpoint(url);

    request({
      method: "GET",
      url: url,
    });
  };

  const handleVerseChange = (newVerse) => {

    const url = "/grave/" + params.graveId + "/verse/change/";

    const data = {
      existVerseId: params.verseId,
    }

    if (typeof newVerse === "number") {
      data.newVerseId = newVerse;
    } else {
      if (newVerse.id == -99) {
        data.newVerseName = newVerse.name;
      } else {
        data.newVerseId = newVerse.id;
      }
    }

    request({
      method: "POST",
      url: url,
      data: data
    });
  }

  const handleClose = (event) => {
    event.preventDefault();
    modal.onClose();
  };

  return (
    <Wrapper>

      <Wrapper>
        {params.varseName != undefined && (
          <div class="text-center">
            <h1 class="modal-header">
              {t("verse_change_title", { verseName: params.varseName })}
            </h1>
            {/* <div class="modal-header-desc">{t("verse_change_desc")}</div> */}
          </div>
        )}
      </Wrapper>

      {versePopular.length > 0 && (
        <PanelVerseForm
          items={versePopular}
          verses={params.verses}
          graveId={params.graveId}
          verseId={params.verseId}
          location={params.location}
          changeModal={true}
          useParams={useParams}
          verseChange={handleVerseChange}
          rlSideBar={params.rlSideBar}
        />
      )}
      <a href="" className={props.closeClassName} onClick={handleClose}>
        x
      </a>
    </Wrapper>
  );
};

export default RLModalVerseChange;
