import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import Wrapper from "../UI/Wrapper"

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import AppContext from "../../utils/app-context";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../hooks/use-axios";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DetailsForLife = props => {

    const { t } = useTranslation();
    const appCtx = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    let detailForLifeType = props.detailForLifeType

    const sliderRef = useRef();

    const [pageType, setPageType] = useState(detailForLifeType);
    const [startLifeType, setStartLifeType] = useState();
    // const [swiper, setSwiper] = useState(null);
    // const slideTo = (index) => swiper.slideTo(index);
    // if(pageType == undefined) {
    //     setPageType("testament")
    // }
    useEffect(() => {
        if(detailForLifeType == undefined) {
            setPageType("testament")
            // swiper.slideTo(0)
        } 
        
    }, []);

    useEffect(() => {
        if (detailForLifeType == "testament") {
            // swiper.slideTo(0)    
            // slideTo = 0;
            // setSwiper(0)
            sliderRef.current.swiper.slideTo(0);
        } else {if (detailForLifeType == "diary")
            // swiper.slideTo(1)
            // slideTo = 1;
            // setSwiper(1)
            sliderRef.current.swiper.slideTo(1);
        }
        
    }, [pageType]);    

    if (pageType != detailForLifeType && detailForLifeType != undefined) {
        setPageType(detailForLifeType)
    }

    const handlePageChnge = (event, type) => {
        event.preventDefault();

        props.handleDetailForLifeType(type)
        setPageType(type);
    }

    const handleSlideChange = event => {

        if(event.activeIndex == 0) {
            props.handleDetailForLifeType("testament")
            setPageType("testament");
        } else if(event.activeIndex == 1) {
            props.handleDetailForLifeType("diary")
            setPageType("diary");
        }
    }

    // const handleClickToScroll = (event, id) => {
    //     event.preventDefault();

    //     const element = document.getElementById(id);
    //     if (element) {
    //         element.scrollIntoView({ behavior: "smooth" });
    //     }
    // };

    const handleLoginSuccess = (url) => {
        history.replace(url);
    }
    const handleLogin = event => {

        event.preventDefault();

        const verseDetailModal = {
            type: "register",
            show: true,
            params: {
                onLoginSuccess: handleLoginSuccess
            }
        };
        appCtx.showModal(verseDetailModal);
    }

    function handleCreateGrave(event, type) {

        if (event != "") {
            event.preventDefault();
        }

        const checkMailModal = {
            type: "createGrave",
            show: true,
            params: { graveType: type, location: location, history: history },
        };
        appCtx.showModal(checkMailModal);
    }

    const handleStartLife = (event) => {

        if (event != "") {
            event.preventDefault();
        }

        let type = "";
        if (pageType == "testament") {
            type = "FOR_YOU"
        } else if (pageType == "diary") {
            type = "FOR_YOU"
        }

        if (type == "FOR_YOU" || type == "FOR_BE_LOVED") {
            if (appCtx.isLoggedIn) {
                setStartLifeType(type);
                // handleCreateGrave(event, type)
                postGraveListFunnelGraves();
            } else {
                handleLogin(event)
            }
        }
    }

    const { response, loading, error, request, responseType } = useAxios();
    useEffect(() => {
        if (response !== null && typeof response !== "undefined") {
            if (responseType == "grave/list-funnel-graves") {
                if (response.data.length == 0) {
                    handleCreateGrave("", startLifeType)
                } else {
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].graveType == startLifeType) {
                            history.replace("/funnel/" + response.data[i].id);
                            appCtx.hideLoading();
                            return;
                        }
                    }

                    handleCreateGrave("", startLifeType)
                }

                appCtx.hideLoading();
            }
        }
    }, [response]);

    function postGraveListFunnelGraves() {
        appCtx.showLoading();
        const url = "grave/list-funnel-graves";

        const data = {};

        request(
            {
                method: "POST",
                url: url,
                data,
            },
        );
    }

    return (
        <Wrapper>
            <div id="rememberland-landing-details-for-life"></div>
            <section className="rememberland-landing-details-for-life">
                <div className="title">{t("landing_detailForLife_title")}</div>

                <div className="rl-detail-for-life-desktop">
                    <div className="rl-detail-for-life-desktop-bttns">
                        <a href="" onClick={event => handlePageChnge(event, "testament")} className={pageType == "testament" ? "active" : ""}>
                            {/* <img src="img/crsl-img-TESTAMENT.jpg" alt="" /> */}
                            <LazyLoadImage src="img/crsl-img-TESTAMENT.jpg"/>
                        </a>
                        <a href="" onClick={event => handlePageChnge(event, "diary")} className={pageType == "diary" ? "active" : ""}>
                            {/* <img src="img/crsl-img-MEMORIAL.jpg" alt="" /> */}
                            <LazyLoadImage src="img/crsl-img-MEMORIAL.jpg"/>
                        </a>
                    </div>

                    <div className="rl-detail-for-life-desktop-text">
                        <div className={`${"box"} ${pageType == "testament" ? "active" : ""}`}>
                            <h3>{Helpers.capitalizeAll(t("landing_hero_navigation_testament"))}</h3>
                            <p>{t("landing_detailForLife_testament_desc")}</p>
                        </div>
                        <div className={`${"box"} ${pageType == "diary" ? "active" : ""}`}>
                            <h3>{Helpers.capitalizeAll(t("landing_hero_navigation_diary"))}</h3>
                            <p>{t("landing_detailForLife_diary_desc")}</p>
                        </div>
                    </div>
                </div>

                {pageType != undefined && (
                    <div className="rl-detail-for-life-mobile">
                        <Swiper
                            // onSwiper={setSwiper}
                            ref={sliderRef}
                            className="swiper rl-for-life-mobile-carousel"
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            scrollbar={{ draggable: true }}
                            modules={[Pagination, Navigation]}
                            navigation={true}
                            onSlideChange={handleSlideChange}
                        >

                            {/* testament */}
                            <SwiperSlide>
                                {/* <img src="img/crsl-img-TESTAMENT.jpg" alt="TESTAMENT" /> */}
                                <LazyLoadImage src="img/crsl-img-TESTAMENT.jpg"/>
                                <div className="text">
                                    <h3>{Helpers.capitalizeAll(t("landing_hero_navigation_testament"))}</h3>
                                    <p>{Helpers.removeBackSlashes(t("landing_detailForLife_testament_desc"))}</p>
                                </div>
                            </SwiperSlide>

                            {/* diary */}
                            <SwiperSlide>
                                {/* <div className="swiper-slide"> */}
                                {/* <img src="img/crsl-img-MEMORIAL.jpg" alt="" /> */}
                                <LazyLoadImage src="img/crsl-img-MEMORIAL.jpg"/>
                                <div className="text">
                                    <h3>{Helpers.capitalizeAll(t("landing_hero_navigation_diary"))}</h3>
                                    <p>{Helpers.removeBackSlashes(t("landing_detailForLife_diary_desc"))}</p>
                                </div>
                                {/* </div> */}
                            </SwiperSlide>
                        </Swiper>
                    </div>
                )}

                <a href="" className="rl-animated-btn slide-vertical" onClick={event => handleStartLife(event, "FOR_YOU")} data-splitting>{t("landing_detailForLife_button")}</a>
            </section>
        </Wrapper>
    )
}

export default DetailsForLife;