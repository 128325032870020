import { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "../../../UI/Wrapper";
import BOUserDetailMain from "./BOUserDetailMain";

const BOUserDetailNavigation = (props) => {
  const { t } = useTranslation();

  const [page, setPage] = useState("PAYMENT_INFO");

  const navClickHandler = (event, type) => {
    event.preventDefault();
    setPage(type);
  };

  return (
    <Wrapper>
      <ul className="rl-user-info-tabs">
        <li onClick={(event) => navClickHandler(event, "PAYMENT_INFO")}>
          <a href="" className={page === "PAYMENT_INFO" ? "active" : ""}>
            <img src="/img/icon-wallet.svg" alt="" /> {t("accountSettings_payment_paymentInfo")}
          </a>
        </li>

        <li onClick={(event) => navClickHandler(event, "GRAVE")}>
          <a href="" className={page === "GRAVE" ? "active" : ""}>
            <img src="/img/icon-eye.svg" alt="Grave" /> Grave
          </a>
        </li>
      </ul>

      <BOUserDetailMain pageType={page} />
    </Wrapper>
  );
};

export default BOUserDetailNavigation;
