import Wrapper from "../../../UI/Wrapper";

const BOGraveDetailAdmin = props => {
    return (
        <Wrapper>
            {"BOGraveDetailAdmin"}
        </Wrapper>
    );
}

export default BOGraveDetailAdmin;