import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Wrapper from "../components/UI/Wrapper";
import useAxios from "../hooks/use-axios";
import AppContext from "../utils/app-context";
import Helpers from "../utils/Helpers";

const GeneralWelcomePage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const queryParams = new URLSearchParams(window.location.search);

  const [token, setToken] = useState();
  const token_ = queryParams.get("token");
  if(token != token_) {
    setToken(token_);
  }

  const homepageHandler = (event) => {
    event.preventDefault();

    if(appCtx.isLoggedIn && Helpers.getOnboardStatus() == "COMPLETED") {
      history.replace("/panel");
    } else {
      history.replace("/");
    }
  };

  let title = "";
  let desc = ""

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {

    if (endpoint == "first") {
      if(location.pathname == "/account/confirm-cancel-account") {
        if(token != undefined) {
          postAccountCancelConfirm();
        }
      }
    }

    if (response !== null && typeof response !== "undefined") {
      //   setData(response);

      if (responseType == "/account/confirm-cancel-account") {
        appCtx.logout();
        Helpers.removeStorage();
        // history.replace("/");
      } 
    }
  }, [response, error, token]);

  const postAccountCancelConfirm = () => {

    const url = "/profile/cancel/confirm";
    setEndpoint(url)

    const params = {
      token: token
    }

    request({
      method: "POST",
      url: url,
      params
    });
  }

  if(location.pathname == "/account/confirm-cancel-account") {
    title = t("generalWelcome_accountCancel_title");
    desc = t("generalWelcome_accountCancel_desc");

  } else if (location.pathname == "/email-verification-error") {
    title = t("generalWelcome_emailVerificationError_title");
    desc = t("generalWelcome_emailVerificationError_desc");
  }

  return (
    <Wrapper>
      <div className="rl-welcome">
        <h2>{title}</h2>
        <p>{desc}</p>
        <a href="" onClick={homepageHandler}>
          {t("general_homepage")}
        </a>
      </div>
    </Wrapper>
  );
};

export default GeneralWelcomePage;
