import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";

const BOUserListItem = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const user = props.item;

  const handleDetail = (event) => {
    event.preventDefault();
    history.replace("/bo/users/"+user.id)
  }

  return (
    <Wrapper>
      <tr>
        <td className="fw-600">{user.userStatus}</td>
        <td className="rl-table-border-l fw-600">{user.fullName}</td>
        <td className="rl-table-border-l fw-600">{user.email}</td>
        <td className="rl-table-border-l fw-600">{user.userTypes.join(', ')}</td>
        <td className="rl-table-border-l fw-600">{Helpers.formatDate(user.registerDate)}</td>
        <td className="w-40 text-right">
          <a href="" onClick={handleDetail} className="rl-table-button blue">
            Detail
          </a>
        </td> 
      </tr>
    </Wrapper>
  );
};

export default BOUserListItem;
