import { useContext, useEffect, useState } from "react";
import Wrapper from "../../UI/Wrapper";
import Helpers from "../../../utils/Helpers";
import { useTranslation } from "react-i18next";
import RLInputText from "../../UI/RLInputText";
import PanelTestamentStepOne from "./PanelTestamentStepOne";
import PanelTestamentStepTwo from "./PanelTestamentStepTwo";
import PanelTestamentStepThree from "./PanelTestamentStepThree";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import { useIndexedDB } from "react-indexed-db";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const PanelTestamentStep = props => {

    const { t } = useTranslation();
    const params = useParams();
    const appCtx = useContext(AppContext);
    const { getByID, update, getByIndex, deleteRecord } = useIndexedDB('testament_step_files');

    const rlSideBar = props.rlSideBar
    const selectedGrave = rlSideBar.selectedGrave
    const testaments = props.testaments
    const __testamentStep = props.testamentStep

    const [reload, setReload] = useState(true);
    const [testamentStep, setTestamentStep] = useState({});

    useEffect(() => {
        const _testamentStep = Helpers.getTestamentStep();
        console.log("testamentStep", _testamentStep);

        if (Object.keys(_testamentStep).length == 0 && testaments.items.length == 0) {
            let userId = Helpers.getUser().id;
            var obj = { userId: {} }
            obj = {
                [selectedGrave.id]: {
                    "step": 1,
                    "file": [],
                    "stepOneStatus": "pending",
                    "stepTwoStatus": "notStarted",
                    "stepThreeStatus": "notStarted"
                }
            };

            Object.assign(_testamentStep, obj);
            Helpers.setTestamentStep(_testamentStep);

            setTestamentStep(_testamentStep[selectedGrave.id]);

        } else if (_testamentStep[selectedGrave.id] != undefined) {
            setTestamentStep(_testamentStep[selectedGrave.id]);

            if (testamentStep != undefined) {
                if (testamentStep.stepOneStatus != _testamentStep[selectedGrave.id].stepOneStatus &&
                    _testamentStep[selectedGrave.id].stepOneStatus == "completed") {
                    props.raloadPageTop()
                }
            }

        } else {
            setTestamentStep({});
        }

    }, [selectedGrave, testaments, __testamentStep, reload]);

    const handleStepOneClick = (event) => {
        event.preventDefault();

        if (testamentStep.stepOneStatus != "notStarted") {
            const _testamentStep = Helpers.getTestamentStep();

            if (_testamentStep[selectedGrave.id]["step"] != 1) {
                _testamentStep[selectedGrave.id]["step"] = 1;
                Helpers.setTestamentStep(_testamentStep);

                handleUpdateStep();
            }
        }
    }

    const handleStepTwoClick = (event) => {
        event.preventDefault();

        if (testamentStep.stepTwoStatus != "notStarted") {
            const _testamentStep = Helpers.getTestamentStep();

            if (_testamentStep[selectedGrave.id]["step"] != 2) {
                _testamentStep[selectedGrave.id]["step"] = 2;
                Helpers.setTestamentStep(_testamentStep);

                handleUpdateStep();
            }
        }
    }

    const handleStepThreeClick = (event) => {
        event.preventDefault();

        if (testamentStep.stepThreeStatus != "notStarted") {
            const _testamentStep = Helpers.getTestamentStep();

            if (_testamentStep[selectedGrave.id]["step"] != 3) {
                _testamentStep[selectedGrave.id]["step"] = 3;
                Helpers.setTestamentStep(_testamentStep);

                handleUpdateStep();
            }
        }
    }

    const handleUpdateStep = (step) => {
        setReload(!reload);
        // setTestamentStep(step);
    }

    const handleTestamentCancel = (event) => {
        event.preventDefault();

        let userId = Helpers.getUser().id;
        let graveId = selectedGrave.id;
        let id = userId + "_" + graveId;

        getByIndex('rlId', id).then(
            async testamentStepFiles => {
                if (testamentStepFiles != undefined) {
                    deleteRecord(testamentStepFiles.id).then(
                        reloadList()
                    );
                } else {
                    reloadList()
                }

            },
            error => {
                console.log(error);
            }
        );
    }

    function findStepClass(step) {

        var stepClass = { class: "", cursor: "", img: "" };
        let testamentStatus = "";
        if (step == 1) {
            testamentStatus = testamentStep.stepOneStatus;
        } else if (step == 2) {
            testamentStatus = testamentStep.stepTwoStatus;
        } else if (step == 3) {
            testamentStatus = testamentStep.stepThreeStatus;
        }

        if (testamentStatus == "pending" && testamentStep.step == step) {
            stepClass = { class: "active", cursor: "", img: "", color: "#A75EFD" };

        } else if (testamentStatus == "pending") {
            stepClass = { class: "active", cursor: "", img: "", color: "#A75EFD" };

        } else if (testamentStatus == "completed" && testamentStep.step == step) {
            stepClass = { class: "completed", cursor: "pointer", img: "/img/icon-check-circle.svg", color: "#A75EFD", filter: "invert(58%) sepia(39%) saturate(7459%) hue-rotate(237deg) brightness(95%) contrast(110%)" };

        } else if (testamentStatus == "completed") {
            stepClass = { class: "completed", cursor: "pointer", img: "/img/icon-check-circle.svg", color: "#56BB67", filter: "invert(62%) sepia(22%) saturate(1021%) hue-rotate(78deg) brightness(99%) contrast(86%)" };

        } else if (testamentStatus == "notStarted") {
            stepClass = { class: "", cursor: "", img: "", color: "#999aa766" };
        }

        return stepClass;
    }

    const { response, loading, error, request, responseType } = useAxios();

    useEffect(() => {
        const _testamentStep = Helpers.getTestamentStep();

        if (!error && response !== null && typeof response !== "undefined") {
            if (responseType == "grave/" + selectedGrave.id + "/testament/create") {

                // localStorage.removeItem("testamentStep");
                Helpers.removeTesamentStep(selectedGrave.id);

                let userId = Helpers.getUser().id;
                let graveId = selectedGrave.id;
                let id = userId + "_" + graveId;

                getByIndex('rlId', id).then(
                    async testamentStepFiles => {
                        deleteRecord(testamentStepFiles.id).then(
                            props.reloadList()
                        );
                    },
                    error => {
                        console.log(error);
                    }
                );

            } else if (responseType == "grave/" +
                params["graveId"] +
                "/testament/update/" +
                _testamentStep[selectedGrave.id].testamentId) {
                // localStorage.removeItem("testamentStep");
                Helpers.removeTesamentStep(selectedGrave.id);

                let userId = Helpers.getUser().id;
                let graveId = selectedGrave.id;
                let id = userId + "_" + graveId;

                getByIndex('rlId', id).then(
                    async testamentStepFiles => {

                        if (testamentStepFiles != undefined) {
                            deleteRecord(testamentStepFiles?.id).then(
                                event => {
                                    reloadList()
                                },
                                error => {
                                    console.log(error);
                                }
                            );
                        } else {
                            reloadList()
                        }

                    },
                    error => {
                        console.log(error);
                    }
                );
            }
        } else {

            if (error) {
                if (responseType == "grave/" +
                    params["graveId"] +
                    "/testament/update/" +
                    _testamentStep[selectedGrave.id].testamentId) {
                    const _testamentStep = Helpers.getTestamentStep();

                    _testamentStep[selectedGrave.id]["step"] = 3;
                    _testamentStep[selectedGrave.id]["stepThreeStatus"] = "pending";
                    Helpers.setTestamentStep(_testamentStep);
                }
            }

        }

        appCtx.hideLoading();
    }, [response, error]);

    function reloadList() {
        // localStorage.removeItem("testamentStep")
        Helpers.removeTesamentStep(selectedGrave.id);
        props.reloadList()
    }

    function handleSubmitTestament(edit = false) {
        const _testamentStep = Helpers.getTestamentStep();

        if (_testamentStep[selectedGrave.id]["step"] == 4 &&
            _testamentStep[selectedGrave.id]["stepOneStatus"] == "completed" &&
            _testamentStep[selectedGrave.id]["stepTwoStatus"] == "completed" &&
            _testamentStep[selectedGrave.id]["stepThreeStatus"] == "completed") {

            let testementName_ = _testamentStep[selectedGrave.id].name;

            let shareEmail_ = "";
            let shareEmail__ = JSON.parse(_testamentStep[selectedGrave.id].shareEmail);
            for (const key in shareEmail__) {
                shareEmail_ =
                    shareEmail_ +
                    (shareEmail_ == ""
                        ? shareEmail__[key].name
                        : "," + shareEmail__[key].name);
            }

            let userId = Helpers.getUser().id;
            let graveId = selectedGrave.id;
            let id = userId + "_" + graveId;

            var _files = [];

            getByIndex('rlId', id).then(
                async testamentStepFiles => {

                    if (testamentStepFiles != undefined) {

                        let _files_db = JSON.parse(testamentStepFiles.files);

                        for (let i = 0; i < _files_db.length; i++) {

                            var base64 = _files_db[i].base;
                            var base64Parts = base64.split(",");

                            const base64Response = await fetch(`${base64Parts[0]},${base64Parts[1]}`);
                            const blob = await base64Response.blob();
                            var file = new File([blob], _files_db[i].name, { type: _files_db[i].type });
                            _files.push(file);
                        }
                    }

                    if (!edit) {
                        let data = {
                            files: _files,
                            testamentName: testementName_,
                            emails: shareEmail_,
                        };

                        appCtx.showLoading();

                        request(
                            {
                                method: "POST",
                                url: "grave/" + selectedGrave.id + "/testament/create",
                                data,
                            },
                            () => { },
                            false,
                            true
                        );

                    } else {

                        // let data = { ids: [_testamentStep[selectedGrave.id].testamentFileId] };
                        let fileIds = [];
                        for (let i = 0; i < _testamentStep[selectedGrave.id].files.length; i++) {
                            fileIds.push(_testamentStep[selectedGrave.id].files[i].id);
                        }

                        let data = {
                            files: _files,
                            name: testementName_,
                            emails: shareEmail_,
                            fileIds: fileIds,
                        };

                        appCtx.showLoading();

                        request({
                            method: "POST",
                            url:
                                "grave/" +
                                params["graveId"] +
                                "/testament/update/" +
                                _testamentStep[selectedGrave.id].testamentId,
                            data: data,
                        },
                            () => { },
                            false,
                            true
                        );

                    }


                },
                error => {
                    console.log(error);
                }
            );
        }
    }

    return (
        <Wrapper>
            {Object.keys(testamentStep).length > 0 && (
                <ul className="rl-create-new-testament-steps">
                    <li>
                        <a href="" onClick={handleStepOneClick}
                            className={findStepClass(1).class}
                            style={{ cursor: findStepClass(1).cursor }}>

                            <span className="step-no">
                                {findStepClass(1).img == ""
                                    ? (1)
                                    : (<img src={findStepClass(1).img} style={{ filter: findStepClass(1).filter }} alt=""></img>)}
                            </span>

                            <span className="text" style={{ color: findStepClass(1).color }}>{t("testament_step_title_name")}</span>

                        </a>
                    </li>

                    <li>
                        <a href="" onClick={handleStepTwoClick}
                            className={findStepClass(2).class}
                            style={{ cursor: findStepClass(2).cursor }}>

                            <span className="step-no">
                                {findStepClass(2).img == ""
                                    ? (2)
                                    : (<img src={findStepClass(2).img} style={{ filter: findStepClass(2).filter }} alt=""></img>)}
                            </span>

                            <span className="text" style={{ color: findStepClass(2).color }}>{t("testament_step_title_content")}</span>

                        </a>
                    </li>

                    <li>
                        <a href="" onClick={handleStepThreeClick}
                            className={findStepClass(3).class}
                            style={{ cursor: findStepClass(3).cursor }}>

                            <span className="step-no">3</span>
                            <span className="text" style={{ color: findStepClass(3).color }}>{t("testament_step_title_share")}</span>
                        </a>
                    </li>
                </ul>
            )}

            {testamentStep?.step == 1 && (
                <PanelTestamentStepOne rlSideBar={rlSideBar} updateStep={handleUpdateStep} cancelStep={handleTestamentCancel} testamentStep={testamentStep} testaments={testaments} />
            )}

            {testamentStep?.step == 2 && (
                <PanelTestamentStepTwo rlSideBar={rlSideBar} updateStep={handleUpdateStep} cancelStep={handleTestamentCancel} testamentStep={testamentStep} availableDiskSize={testaments.availableDiskSize} testaments={testaments}/>
            )}

            {testamentStep?.step == 3 && (
                <PanelTestamentStepThree rlSideBar={rlSideBar} updateStep={handleUpdateStep} cancelStep={handleTestamentCancel} testamentStep={testamentStep} submitTestament={handleSubmitTestament} testaments={testaments}/>
            )}



        </Wrapper>
    );

}

export default PanelTestamentStep;