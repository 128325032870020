import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper";
import PanelAdminList from "../PanelAdmin/PanelAdminList";
import RLInput from "../../UI/RLInput";
import RLInputSelect from "../../UI/RLInputSelect";
import PanelContentListItem from "./PanelContentListItem";
import { MenuItem, Select } from "@mui/material";
import useAxios from "../../../hooks/use-axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RLEmptyListCard from "../../UI/RLModal/RLEmptyListCard";

const PanelContentList = (props) => {
  const params = useParams();
  const { t } = useTranslation();

  const [listFilters, setlistFilter] = useState({ verse: null, type: null });

  const contents = props.items;

  const FileTypes = [
    { id: "AUDIO", name: t("general_enum_AUDIO") },
    { id: "IMAGE", name: t("general_enum_IMAGE") },
    { id: "LINK", name: t("general_enum_LINK") },
    { id: "TEXT", name: t("general_enum_TEXT") },
    { id: "VIDEO", name: t("general_enum_VIDEO") },
  ];

  const contentRemoveHandler = () => {
    props.onListFilterChange(listFilters);
  };

  function versesFilterHandler(value) {
    setlistFilter({
      verse: value,
      type: listFilters.type,
    });
    props.onListFilterChange({ verse: value, type: listFilters.type });
  }

  function typeFilterHandler(value) {
    setlistFilter({
      verse: listFilters.verse,
      type: value,
    });
    props.onListFilterChange({ verse: listFilters.verse, type: value });
  }

  let itemList = "";
  if (contents.length > 0) {
    itemList = contents.map((item) => (
      <PanelContentListItem
        removeContent={contentRemoveHandler}
        id={item.id}
        key={item.id}
        item={item}
        RLVerses={props.RLVerses}
      />
    ));
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <form
          action=""
          className="rl-bo-users-search-form rl-table-admin-bottom-filters"
        >
          <div className="column">
            <div className="column-2">
              <div className="rl-form-group">
                <div className="rl-form-group-material">
                  <RLInputSelect
                    label={t("content_form_selectverse")}
                    defaultValue={listFilters.verse}
                    standart={true}
                    onChange={versesFilterHandler}
                    options={props.RLVerses}
                  />
                </div>
              </div>

              <div className="rl-form-group">
                <div className="rl-form-group-material">
                  <RLInputSelect
                    label={t("content_form_selectfiletype")}
                    defaultValue={listFilters.type}
                    standart={true}
                    onChange={typeFilterHandler}
                    options={FileTypes}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="column"></div>
          <div className="column"></div>
        </form>

        {contents.length > 0 ? (
          <div className="rl-table-outer rl-custom-scroll">
            <table className="rl-table-admin-bottom">
              <thead>
                <tr>
                  {/* <th>
                  <input type="checkbox" className="form-checkbox" />
                </th> */}
                  <th className="">{t("general_status")}</th>
                  <th className="w-20 rl-table-border-l">{t("general_verse")}</th>
                  <th className="rl-table-border-l">{t("general_link")}</th>
                  <th className="w-40 text-right">
                    {/* <a href="" className="rl-table-button disabled">
                    Remove
                  </a> */}
                  </th>
                </tr>
              </thead>
              <tbody>{itemList}</tbody>
            </table>
          </div>
        ) : (
          // <RLEmptyListCard />
          <div></div>
        )}
      </div>
    </Wrapper>
  );
};

export default PanelContentList;
