import Wrapper from "../../../UI/Wrapper";
import BOGraveDetailAdmin from "./BOGraveDetailAdmin";
import BOGraveDetailContent from "./BOGraveDetailContent";
import BOGraveDetailGuardian from "./BOGraveDetailGuardian";
import BOGraveDetailKyc from "./BOGraveDetailKyc";
import BOGraveDetailSubs from "./BOGraveDetailSubs";
import BOGraveDetailTestament from "./BOGraveDetailTestament";
import BOGraveDetailVerse from "./BOGraveDetailVerse";

const BOGraveDetailMain = props => {

    return (
        <Wrapper>
            {props.pageType === "ADMIN" && (
                <BOGraveDetailAdmin />
            )}
            {props.pageType === "GUARDIAN" && (
                <BOGraveDetailGuardian />
            )}
            {props.pageType === "CONTENT" && (
                <BOGraveDetailContent />
            )}
            {props.pageType === "TESTAMENT" && (
                <BOGraveDetailTestament />
            )}
            {props.pageType === "VERSE" && (
                <BOGraveDetailVerse />
            )}
            {props.pageType === "SUBS" && (
                <BOGraveDetailSubs />
            )}
            {props.pageType === "KYC" && (
                <BOGraveDetailKyc />
            )}
        </Wrapper>
    );
}

export default BOGraveDetailMain;