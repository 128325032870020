import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import RLEmptyListCard from "../../UI/RLModal/RLEmptyListCard";
import Wrapper from "../../UI/Wrapper";
import PanelVerseForm from "./PanelVerseForm";
import PanelVerseList from "./PanelVerseList";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import PanelContentTop from "../PanelContentTop";
import PanelVersePopularList from "./PanelVersePopularList";
import PanelVerseSuggestedList from "./PanelVerseSuggestedList";
import AppContext from "../../../utils/app-context";

const PanelVerse = (props) => {
  const params = useParams();
  const location = useLocation();
  const appCtx = useContext(AppContext);

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave

  const [graveVerses, setGraveVerses] = useState([]);
  const [popularVerses, setPopularVerses] = useState([]);
  const [sugestedVerses, setsugestedVerses] = useState([]);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    getGraveVerseList();
    // getVersePopular();
    // getVerseList();
  }, [selectedGrave]);

  useEffect(() => {
    // getGraveVerseList();
    // getVersePopular();
    // getVerseList();
    appCtx.showLoading();
  }, [reload]);

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {

    if (response !== null && typeof response !== "undefined") {

      if (responseType == "grave/" + params["graveId"] + "/verse/list") {
        setGraveVerses(response.data);
        getVersePopular();

      } else if (responseType == "/verse/popular") {
        setPopularVerses(response.data);
        getVerseList();
        // appCtx.hideLoading();

      } else if (responseType == "/verse/list") {
        setsugestedVerses(response.data);
        appCtx.hideLoading();

      }
    } else if (error) {
      appCtx.hideLoading();
    }
  }, [response, error]);

  const getGraveVerseList = () => {
    appCtx.showLoading();
    const url = "grave/" + params["graveId"] + "/verse/list";

    request({
      method: "GET",
      url: url,
    });
  };

  const getVersePopular = () => {
    appCtx.showLoading();
    const url = "/verse/popular";

    request({
      method: "GET",
      url: url,
    });
  };

  const getVerseList = () => {
    appCtx.showLoading();
    const url = "/verse/list";

    request({
      method: "GET",
      url: url,
    });
  };

  function handleReloadList() {

    rlSideBar.reloadSideBar(rlSideBar);
    setReload(!reload);

    // const element = document.getElementById("rl-admin-content");
    // if (element) {
    //   element.scrollIntoView({ behavior: "smooth" });
    // }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  return (
    <Wrapper>
      <section className="rl-admin-content" id="rl-admin-content">

        <PanelContentTop rlSideBar={rlSideBar} />

        {(popularVerses.length > 0)
          ? (<Wrapper>
            <PanelVerseList rlSideBar={rlSideBar} graveVerses={graveVerses} reloadList={handleReloadList} />

            <PanelVersePopularList rlSideBar={rlSideBar} graveVerses={graveVerses} popularVerses={popularVerses} reloadList={handleReloadList} />

            <PanelVerseSuggestedList rlSideBar={rlSideBar} graveVerses={graveVerses} sugestedVerses={sugestedVerses} reloadList={handleReloadList} />
          </Wrapper>)
          : (<RLEmptyListCard />)}

        <PanelVerseForm rlSideBar={rlSideBar} reloadList={handleReloadList} />

      </section>
    </Wrapper>
  );
};

export default PanelVerse;
