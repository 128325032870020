import Wrapper from "../../../UI/Wrapper";

const BOGraveDetailContent = props => {
    return (
        <Wrapper>
            {"BOGraveDetailContent"}
        </Wrapper>
    );
}

export default BOGraveDetailContent;