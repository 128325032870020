import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RLButton from "../components/UI/RLButton";
import RLInput from "../components/UI/RLInput";
import RLInputText from "../components/UI/RLInputText";
import RLModal from "../components/UI/RLModal/RLModal";
import Wrapper from "../components/UI/Wrapper";
import useAxios from "../hooks/use-axios";
import AppContext from "../utils/app-context";
import Helpers from "../utils/Helpers";

const GraveInvitationPage = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const history = useHistory();
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const [graveAssignInfo, setGraveAssignInfo] = useState([]);
  const [name, setName] = useState({
    value: "",
    isValid: true,
    disabled: true,
    validation: ["empty"],
    onChange: handleNameChange,
  });
  const [surname, setSurname] = useState({
    value: "",
    isValid: true,
    disabled: true,
    validation: ["empty"],
    onChange: handleSurnameChange,
  });
  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    disabled: true,
    validation: ["empty", "email"],
    onChange: handleEmailChange,
  });
  const [password, setPassword] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handlePasswordChange,
  });
  const [passwordConfirm, setPasswordConfirm] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handlePasswordConfirmChange,
  });
  const [agreement, setAgreement] = useState({
    value: false,
    isValid: true,
    validation: ["empty"],
    onChange: handleAgreementChange,
  });

  const [isPasswordsMatch, setIsPasswordsMatch] = useState(true);
  const [ownerName, setOwnerName] = useState("");

  function handleNameChange(value, isValid = false) {
    setName({ ...name, value: value, isValid: isValid });
  }

  function handleSurnameChange(value, isValid = false) {
    setSurname({ ...surname, value: value, isValid: isValid });
  }

  function handleEmailChange(value, isValid = false) {
    setEmail({ ...email, value: value, isValid: isValid });
  }

  function handlePasswordChange(value, isValid = false) {
    setPassword({ ...password, value: value, isValid: isValid });
  }

  function handlePasswordConfirmChange(value, isValid = false) {
    setPasswordConfirm({ ...passwordConfirm, value: value, isValid: isValid });
  }

  function handleAgreementChange() {
    setAgreement({
      ...agreement,
      value: !agreement.value,
      isValid: !agreement.value,
    });
  }

  function handleMailConfirm() {
    history.replace("/");
  }

  function isValidForm() {
    let isValidFormName = name.isValid;
    if (isValidFormName && name.validation.includes("empty")) {
      isValidFormName = name.value == "" ? false : true;
      setName({ ...name, isValid: isValidFormName });
    }

    let isValidFormSurname = surname.isValid;
    if (isValidFormSurname && surname.validation.includes("empty")) {
      isValidFormSurname = surname.value == "" ? false : true;
      setSurname({ ...surname, isValid: isValidFormSurname });
    }

    let isValidFormEmail = email.isValid;
    if (isValidFormEmail && email.validation.includes("empty")) {
      isValidFormEmail = email.value == "" ? false : true;
      setEmail({ ...email, isValid: isValidFormEmail });
    }

    let isValidFormPassword = password.isValid;
    if (isValidFormPassword && password.validation.includes("empty")) {
      isValidFormPassword = password.value == "" ? false : true;
      setPassword({ ...password, isValid: isValidFormPassword });
    }

    let isValidFormPasswordConfirm = passwordConfirm.isValid;
    if (
      isValidFormPasswordConfirm &&
      passwordConfirm.validation.includes("empty")
    ) {
      isValidFormPasswordConfirm = passwordConfirm.value == "" ? false : true;
      setPasswordConfirm({
        ...passwordConfirm,
        isValid: isValidFormPasswordConfirm,
      });
    }

    let isPasswordsMatch_ = isPasswordsMatch;
    if (isValidFormPassword && isValidFormPasswordConfirm) {
      if (password.value === passwordConfirm.value) {
        isPasswordsMatch_ = true;
      } else {
        isPasswordsMatch_ = false;
      }
    }

    let isValidFormAggreement = agreement.isValid;
    if (isValidFormAggreement && agreement.validation.includes("empty")) {
      isValidFormAggreement = agreement.value === false ? false : true;
      setAgreement({ ...agreement, isValid: isValidFormAggreement });
    }

    return (
      isValidFormName &&
      isValidFormSurname &&
      isValidFormEmail &&
      isValidFormPassword &&
      isValidFormPasswordConfirm &&
      isPasswordsMatch_ &&
      isValidFormAggreement
    );
  }

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getGraveAssignemntInfo();
    }

    if (password.isValid && passwordConfirm.isValid) {
      if (password.value === passwordConfirm.value) {
        setIsPasswordsMatch(true);
      } else {
        setIsPasswordsMatch(false);
      }
    }

    if(error != "") {
      if (responseType == "user/accept-grave-invitation" || responseType == "auth/grave-assignment-info") {
        const warninglModal = {
          type: "warningModal",
          show: true,
          params: {
            description: error.response.data.message ? error.response.data.message : "error",
            enableClose: false
          },
          onConfirm: handleWarningOnConfirm

        };
        appCtx.showModal(warninglModal);
      }

    } else if (response !== null && typeof response !== "undefined") {
      if (responseType == "auth/grave-assignment-info") {

        let graveAssingmentInfo_ = response.data
        graveAssingmentInfo_["invitationToken"] = token
        setGraveAssignInfo(graveAssingmentInfo_)

        if (response.data.name != name.value) {
          setName({ ...name, value: response.data.name });
        }

        if (response.data.surname != name.value) {
          setSurname({ ...surname, value: response.data.surname });
        }

        if (response.data.name != email.value) {
          setEmail({ ...email, value: response.data.email });
        }

        if (response.data.ownerName != ownerName) {
          setOwnerName(response.data.ownerName);
        }

        if(!response.data.grave.hasSubscription) {

          history.replace("/holdon?ownerName=" + response.data.ownerName + "&assignmentType=" + response.data.graveAssignmentType);

        } else if (response.data.invitedRegistered) {

          const user = Helpers.getUser();

          if (appCtx.isLoggedIn && response.data.email == user.email) {
            postAcceptGraveInvitation();
          } else {
            handleLogin(graveAssingmentInfo_);
            appCtx.logout();
            Helpers.removeStorage();
          }
        }

      } else if (responseType == "auth/register") {

        let token = response.data.token;
        let profile = response.data.profile;

        appCtx.login(token, profile);

        history.replace("/congrats?ownerName=" + ownerName);

      } else if (responseType == "user/accept-grave-invitation") {

        appCtx.updateProfile(response.data)

        history.replace("/panel");

      }
    }
  }, [response, error, password, passwordConfirm]);

  function getGraveAssignemntInfo() {
    const url = "auth/grave-assignment-info";
    setEndpoint(url);

    const params = {
      token: token,
    };

    request(
      {
        method: "GET",
        url: url,
        params,
      },
      () => { },
      false
    );
  }

  function postAcceptGraveInvitation() {
    const url = "user/accept-grave-invitation";
    setEndpoint(url);

    const params = {
      token: token,
    };

    request(
      {
        method: "POST",
        url: url,
        params,
      },
    );
  }

  const registerHandler = async (event) => {
    event.preventDefault();

    if (isValidForm() && agreement) {
      const data = {
        email: email.value,
        name: name.value,
        surname: surname.value,
        password: password.value,
        onboardType: "USER",
        invitationToken: token,
      };

      const url = "auth/register";
      setEndpoint(url);

      request(
        {
          method: "POST",
          url: url,
          data,
        },
        () => { },
        false
      );
    }
  };

  const handleLoginSuccess = (url) => {
    history.replace(url);
  }
  const handleLogin = graveAssignInfo => {

    const verseDetailModal = {
      type: "login",
      show: true,
      params: {
        onLoginSuccess: handleLoginSuccess,
        graveAssignInfo: graveAssignInfo,
        from: "graveInvitation"
        // blockCloserEnabled: false
      }
    };
    appCtx.showModal(verseDetailModal);
  }

  const handleWarningOnConfirm = event => {
    // event.preventDefault();

    history.replace("/");
  }

  return (
    <Wrapper>

      <div className="sign-in-white-outer" style={{height: "max-content"}}>
        <img
          src="img/logo-black.svg"
          alt="REMEMBERLAND"
          width="200"
          className="rl-logo"
        />

        <div className="sign-in-white-box">
          <div className="text-center">
            <h1 className="modal-header">
              {Helpers.capitalizeFirst(t("register_title"))}
            </h1>
            <div className="modal-header-desc">
              {t("register_description_invitation")}
            </div>
          </div>
          <form
            onSubmit={registerHandler}
            className="sign-in-up-form"
            autoComplete="off"
          >
            <div className="rl-form-group">
              {/* {!isValidToken && (
                <div className="rl-form-group-material error">
                  <div className="error-text">Davetinizi dogrulanamadi!</div>
                </div>
              )} */}

              <div className="rl-form-group-material">
                <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_name"))} data={name} />
              </div>
            </div>

            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  label={Helpers.capitalizeFirst(t("general_surname"))}
                  data={surname}
                />
              </div>
            </div>

            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_email"))} data={email} />
              </div>
            </div>

            {!graveAssignInfo?.invitedRegistered && (
              <Wrapper>
                <div className="rl-form-group">
                  <div className="rl-form-group-material">
                    <RLInputText
                      inputType="text"
                      inputFeature="password"
                      label={t("password")}
                      data={password}
                    />
                  </div>
                </div>

                <div className="rl-form-group">
                  <div className="rl-form-group-material error">
                    <RLInputText
                      inputType="text"
                      inputFeature="password"
                      label={t("password")}
                      data={passwordConfirm}
                    />
                  </div>
                  {!isPasswordsMatch && (
                    <div className="error-line">
                      <img
                        src="img/icon-circle-info.svg"
                        alt="Wrong Password"
                        width="24"
                      />{" "}
                      {Helpers.capitalizeAllFirst(t("password_error"))}
                    </div>
                  )}
                </div>

                <div className="rl-form-remember-forgot">
                  <div className="left form-checkbox-outer">
                    <label htmlFor="14">
                      <input
                        onChange={handleAgreementChange}
                        type="checkbox"
                        id="14"
                        className="form-checkbox"
                      />
                      <a
                        href={t("register_agreement_doc")}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "black" }}
                      >
                        {t("register_agree_terms")}
                      </a>
                    </label>
                  </div>
                  <div className="right">
                    <div className="rl-form-group">
                      {!agreement.isValid && (
                        <div className="error-line">
                          <img
                            src="img/icon-circle-info.svg"
                            alt="must not empty"
                            width="24"
                          />{" "}
                          {Helpers.capitalizeAllFirst(t("register_agree_error"))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Wrapper>
            )}

            {!graveAssignInfo?.invitedRegistered && (
              <Wrapper>
                <button className="rl-form-button">{t("register")}</button>

                {graveAssignInfo?.inviteStatus != "WAITING" && (
                  <Wrapper>
                    {/* <div className="rl-form-or">
                      <span>{t("general_or")}</span>
                    </div> */}

                    <div className="rl-form-social-btns">
                      <a
                        href=""
                        className="rl-form-social-btns-facebook"
                        title="Continue with Facebook"
                      >
                        <img src="img/icon-facebook-btn.svg" alt="Google" />
                        {t("login_social_facebook")}
                      </a>
                      <a
                        href=""
                        className="rl-form-social-btns-google"
                        title="Continue with Google"
                      >
                        <img src="img/icon-google-btn.png" alt="Google" />
                        {t("login_social_google")}
                      </a>
                    </div>

                    <div className="rl-sign-up-or-in">
                      {t("register_signin")}{" "}
                      <RLButton
                        text={Helpers.capitalizeAll(t("signin"))}
                        onClick={handleLogin}
                        buttonType="a"
                      />
                    </div>
                  </Wrapper>
                )}

              </Wrapper>
            )}

          </form>
        </div>
      </div>
      {/* <RLModal
        title="My Modal"
        show={showModal}
        type="checkEmail"
        onConfirm={handleMailConfirm}
      /> */}
    </Wrapper>
  );
};

export default GraveInvitationPage;
