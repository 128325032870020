import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import Wrapper from "../UI/Wrapper";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { Tweet } from 'react-tweet'
import RenderIfVisible from 'react-render-if-visible'

const Social = (props) => {
  const { t } = useTranslation();

  return (
    <section className="rememberland-landing-social-endorsement">
      <div className="title">{t("landing_social_title")}</div>

      <Swiper
        className="swiper rl-social-endorsement-carousel"
        // install Swiper modules
        modules={[Navigation, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        // loop={true}
        // navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
        // breakpoints={{
        //   1024: {
        //     slidesPerView: 3,
        //     spaceBetween: 80,
        //     centeredSlides: true,
        //     loop: true,
        //   },
        // }}
        breakpoints={{
          1280: {
            slidesPerView: 3,
            spaceBetween: 20,
            // centeredSlides: true,
            // centeredSlides: true,
            loop: false,
            // navigation: false
          }
        }}
      >
        {Helpers.getLang() == "en" && (
          <Wrapper>
            <SwiperSlide>
              <div className="light twitter-card-react">
                <RenderIfVisible>
                  <Tweet id="1647949554623365121" />
                </RenderIfVisible>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="light twitter-card-react">
                <RenderIfVisible>
                  <Tweet id="1647945562388348928" />
                </RenderIfVisible>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="light twitter-card-react">
                <RenderIfVisible>
                  <Tweet id="1647950264505102339" />
                </RenderIfVisible>
              </div>
            </SwiperSlide>
          </Wrapper>
        )}
        {Helpers.getLang() == "tr" && (
          <Wrapper>
            <SwiperSlide>
              <div className="light twitter-card-react">
                <RenderIfVisible>
                  <Tweet id="1659183658178150401" />
                </RenderIfVisible>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="light twitter-card-react">
                <RenderIfVisible>
                  <Tweet id="1659204205549359107" />
                </RenderIfVisible>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="light twitter-card-react">
                <RenderIfVisible>
                  <Tweet id="1659190116857040896" />
                </RenderIfVisible>
              </div>
            </SwiperSlide>
          </Wrapper>
        )}

      </Swiper>
    </section>
  );
};

export default Social;
