import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";

const PanelKycListItem = (props) => {
  const { t } = useTranslation();
  const params = useParams();

  const kyc = props.item;

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (
        responseType ==
        "grave/" + params["graveId"] + "/kyc/view/" + kyc.guid
      ) {
        const type = "application/octet-stream";
        const blob = new Blob([response], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = kyc.originalFileName;
        link.click();
      } else if (
        responseType ==
        "grave/" + params["graveId"] + "/kyc/change/" + kyc.id
      ) {
        props.reloadList();
      }
    }
  }, [response, error]);

  const handleContentOpen = (event) => {
    event.preventDefault();

    request({
      method: "GET",
      url: "grave/" + params["graveId"] + "/kyc/view/" + kyc.guid,
      responseType: "blob",
    });
  };

  const [file, setFile] = useState(undefined);
  const inputRef = useRef(null);

  const handleChangeContent = (event) => {
    event.preventDefault();

    inputRef.current.click();
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      postKycChange(e.target.files[0]);
    }
  };

  function postKycChange(file) {
    const data = {
      file: file,
      kycId: kyc.id,
    };

    request(
      {
        method: "POST",
        url: "grave/" + params["graveId"] + "/kyc/change/" + kyc.id,
        data: data,
      },
      () => {},
      false,
      true
    );
  }

  return (
    <Wrapper>
      <input
        ref={inputRef}
        type="file"
        id="file"
        name="myfiles[]"
        multiple=""
        onChange={handleChange}
        hidden={true}
      />
      <tr>
        {/* <td>
          <input type="checkbox" className="form-checkbox" />
        </td> */}
        <td className="">{t("general_enum_"+kyc.fileType)}</td>
        <td className="rl-table-border-l fw-600">{t("general_enum_"+kyc.kycStatus)+ ((kyc.comment != null) ? (" (" + kyc.comment + ")") : (""))}</td>
        <td className="rl-table-border-l fw-600">
          <a className="rl-content-link">
            {Helpers.getFileIcon(kyc.contentType)} {kyc.originalFileName}
          </a>
        </td>

        <td className="w-40 text-right">
          <a
            href=""
            onClick={handleContentOpen}
            target="_blank"
            className="rl-link-black"
          >
            <img
              src="/img/icon-download.svg"
              alt="Download"
              width="20"
              height="20"
            />{" "}
            {t("general_download")}
          </a>

          {kyc.kycStatus == "DECLINED" && (
            <a
              href=""
              onClick={handleChangeContent}
              className="rl-table-button blue"
            >
              {t("general_change")}
            </a>
          )}
        </td>
      </tr>
    </Wrapper>
  );
};

export default PanelKycListItem;
