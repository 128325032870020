import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../../../hooks/use-axios";
import Wrapper from "../../../UI/Wrapper";
import BOUserDetailNavigation from "./BOUserDetailNavigation";

const BOUserDetail = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();

  const [userDetail, setUserDetail] = useState([]);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getUserDetail();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "backOffice/user/" + params["userId"] + "/detail") {
        setUserDetail(response.data);
      }
    }
  }, [response, error]);

  function getUserDetail() {
    const url = "backOffice/user/" + params["userId"] + "/detail";
    setEndpoint(url);

    const data = {};

    request({
      method: "GET",
      url: url,
      data,
    });
  }

  const handleGraveChange = (event) => {
    event.preventDefault();

    history.replace("/bo/users");
  };

  return (
    <Wrapper>
      <section className="rl-admin-content">
        <div>
          <header className="rl-bo-user-detail-header">
            <div className="rl-bo-user-detail-header-left">
              {/* <figure>
                <img
                  src={
                    userDetail.profilePhoto
                      ? userDetail.profilePhoto
                      : "/img/avatar-placeholder.png"
                  }
                  alt="User Name"
                  className="rl-user-avatar"
                />
              </figure> */}
              <div className="rememberland-panel-sidebar-user">
                <figure>
                  <img
                    src={
                      userDetail.profilePhoto
                        ? userDetail.profilePhoto
                        : "/img/avatar-placeholder.png"
                    }
                    alt="User Name"
                    className="rl-user-avatar"
                  />
                </figure>
              </div>
              <div className="username-info">
                <div className="username">{userDetail.name}</div>
                <div className="email">{userDetail.email}</div>
              </div>
            </div>
            <div className="rl-bo-user-detail-header-right">
              <a href="" onClick={handleGraveChange} className="rl-link-black">
                <img src="/img/icon-refresh.svg" alt="Change User" />{" "}
                {t("bo_user_detail_change")}
              </a>
            </div>
          </header>
        </div>

        <BOUserDetailNavigation />
      </section>
    </Wrapper>
  );
};

export default BOUserDetail;
