import Wrapper from "../UI/Wrapper";

const PanelPaymentCardItem = (props) => {
  const card = props.item;

  const handleChange = (event) => {
    // event.preventDefault();

    props.onChange(event.target.value, 'REGISTERED')
  }

  return (
    <Wrapper>
      <div className="rl-form-group">
        <label className="form-label">
          <input
            onChange={handleChange}
            value={card.cardToken}
            type="radio"
            className="form-radio"
            name="cards"
            checked={props.isSelected}
          />{" "}
          <span>{card.binNumber.slice(0, 4)}</span> <span>● ● ● ●</span> <span>● ● ● ●</span>{" "}
          <span>{card.lastFourDigits}</span>
        </label>
      </div>
    </Wrapper>
  );
};

export default PanelPaymentCardItem;
