import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";
import PanelSubs from "../../Panel/PanelSubs/PanelSubs";
import RLInputSelect from "../../UI/RLInputSelect";
import Wrapper from "../../UI/Wrapper"

const AccountSettingsSubs = props => {

    const { t } = useTranslation();

    const [graves, setGraves] = useState([]);
    const [selectedGrave, setSelectedGrave] = useState();

    const { response, loading, error, request, responseType } = useAxios();
    const [endpoint, setEndpoint] = useState("first");

    useEffect(() => {
        if (endpoint == "first") {
            getGraveList();
        }

        if (response !== null && typeof response !== "undefined") {
            if (responseType == "grave/list") {

                setGraves(response.data);

                let graveSubs = [];
                let isSelected = false;

                for (const key in response.data) {
                    if (response.data[key].hasSubscription) {
                        graveSubs.push(
                            {
                                id: response.data[key].id,
                                name: response.data[key].name
                            }
                        );

                        if (!isSelected) {
                            isSelected = true;

                            setSelectedGrave(response.data[key])
                        }
                    }
                }

                setGraves(graveSubs);
            }
        }
    }, [response, error]);

    useEffect(() => {

    }, [selectedGrave])

    function getGraveList() {
        const url = "grave/list";
        setEndpoint(url);

        request({
            method: "POST",
            url: url,
        });
    }

    function gravesFilterHandler(value) {
        if (value != null) {
            setSelectedGrave(value);
        }

    }

    const handlePaymentInfo = event => {
        event.preventDefault();
        props.handlePageType("paymentInfo");
    }

    return (
        <Wrapper>

            {graves.length > 0 && (
                <Wrapper>
                    {(Helpers.getUserGraveRoles().includes("OWNER") ||
                        Helpers.getUserGraveRoles().includes("PURCHASER")) && (
                            <a
                                href=""
                                onClick={event => handlePaymentInfo(event)}
                            >
                                <h2 className="h1" style={{ color: "black", margin: "0 0 40px", padding: "16px 0 16px" }}>{t("accountSettings_payment_paymentInfo")} &gt;</h2>
                            </a>
                        )}
                </Wrapper>
            )}
        </Wrapper>
    )
}

export default AccountSettingsSubs;