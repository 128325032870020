import { useTranslation } from "react-i18next";
import Wrapper from "../components/UI/Wrapper"
import Helpers from "../utils/Helpers";
import { useContext } from "react";
import AppContext from "../utils/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const HoldOnPage = props => {

    const { t } = useTranslation();
    const appCtx = useContext(AppContext);
    const history = useHistory();

    const queryParams = new URLSearchParams(window.location.search);
    const ownerName = queryParams.get("ownerName");
    const onboardType = queryParams.get("assignmentType");;

    const homepageHandler = (event) => {
        event.preventDefault();

        appCtx.logout();
        Helpers.removeStorage();
        history.replace("/");
    };

    return (
        <Wrapper>
            <div className="rl-welcome admin">
                <img
                    src="img/icon-clock.png"
                    className="img-heart"
                />

                <h2>{t("holdon_title")}</h2>

                {onboardType == "GUARDIAN" && <p>{t("holdon_desc1_guardian", { ownerName: ownerName })}</p>}
                {onboardType == "GUARDIAN" && <p className="italic">{t("holdon_desc2_guardian", { ownerName: ownerName })}</p>}
                {onboardType == "ADMIN" && <p>{t("holdon_desc1_admin", { ownerName: ownerName })}</p>}
                {onboardType == "ADMIN" && <p className="italic">{t("holdon_desc2_admin", { ownerName: ownerName })}</p>}

                <a href="" onClick={homepageHandler} className="rl-go-to-home">
                    {Helpers.capitalizeAll(t("holdon_button"))}
                </a>

                {/* 
                <div className="rl-special-offer">
                    <h3>{t("congrats_admin_specialOffer")}</h3>

                    {onboardType == "GUARDIAN" && <p>{t("congrats_admin_specialOffer_guardian_message")}</p>}
                    {onboardType == "ADMIN" && <p>{t("congrats_admin_specialOffer_admin_message")}</p>}

                    <div className="rl-special-offer-buttons">
                        <a href="" onClick={(event) => handleCreateGrave(event, "FOR_YOU")}>
                            {t("landing_forwhom_foryou")}
                        </a>
                        <a
                            href=""
                            onClick={(event) => handleCreateGrave(event, "FOR_BE_LOVED")}
                        >
                            {t("landing_forwhom_beloved")}
                        </a>
                    </div>

                    <a href="" onClick={homepageHandler} className="rl-go-to-home">
                        {Helpers.getUserGraveRoles().includes("ADMIN") ? Helpers.capitalizeAll(t("congrats_admin_backToMain")) : t("congrats_admin_logout")}
                    </a>
                </div> */}
            </div>
        </Wrapper>
    )
}

export default HoldOnPage;