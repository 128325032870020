import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import Wrapper from "../Wrapper";

import classes from "./RLModal.module.css";
import Card from "../Card";
import RLModalLogin from "./RLModalLogin";
import RLModalRegister from "./RLModalRegister";
import RLModalCreateGrave from "./RLModalCreateGrave";
import RLModalPasswordChange from "./RLModalPasswordChange";
import RLModalForgotPassword from "./RLModalForgotPassword";
import RLModalCheckEmail from "./RLModalCheckEmail";
import RLModal3DSecure from "./RLModal3DSecure";
import { prop } from "dom7";
import AppContext from "../../../utils/app-context";
import RLModalCancelSub from "./RLModalCancelSub";
import RLModalNewCard from "./RLModalNewCard";
import RLModalChangeCard from "./RLModalChangeCard";
import RLModalCancelCard from "./RLModalCancelCard";
import RLModalVerseDetail from "./RLModalVerseDetail";
import RLModalVerseChange from "./RLModalVerseChange";
import RLModalConfirmationModal from "./RLModalConfirmationModal";
import RLModalVerseVideo from "./RLModalVerseVideo";
import RLModalKycReject from "./RLModalKycReject";
import RLModalFunnelChoose from "./RLModalFunnelChoose";
import RLModalWarning from "./RLModalWarning";

const RLModalRouter = (props) => {
  const appCtx = useContext(AppContext);

  const modal_ = {
    type: props.modal.type,
    content: props.modal.content,
    params: props.modal.params, // graveType vs
    show: props.modal.show ?? false,
    onClose: handleClose,
    onConfirm: props.modal.onConfirm,
  };

  function handleClose() {
    appCtx.hideModal();
  }

  const handleBlockerClose = (event) => {
    // console.log("handleBlockerClose")
    // event.preventDefault();

    if (event.target === event.currentTarget) {
      if (modal_.params.blockCloserEnabled) {
        appCtx.hideModal();
      }
    }
  }

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={modal_.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 0 }}
    >
      <Wrapper>
        {/* <div className={classes.backdrop} onClick={props.onClose} /> */}
        <Card className={classes.blocker} onClick={handleBlockerClose}>
          <Card
            className={`${classes.modal} ${"rememberland-modal"} ${props.modal.className
              }`}
          // style={props.modal.style}
          >
            <div className={`${"rememberland-modal-body"} ${classes["w-100"]}`}>
              {/* {props.type == "login" && (
                <RLModalLogin
                  onClose={props.onClose}
                  openModal={props.openModal}
                  // openOtherModal={props.openOtherModal}
                  closeClassName={classes["close-modal"]}
                />
              )} */}
              {modal_.type == "login" && (
                <RLModalLogin
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {/* {props.type == "register" && (
                <RLModalRegister
                  onClose={props.onClose}
                  openModal={props.openModal}
                  closeClassName={classes["close-modal"]}
                />
              )} */}
              {modal_.type == "register" && (
                <RLModalRegister
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {/* {props.type == "forgotPassword" && (
                <RLModalForgotPassword
                  onClose={props.onClose}
                  openModal={props.openModal}
                  closeClassName={classes["close-modal"]}
                />
              )} */}

              {modal_.type == "forgotPassword" && (
                <RLModalForgotPassword
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "checkEmail" && (
                <RLModalCheckEmail
                  modal={modal_}
                  //   onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                //   onConfirm={
                //     props.onConfirm != undefined ? props.onConfirm : undefined
                //   }
                />
              )}

              {modal_.type == "createGrave" && (
                <RLModalCreateGrave
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "cancelSubs" && (
                <RLModalCancelSub
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "newCard" && (
                <RLModalNewCard
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "changeCard" && (
                <RLModalChangeCard
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "cancelCard" && (
                <RLModalCancelCard
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "verseDetail" && (
                <RLModalVerseDetail
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "verseVideo" && (
                <RLModalVerseVideo
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "verseChange" && (
                <RLModalVerseChange
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "confirmationModal" && (
                <RLModalConfirmationModal
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "warningModal" && (
                <RLModalWarning
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "funnelChoose" && (
                <RLModalFunnelChoose
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {modal_.type == "kycReject" && (
                <RLModalKycReject
                  modal={modal_}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {props.type == "changePassword" && (
                <RLModalPasswordChange
                  onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                />
              )}

              {props.type == "3dSecure" && (
                <RLModal3DSecure
                  onClose={props.onClose}
                  closeClassName={classes["close-modal"]}
                  content={props.content}
                />
              )}
            </div>
          </Card>
        </Card>
      </Wrapper>
      {/* </div> */}
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default RLModalRouter;
