import Helpers from "../../../../utils/Helpers";
import Wrapper from "../../../UI/Wrapper";

const BOGraveDetailTestamentItem = props => {

    const testement = props.item

    return (
        <Wrapper>
            <tr>
                <td className="fw-600">{Helpers.formatDate(testement.createdAt)}</td>
                <td className="rl-table-border-l">
                  {testement.name}
                </td>
              </tr>
        </Wrapper>
    );
}

export default BOGraveDetailTestamentItem;