import { useState } from "react";
import Wrapper from "./Wrapper";

const RLCheckBox = (props) => {
  const [input, setInput] = useState();

  let input_ = {};
  if (props.input != input) {
    input_ = {
      value: props.input.value,
      isChecked: props.input.isChecked,
      onClick: props.input.onClick,
    };

    if (JSON.stringify(input_) != JSON.stringify(input)) {
      setInput(input_);
    }
  }

  const handleOnClick = (event) => {
    // event.preventDefault();
    if (input != undefined) {
      input.onClick(event);
    }
  };

  const handleOnChange = (event) => {
    // console.log("handleOnChange")
  };

  return (
    <Wrapper>
      <input
        onClick={handleOnClick}
        onChange={handleOnChange}
        checked={input_.isChecked}
        type="checkbox"
        className="form-checkbox"
        value={input_.value}
      />
    </Wrapper>
  );
};

export default RLCheckBox;
