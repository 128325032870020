import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import RLEmptyListCard from "../../UI/RLModal/RLEmptyListCard";
import Wrapper from "../../UI/Wrapper";
import PanelAdminList from "../PanelAdmin/PanelAdminList";
import PanelKycForm from "./PanelKycForm";
import PanelKycList from "./PanelKycList";
import PanelKycListItem from "./PanelKycListItem";
import { useTranslation } from "react-i18next";

const PanelKyc = (props) => {
  const params = useParams();
  const { t } = useTranslation();

  const [kycs, setKycs] = useState([]);
  const [kycTypes, setKycTypes] = useState([]);
  const [uploadable, setUploadable] = useState(false);
  //   const [filter, setFilter] = useState("");

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (endpoint == "first") {
      getKycList();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + params["graveId"] + "/kyc/list") {
        setKycs(response.data);

        var kycTypes_ = [];
        var hasPassport = false;
        var hasAdress = false;
        response.data.kycs.forEach((kyc) => {
          if (kyc.fileType == "PASSPORT") {
            hasPassport = true
          }
          if (kyc.fileType == "ADDRESS") {
            hasAdress = true
          }
        });

        if (!hasPassport) {
          kycTypes_.push({ id: "PASSPORT", name: t("general_enum_PASSPORT") })
        }

        if (!hasAdress) {
          kycTypes_.push({ id: "ADDRESS", name: t("general_enum_ADDRESS") })
        }

        setKycTypes(kycTypes_)

        if ((response.data.kycStatus == "NOT_STARTED" || response.data.kycStatus == "REJECTED" || response.data.kycStatus == "WAITING") &&
          (!hasPassport || !hasAdress)) {
          setUploadable(true)
        } else {
          setUploadable(false)
        }
      }
    }
  }, [response, error, reload]);

  function getKycList(filter) {
    const url = "grave/" + params["graveId"] + "/kyc/list";
    setEndpoint(url);

    let data = {};
    if (filter != undefined) {
      data = filter;
    }

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  const listFilterChangeHandler = (data) => {
    let filter = {};
    if (data.kyc != undefined) {
      filter = Object.assign(filter, { kycFileType: data.kyc.id });
    }

    getKycList(filter);
  };

  return (
    <Wrapper>
      <section className="rl-admin-content">

        <div className="rl-admin-content-box">
          <PanelKycForm
            reloadList={() => {
              getKycList();
            }}
            documentTypes={kycTypes}
            rlSideBar={rlSideBar}
            uploadable={uploadable}
            count={0}
          />
        </div>

        {kycs.kycs?.length > 0 ? (
          <PanelKycList
            documentTypes={kycTypes}
            items={kycs}
            onListFilterChange={listFilterChangeHandler}
            reloadList={() => {
              getKycList();
            }}
          />
        ) : (
          <RLEmptyListCard />
        )}
      </section>
    </Wrapper>
  );
};

export default PanelKyc;

const DocumentTypes = [
  { id: "PASSPORT", name: "PASSPORT" },
  { id: "ADDRESS", name: "ADDRESS" },
];
