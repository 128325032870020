import React, { useState, useRef, useEffect } from "react";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import Wrapper from "./Wrapper";
import RLModal from "./RLModal/RLModal";

export const SplittingJS = (props) => {
  // state for the array of lines found after running Splitting
  // const [lines, setLines] = useState([]);
  // create a ref object to our text to split
  const splitRef = useRef(null);

  let classes = []
  if (props.styleClass != undefined) {
    classes = props.styleClass
  }


  // should fire anytime splitRef is changed (onload splitRef won't exist)
  useEffect(() => {
    // double checking we actually have a reference (and the value is not null)
    if (splitRef) {
      // run the SplittingJS magic here, using 'lines' as the splitting technique
      let split_res = Splitting({
        by: "chars",
      });
      // finding the first block of text and its lines - then assigning it to our state defined above
      // setLines(split_res[0].lines);
      // console.log(`Split text into ${lines} lines`);
    }
  }, [splitRef]);

  return (
    <Wrapper>
      <a
        onClick={props.onClick}
        className={`${props.className} ${"slide-vertical"}`}
        
        rel="modal:open"
        ref={splitRef}
        data-splitting="true"
      >
        {props.text}
      </a>
    </Wrapper>


  );
};
