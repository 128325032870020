import { TextareaAutosize } from "@mui/base";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";
import RLButton from "../../UI/RLButton";
import RLInput from "../../UI/RLInput";
import RLInputText from "../../UI/RLInputText";
import Wrapper from "../../UI/Wrapper";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import AppContext from "../../../utils/app-context";

const PanelDiaryForm = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  let btnRef = useRef();
  const [disableNextPrev, setDisableNextPrev] = useState({next: false, prev: false});

  const isFunnel = Helpers.isFunnel(location);
  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave
  let selectedGraveFunnelSteps = undefined;
  const diaryCount = props.count;

  const params = useParams();
  let graveId = params["graveId"];
  const diariesEdit = props.diariesEdit;

  const [message, setMessage] = useState({
    value: t("diary_form_defaultMessage"),
    isValid: true,
    validation: ["empty", "min"],
    onChange: handleMessageChange,
  });

  const [isEdit, setIsEdit] = useState(false);

  function handleMessageChange(value, isValid = false) {
    setMessage({ ...message, value: value, isValid: isValid });
    btnRef.current.removeAttribute("disabled");
  }

  function isValidForm() {
    let isValidFormMessage = message.isValid;
    if (isValidFormMessage && message.validation.includes("empty")) {
      isValidFormMessage = message.value == "" ? false : true;
      setMessage({ ...message, isValid: isValidFormMessage });
    }

    return (
      isValidFormMessage
    );
  }

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  if (diariesEdit != undefined) {
    if (!isEdit && props.isEdit) {
      setIsEdit(true);
      // setMessage({ ...message, value: diariesEdit.summary });
      getDiary();
    }
  }

  useEffect(() => {

    if (!error && response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/diary/create") {
        setIsEdit(false);
        setMessage({ ...message, value: t("diary_form_defaultMessage") });

        props.saveDiary(response.data);
        btnRef.current.removeAttribute("disabled");

        appCtx.addToast(t("diary_list_item_added"), "green");

      } else if (responseType == "funnel/move-forward-funnel") {

        if (selectedGrave.graveType == "FOR_YOU") {
          history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/testament");
        }

        rlSideBar.funnelMoveForward(rlSideBar, response.data);

      } else if (responseType == "grave/" + graveId + "/diary/" + diariesEdit.id + "/update") {
        setIsEdit(false);
        setMessage({ ...message, value: t("diary_form_defaultMessage") });

        props.saveDiary(response.data);
        btnRef.current.removeAttribute("disabled");

        appCtx.addToast(t("diary_list_item_updated"), "green");

      } else if (responseType == "grave/" + graveId + "/diary/" + diariesEdit.id) {
        setMessage({ ...message, value: response.data.text });

      } else if (responseType == "funnel/select-funnel-step") {

        rlSideBar.funnelMoveForward(rlSideBar, response.data);
      }

    } else {
      if (error) {
        btnRef.current.removeAttribute("disabled");
      }
    }
  }, [response, error]);

  function getDiary() {
    const url = "grave/" + graveId + "/diary/" + diariesEdit.id;

    const data = {};

    request({
      method: "GET",
      url: url,
      data,
    });
  }

  const postCreateDiary = (event) => {
    event.preventDefault();

    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }

    if (isValidForm()) {

      const url = "grave/" + graveId + "/diary/create";
      setEndpoint(url);

      const data = {
        content: message.value,
      };

      request({
        method: "POST",
        url: "grave/" + graveId + "/diary/create",
        data,
      });
    }
  };

  const postUpdateDiary = event => {
    event.preventDefault();

    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }

    if (isValidForm()) {

      const url = "grave/" + graveId + "/diary/" + diariesEdit.id + "/update";
      setEndpoint(url);

      const data = {
        content: message.value,
      };

      request({
        method: "POST",
        url: "grave/" + graveId + "/diary/" + diariesEdit.id + "/update",
        data,
      });
    }
  }

  const handleUpdateDiaryCancel = event => {
    event.preventDefault();

    setIsEdit(false);
    setMessage({ ...message, value: t("diary_form_defaultMessage") });

    props.saveDiary(response.data);
  }

  for (let i = 0; i < selectedGrave.graveFunnelSteps.length; i++) {
    if (selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == "DIARY") {
      selectedGraveFunnelSteps = selectedGrave.graveFunnelSteps[i]
    }
  }

  const handleBack = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, prev: true });

    if (selectedGrave.graveType == "FOR_YOU") {
      history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/content");
      postSelectFunnelStep("CONTENT")
    } else if (selectedGrave.graveType == "FOR_BE_LOVED") {
    }
  }
  const handleSkipForNow = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, next: true });
    // history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/testament");
    postFunnelMoveForward();
  }

  function postFunnelMoveForward() {

    if (isFunnel) {

      let graveId = -1;
      let graveFunnelStepId = -1;
      let skipped = diaryCount > 0 ? false : true;

      graveId = selectedGraveFunnelSteps.graveId
      graveFunnelStepId = selectedGraveFunnelSteps.id

      if (selectedGraveFunnelSteps.status == "COMPLETED") {
        history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/testament");
        postSelectFunnelStep("TESTAMENT")
        return;
      }

      const data = {
        graveId: graveId,
        graveFunnelStepId: graveFunnelStepId,
        skipped: skipped,
      };

      request({
        method: "POST",
        url: "funnel/move-forward-funnel",
        data,
      });
    }
  }

  function postSelectFunnelStep(type) {
    if (isFunnel) {
      let graveId = -1;
      let graveFunnelStepId = -1;

      for (let i = 0; i < selectedGrave.graveFunnelSteps.length; i++) {
        if (selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == type) {
          graveId = selectedGrave.graveFunnelSteps[i].graveId
          graveFunnelStepId = selectedGrave.graveFunnelSteps[i].id
        }
      }

      const data = {
        graveId: graveId,
        graveFunnelStepId: graveFunnelStepId,
      };

      request({
        method: "POST",
        url: "funnel/select-funnel-step",
        data,
      });
    }
  }

  let backButton = "";
  let nextButton = "";
  if (isFunnel) {
    if (selectedGrave.graveType == "FOR_YOU") {
      backButton = "panel_navigation_contents"
    } else if (selectedGrave.graveType == "FOR_BE_LOVED") {
    }

    if (selectedGraveFunnelSteps.status == "IN_PROGRESS" && (diaryCount == undefined || diaryCount == 0)) {
      nextButton = "general_skipfornow"
    } else {
      nextButton = "general_next"
    }
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box">
        <form className="rl-admin-add-user-form rl-diary">

          <div className="rl-form-group expand-row">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="textArea"
                label={""}
                data={message}
                height="240"
              />
            </div>
          </div>

          {/* {!Helpers.getFunnel() && ( */}

          {!isFunnel && (
            <div>
              {/* <button ref={btnRef} className="rl-form-button" onClick={postCreateDiary}>
                {t("general_submit")}
              </button> */}

              {!props.isEdit && (
                  <button ref={btnRef} className="rl-form-button" onClick={postCreateDiary}>
                    {t("general_submit")}
                  </button>
                )}

                {props.isEdit && (
                  <Wrapper>
                    <div className="modal-2-buttons">
                      <a href="" onClick={handleUpdateDiaryCancel} className="rl-btn-border black">
                        {" "}
                        {t("confirmation_cancelButton")}
                      </a>
                      <button ref={btnRef} className="rl-form-button" onClick={postUpdateDiary}>
                        {t("general_save")}
                      </button>
                    </div>
                  </Wrapper>
                )}
            </div>
          )}

          {isFunnel && (
            <div className="expand-row-submit">
              <div className="left">
                <a href="" onClick={handleBack} class="rl-panel-wizard-btn-skip w-icon left" style={{ fontWeight: "bold", pointerEvents: disableNextPrev.prev ? 'none' : 'auto' }}>
                  <img src="/img/icon-chevron-right.svg" alt="Testaments" /> {Helpers.capitalizeAllFirst(t("panel_navigation_contents"))}
                </a>
              </div>


              <div class="right">
                {!props.isEdit && (
                  <button ref={btnRef} className="rl-form-button" onClick={postCreateDiary}>
                    {t("general_submit")}
                  </button>
                )}

                {props.isEdit && (
                  <Wrapper>
                    <div className="modal-2-buttons">
                      <a href="" onClick={handleUpdateDiaryCancel} className="rl-btn-border black">
                        {" "}
                        {t("confirmation_cancelButton")}
                      </a>
                      <button ref={btnRef} className="rl-form-button" onClick={postUpdateDiary}>
                        {t("general_save")}
                      </button>
                    </div>
                  </Wrapper>
                )}

                {isFunnel && (
                  <div className="column">
                    <a href="" onClick={handleSkipForNow} className="rl-panel-wizard-btn-skip w-icon" style={{ fontWeight: "bold", pointerEvents: disableNextPrev.next ? 'none' : 'auto' }}>{t(nextButton)} <img src="/img/icon-chevron-right.svg" alt="" /></a>
                  </div>
                )}
              </div>
            </div>
          )}

        </form>
      </div>
    </Wrapper>
  );
};

export default PanelDiaryForm;
