import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLButton from "../RLButton";
import RLInputText from "../RLInputText";
import Wrapper from "../Wrapper";

const RLModalCreateGrave = (props) => {
  // const history = useHistory();
  // const location = useLocation();
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const modal = props.modal;
  const params = modal.params;

  const [graveName, setGraveName] = useState({
    value: "",
    isValid: true,
    validation: ["empty","min","max30"],
    onChange: handleChangeGraveName,
  });

  const [grave, setGrave] = useState();

  const onboardStatus = Helpers.getOnboardStatus();
  const onboardType = Helpers.getOnboardType();

  function handleChangeGraveName(value, isValid = false) {
    setGraveName({ ...graveName, value: value, isValid: isValid });
  }

  const handleCreateGrave = (event) => {
    event.preventDefault();

    if (event.target === event.currentTarget) {
      let isValidFormGraveName = graveName.isValid;
      if (isValidFormGraveName && graveName.validation.includes("empty")) {
        isValidFormGraveName = graveName.value == "" ? false : true;
        setGraveName({ ...graveName, isValid: isValidFormGraveName });
      }

      if (!isValidFormGraveName) {
        return;
      }

      if (onboardType == "USER" || onboardType == "OWNER" || onboardType == "PURCHASER" || onboardType == "ADMIN" || onboardType == "GUARDIAN") {
        appCtx.showLoading();
        postCreateGrave();
      }

      // // TRAIL SENARYOSU ICIN KAPATILDI
      
      // // owner, purchaser payment
      // if (onboardType == "USER") {
      //   if (onboardStatus == "WAITING") {
      //     if (graveName.isValid) {
      //       // modal.onClose();
      //       // params.history.replace("/panelPayment?graveName="+graveName.value+"&graveType="+params.graveType);

      //       appCtx.showLoading();
      //       postCreateGrave()
      //     }
      //   }

      //   // owner, purchaser - panel create grave
      // } else if (onboardType == "OWNER" || onboardType == "PURCHASER") {

      //   if (graveName.isValid) {
      //     modal.onClose();
      //     params.history.replace("/panelPayment?graveName=" + graveName.value + "&graveType=" + params.graveType);
      //   }

      //   // admin, guardian - welcome create grave
      // } else if (onboardType == "ADMIN" || onboardType == "GUARDIAN") {
      //   if (onboardStatus == "COMPLETED") {
      //     if (graveName.isValid) {
      //       modal.onClose();
      //       params.history.replace("/panelPayment?graveName=" + graveName.value + "&graveType=" + params.graveType);
      //     }
      //   }
      // }
    }
  };

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      appCtx.hideLoading();
      if (responseType == "grave/create-grave") {
        setGrave(response.data)
        appCtx.showLoading();
        getProfile();
      } else if (responseType == "profile") {

        appCtx.login(localStorage.getItem("token"), response.data);

        appCtx.hideLoading();

        // if (!grave.hasSubscription) {
        //   if(modal.onConfirm != undefined) {
        //     modal.onConfirm("/funnel/"+grave.id+"")
        //     modal.onClose();
        //   } else {
        //     window.location.reload(false);
        //     window.location.href = "/funnel/"+grave.id+""
        //   }
        // } else {
        //   if(modal.onConfirm != undefined) {
        //     modal.onConfirm("/panel/"+grave.id+"")
        //     modal.onClose();
        //   } else {
        //     window.location.reload(false);
        //   }
        // }
        window.location.href = "/funnel/"+grave.id+""

        modal.onClose();
      }
      //   setData(response);

      //   if (params.location.pathname.includes("/panel/")) {
      //     // props.onClose
      //     // history.replace(location.pathname);
      //     window.location.reload(false);
      //   } else {
      //     // history.replace("/payment-welcome");
      //   }
    }
  }, [response]);

  function postCreateGrave() {
    const data = {
      name: graveName.value,
      graveType: params.graveType,
    }
    const url = "grave/create-grave";

    request(
      {
        method: "POST",
        url: url,
        data,
      });
  }

  function getProfile() {
    const url = "profile";

    request({
      method: "GET",
      url: url,
    });
  }



  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">{t("createGrave_title")}</h1>
        <form onSubmit={handleCreateGrave} className="rl-grave-name">
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="text"
                label={t("createGrave_graveName")}
                data={graveName}
              />
            </div>
          </div>
          {/* <button className="rl-form-button reset-pass-btn">Continue</button> */}
          <RLButton className="reset-pass-btn" text={t("general_continue")} />
        </form>
      </div>
      <a
        rel="modal:close"
        className={props.closeClassName}
        onClick={modal.onClose}
      >
        x
      </a>
    </Wrapper>
  );
};

export default RLModalCreateGrave;
