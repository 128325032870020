import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLInputSelect from "../RLInputSelect";
import RLInputSelectStandart from "../RLInputSelectStandart";
import Wrapper from "../Wrapper";

const RLModalChangeCard = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const modal = props.modal;

  const [cardList, setCardList] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleCardChange,
    options: [],
  });

  function handleCardChange(value, isValid_ = false) {
    let isValid = value === null ? false : true;
    setCardList({ ...cardList, isValid: isValid, selectedCard: value });
  }

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getCardList();
    }

    if (!error && response !== null && typeof response !== "undefined") {
      if (responseType == "payment/card/list") {
        // setCardList(response.data)
        let cards = [];
        let selectedCard = {};
        response.data.forEach((card, index) => {
          cards.push({
            id: card.id,
            name:
              card.binNumber.slice(0, 4) + " **** **** " + card.lastFourDigits,
          });

          if (card.id == modal.params.userCardId) {
            selectedCard = {
              id: card.id,
              name: Helpers.formatCreditCardNumber(card)
            };
          }
        });

        const isValid = selectedCard != {} ? true : false;

        setCardList({
          ...cardList,
          options: cards,
          selectedCard: selectedCard,
          isValid: isValid,
        });
      } else if (responseType == "payment/changeGravePaymentCard" || responseType == "payment/changePaymentCard") {

        if (modal.params.type == "cancel") {

          const cancelCardModal = {
            type: "cancelCard",
            show: true,
            params: {
              cardNumber: Helpers.formatCreditCardNumber(modal.params.cardNumber),
              userCardId: modal.params.userCardId
            },
          };
          appCtx.showModal(cancelCardModal);

        } else {
          window.location.reload(false);
        }
        
      }
    }
  }, [response, error]);

  function getCardList() {
    const url = "payment/card/list";
    setEndpoint(url);

    request({
      method: "GET",
      url: url,
    });
  }

  const handleChange = (event) => {
    event.preventDefault();

    if (cardList.isValid && cardList.selectedCard.id != modal.params.userCardId) {
      if (modal.params.type == "cancel") {
        const url = "payment/changePaymentCard";

        const data = {
          newUserCreditCardId: cardList.selectedCard.id,
          userCreditCardId: modal.params.userCardId,
        };

        request({
          method: "POST",
          url: url,
          data: data,
        });
      } else {
        const url = "payment/changeGravePaymentCard";

        const data = {
          graveId: parseInt(modal.params.graveId),
          userCreditCardId: cardList.selectedCard.id,
        };

        request({
          method: "POST",
          url: url,
          data: data,
        });
      }
    }
  };

  const handleClose = (event) => {
    event.preventDefault();

    appCtx.hideModal();
  };

  let title = t("accountSettings_payment_changeCard");
  let desc = (
    <Wrapper>
      <div className="modal-header-desc">
        {t("accountSettings_payment_changeCard_desc")}
      </div>
    </Wrapper>
  );

  if (modal.params.type == "cancel") {
    title = t("accountSettings_payment_cancelCard");
    desc = (
      <Wrapper>
        <div className="modal-header-desc">
          {t("accountSettings_payment_cancelCard_desc")} <br />
          <b>{t("accountSettings_payment_cancelCard_descBold")}</b>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">{title}</h1>

        {desc}

        <form action="" className="sign-in-up-form">
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputSelect
                label={t("accountSettings_payment_changeCard_cardName")}
                standart={true}
                onChange={handleCardChange}
                defaultValue={cardList.selectedCard}
                options={cardList.options}
              />
            </div>
            {!cardList.isValid && (
              <div className="error-line">
                <img src="/img/icon-circle-info.svg" width="24" />
                <span>You must chouse card</span>
              </div>
            )}
          </div>

          <div className="modal-header-desc"></div>

          <div className="modal-2-buttons">
            <a href="" onClick={handleClose} className="rl-btn-border black">
              {t("accountSettings_payment_cancelSubs_cancel")}
            </a>
            <a href="" onClick={handleChange} className="rl-btn-filled black">
              {t("accountSettings_payment_changeCard")}
            </a>
          </div>
        </form>
      </div>

      <a
        rel="modal:close"
        className={props.closeClassName}
        onClick={modal.onClose}
      >
        x
      </a>
    </Wrapper>
  );
};

export default RLModalChangeCard;
