import { useContext, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLCheckBox from "../../UI/RLCheckBox";
import Wrapper from "../../UI/Wrapper";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PanelAdminListItem = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const location = useLocation();

  const params = useParams();
  let graveId = params["graveId"];

  const isFunnel = Helpers.isFunnel(location);

  const item = props.item;
  const admin = item.value;
  const checkbox = item.checkbox;
  const btnRef = useRef();

  // const [graveType, setGraveType] = useState(undefined);
  // if (graveType != Helpers.getGraveType()) {
  //   setGraveType(Helpers.getGraveType());
  // }

  const { response, loading, error, request, responseType } = useAxios();

  const submit = (event) => {
    // event.preventDefault();
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => postRemoveAdmin,
        },
        {
          label: "No",
          onClick: () => console.log("asdf"),
        },
      ],
    });
  };

  const postAssignToGuardian = (event) => {
    event.preventDefault();

    request({
      method: "POST",
      url: "grave/" + graveId + "/assignment/guardian/assign/" + admin.id,
    });
  };

  const handleAssignToGuardian = (event) => {
    event.preventDefault();

    const verseDetailModal = {
      type: "confirmationModal",
      show: true,
      params: {
        title: t("admin_modal_assign_title"),
        description: t("admin_modal_assign_desc", { name: admin.name + " " + admin.surname }),
        confirmButton: t("admin_modal_assign_button"),
      },
      onConfirm: postAssignToGuardian,
    };
    appCtx.showModal(verseDetailModal);
  };

  const postResendAdmin = (event) => {
    event.preventDefault();

    if (btnRef.current.attributes.disabled != undefined) {
      return;
    }

    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }

    request({
      method: "POST",
      url: "grave/" + graveId + "/assignment/" + admin.id + "/resend",
    });
  };

  const postRemoveAdmin = (event) => {
    event.preventDefault();

    const data = [admin.id];

    request({
      method: "DELETE",
      url: "grave/" + graveId + "/assignment/admin/remove",
      data,
    });
  };

  const handleRemoveConfirm = (event) => {
    postRemoveAdmin(event);
  };

  const handleRemove = (event) => {
    event.preventDefault();

    const verseDetailModal = {
      type: "confirmationModal",
      show: true,
      params: {
        title: t("admin_modal_remove_title"),
        description: t("admin_modal_remove_desc", { name: admin.name + " " + admin.surname }),
        buttonName: t("admin_modal_remove_button"),
      },
      onConfirm: handleRemoveConfirm,
    };
    appCtx.showModal(verseDetailModal);
  };

  useEffect(() => {
    if (error) {
    }
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/assignment/admin/remove") {
        props.adminRemoveHandler();
      } else if (
        responseType ==
        "grave/" + graveId + "/assignment/guardian/assign/" + admin.id
      ) {
        appCtx.addToast(t("admin_list_add_guardian_message"), "green");
        props.adminRemoveHandler();
      } else if (
        responseType ==
        "grave/" + graveId + "/assignment/" + admin.id + "/resend"
      ) {
        appCtx.addToast(t("general_resent"), "green");
        btnRef.current.removeAttribute("disabled");
      }
    }
  }, [response, error]);

  return (
    <Wrapper>
      <tr>
        {/* <td>
          <RLCheckBox input={checkbox} />
        </td> */}
        <td className="fw-600">{t("general_enum_" + admin.inviteStatus)}</td>
        <td className="rl-table-border-l fw-600">
          {admin.name + " " + admin.surname}
        </td>
        <td className="rl-table-border-l">{admin.email}</td>

        <td className="w-40 text-right">
          <div class="w-40" style={{ minWidth: "480px" }}>

            {/* {(!admin.graveGuardian) && (
              <a
                href=""
                className="rl-link-black"
                rel="modal:open"
                onClick={handleAssignToGuardian}
              >
                <img src="/img/icon-plus.svg" alt="Add to Guardian" />{" "}
                {t("admin_list_add_guardian")}
              </a>
            )} */}


            {(admin.inviteStatus != "ACCEPTED" && !isFunnel) && (
              <a
                href=""
                onClick={postResendAdmin}
                className="rl-table-button blue"
                ref={btnRef}
              >
                {t("general_resend")}
              </a>
            )}

            <a href="" className="rl-table-button red" onClick={handleRemove}>
              {t("general_remove")}
            </a>
          </div>
        </td>
      </tr>
    </Wrapper>
  );
};

export default PanelAdminListItem;
