import Wrapper from "../../../UI/Wrapper";

const BOGraveDetailGuardian = props => {
    return (
        <Wrapper>
            {"BOGraveDetailGuardian"}
        </Wrapper>
    );
}

export default BOGraveDetailGuardian;