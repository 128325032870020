import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Wrapper from "../Wrapper";

const RLModalCancelSub = (props) => {
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();

  const modal = props.modal;
  const params = modal.params;

  const handleClose = (event) => {
    event.preventDefault();

    appCtx.hideModal();
  };

  const handleCancleSubs = (event) => {
    event.preventDefault();

    const url = "profile/cancel";

    request({
      method: "POST",
      url: url,
    });
  };

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (!error && response !== null && typeof response !== "undefined") {
      if (responseType == "profile/cancel") {
        const checkMailModal = {
          type: "checkEmail",
          show: true,
        };
        appCtx.showModal(checkMailModal);
      }
    }
  }, [response]);

  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">
          {t("accountSettings_payment_cancelSubs_header")}
        </h1>
        <div className="modal-header-desc">
          {" "}
          {t("accountSettings_payment_cancelSubs_desc")}{" "}
        </div>

        <div className="modal-header-desc"></div>
        <div className="modal-2-buttons">
          <a href="" onClick={handleCancleSubs} className="rl-btn-border black">
            {" "}
            {t("accountSettings_payment_cancelSubs_delete")}{" "}
          </a>
          <a href="" onClick={handleClose} className="rl-btn-filled black">
            {" "}
            {t("accountSettings_payment_cancelSubs_cancel")}{" "}
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default RLModalCancelSub;
