import { useContext } from "react";
import Layout from "./components/Main/MainLayout";
import AppContext from "./utils/app-context";
import { AppRoutes } from "./routes/Routes";

import { DBConfig } from './utils/DBConfig';
import { initDB } from 'react-indexed-db';

initDB(DBConfig);

function App() {

  return (
    <Layout>
      {/* <Switch> */}
        <AppRoutes />
      {/* </Switch> */}
    </Layout>
  );
}

export default App;
