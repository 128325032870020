import { SplittingJS } from "../UI/Splitting";
import classes from "../../css-panel/style.module.css";
import Image from "../UI/Image";
import Wrapper from "../UI/Wrapper";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../utils/app-context";
import RLLang from "../UI/RLLang";
import Helpers from "../../utils/Helpers";
import { useTranslation } from "react-i18next";
import { Help } from "@mui/icons-material";
import RLButton from "../UI/RLButton";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import useAxios from "../../hooks/use-axios";

const PanelHeader = (props) => {
  const history = useHistory();
  const location = useLocation();
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const lang_ = Helpers.getLang();
  const user = Helpers.getUser();

  const [lang, setLang] = useState(lang_);
  const [showLang, setShowLang] = useState(false);
  const [showRightHeader, setshowRightHeader] = useState(false);
  const [endpoint, setEndpoint] = useState("first");

  const myDashboardHandler = (event) => {
    event.preventDefault();

    if (appCtx.isLoggedIn) {
      // history.replace("/panel");
      getGraveList();
    }
  };

  const landingHandler = (event) => {
    event.preventDefault();

    history.replace("/");
    // window.location.reload(false);

  };

  const showLangHandler = (event) => {

    event.preventDefault();

    setShowLang(!showLang);
  }

  function cahngeLocalizationHandler(event, lang) {

    event.preventDefault();

    setLang(lang);
    i18n.changeLanguage(lang);
    setShowLang(false);
    Helpers.setLang(lang);

    postProfileUpdateLocale(lang);

    window.location.reload();
  }

  const showRightHeaderHandler = (event) => {
    event.preventDefault();
    setshowRightHeader(!showRightHeader);

    appCtx.setMobileSideBar(!showRightHeader)
  }

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/list-funnel-graves") {

        if (endpoint == "grave/list") {
          appCtx.hideLoading();
          if (response.data.length == 0) {
            history.replace("/panel");
            document.body.classList.remove('body-no-scroll');
          } else if (response.data.length == 1) {
            history.replace("/funnel/" + response.data[0]?.id)
            document.body.classList.remove('body-no-scroll');
          }

        } 

      } else if (responseType == "grave/list") {

        if (response.data.length > 0) {

          for (var grave in response.data) {
            if (response.data[grave].hasSubscription) {
              history.replace("/panel");
              document.body.classList.remove('body-no-scroll');
              return
            }
          }

          appCtx.hideLoading();
          postGraveListFunnelGraves()
        }

        appCtx.hideLoading();
      }
    }
  }, [response]);

  function postProfileUpdateLocale(lang) {
    const url = "profile/update";
    // setEndpoint(url);

    const data = {
      locale: lang,
    };

    request(
      {
        method: "POST",
        url: url,
        data,
      }
    );
  }

  const handleLogout = (event) => {
    event.preventDefault();

    appCtx.logout();
    Helpers.removeStorage();
    history.replace("/");

    // window.$chatwoot.toggleBubbleVisibility('show')
  };

  const handleWhatIsrememberland = (event) => {
    event.preventDefault();
    history.replace("/?action=whatisrememberland");
  }

  const handleAvatarClick = (event) => {
    event.preventDefault();

    history.replace("/account");
  }

  function getGraveList() {
    // appCtx.showLoading();
    const url = "grave/list";
    setEndpoint(url);
    const data = {};

    request({
      method: "POST",
      url: url,
      data: data
    });
  }

  function postGraveListFunnelGraves() {
    const url = "grave/list-funnel-graves";

    const data = {};

    request(
      {
        method: "POST",
        url: url,
        data,
      },
    );
  }

  return (
    <Wrapper>
      <header className={classes["rememberland-header"]}>
        <a href="" onClick={handleAvatarClick} className={classes["mobile-user-avatar"]}>
          <Wrapper>
            <div className="rememberland-panel-sidebar-user">
              <figure>
                <img
                  src={
                    user.profilePhoto
                      ? user.profilePhoto
                      : "/img/avatar-placeholder.png"
                  }
                  alt="User Name"
                  className="rl-user-avatar"
                  style={{ width: "40px", height: "40px" }}
                />
              </figure>
            </div>
          </Wrapper>
        </a>

        <a href="" onClick={landingHandler}>
          <img
            src="/img/logo-black.svg"

            alt="REMEMBERLAND"
            className={classes["rememberland-logo-white"]}
          />
        </a>
        <a
          onClick={showRightHeaderHandler}
          className={classes["mobile-hamburger"]}
        >
          <img src="/img/icon-hamburger.svg" alt="Mobile Menu" />
        </a>
      </header>

      <nav
        className={`${classes["rememberland-nav-top"]}`}
      >
        <div
          className={`${classes["rememberland-header-right"]}`}
        >

          {/* <a href=""
            onClick={handleWhatIsrememberland}
            style={{ padding: "18px" }}>
            {t("landing_header_whatisrememberland")}
          </a> */}

          {((!location.pathname.includes("/panel") && !location.pathname.includes("/funnel")) && appCtx.isLoggedIn && Helpers.getOnboardStatus() == "COMPLETED") && (
            <a href="" onClick={myDashboardHandler} className={classes.login} >{t("landing_mydashboard")}</a>
          )}

          {!appCtx.isLoggedIn && (
            <SplittingJS className={classes.login} href="" text="LOGIN" />
          )}

          {/* <SplittingJS
            className={classes["read-more"]}
            href=""
            text="READ MORE"
          /> */}

          {/* <a
            href=""
            onClick={(event) => event.preventDefault()}
            className={`${classes["read-more"]} ${classes["slide-vertical"]}`}
            data-splitting
          >
            {t("landing_readmore")}
          </a> */}

          {/* <div className={`${classes["rl-lang-part"]}`}>
            <a
              href=""
              className={`${classes["rl-lang"]} ${
                showLang ? classes["open"] : ""
              }`}
              onClick={showLangHandler}
            >
              ENG <img src="/img/icon-chevron-down.svg" alt="" />
            </a>
            {(showLang || showRightHeader) && (
              <ul
                className={`${classes["rl-lang-dropdown"]} ${classes["show"]}`}
              >
                <li>
                  <a onClick={() => Helpers.setLang("ENG")} className="active">
                    ENG
                  </a>
                </li>
                <li>
                  <a onClick={() => Helpers.setLang("TR")}>TR</a>
                </li>
              </ul>
            )}
          </div> */}

          {(location.pathname.includes("/panel") || location.pathname.includes("/funnel") || location.pathname.includes("/account")) && (
            <div className={`${classes["rl-lang-part"]}`} style={{ marginLeft: "20px" }}>
              <a
                href=""
                className={`${classes["rl-lang"]} ${showLang ? classes["open"] : ""
                  }`}
                onClick={showLangHandler}
              >
                {lang == "en" ? "ENG" : "TR"}
                <img src="/img/icon-chevron-down.svg" alt="" />
              </a>

              {(showLang || props.showRightHeader) && (
                <ul
                  className={`${classes["rl-lang-dropdown"]} ${classes["show"]}`}
                >
                  <li>
                    <a
                      href=""
                      onClick={(event) => cahngeLocalizationHandler(event, "en")}
                      className={`${lang == "en" ? classes["active"] : ""}`}
                    >
                      ENG
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={(event) => cahngeLocalizationHandler(event, "tr")}
                      className={`${lang == "tr" ? classes["active"] : ""}`}
                    >
                      TR
                    </a>
                  </li>
                </ul>
              )}
            </div>
          )}

          {/* <div>
            <a href="" onClick={handleLogout}>Log out</a>
          </div> */}
        </div>
      </nav>
    </Wrapper>
  );
};

export default PanelHeader;
