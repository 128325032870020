import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const RLTextField = styled((props) => (
  <TextField 
  // id= "asdf"
  InputProps={{ 
    disableUnderline: true,
  }} 
  {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #CECED6",
    borderRadius: 0,
    height: "56px",
    overflow: "hidden",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      // color: "#646672",
    },
  },
  "& .MuiInputBase-root": {
    "&.Mui-disabled": {
      backgroundColor: "#fff",
      color: "#123123",
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      fontSize: "14px",
      color: "#646672",
      zIndex: 10,
    },
    fontSize: "14px",
    fontWeight: 400,
    color: "#999AA7",
  },

  "& input[type=number]": {
    "-MozAppearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-WebkitAppearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-WebkitAppearance": "none",
    margin: 0,
  },
  // '& .MuiInputBase-root.Mui-disabled': {
  // backgroundColor: '#fff',
  // color: "#999AA7",
  // },
}));

export default RLTextField;
