import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Wrapper from "./Wrapper";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartDoughnut = (props) => {

    let data_ = [];
    let backgroundColor_ = [];
    for (let i = 0; i < props.total; i++) {
        data_.push(1);

        if(i < props.count) {
            backgroundColor_.push("rgba(105, 37, 249, 1)")
        } else {
            backgroundColor_.push("rgba(217, 217, 217, 1)")
        }
        
    }

  const data = {
    labels: ["1", "2", "3"],
    datasets: [
      {
        // label: '# of Votes',
        data: data_,
        backgroundColor: backgroundColor_
        //   borderWidth: 35,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      customCanvasBackgroundColor: {
        color: "lightGreen",
      },
    },
    elements: {
      // radius: 10,
      backgroundColor: "#9BD0F5",
      borderColor: "#9BD0F5",
    },
    cutout: '75%',
    maintainAspectRatio: false,
    spacing: 10,
  };

  return (
    <Wrapper>
      <Doughnut data={data} options={options} />
    </Wrapper>
  );
};

export default ChartDoughnut;
