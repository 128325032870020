import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import CardListNoRecord from "../../UI/CardListNoRecord";
import Wrapper from "../../UI/Wrapper";
import BOGraveForm from "./BOGraveForm";
import BOGraveList from "./BOGraveList";

const BOGrave = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const [users, setUsers] = useState([]);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (endpoint == "first") {
      getBOGrave();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "backOffice/grave/list") {
        setUsers(response.data);
        appCtx.addToast(
          t("list_search_complete", { count: response.data.length }),
          "green"
        );
        appCtx.hideLoading();
      }
    }
  }, [response, error, reload]);

  function handleSearch(data) {
    getBOGrave(data);
  }

  function getBOGrave(filter = {}) {
    const url = "backOffice/grave/list";
    setEndpoint(url);

    const data = filter;

    appCtx.showLoading();
    request({
      method: "POST",
      url: url,
      data,
    });
  }

  return (
    <Wrapper>
      <section className="rl-admin-content">
        <BOGraveForm handleSearch={handleSearch} />

        {users.length > 0 ? (
          <BOGraveList items={users} />
        ) : (
          <CardListNoRecord />
        )}
      </section>
    </Wrapper>
  );
};

export default BOGrave;
