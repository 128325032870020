import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RLButton from "../components/UI/RLButton";
import RLInputText from "../components/UI/RLInputText";
import Wrapper from "../components/UI/Wrapper";
import useAxios from "../hooks/use-axios";
import AppContext from "../utils/app-context";
import Helpers from "../utils/Helpers";

const BOLogin = (props) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "email"],
    onChange: handleEmailChange,
  });
  const [password, setPassword] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handlePasswordChange,
  });
  const [rememberMe, setRememberMe] = useState(false);

  function handleEmailChange(value, isValid = false) {
    setEmail({ ...email, value: value, isValid: isValid });
  }

  function handlePasswordChange(value, isValid = false) {
    setPassword({ ...password, value: value, isValid: isValid });
  }

  function isValidForm() {
    let isValidFormEmail = email.isValid;
    if (isValidFormEmail && email.validation.includes("empty")) {
      isValidFormEmail = email.value == "" ? false : true;
      setEmail({ ...email, isValid: isValidFormEmail });
    }

    let isValidFormPassword = password.isValid;
    if (isValidFormPassword && password.validation.includes("empty")) {
      isValidFormPassword = password.value == "" ? false : true;
      setPassword({ ...password, isValid: isValidFormPassword });
    }

    return isValidFormEmail && isValidFormPassword;
  }

  const { response, loading, error, request, responseType } = useAxios();

  const postLogin = (event) => {
    event.preventDefault();

    if (isValidForm()) {
      const data = {
        email: email.value,
        password: password.value,
        rememberMe: rememberMe,
      };

      request(
        {
          method: "POST",
          url: "auth/login",
          data,
        },
        () => {},
        false
      );
    }
  };

  function postProfileUpdateLocale(lang, token) {
    const url = "profile/update";
    // setEndpoint(url);

    const data = {
      locale: lang,
    };

    request(
      {
        method: "POST",
        url: url,
        data,
        headers: {
          Authorization: "Bearer " + token,
        },
      },
      () => {},
      false
    );
  }

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "auth/login") {
        let token = response.data.token;
        let profile = response.data.profile;

        if (profile.roles.includes("ROLE_ADMIN")) {
          appCtx.login(token, profile);

          if (profile.locale === null) {
            const lang = Helpers.getLang();
            i18n.changeLanguage(lang);
            postProfileUpdateLocale(lang, response.data.token);
          } else {
            let lang = "";
            if (profile.locale === "en" || profile.locale === "EN") {
              lang = "en";
            } else if (profile.locale === "tr" || profile.locale === "TR") {
              lang = "tr";
            }

            i18n.changeLanguage(lang);
            Helpers.setLang(lang);
          }

          history.replace("/bo");
        } else {
          history.replace("/");
        }
      }
    }
  }, [response]);

  const handleForgotPassword = (event) => {
    const forgotPasswordModal = {
      type: "forgotPassword",
      show: true,
      params: {
        // onLoginSuccess: params.onLoginSuccess,
      },
    };
    appCtx.showModal(forgotPasswordModal);
  };

  return (
    <Wrapper>
      <body className="bg-white">
        <div className="rl-sign-in-white-outer">
          <img
            src="/img/logo-black.svg"
            alt="REMEMBERLAND"
            width="200"
            className="rl-logo"
          />

          <div className="rl-sign-in-white-box">
            <div className="title">{t("login")}</div>
            <div className="desc">{t("login_description")}</div>
            <form onSubmit={postLogin} className="sign-in-up-form">
              <div className="rl-form-group">
                <div className="rl-form-group-material">
                  <RLInputText
                    inputType="text"
                    label={Helpers.capitalizeFirst(t("general_email"))}
                    data={email}
                  />
                </div>
              </div>

              <div className="rl-form-group">
                <div className="rl-form-group-material">
                  <RLInputText
                    inputType="text"
                    inputFeature="password"
                    label={Helpers.capitalizeFirst(t("password"))}
                    data={password}
                  />
                </div>
              </div>

              <div className="rl-form-remember-forgot">
                <div className="left form-checkbox-outer">
                  <label htmlFor="12">
                    <input
                      onChange={() => setRememberMe(!rememberMe)}
                      type="checkbox"
                      id="12"
                      className="form-checkbox"
                    />
                    {Helpers.capitalizeAllFirst(t("login_rememberme"))}
                  </label>
                </div>
                <div className="right">
                  {/* <RLButton
                  className="rl-forgot-pass"
                  text={Helpers.capitalizeAllFirst(t("login_forgotPassword"))}
                  onClick={() => props.openModal("second", "forgotPassword")}
                  showSecondModal={true}
                  buttonType="a"
                  modalType="forgotPassword"
                /> */}
                  <RLButton
                    className="rl-forgot-pass"
                    text={Helpers.capitalizeAllFirst(t("login_forgotPassword"))}
                    onClick={handleForgotPassword}
                    buttonType="a"
                  />
                </div>
              </div>

              <RLButton text={Helpers.capitalizeFirst(t("login"))} />
            </form>
          </div>
        </div>
      </body>
    </Wrapper>
  );
};

export default BOLogin;
