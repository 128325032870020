import { useEffect, useState } from "react";
import Wrapper from "../UI/Wrapper";
import BOUser from "./BOUser/BOUser";
import BOTag from "./BOTag/BOTag";
import BOGrave from "./BOGrave/BOGrave";
import BOGraveDetail from "./BOGrave/BOGraveDetail/BOGraveDetail";
import { useLocation } from "react-router-dom";
import BOUserDetail from "./BOUser/BOUserDetail/BOUserDetail";

const BOMain = (props) => {

  // const location = useLocation();
  // const paths = location.pathname.split("/");

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);

  const pathName = props.pathName
  let page = <BOUser />;

  // if(paths.includes("grave")) {

  // }


  if(props.page == "USER" || (pathName.includes("/bo/users/") && (props.page == ''))) {
    if(pathName.includes("/bo/users/")) {
      page = <BOUserDetail />
    } else {
      page = <BOUser />
    }
  }

  if(props.page == "TAG") {
    page = <BOTag />
  }

  if(props.page == "GRAVE" || (pathName.includes("/bo/grave/") && (props.page == ''))) {

    if(pathName.includes("/bo/grave/")) {
      page = <BOGraveDetail />
    } else {
      page = <BOGrave />
    }
  }

  return (
    <Wrapper>
      {page}
    </Wrapper>
  );
};

export default BOMain;
