import { useCallback, useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../utils/auth-context";
import AppContext from "../utils/app-context";
import { useHistory } from "react-router-dom";
import Helpers from "../utils/Helpers";

// axios.defaults.baseURL = "http://localhost:8080/api/";
axios.defaults.baseURL = process.env.REACT_APP_API_URL + "/api/";

export const useAxios = () => {
  const history = useHistory();
  // const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);

  const [response, setResponse] = useState(undefined);
  const [responseType, setResponseType] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const closeModalHandler = (event) => {
    if (event.target === event.currentTarget) {
      setShowModal(false);
    }
  };

  function getLang() {
    const lang = Helpers.getLang();
    return lang;
  }

  const request = useCallback(
    async (params, applyData = () => {}, auth = true, file = false, blob = false) => {
      // console.log("REQUEST " + params);

      let headers = params.headers;
      headers = { ...headers,
        "Accept-Language": getLang(),
        // "ngrok-skip-browser-warning": "69420"
      };

      if (auth) {
        headers = { ...headers,
          Authorization: "Bearer " + localStorage.getItem("token"),
        };
      } 

      if (file) {
        headers = {
          ...headers,
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        };
      }

      params.headers = headers

      try {
        setLoading(true);
        const result = await axios.request(params, { headers });

        if(result.config.url == "grave/list") {
          Helpers.setHasFunnelThroughGraveList(result.data.data);
        }

        setResponse(result.data);
        setResponseType(result.config.url)
        setError("");
        applyData(result.data);
      } catch (error) {
        if(error.response.status==403) {
          appCtx.logout();
          Helpers.removeStorage();
          history.replace("/?action=login");
          window.location.reload(false);
        } else {
          appCtx.addToast(error.response.data.message, "red");
          console.log(error);
          
        }
        setResponse(undefined);
        setResponseType(error.config.url)
        setError(error);

        appCtx.hideLoading();
        
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { response, error, loading, request, responseType };
};

export default useAxios;
