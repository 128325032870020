import { useTranslation } from "react-i18next";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper"
import RLInputText from "../../UI/RLInputText";
import { useEffect, useState } from "react";

const PanelTestamentStepOne = props => {

    const { t } = useTranslation();

    const rlSideBar = props.rlSideBar
    const selectedGrave = rlSideBar.selectedGrave
    const testamentStep = props.testamentStep

    const [testamentName, setTestamentName] = useState({
        value: "",
        isValid: true,
        validation: [],
        onChange: handleTestamentName,
    });

    function handleTestamentName(value, isValid = false) {
        setTestamentName({ ...testamentName, value: value, isValid: isValid });
    }

    useEffect(() => {
        const _testamentStep = Helpers.getTestamentStep();

        if (_testamentStep[selectedGrave.id] != undefined) {
            setTestamentName({ ...testamentName, value: _testamentStep[selectedGrave.id].name == undefined ? "" : _testamentStep[selectedGrave.id].name });
        }

    }, [selectedGrave, testamentStep]);

    function isValidForm() {
        let isValidFormTestamentName = testamentName.value.trim().length >= 2;

        if (!isValidFormTestamentName) {
            setTestamentName({ ...testamentName, value: testamentName.value, isValid: isValidFormTestamentName });
        }

        return isValidFormTestamentName;
    }

    const handleStepOneSubmit = (event) => {
        event.preventDefault();

        if (isValidForm()) {

            const _testamentStep = Helpers.getTestamentStep();

            _testamentStep[selectedGrave.id]["name"] = testamentName.value;
            _testamentStep[selectedGrave.id]["step"] = 2;
            _testamentStep[selectedGrave.id]["stepOneStatus"] = "completed";
            _testamentStep[selectedGrave.id]["stepTwoStatus"] = "pending";

            Helpers.setTestamentStep(_testamentStep);

            props.updateStep(_testamentStep);

        }
    }

    const handleTestamentCancel = (event) => {
        event.preventDefault();

        props.cancelStep(event);
    }

    return (
        <Wrapper>
            <div className="rl-admin-content-box">
                <form onSubmit={event => event.preventDefault()} className="rl-new-testament-form">
                    <div className="column flex-1">
                        <div className="rl-form-group">
                            <div className="rl-form-group-material">
                                <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_name"))} data={testamentName} />
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <a href="" onClick={handleStepOneSubmit} className="rl-form-button">{t("general_next")}</a>
                    </div>

                    {props.testaments.items.length > 0 && (
                        <div className="column">
                            <a href="" onClick={handleTestamentCancel} className="rl-form-button">{t("general_cancel")}</a>
                        </div>
                    )}

                </form>
                {!testamentName.isValid && (
                    <div className="rl-form-group">
                        <div className="error-line">
                            <img src="/img/icon-circle-info.svg" width="24" />
                            <span>{t("form_validation_minChar")}</span>
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    )
}

export default PanelTestamentStepOne;