import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";

const PanelContentFormFileItem = (props) => {
  const file = props.item;

  const handleFileRemove = (event) => {
    event.preventDefault();
    props.removeFile(file);
  };

  return (
    <Wrapper>
      <div className="rl-uploaded-files-row">
        <div className="rl-content-link">
          {Helpers.getFileIcon(file.type)} <span>{file.name}</span>
        </div>
        <a href="" onClick={handleFileRemove}>
          <img src="/img/icon-x-circle.svg" alt="Sil" />
        </a>
      </div>
    </Wrapper>
  );
};

export default PanelContentFormFileItem;
