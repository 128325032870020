import { Redirect } from "react-router-dom";

// const WithCondition = (props) => {
  // return function InnerComponent(props) {
const WithCondition = (props) => {
  // console.log(props.Component.name + ' / ' + props.condition);
    return props.condition ? <props.Component {...props} /> : <Redirect to={props.redirectTo} />;
  // }
};

export default WithCondition;
