import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import AppContext from "../../utils/app-context";
import Helpers from "../../utils/Helpers";
import Wrapper from "../UI/Wrapper";
import useAxios from "../../hooks/use-axios";

const CongratsAdmin = (props) => {
  const history = useHistory();
  const location = useLocation();
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(window.location.search);
  const ownerName = queryParams.get("ownerName");

  const onboardType = Helpers.getOnboardType();

  const [endpoint, setEndpoint] = useState("first");

  function handleCreateGrave(event, type) {
    event.preventDefault();
    const checkMailModal = {
      type: "createGrave",
      show: true,
      params: { graveType: type, location: location, history: history },
    };
    appCtx.showModal(checkMailModal);
  }

  const homepageHandler = (event) => {
    event.preventDefault();

    if (!appCtx.isLoggedIn) {
      appCtx.logout();
      Helpers.removeStorage();
      history.replace("/");
    } else {
      if (Helpers.getUserGraveRoles().includes("ADMIN")) {
        // history.replace("/panel");
        getGraveList()
      } else if (Helpers.getUserGraveRoles().includes("GUARDIAN")) {
        appCtx.logout();
        Helpers.removeStorage();
        history.replace("/");
      }
    }
  };

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {

   if (response !== null && typeof response !== "undefined") {
     if (responseType == "grave/list-funnel-graves") {
      //  setListFunnelGrave(response.data)

       if(endpoint == "grave/list") {
           appCtx.hideLoading();
         if (response.data.length == 0) {
           history.replace("/panel");
         } else if (response.data.length == 1) {
           history.replace("/funnel/" + response.data[0]?.id)
         } else if (response.data.length == 2) {
           handleLoginSuccess("", true, response.data);
         }
       }
     } else if (responseType == "grave/list") {
       if (response.data.length > 0) {

         for (var grave in response.data) {
           if (response.data[grave].hasSubscription) {
             history.replace("/panel");
             return
           }
         }

         appCtx.hideLoading();
         postGraveListFunnelGraves()
       }

       appCtx.hideLoading();
     }
   }
 }, [response]);

  function getGraveList() {
    // appCtx.showLoading();
    const url = "grave/list";
    setEndpoint(url);
    const data = {};

    request({
      method: "POST",
      url: url,
      data: data
    });
  }

  function postGraveListFunnelGraves() {
    const url = "grave/list-funnel-graves";

    const data = {};

    request(
      {
        method: "POST",
        url: url,
        data,
      },
    );
  }

  const confirmChooseFunnel = (funnel) => {
    history.replace("/funnel/" + funnel.id);
  }

  const handleLoginSuccess = (url, isFUnnel = false, funnels = []) => {
    if (!isFUnnel) {
      history.replace(url);
    } else {

      let funnel1 = funnels[0]
      let funnel2 = funnels[1]

      const funenlChooseModal = {
        type: "funnelChoose",
        show: true,
        params: {
          title: t("modal_funnelChoose_title"),
          funnel1: funnel1,
          funnel2: funnel2,
        },
        onConfirm: confirmChooseFunnel,
      };
      appCtx.showModal(funenlChooseModal);
    }

  };

  return (
    <Wrapper>
      <div className="rl-welcome admin">
        <img
          src="img/icon-heart-check.svg"
          alt={t("congrats_owner_congrats")}
          className="img-heart"
        />

        <h2>{t("congrats_owner_congrats")}</h2>

        {onboardType=="GUARDIAN" && <p>{ ownerName != null ? t("congrats_guardian_message", {ownerName:ownerName}) : t("congrats_guardian_message_second")}</p>}
        {onboardType=="ADMIN" && <p>{t("congrats_admin_message", {ownerName:ownerName})}</p>}

        <div className="rl-special-offer">
          <h3>{t("congrats_admin_specialOffer")}</h3>
          
          {onboardType=="GUARDIAN" && <p>{t("congrats_admin_specialOffer_guardian_message")}</p>}
          {onboardType=="ADMIN" && <p>{t("congrats_admin_specialOffer_admin_message")}</p>}

          <div className="rl-special-offer-buttons">
            <a href="" onClick={(event) => handleCreateGrave(event, "FOR_YOU")}>
              {t("landing_forwhom_foryou")}
            </a>
            <a
              href=""
              onClick={(event) => handleCreateGrave(event, "FOR_BE_LOVED")}
            >
              {t("landing_forwhom_beloved")}
            </a>
          </div>

          <a href="" onClick={homepageHandler} className="rl-go-to-home">
            { Helpers.getUserGraveRoles().includes("ADMIN") ? Helpers.capitalizeAll(t("congrats_admin_backToMain")) : t("congrats_admin_logout") }
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default CongratsAdmin;
