import { useContext, useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import RLButton from "../RLButton";
import Wrapper from "../Wrapper";
import { useAxios } from "../../../hooks/use-axios";
import { useTranslation } from "react-i18next";
import Helpers from "../../../utils/Helpers";
import AppContext from "../../../utils/app-context";
import RLInputText from "../RLInputText";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";

const RLModalLogin = (props, { onClick }) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const modal = props.modal;
  const params = modal.params;

  let emailValue = "";
  let emailDisabled = false;

  const [email, setEmail] = useState({
    value: emailValue,
    isValid: true,
    disabled: emailDisabled,
    validation: ["empty", "email"],
    onChange: handleEmailChange,

  });
  const [password, setPassword] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handlePasswordChange,
  });
  const [rememberMe, setRememberMe] = useState(false);

  function handleEmailChange(value, isValid = false) {
    setEmail({ ...email, value: value, isValid: isValid });
  }

  function handlePasswordChange(value, isValid = false) {
    setPassword({ ...password, value: value, isValid: isValid });
  }

  function isValidForm() {
    let isValidFormEmail = email.isValid;
    if (isValidFormEmail && email.validation.includes("empty")) {
      isValidFormEmail = email.value == "" ? false : true;
      setEmail({ ...email, isValid: isValidFormEmail });
    }

    let isValidFormPassword = password.isValid;
    if (isValidFormPassword && password.validation.includes("empty")) {
      isValidFormPassword = password.value == "" ? false : true;
      setPassword({ ...password, isValid: isValidFormPassword });
    }

    return isValidFormEmail && isValidFormPassword;
  }

  const { response, loading, error, request, responseType } = useAxios();

  const postLogin = (event) => {

    appCtx.showLoading();

    // console.log(postLogin);
    event.preventDefault();

    if (isValidForm()) {
      const data = {
        email: email.value,
        password: password.value,
        rememberMe: rememberMe
      };

      if (params?.graveAssignInfo?.invitationToken) {
        data.invitationToken = params?.graveAssignInfo?.invitationToken;
      }

      request(
        {
          method: "POST",
          url: "auth/login",
          data,
        },
        () => { },
        false
      );
    }
  };

  function postProfileUpdateLocale(lang, token) {
    const url = "profile/update";
    // setEndpoint(url);

    const data = {
      locale: lang,
    };

    request(
      {
        method: "POST",
        url: url,
        data,
        headers: {
          Authorization: "Bearer " + token,
        },
      },
      () => { },
      false
    );
  }

  function getGraveList(token) {
    appCtx.showLoading();
    const url = "grave/list";
    const data = {};

    request({
      method: "POST",
      url: url,
      headers: {
        Authorization: "Bearer " + token,
      },
    },
      () => { },
      false
    );
  }

  function postGraveListFunnelGraves(token) {

    appCtx.showLoading();

    const url = "grave/list-funnel-graves";

    const data = {};

    request(
      {
        method: "POST",
        url: url,
        data,
      },
    );
  }

  const handleForgotPassword = (event) => {
    const forgotPasswordModal = {
      type: "forgotPassword",
      show: true,
      params: {
        // onLoginSuccess: params.onLoginSuccess,
        graveAssignInfo: params?.graveAssignInfo,
        onLoginSuccess: params?.graveAssignInfo ? params.onLoginSuccess : undefined,
      },
    };
    appCtx.showModal(forgotPasswordModal);
  };

  const handleClose = (event) => {
    event.preventDefault();
    modal.onClose();
  };

  useEffect(() => {
    if (error != "") {
      if (params.from == "graveInvitation") {
        if (responseType == "auth/login" ||
          responseType == "auth/loginWithGoogle" ||
          responseType == "auth/loginWithFacebook" ||
          responseType == "auth/loginWithAppleId") {

          const warninglModal = {
            type: "warningModal",
            show: true,
            params: {
              description: error.response.data.message ? error.response.data.message : "error",
              enableClose: false
            },
            onConfirm: handleWarningOnConfirm

          };
          appCtx.showModal(warninglModal);
        }
      }

    } else if (response !== null && typeof response !== "undefined") {
      if (
        responseType == "auth/login" ||
        responseType == "auth/loginWithGoogle" ||
        responseType == "auth/loginWithFacebook" ||
        responseType == "auth/loginWithAppleId"
      ) {

        // window.$chatwoot.toggleBubbleVisibility('show')

        let token = response.data.token;
        let profile = response.data.profile;
        // appCtx.setUser(response.data.user, response.data.roles);

        if (profile.roles.includes("ROLE_ADMIN")) {
          appCtx.hideLoading()
          modal.onClose();

        } else if(profile.graveRoles.length == 1 && profile.graveRoles.includes("GUARDIAN") ) {

          appCtx.hideLoading()
          modal.onClose();

          appCtx.login(token, profile);
          params.onLoginSuccess("/congrats");
          
        } else {
          appCtx.login(token, profile);

          // postGraveListFunnelGraves(response.data.token)

          appCtx.hideLoading();
          getGraveList(token);

        }

        if (profile.locale === null) {
          const lang = Helpers.getLang();
          i18n.changeLanguage(lang);
          postProfileUpdateLocale(lang, token);
        } else {
          let lang = "";
          if (profile.locale === "en" || profile.locale === "EN") {
            lang = "en";
          } else if (profile.locale === "tr" || profile.locale === "TR") {
            lang = "tr";
          }

          i18n.changeLanguage(lang);
          Helpers.setLang(lang);
        }
      } else if (responseType == "loginWithGoogle") {

      } else if (responseType == "grave/list") {

        if (response.data.length > 0) {

          for (var grave in response.data) {
            if (response.data[grave].hasSubscription) {
              params.onLoginSuccess("/panel");
              modal.onClose();
              return
            }
          }

          postGraveListFunnelGraves(Helpers.token)

          appCtx.hideLoading();
        } else {

          modal.onClose();
          appCtx.hideLoading();
        }


      } else if (responseType == "grave/list-funnel-graves") {

        const onboardStatus = Helpers.getOnboardStatus();

        if (response.data.length == 0) {
          if (onboardStatus == "WAITING") {
            params.onLoginSuccess("/payment");
            modal.onClose();

          } else if (onboardStatus == "COMPLETED") {
            params.onLoginSuccess("/panel");
            modal.onClose();
          }
        } else if (response.data.length == 1) {
          params.onLoginSuccess("/funnel/" + response.data[0].id);
          modal.onClose();

        } else if (response.data.length == 2) {

          params.onLoginSuccess("", true, response.data);
          // modal.onClose();
        }
      }

      appCtx.hideLoading();
    }
  }, [response, error]);

  const responseFacebook = (response) => {
    console.log("responseFacebook => " + JSON.stringify(response));

    if (response.status == "unknown") {
      appCtx.hideLoading();
    }

    if (response.accessToken) {
      const token = response.accessToken;

      const data = {
        access_token: token,
      };

      if (params?.graveAssignInfo?.invitationToken) {
        data.invitation_token = params?.graveAssignInfo?.invitationToken;
      }

      const url = "auth/loginWithFacebook";

      request(
        {
          method: "POST",
          url: url,
          data,
        },
        () => { },
        false
      );
    }
  };

  const responseGoogle = (response) => {
    // event.preventDefault();
    console.log("responseGoogle => " + JSON.stringify(response));
  };

  const responseApple = (response) => {
    // event.preventDefault();
    console.log("responseApple => " + JSON.stringify(response));

    if (response.error != undefined) {
      appCtx.hideLoading();
      return;
    }

    const data = {
      code: response.authorization.code,
      idToken: response.authorization.id_token,
      firstName: response.user == undefined ? null : response.user.name.firstName,
      lastName: response.user == undefined ? null : response.user.name.lastName,
      email: response.user == undefined ? null : response.user.email,
    };

    if (params?.graveAssignInfo?.invitationToken) {
      data.invitationToken = params?.graveAssignInfo?.invitationToken;
    }

    const url = "auth/loginWithAppleId";

    request(
      {
        method: "POST",
        url: url,
        data,
      },
      () => { },
      false
    );
  };

  const handleRegister = (event) => {
    modal.onClose();
    const verseDetailModal = {
      type: "register",
      show: true,
      params: {
        onLoginSuccess: params.onLoginSuccess,
      },
    };
    appCtx.showModal(verseDetailModal);
  };

  const handleGoogleLogin = useGoogleLogin({
    cancel_on_tap_outside: false,
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      if (tokenResponse.access_token) {
        const token = tokenResponse.access_token;

        const data = {
          access_token: token,
        };

        if (params?.graveAssignInfo?.invitationToken) {
          data.invitation_token = params?.graveAssignInfo?.invitationToken;
        }

        const url = "auth/loginWithGoogle";

        request(
          {
            method: "POST",
            url: url,
            data,
          },
          () => { },
          false
        );
      }
    },
    onError: (tokenResponse) => {
      console.log(tokenResponse);
      appCtx.hideLoading();
    },
    onNonOAuthError: (tokenResponse) => {
      appCtx.hideLoading();
    },

  });

  const handleWarningOnConfirm = event => {
    // event.preventDefault();
    modal.onClose();
    params.onLoginSuccess("/");
  }

  return (
    <Wrapper>
      <div className="text-center">
        {/* <h1 className="modal-header">{capitalizeFirst(t("login"))}</h1> */}
        <h1 className="modal-header">{Helpers.capitalizeFirst(t("login"))}</h1>
        <div className="modal-header-desc">
          {Helpers.capitalizeAllFirst(t("login_description"))}
        </div>
      </div>
      <form onSubmit={postLogin} className="sign-in-up-form" autoComplete="off">
        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText
              inputType="text"
              label={Helpers.capitalizeFirst(t("general_email"))}
              data={email}
            />
          </div>
        </div>

        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText
              inputType="text"
              inputFeature="password"
              label={t("password")}
              data={password}
            />
          </div>
        </div>

        <div className="rl-form-remember-forgot">
          <div className="left form-checkbox-outer">
            <label htmlFor="12">
              <input
                onChange={() => setRememberMe(!rememberMe)}
                type="checkbox"
                id="12"
                className="form-checkbox"
              />
              {Helpers.capitalizeAllFirst(t("login_rememberme"))}
            </label>
          </div>
          <div className="right">
            {/* <RLButton
              className="rl-forgot-pass"
              text={Helpers.capitalizeAllFirst(t("login_forgotPassword"))}
              onClick={() => props.openModal("second", "forgotPassword")}
              showSecondModal={true}
              buttonType="a"
              modalType="forgotPassword"
            /> */}
            <RLButton
              className="rl-forgot-pass"
              text={Helpers.capitalizeAllFirst(t("login_forgotPassword"))}
              onClick={handleForgotPassword}
              buttonType="a"
            />
          </div>
        </div>

        <RLButton text={Helpers.capitalizeFirst(t("login"))} />

        <div className="rl-form-or">
          <span>{Helpers.capitalizeAll(t("general_or"))}</span>
        </div>

        <div className="rl-form-social-btns" >
          
          {/* <FacebookLogin
            // appId="845182133206747" // test
            // appId="887472022292610" // prod
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            fields="name,first_name,last_name,email,picture"
            scope="public_profile,email"
            // autoLoad
            isMobile={false}
            redirectUri={process.env.REACT_APP_URL}
            disableMobileRedirect={true}
            callback={responseFacebook}
            render={(renderProps) => (
              <a
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  appCtx.showLoading();
                  renderProps.onClick();
                }}
                className="rl-form-social-btns-facebook"
                title="Continue with Facebook"
              >
                <img src="img/icon-facebook-btn.svg" alt="Google" />
                {t("login_social_facebook")}
              </a>
            )}
              /> */}

          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              appCtx.showLoading();
              handleGoogleLogin();
            }}
            className="rl-form-social-btns-google"
            title="Continue with Google"
          >
            <img src="img/icon-google-btn.png" alt="Google" />
            {t("login_social_google")}
          </a>
        </div>

        {/* <div className="rl-form-social-btns" style={{ display: "flex", justifyContent: "center" }}>
          <AppleLogin clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
            redirectURI={process.env.REACT_APP_URL}
            usePopup={true}
            responseMode="form_post"
            responseType="code id_token"
            scope="name email"
            callback={responseApple}
            render={(renderProps) => (
              <a
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  appCtx.showLoading();
                  renderProps.onClick();
                }}
                className="rl-form-social-btns-apple"
                title=""
              >
                <img src="img/icon-apple-btn.svg" alt="Google" />
                {t("login_social_apple")}
              </a>
            )}
          />
        </div> */}

        {!params?.graveAssignInfo?.invitedRegistered && (
          <Wrapper>

            <div className="rl-sign-up-or-in">
              {Helpers.capitalizeAll(t("login_signup"))}{" "}
              <RLButton
                text={Helpers.capitalizeAll(t("signup"))}
                onClick={handleRegister}
                buttonType="a"

              />
            </div>
          </Wrapper>
        )}

      </form>
      {(!params?.graveAssignInfo?.invitedRegistered) && (
        <a href="" className={props.closeClassName} onClick={handleClose}>
          x
        </a>
      )}

    </Wrapper>
  );
};

export default RLModalLogin;
