import { Autocomplete, Chip, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import RLTextField from "./RLTextField";
import RLTextFieldMultiple from "./RLTextFieldMultiple";
import Wrapper from "./Wrapper";
// import { ThemeProvider } from "@emotion/react";
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import Helpers from "../../utils/Helpers";


const RLInputSelect = (props) => {
  const [selected, setSelected] = useState([]);

  let _locale = "enUS";
  const lang = Helpers.getLang()
  if (lang == "tr") {
    _locale = "trTR"
  }

  const theme = useTheme();
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[_locale]),
    [_locale, theme],
  );

  let isMultiple = null;
  if (props.isMultiple === true) {
    isMultiple = true;
  }

  let isStandart = false;
  if (props.standart === true) {
    isStandart = true;
  }

  let isFree = false;
  if (props.isFree === true) {
    isFree = true;
  }

  // useEffect(() => {
  let defaultValue = [];
  if (props.defaultValue != "") {
    if (isMultiple) {
      if (props.defaultValue.length > 0) {
        defaultValue = props.defaultValue;
      }
    } else {
      if (
        props.defaultValue?.id != undefined &&
        props.defaultValue?.name != undefined
      ) {
        defaultValue = props.defaultValue;
      }
    }
  }
  if (JSON.stringify(selected) != JSON.stringify(defaultValue)) {
    setSelected(defaultValue);
  }
  // });

  function handleChange(event, newValue) {
    event.preventDefault();
    setSelected(newValue);
    props.onChange(newValue);
  }

  let disableProps = {};
  if (props.disabled) {
    disableProps = {
      disabled: true,
    };
  }

  function onBlur(event) {
    let id = selected.id;

    let name = selected.name;

    if (selected.name != event.target.value) {
      id = -99;
      name = event.target.value;
    } else {
      if (id == undefined) {
        id = -99;
      }

      if (name == undefined) {
        name = event.target.value;
      }
    }

    if (!isStandart || isFree) {
      let selected_ = undefined;
      if (selected != undefined && isMultiple) {
        if (isMultiple) {
          selected_ = selected.concat({
            id: id,
            name: name,
          });
        } else {
          selected_ = {
            id: id,
            name: name,
          };
        }
      } else {
        selected_ = { id: id, name: name };
      }
      // }
      if (name != "") {
        handleChange(event, selected_);
      }
    }
  }

  return (
    <Wrapper>
      <ThemeProvider theme={themeWithLocale}>
        <Autocomplete
          multiple={isMultiple}
          {...disableProps}
          // defaultValue={defaultValue}
          value={selected}
          id="tags-filled"
          getOptionLabel={(option) =>
            option.name != undefined ? option.name : ""
          }
          options={props.options}
          // freeSolo
          // isOptionEqualToValue={(option, value) => value.length > 0 || option.name === value.name}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              // Prevent's default 'Enter' behavior.
              event.defaultMuiPrevented = true;
              onBlur(event);

              // // your handler code
              // if (!isStandart) {
              //   let selected_ = undefined;
              //   if (selected != undefined && isMultiple) {
              //     selected_ = selected.concat({
              //       id: -99,
              //       name: event.target.value,
              //     });
              //   } else {
              //     selected_ = { id: -99, name: event.target.value };
              //   }
              //   // }
              //   if (event.target.value != "") {
              //     handleChange(event, selected_);
              //   }
              // }
            }
          }}
          onChange={(event, newValue) => {
            handleChange(event, newValue);
          }}
          onBlur={(event) => {
            onBlur(event);
            // if (!isStandart || isFree) {
            //   let selected_ = undefined;
            //   if(selected.length > 0 && (selected?.id != undefined || selected?.id != -99)) {
            //     selected_=selected;
            //   } else if (selected != undefined && isMultiple) {
            //     selected_ = selected.concat({
            //       id: -99,
            //       name: event.target.value,
            //     });
            //   } else if(selected.length == 0) {
            //     selected_ = { id: -99, name: event.target.value };
            //   }
            //   // }
            //   if (event.target.value != "" && selected_ != undefined) {
            //     handleChange(event, selected_);
            //   }
            // }
          }}
          renderInput={(params) => (
            <RLTextFieldMultiple
              {...params}
              label={props.label}
              variant="filled"
              onKeyDown={(e) => {
                if (isFree) {
                  if (e.code === "Enter" && e.target.value) {
                    let selected_ = undefined;
                    if (selected != undefined && isMultiple) {
                      selected_ = selected.concat({
                        id: -99,
                        name: e.target.value,
                      });
                    } else {
                      selected_ = { id: -99, name: e.target.value };
                    }
                    // }
                    handleChange(e, selected_);
                  }
                }
              }}
            />
          )}
        />
      </ThemeProvider>

    </Wrapper>
  );
};

export default RLInputSelect;

const top100Films = [
  { title: "The Shawshank Redemption", id: 1994 },
  { title: "The Godfather", id: 1972 },
  { title: "The Godfather: Part II", id: 1974 },
];
