import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../../../hooks/use-axios";
import Helpers from "../../../../utils/Helpers";
import Wrapper from "../../../UI/Wrapper";
import BOGraveDetailMain from "./BOGraveDetailMain";
import BOGraveDetailNavigation from "./BOGraveDetailNavigation";

const BOGraveDetail = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const params = useParams();

  const user = Helpers.getUser();
  // const grave = props.location.state;

  const [grave, setGrave] = useState(undefined);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getGraveDetail();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "backOffice/grave/" + params["graveId"] + "/detail") {
        setGrave(response.data);
      }
    }
  }, [response, error]);

  function getGraveDetail() {
    const url = "backOffice/grave/" + params["graveId"] + "/detail";
    setEndpoint(url);

    request({
      method: "GET",
      url: url,
    });
  }

  const handleGraveChange = (event) => {
    event.preventDefault();

    history.replace("/bo/grave");
  };

  return (
    <Wrapper>
      <section className="rl-admin-content">
        <div>
          <h1>{grave?.name}</h1>
          <header className="rl-bo-user-detail-header">
            <div className="rl-bo-user-detail-header-left">

              <div className="rememberland-panel-sidebar-user">
                <figure>
                  <img
                    src={
                      grave?.owner?.profilePhoto
                        ? grave?.owner?.profilePhoto
                        : "/img/avatar-placeholder.png"
                    }
                    alt="User Name"
                    className="rl-user-avatar"
                  />
                </figure>
              </div>

              <div className="username-info grave">
                <div className="username">
                  {grave?.owner?.name + " " + grave?.owner?.surname} /{" "}
                </div>
                <div className="email">{grave?.owner?.email}</div>
              </div>
            </div>
            <div className="rl-bo-user-detail-header-right">
              <a href="" onClick={handleGraveChange} className="rl-link-black">
                <img src="/img/icon-refresh.svg" alt="Change Grave" />{" "}
                {t("bo_grave_changeGrave")}
              </a>
            </div>
          </header>
        </div>

        {<BOGraveDetailNavigation />}
      </section>
    </Wrapper>
  );
};

export default BOGraveDetail;
