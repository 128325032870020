import { useContext, useEffect, useState, useRef } from "react";
import Wrapper from "./Wrapper";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import AppContext from "../../utils/app-context";
import useAxios from "../../hooks/use-axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import useOutsideClick from "../../hooks/use-click-away";

const RLFeatures = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [startLifeType, setStartLifeType] = useState();

  const handleShow = (event) => {
    if (event != null) event.preventDefault();
    setShow(!show);
  };

  const handleFeatureClick = (event, type) => {
    if (event != undefined) {
      event.preventDefault();
    }

    if (type == "FOR_YOU" || type == "FOR_BE_LOVED") {
      if (appCtx.isLoggedIn) {
        setStartLifeType(type);
        postGraveListFunnelGraves();
      } else {
        handleLogin(event);
      }
    } else {
      handleClickToScroll(event, type);
      handleShow(event);
    }
  };

  const handleDetailForLifeType = (event, type) => {
    event.preventDefault();

    props.handleDetailForLifeType(type, true);
    handleShow(event);
  };

  const handleLogin = (event) => {
    event.preventDefault();

    const verseDetailModal = {
      type: "register",
      show: true,
      params: {
        onLoginSuccess: handleLoginSuccess,
      },
    };
    appCtx.showModal(verseDetailModal);
  };
  const handleLoginSuccess = (url) => {
    history.replace(url);
  };

  function handleCreateGrave(type) {
    if (Helpers.findTopMenu("ADMIN")) {
      history.replace("/payment");
    } else {
      const checkMailModal = {
        type: "createGrave",
        show: true,
        params: { graveType: type, location: location, history: history },
      };
      appCtx.showModal(checkMailModal);
    }
  }

  const confirmChooseFunnel = (funnel) => {
    // history.replace("/funnel/" + funnel.id);
    handleFeatureClick(undefined, funnel.graveType);
    setStartLifeType(funnel.graveType);
  };
  const handleChooseFunnel = (event) => {
    event.preventDefault();

    if (appCtx.isLoggedIn) {
      let funnels = [{ 0: {} }, { 1: {} }];
      funnels[0].graveType = "FOR_YOU";
      funnels[1].graveType = "FOR_BE_LOVED";

      const funenlChooseModal = {
        type: "funnelChoose",
        show: true,
        params: {
          title: t("modal_funnelChoose_title"),
          funnel1: funnels[0],
          funnel2: funnels[1],
        },
        onConfirm: confirmChooseFunnel,
      };
      appCtx.showModal(funenlChooseModal);
    } else {
      handleLogin(event);
    }
  };

  const handleClickToScroll = (event, id) => {
    event.preventDefault();

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/list-funnel-graves") {
        if (response.data.length == 0) {
          handleCreateGrave(startLifeType);
        } else {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].graveType == startLifeType) {
              history.replace("/funnel/" + response.data[i].id);
              appCtx.hideLoading();
              return;
            }
          }

          handleCreateGrave(startLifeType);
        }

        appCtx.hideLoading();
      }
    }
  }, [response]);
  function postGraveListFunnelGraves() {
    appCtx.showLoading();
    const url = "grave/list-funnel-graves";

    const data = {};

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  const menuRef = useRef(null);

  useOutsideClick(() => {
    //   handleShow();
    setShow(false);
  }, menuRef);

  return (
    <Wrapper>
      <div className="rl-features" ref={menuRef}>
        <a
          href=""
          onClick={handleShow}
          className={`${"features-btn"} ${show ? "open" : ""}`}
        >
          {Helpers.capitalizeAll(t("landing_header_features_title"))}
          <img src="img/icon-chevron-down.svg" alt="" />
        </a>

        {show && (
          <ul className="rl-features-dropdown show">
            {/* testament */}
            <li>
              <a
                href=""
                onClick={(event) => handleFeatureClick(event, "FOR_YOU")}
              >
                {Helpers.capitalizeAll(t("landing_hero_navigation_testament"))}
              </a>
            </li>

            {/* memorial */}
            <li>
              <a href="" onClick={handleChooseFunnel}>
                {Helpers.capitalizeAll(t("landing_hero_navigation_memorial"))}
              </a>
            </li>

            {/* graveyard */}
            <li>
              <a
                href=""
                onClick={(event) =>
                  handleFeatureClick(event, "rememberland-landing-gravyeards")
                }
              >
                {Helpers.capitalizeAll(t("landing_hero_navigation_graveyard"))}
              </a>
            </li>

            {/* diary */}
            <li>
              <a
                href=""
                onClick={(event) => handleDetailForLifeType(event, "diary")}
              >
                {Helpers.capitalizeAll(t("landing_hero_navigation_diary"))}
              </a>
            </li>

            {/* guardian */}
            <li>
              <a
                href=""
                onClick={(event) => handleFeatureClick(event, "FOR_YOU")}
              >
                {Helpers.capitalizeAll(t("landing_hero_navigation_guardian"))}
              </a>
            </li>

            {/* verses */}
            <li>
              <a href="" onClick={handleChooseFunnel}>
                {Helpers.capitalizeAll(t("landing_hero_navigation_verses"))}
              </a>
            </li>
          </ul>
        )}
      </div>
    </Wrapper>
  );
};

export default RLFeatures;
