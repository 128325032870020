import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Wrapper from "../UI/Wrapper"

const CraftGatePayment = props =>{

    const location = useLocation();
    const params = useParams();
    const queryParams = new URLSearchParams(window.location.search);

    return(
        <Wrapper>
            <h1>CraftGate Payment</h1>
        </Wrapper>
    )
}

export default CraftGatePayment;