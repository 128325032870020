import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../../hooks/use-axios";
import Wrapper from "../../UI/Wrapper";
import PanelTestamentList from "./PanelTestamentList";
import PanelContentTop from "../PanelContentTop";
import PanelTestamentStep from "./PanelTestamentStep";
import { useTranslation } from "react-i18next";
import Helpers from "../../../utils/Helpers";

const PanelTestament = (props) => {
  const params = useParams();
  const { t } = useTranslation();

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave

  const [testaments, setTestaments] = useState({});
  // const _testamentStep = Helpers.getTestamentStep();
  const [testamentStep, setTestamentStep] = useState();
  const [reloadTop, setReloadTop] = useState(true)

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    getTestamentList();

    const _testamentStep = Helpers.getTestamentStep();
    setTestamentStep(_testamentStep);

  }, [selectedGrave]);

  useEffect(() => {

    if (response !== null && typeof response !== "undefined") {

      if (responseType == "grave/" + params["graveId"] + "/testament/list") {
        setTestaments(response.data);

        if (!testamentStep.hasOwnProperty(rlSideBar.selectedGrave.id) && response.data.items.length == 0) {
          let obj = {
            [selectedGrave.id]: {
              "step": 1,
              "file": [],
              "stepOneStatus": "pending",
              "stepTwoStatus": "notStarted",
              "stepThreeStatus": "notStarted"
            }
          };

          Object.assign(testamentStep, obj);
          Helpers.setTestamentStep(testamentStep);

          handleReloadList();
        }
      }
    }
  }, [response, error]);

  function getTestamentList() {
    const url = "grave/" + params["graveId"] + "/testament/list";

    let data = {};

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  function handleReloadList() {

    getTestamentList();
    const _testamentStep = Helpers.getTestamentStep();
    setTestamentStep(_testamentStep);

    // const element = document.getElementById("rl-admin-content");
    // if (element) {
    //   element.scrollIntoView({ behavior: "smooth" });
    // }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  function handleRaloadPageTop() {

    setReloadTop(!reloadTop)
  }

  const handleNewTestament = event => {
    event.preventDefault();

    let obj = {
      [selectedGrave.id]: {
        "step": 1,
        "file": [],
        "stepOneStatus": "pending",
        "stepTwoStatus": "notStarted",
        "stepThreeStatus": "notStarted"
      }
    };

    Object.assign(testamentStep, obj);
    Helpers.setTestamentStep(testamentStep);

    handleReloadList();
  }

  return (
    <Wrapper>
      <section className="rl-admin-content">

        <PanelContentTop rlSideBar={rlSideBar} itemLength={0} testaments={testaments} reloadTop={reloadTop} />

        {(Object.keys(testaments).length > 0) && (
          <Wrapper>

            {!testamentStep.hasOwnProperty(rlSideBar.selectedGrave.id) ? (
              <Wrapper>
                <div>
                  <a href="" onClick={handleNewTestament} class="rl-form-button rl-add-new-test-btn">{t("testament_add_new")}</a>
                </div>

                <PanelTestamentList rlSideBar={rlSideBar} testaments={testaments} reloadList={handleReloadList} />
              </Wrapper>
            ) : (
              <PanelTestamentStep rlSideBar={rlSideBar} testaments={testaments} reloadList={handleReloadList} raloadPageTop={handleRaloadPageTop} testamentStep={testamentStep} />
            )}

          </Wrapper>
        )}

      </section>
    </Wrapper>
  );
};

export default PanelTestament;
