import React, { FunctionComponent, useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import AccessDenied from "../pages/AccessDenied";
import LandingPage from "../pages/LandingPage";
import AccountPage from "../pages/AccountPage";
import PanelPage from "../pages/PanelPage";
import PaymentPage from "../pages/PaymentPage";
import { WithAdmin, WithLoggedIn, WithLoggedOut, IfLoggedIn } from "./withLoggedIn";
import BOPage from "../pages/BOPage";
import NotFoundPage from "../pages/NotFoundPage";
import EmailVerificationPage from "../pages/EmailVerificationPage";
import PaymentWelcomeOwner from "../components/Payment/PaymentWelcomeOwner";
import PasswordResetPage from "../pages/PasswordResetPage";
import BOGraveDetail from "../components/BO/BOGrave/BOGraveDetail/BOGraveDetail";
import Helpers from "../utils/Helpers";
import GraveInvitationPage from "../pages/GraveInvitationPage";
import PanelPaymentPage from "../pages/PanelPaymentPage";
import CraftGatePayment from "../components/PanelPayment/CraftGatePayment";
import CongratsPage from "../pages/CongratsPage";
import GeneralWelcomePage from "../pages/GeneralWelcomePage";
import BOLogin from "../pages/BOLogin";
import TranslationJson from "../pages/TranslationJson";
import EmailUpdateConfirmPage from "../pages/EmailUpdateConfirmPage";
import HoldOnPage from "../pages/HoldOnPage";

export function AppRoutes() {

  return (
    <Switch>
      <Route exact path="/" >{<LandingPage />}</Route>
      <Route path="/access-denied" >{<AccessDenied />}</Route>
      
      {/* GRAVE */}
      <Route exact path="/panel" >{WithLoggedIn(PanelPage, "COMPLETED")}</Route>
      <Route path="/panel/:graveId" >{WithLoggedIn(PanelPage, "COMPLETED")}</Route>
      {/* <Route path="/panel/:graveId/" >{WithLoggedIn(PanelPage, "COMPLETED")}</Route> */}
      <Route path="/panel/:graveId/admin" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU", "FOR_BE_LOVED"])}</Route>
      <Route path="/panel/:graveId/guardian" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU"])}</Route>
      <Route path="/panel/:graveId/content" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU", "FOR_BE_LOVED", "ADMIN"])}</Route>
      <Route path="/panel/:graveId/testament" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU"])}</Route>
      <Route path="/panel/:graveId/verse" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU", "FOR_BE_LOVED"])}</Route>
      <Route path="/panel/:graveId/subs" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], [])}</Route>
      <Route path="/panel/:graveId/kyc" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU"])}</Route>
      <Route path="/panelPayment" >{WithLoggedIn(PanelPaymentPage, "", [])}</Route>
      {/* <Route exact path="/panel/:graveId/" >{WithLoggedIn(PanelPage, "COMPLETED")}</Route> */}

      {/* FUNNEL */}
      <Route exact path="/funnel" >{WithLoggedIn(PanelPage, "COMPLETED")}</Route>
      <Route path="/funnel/:graveId" >{WithLoggedIn(PanelPage, "COMPLETED")}</Route>
      {/* <Route path="/panel/:graveId/" >{WithLoggedIn(PanelPage, "COMPLETED")}</Route> */}
      <Route path="/funnel/:graveId/admin" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU", "FOR_BE_LOVED"])}</Route>
      <Route path="/funnel/:graveId/guardian" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU"])}</Route>
      <Route path="/funnel/:graveId/content" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU", "FOR_BE_LOVED", "ADMIN"])}</Route>
      <Route path="/funnel/:graveId/testament" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU"])}</Route>
      <Route path="/funnel/:graveId/verse" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU", "FOR_BE_LOVED"])}</Route>
      <Route path="/funnel/:graveId/subs" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], [])}</Route>
      <Route path="/funnel/:graveId/kyc" >{WithLoggedIn(PanelPage, "COMPLETED", ["GUARDIAN"], ["FOR_YOU"])}</Route>
      
      <Route exact path="/account" >{WithLoggedIn(AccountPage, "COMPLETED")}</Route>
      <Route path="/account/confirm-cancel-account">{<GeneralWelcomePage />}</Route>
      
      <Route path="/payment" >{WithLoggedIn(PaymentPage)}</Route>
      <Route path="/webhook/payment/craftgate" >{WithLoggedIn(CraftGatePayment)}</Route>

      {/* <Route path="/payment-welcome" >{WithLoggedIn(PaymentWelcomeOwner, "WAITING")}</Route> */}
      <Route path="/payment-welcome" >{<PaymentWelcomeOwner />}</Route>
      <Route path="/congrats" >{<CongratsPage />}</Route>
      <Route path="/holdon" >{<HoldOnPage />}</Route>


      <Route exact path="/bo" >{WithAdmin(BOPage)}</Route>
      <Route path="/bo-login" >{<BOLogin />}</Route>
      <Route path="/bo/login" >{<BOLogin />}</Route>
      <Route path="/bo/users/:userId" >{WithAdmin(BOPage)}</Route>
      <Route path="/bo/users" >{WithAdmin(BOPage)}</Route>
      <Route path="/bo/tags" >{WithAdmin(BOPage)}</Route>
      <Route path="/bo/grave/:graveId" >{WithAdmin(BOPage)}</Route>
      <Route path="/bo/grave" >{WithAdmin(BOPage)}</Route>

      <Route path="/email-verification">{<EmailVerificationPage />}</Route>
      <Route path="/email-update-confirm">{<EmailUpdateConfirmPage />}</Route>
      <Route path="/email-verification-error">{<GeneralWelcomePage />}</Route>
      <Route path="/password-reset">{<PasswordResetPage />}</Route>
      <Route path="/grave-invitation">{<GraveInvitationPage />}</Route>

      <Route path="/translation_Json">{<TranslationJson />}</Route>

      <Route exact path="*" >{<NotFoundPage />}</Route>

    </Switch>
  );
}