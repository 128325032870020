import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import AppContext from "../../utils/app-context";
import Wrapper from "../UI/Wrapper"
import useAxios from "../../hooks/use-axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Helpers from "../../utils/Helpers";

const StartLife = props => {

    const { t } = useTranslation();
    const appCtx = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    const [startLifeType, setStartLifeType] = useState();

    const handleLoginSuccess = (url) => {
        history.replace(url);
    }
    const handleLogin = event => {

        event.preventDefault();

        const verseDetailModal = {
            type: "register",
            show: true,
            params: {
                onLoginSuccess: handleLoginSuccess
            }
        };
        appCtx.showModal(verseDetailModal);
    }

    function handleCreateGrave(type) {

        const checkMailModal = {
            type: "createGrave",
            show: true,
            params: { graveType: type, location: location, history: history },
        };
        appCtx.showModal(checkMailModal);
    }

    const handleStartLife = (event, type) => {
        event.preventDefault();

        if (type == "FOR_YOU" || type == "FOR_BE_LOVED") {
            if (appCtx.isLoggedIn) {
                setStartLifeType(type);
                // handleCreateGrave(event, type)
                postGraveListFunnelGraves();
            } else {
                handleLogin(event)
            }
        } else {

        }

        // if (appCtx.isLoggedIn) {
        //     handleCreateGrave(event, type)
        // } else {
        //     handleLogin(event)
        // }
    }

    function postGraveListFunnelGraves(token) {
        const url = "grave/list-funnel-graves";

        const data = {};

        request(
            {
                method: "POST",
                url: url,
                data,
            },
        );
    }

    const { response, loading, error, request, responseType } = useAxios();
    useEffect(() => {
        if (response !== null && typeof response !== "undefined") {
            if (responseType == "grave/list-funnel-graves") {
                if (response.data.length == 0) {
                    handleCreateGrave(startLifeType)
                } else {
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].graveType == startLifeType) {
                            history.replace("/funnel/" + response.data[i].id);
                            return;
                        }
                    }

                    handleCreateGrave(startLifeType)
                }
            }
        }
    }, [response]);

    return (
        <Wrapper>
            <section className="rememberland-landing-for-whom" id="rememberland-landing-for-whom">
                <div className="rememberland-landing-for-whom-wrapper">
                    <div className="rememberland-landing-for-whom-title">{Helpers.capitalizeAll(t("landing_hero_navigation_startLife"))}</div>
                    <div className="rememberland-landing-for-whom-desc">{t("landing_startLife_desc")}</div>
                    <div className="rememberland-landing-for-whom-bottom">
                        <div className="rememberland-landing-for-whom-bottom-w-link">
                            <img src="img/icon-for-you.svg" alt="" className="m-auto" />
                            <div className="title">{Helpers.capitalizeAll(t("landing_startLife_foryou"))}</div>
                            <a href="" onClick={event => handleStartLife(event, "FOR_YOU")} className="slide-vertical" data-splitting>{t("landing_startLife_create")}</a>
                        </div>
                        <div className="middle">
                            <img src="img/landing-forwhom-seperator.png" alt="" />
                        </div>
                        <div className="rememberland-landing-for-whom-bottom-w-link">
                            <img src="img/icon-for-you-bloved.svg" alt="" className="m-auto" />
                            <div className="title">{Helpers.capitalizeAll(t("landing_startLife_beloved"))}</div>
                            <a href="" onClick={event => handleStartLife(event, "FOR_BE_LOVED")} className="slide-vertical" data-splitting>{t("landing_startLife_create")}</a>
                        </div>

                    </div>
                </div>
                {/* <div id="rememberland-landing-gravyeards"></div> */}
            </section>

        </Wrapper>
    )
}

export default StartLife;