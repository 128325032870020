import { useContext, useEffect, useState, useTransition, useRef } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "./Wrapper";
// import classes from "../../css-landing/style.module.css";
import Helpers from "../../utils/Helpers";
import { useLocation } from "react-router";
import useAxios from "../../hooks/use-axios";
import AppContext from "../../utils/app-context";
import useOutsideClick from "../../hooks/use-click-away";

const RLLang = (props) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const appCtx = useContext(AppContext);

  const lang_ = Helpers.getLang();
  const [lang, setLang] = useState(lang_);
  const [showLang, setShowLang] = useState(false);
  const [data, setData] = useState(undefined);

  let classes = [];
  if (props.styleClass != undefined) {
    classes = props.styleClass;
  }

  const showLangHandler = (event) => {
    if (event != null) event.preventDefault();
    setShowLang(!showLang);
  };

  function cahngeLocalizationHandler(event, lang) {
    event.preventDefault();

    setLang(lang);
    i18n.changeLanguage(lang);
    setShowLang(false);
    Helpers.setLang(lang);

    if (appCtx.isLoggedIn) {
      postProfileUpdateLocale(lang);
    } else {
      props.reloadPage();
    }
  }

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "profile/update") {
        props.reloadPage();
      }
    }
  }, [response, error]);

  function postProfileUpdateLocale(lang) {
    const url = "profile/update";
    // setEndpoint(url);

    const data = {
      locale: lang,
    };

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  const menuLangRef = useRef(null);

  useOutsideClick(() => {
    setShowLang(false);
  }, menuLangRef);

  return (
    <Wrapper>
      <div className={`${"rl-lang-part"}`} ref={menuLangRef}>
        <a
          href=""
          onClick={showLangHandler}
          className={`${"rl-lang"} ${showLang ? "open" : ""}`}
        >
          {lang == "en" ? "ENG" : "TR"}
          <img src="img/icon-chevron-down.svg" alt="" />
        </a>

        {(showLang || props.showRightHeader) && (
          <ul className={`${"rl-lang-dropdown"} ${"show"}`}>
            <li>
              <a
                href=""
                onClick={(event) => cahngeLocalizationHandler(event, "en")}
                className={`${lang == "en" ? "active" : ""}`}
              >
                ENG
              </a>
            </li>

            <li>
              <a
                href=""
                onClick={(event) => cahngeLocalizationHandler(event, "tr")}
                className={`${lang == "tr" ? "active" : ""}`}
              >
                TR
              </a>
            </li>
          </ul>
        )}
      </div>
    </Wrapper>
  );
};

export default RLLang;
