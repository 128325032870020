import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../../hooks/use-axios";
import Wrapper from "../../../UI/Wrapper";
import BOGraveDetailKycItem from "./BOGraveDetailKycItem";

const BOGraveDetailKyc = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const [kycs, setKycs] = useState([]);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getKycList();
    }

    if (response !== null && typeof response !== "undefined") {
      if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/kyc/list"
      ) {
        setKycs(response.data);
      }
    }
  }, [response, error]);

  function getKycList() {
    const url = "backOffice/grave/" + params["graveId"] + "/kyc/list";
    setEndpoint(url);

    const data = {};

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  function reloadList() {
    getKycList();
  }

  let itemList = "";
  if (kycs.kycs != undefined) {
    if (kycs.kycs.length > 0) {
      itemList = kycs.kycs.map((item) => (
        <BOGraveDetailKycItem
          id={item.id}
          key={item.id}
          item={item}
          reloadList={reloadList}
        />
      ));
    }
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <form
          action=""
          className="rl-bo-users-search-form rl-table-admin-bottom-filters"
        >
          <div className="column">
            <div className="column-2"></div>
          </div>
          <div className="column"></div>
          <div className="column">
            <div className="kyc-text-desc">
              {t("kyc_status")} {kycs.kycStatus}
            </div>
          </div>
        </form>
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                <th>{t("bo_grave_kyc_list_fileType")}</th>
                <th className="rl-table-border-l">{t("kyc_form_document")}</th>
                <th className="rl-table-border-l">
                  {t("bo_grave_kyc_list_isapproved")}
                </th>
                <th className="w-40 text-right">
                  {/* <a className="rl-table-download-btn disabled">
                    <img
                      src="/img/icon-download.svg"
                      alt="Download"
                      width="20"
                      height="20"
                    />{" "}
                    Download
                  </a> */}
                </th>
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default BOGraveDetailKyc;
