import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Wrapper from "../components/UI/Wrapper";
import useAxios from "../hooks/use-axios";
import "../css-landing/style.css";
import { useTranslation } from "react-i18next";
import RLInput from "../components/UI/RLInput";
import Helpers from "../utils/Helpers";
import RLInputText from "../components/UI/RLInputText";
import AppContext from "../utils/app-context";

const PasswordResetPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const appCtx = useContext(AppContext)
  const queryParams = new URLSearchParams(window.location.search);

  const token = queryParams.get("token");

  const [newPassword, setNewPassword] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handleNewPasswordChange,
  });
  const [newPasswordConfirm, setNewPasswordConfirm] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handlePasswordConfirmChange,
  });

  const [isPasswordsMatch, setIsPasswordsMatch] = useState(
    newPassword.value == newPasswordConfirm.value
  );
  const isPasswordsMatchq = newPassword.value == newPasswordConfirm.value;

  function handleNewPasswordChange(value, isValid = false) {
    let isPasswordsMatch_=false
    if (value === newPasswordConfirm.value) {
      isPasswordsMatch_ = true;
    } 

    // setIsPasswordsMatch(isPasswordsMatch_)

    setNewPassword({
      ...newPassword,
      value: value,
      isValid: isValid,
    });
  }

  function handlePasswordConfirmChange(value, isValid = false) {
    let isPasswordsMatch_=false
    if (value === newPassword.value) {
      isPasswordsMatch_ = true;
    } 

    // setIsPasswordsMatch(isPasswordsMatch_)

    setNewPasswordConfirm({
      ...newPasswordConfirm,
      value: value,
      isValid: isValid,
    });
  }

  function isValidForm() {
    let isValidFormNewPassword = newPassword.isValid;
    if (isValidFormNewPassword && newPassword.validation.includes("empty")) {
      isValidFormNewPassword = newPassword.value == "" ? false : true;
      setNewPassword({ ...newPassword, isValid: isValidFormNewPassword });
    }

    let isValidFormPasswordConfirm = newPasswordConfirm.isValid;
    if (
      isValidFormPasswordConfirm &&
      newPasswordConfirm.validation.includes("empty")
    ) {
      isValidFormPasswordConfirm =
        newPasswordConfirm.value == "" ? false : true;
      setNewPasswordConfirm({
        ...newPasswordConfirm,
        isValid: isValidFormPasswordConfirm,
      });
    }

    let isPasswordsMatch_ = isPasswordsMatch;
    if (isValidFormPasswordConfirm) {
      if (newPassword.value === newPasswordConfirm.value) {
        isPasswordsMatch_ = true;
      } else {
        isPasswordsMatch_ = false;
      }
    }

    return (
      isValidFormNewPassword &&
      isValidFormPasswordConfirm &&
      isPasswordsMatch_
    );
  }

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("");

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "auth/password-reset") {
        history.replace("/");

      } else if (responseType == "auth/password-reset-link-status?token=" + token) {
        console.log(response)

      }

    } else if(error) {
      if (responseType == "auth/password-reset-link-status?token=" + token) {
        const warninglModal = {
          type: "warningModal",
          show: true,
          params: {
            description: error.response.data.message,
            enableClose: false
          },
          onConfirm: handleWarningOnConfirm

        };
        appCtx.showModal(warninglModal);
      }
    }

  }, [response, error]);

  const postPasswordReset = (event) => {
    event.preventDefault();

    const url = "auth/password-reset";
    setEndpoint(url);

    if (isValidForm()) {
      const data = {
        token: token,
        password: newPassword.value,
        passwordAgain: newPasswordConfirm.value,
      };
  
      request(
        {
          method: "POST",
          url: url,
          data,
        },
        () => {},
        false
      );
    }
  };

  useEffect(() => {
    const url = "auth/password-reset-link-status?token=" + token;

    request(
      {
        method: "GET",
        url: url,
      },
      () => {},
      false
    );

  }, []);

  const handleWarningOnConfirm = event => {
    // event.preventDefault();

    history.replace("/");
  }
  
  return (
    <Wrapper>
      <div className="sign-in-white-outer">
        <img
          src="img/logo-black.svg"
          alt="REMEMBERLAND"
          width="200"
          className="rl-logo"
        />

        <div className="sign-in-white-box">
          <div className="title">{t("newPasswordPage_title")}</div>
          {/* <div className="desc">{t("newPasswordPage_desc")}</div> */}

          <form onSubmit={postPasswordReset} className="sign-in-up-form" >
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                {/* <RLInput
                  onPasswordChange={handleNewPasswordChange}
                  inputType="password"
                  label={t("passwordNew")}
                /> */}
                <RLInputText
                  inputType="text"
                  inputFeature="password"
                  label={t("passwordNew")}
                  data={newPassword}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputText
                  inputType="text"
                  inputFeature="password"
                  label={t("passwordNewAgain")}
                  data={newPasswordConfirm}
                  autoComplete="off"
                />
              </div>
              {!isPasswordsMatchq && (
                <div className="error-line">
                  <img
                    src="img/icon-circle-info.svg"
                    alt="Wrong Password"
                    width="24"
                  />{" "}
                  {Helpers.capitalizeAllFirst(t("password_error"))}
                </div>
              )}
            </div>

            <button className="rl-form-button">
              {t("newPasswordPage_change")}
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default PasswordResetPage;
