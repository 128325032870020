import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";

const PanelVerseFormItem = (props) => {

  const appCtx = useContext(AppContext);
  const params = useParams();

  const verse = props.item;
  const type = props.type;

  const isSelected_ = props.isSelected
  const [isSelected, setIsSelected] = useState(false);
  if (isSelected_ != isSelected) {
    setIsSelected(isSelected_);
  }

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + params["graveId"] + "/verse/add") {
        handleReloadList();

        if (response.message) {
          appCtx.addToast(response.message, "green")
        }
      }
    }
  }, [response, error]);

  function postAddVerse() {
    const url = "grave/" + params["graveId"] + "/verse/add";

    const data = {
      verseId: verse.id,
    };

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  const handleVerseClick = (event) => {
    event.preventDefault();

    if (type == "popular") {

      if (!changeModal || 1 == 1) {
        const verseDetailModal = {
          type: "verseDetail",
          show: true,
          params: {
            versePopular: verse,
            graveId: params["graveId"],
            reloadList: handleReloadList
          },
          className: "rl-add-verse-modal",
        };
        appCtx.showModal(verseDetailModal);
      }
    } else {

    }
  };

  const handleReloadList = (event) => {
    props.reloadList();
  }

  const handleAddVerse = (event) => {
    event.stopPropagation();
    event.preventDefault();

    postAddVerse();
  }

  const changeModal = (event) => {
    event.preventDefault();
  }

  const percent =
    ((verse.count * 100) / verse.capacity).toString() + "%";

  return (
    <Wrapper>
      <div
        onClick={handleVerseClick}
        className={`${"rl-verse-list-item"}`}
        style={{ cursor: ((type == "popular") ? "pointer" : "" )}}
      >
        <div className="rl-verse-name">
          <div >
            {verse.name}
          </div>
          {(verse.videoUrl != null || verse.mobileVideoUrl != null) && (
            <div style={{ marginRight: "0px", width: "20px" }}><img src="/img/icon-play-circle.svg" alt="video" /></div>
          )}
        </div>

        <div className="rl-progress-bar">
          <div
            className="rl-progress-bar-fill"
            style={{ width: percent, backgroundColor: verse.color }}
          ></div>
        </div>

        <div className="rl-verse-details">
          <div className="rl-verse-details-left">
            {verse.count}/<span>{verse.capacity}</span>
          </div>

          <div className="rl-verse-details-right">
            {isSelected ? (
              <a
                href=""
                onClick={event => (event.preventDefault())}
                className="rl-link-black"
                rel="modal:open"
              >
                {" "}
                {t("verse_form_selected")}{" "}
              </a>
            ) : (
              <a
                href=""
                onClick={handleAddVerse}
                className="rl-link-black"
                rel="modal:open"
              >
                <img src="/img/icon-plus.svg" alt="Add Verse" />{" "}
                {t("verse_form_add")}
              </a>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PanelVerseFormItem;
