import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Wrapper from "../Wrapper";
import Helpers from "../../../utils/Helpers";

const RLEmptyListCard = props => {

    const location = useLocation();
    const { t } = useTranslation();

    let desc = "";
    if (location.pathname.includes("admin")) {
      // desc = Helpers.capitalizeFirst(t("panel_navigation_admins"));
      desc = t("general_empty_admin");

    } else if (location.pathname.includes("guardian")) {
      // desc = Helpers.capitalizeFirst(t("panel_navigation_guardians"));
      desc = t("general_empty_guardian");

    } else if (location.pathname.includes("content")) {
      // desc = Helpers.capitalizeFirst(t("panel_navigation_contents"));
      desc = t("general_empty_content");

    } else if (location.pathname.includes("testament")) {
      desc = Helpers.capitalizeFirst(t("panel_navigation_testaments"));
      desc = t("general_empty_list", {page: desc})

    } else if (location.pathname.includes("verse")) {
      // desc = Helpers.capitalizeFirst(t("panel_navigation_verses"));
      desc = t("general_empty_verse");

    } else if (location.pathname.includes("subs")) {
      desc = Helpers.capitalizeFirst(t("panel_navigation_subsInfo"));
      desc = t("general_empty_list", {page: desc})

    } else if (location.pathname.includes("kyc")) {
      // desc = Helpers.capitalizeFirst(t("panel_navigation_kyc"));
      desc = t("general_empty_kyc");

    } else if (location.pathname.includes("diary")) {
      desc = Helpers.capitalizeFirst(t("panel_navigation_diaries"));
      desc = t("general_empty_diary");

    } else if (location.pathname.includes("/panel")) {
      desc = t("general_empty_list");
    }

    return (
        <Wrapper>
            <div className="rl-admin-content-box mt-16">
            <div className="rl-table-outer rl-custom-scroll">
              <table className="rl-table-admin-bottom">
                <tbody>
                  <tr>
                    <td colSpan="6">
                      <div className="no-content" style={{whiteSpace: "pre-line", minWidth: "1000px"}}>
                        <p>{desc}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Wrapper>
    );
}

export default RLEmptyListCard;