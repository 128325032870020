import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Wrapper from "../components/UI/Wrapper";
import useAxios from "../hooks/use-axios";
import AppContext from "../utils/app-context";

const EmailVerificationPage = (props) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);
  const queryParams = new URLSearchParams(window.location.search);

  const token = queryParams.get("token");

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getEmailVerification();
    } else {
      if (error!== "" && typeof error!== "undefined") {
        history.replace("/email-verification-error");
      }
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "auth/email-verification") {

        let token = response.data.token;
        let profile = response.data.profile

        appCtx.login(
          token,
          profile,
        );

        if (
          profile.onboardType == "GUARDIAN" ||
          profile.onboardType == "ADMIN"
        ) {
          history.replace("/congrats");
        } else if (profile.onboardType == "USER") {
          if (profile.onboardStatus == "WAITING") {
            history.replace("/payment");
          }
        }
      }
    } 
  }, [response, error]);

  const getEmailVerification = () => {
    const url = "auth/email-verification";
    setEndpoint(url);

    const params = {
      token: token,
    };

    const data = {
      token: token,
    };

    request(
      {
        method: "GET",
        url: url,
        params,
      },
      () => {},
      false
    );
  };

  return <Wrapper></Wrapper>;
};

export default EmailVerificationPage;
