import React, { useContext, useEffect } from "react";
// import styled from "styled-components";
// import { useToast } from "../ToastProvider";
// import { animated } from "react-spring";
import { styled } from "@mui/material";
import AppContext from "../../../utils/app-context";
import Wrapper from "../Wrapper";

// const WrapperT = styled(animated.div)`
//   margin-right: 16px;
//   margin-top: 16px;
//   width: 200px;

//   position: relative;
//   padding: 16px;
//   border: 1px solid #d7d7d7;
//   border-radius: 3px;
//   background: white;
//   box-shadow: 0px 4px 10px 0px #d7d7d7;
//   color: #494e5c;
// `;

const Toast = (props) => {
//   const { removeToast } = useToast();
  const appCtx = useContext(AppContext);

  const handleClose = event => {
    event.preventDefault();
    appCtx.removeToast(props.id);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
        appCtx.removeToast(props.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [props.id, appCtx.removeToast]);

  return (
    <div className={`${"rl-toast-message"} ${props.item.type}`}>
        <img onClick={handleClose} src="/img/icon-x-circle.svg" alt="Resend" /> {props.item.content}
    </div>
  );
  // <Wrapper style={style}>{children}</Wrapper>;
};

export default Toast;
