import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";
import AccountSettingsPaymentMethodItem from "./AccountSettingsPaymentMethodItem";

const AccountSettingsPaymentMethod = (props) => {
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();

  const [paymentMethods, setPaymentMethods] = useState([]);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getPaymentMethods();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "payment/paymentMethods") {
        setPaymentMethods(response.data);
      }
    }
  }, [response, error]);

  function getPaymentMethods() {
    const url = "payment/paymentMethods";
    setEndpoint(url);

    request({
      method: "GET",
      url: url,
    });
  }

  const handleNewCard = (event) => {
    event.preventDefault();

    const addNewCardModal = {
      type: "newCard",
      show: true,
      params: {},
    };
    appCtx.showModal(addNewCardModal);
  };

  let itemList = "";
  if (paymentMethods.length > 0) {
    let first = true;
    itemList = paymentMethods.map((item, index) => (
      <AccountSettingsPaymentMethodItem
        id={item.id}
        key={item.id}
        item={item}
        itemCount={paymentMethods.length}
        itemIndex={index}
      />
    ));
  }

  return (
    <Wrapper>
      <h2 className="h1">{t("accountSettings_payment_paymentMethods")}</h2>

      <div className="rl-account-cards-grid">
        {itemList}

        <div onClick={handleNewCard} className="rl-add-new-card">
          <a href="" onClick={handleNewCard}>
            <img
              src="/img/icon-credit-card-plus.svg"
              alt={t("accountSettings_payment_addCard")}
              width="64"
              height="64"
            />
            {t("accountSettings_payment_addCard")}
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default AccountSettingsPaymentMethod;
