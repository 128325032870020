import { Link } from "react-router-dom";
import NotFoundPage from "./NotFoundPage";

export default function AccessDenied() {
  return (
    // <div>
    //   <h2>AccessDenied</h2>
    //   <Link to="/">Go back home</Link>
    // </div>
    // <div className="page-404">
    //     <img src="/img/404.svg" alt="404" />
    //     <p>NOT FOUND</p>
    //     <Link to="/">Go back home</Link>
    // </div>
    <NotFoundPage />
  );
}
