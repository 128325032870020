import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLInput from "../RLInput";
import RLInputText from "../RLInputText";
import Wrapper from "../Wrapper";

const RLModalPasswordChange = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const location = useLocation();
  

  // const [password, setPassword] = useState("");
  // const [newPassword, setNewPassword] = useState("");
  // const [isValidPassword, setIsValidPassword] = useState(true);
  // const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  // const [isPasswordConfirm, setIsPasswordConfirm] = useState(true);

  const [password, setPassword] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handlePasswordChange,
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handleNewPasswordChange,
  });
  const [newPasswordConfirm, setNewPasswordConfirm] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "password"],
    onChange: handlePasswordConfirmChange,
  });

  const [isPasswordsMatch, setIsPasswordsMatch] = useState(
    newPassword.value == newPasswordConfirm.value
  );
  const isPasswordsMatchq = newPassword.value == newPasswordConfirm.value;

  const { response, loading, error, request, responseType } = useAxios();

  function handlePasswordChange(value, isValid = false) {
    setPassword({ ...password, value: value, isValid: isValid });
  }

  function handleNewPasswordChange(value, isValid = false) {
    let isPasswordsMatch_ = false;
    if (value === newPasswordConfirm.value) {
      isPasswordsMatch_ = true;
    }

    // setIsPasswordsMatch(isPasswordsMatch_)

    setNewPassword({
      ...newPassword,
      value: value,
      isValid: isValid,
    });
  }

  function handlePasswordConfirmChange(value, isValid = false) {
    let isPasswordsMatch_ = false;
    if (value === newPassword.value) {
      isPasswordsMatch_ = true;
    }

    // setIsPasswordsMatch(isPasswordsMatch_)

    setNewPasswordConfirm({
      ...newPasswordConfirm,
      value: value,
      isValid: isValid,
    });
  }

  function isValidForm() {
    let isValidFormPassword = password.isValid;
    if (isValidFormPassword && password.validation.includes("empty")) {
      isValidFormPassword = password.value == "" ? false : true;
      setPassword({ ...password, isValid: isValidFormPassword });
    }

    let isValidFormNewPassword = newPassword.isValid;
    if (isValidFormNewPassword && newPassword.validation.includes("empty")) {
      isValidFormNewPassword = newPassword.value == "" ? false : true;
      setNewPassword({ ...newPassword, isValid: isValidFormNewPassword });
    }

    let isValidFormPasswordConfirm = newPasswordConfirm.isValid;
    if (
      isValidFormPasswordConfirm &&
      newPasswordConfirm.validation.includes("empty")
    ) {
      isValidFormPasswordConfirm =
        newPasswordConfirm.value == "" ? false : true;
      setNewPasswordConfirm({
        ...newPasswordConfirm,
        isValid: isValidFormPasswordConfirm,
      });
    }

    let isPasswordsMatch_ = isPasswordsMatch;
    if (isValidFormPassword && isValidFormPasswordConfirm) {
      if (newPassword.value === newPasswordConfirm.value) {
        isPasswordsMatch_ = true;
      } else {
        isPasswordsMatch_ = false;
      }
    }

    return (
      isValidFormPassword &&
      isValidFormNewPassword &&
      isValidFormPasswordConfirm &&
      isPasswordsMatch_
    );
  }

  useEffect(() => {
    if (response == undefined && error == "") {
      if (newPassword.isValid && newPasswordConfirm.isValid) {
        if (newPassword.value === newPasswordConfirm.value) {
          setIsPasswordsMatch(true);
        } else {
          setIsPasswordsMatch(false);
        }
      }
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "profile/update/password") {
        setPassword({ ...password, value: "" });
        setNewPassword({ ...newPassword, value: "" });
        setNewPasswordConfirm({ ...newPasswordConfirm, value: "" });

        appCtx.addToast(t("accountSettings_updatedMessage"), "green");

        appCtx.logout();
        Helpers.removeStorage();

        if(location.pathname.includes("/bo")) {
          history.replace("/bo/login");
        } else {
          history.replace("/?login");
        }
        
      }
    }
  }, [response]);

  const postUpdatePassword = (event) => {
    event.preventDefault();

    if (isValidForm()) {
      const data = {
        currentPassword: password.value,
        newPassword: newPassword.value,
        newPasswordAgain: newPasswordConfirm.value,
      };

      request({
        method: "POST",
        url: "profile/update/password",
        data,
      });
    }
  };

  return (
    <Wrapper>
      <div class="text-center">
        <h1 class="modal-header">{t("newPasswordPage_change")}</h1>
        <div class="modal-header-desc"></div>
        <form onSubmit={postUpdatePassword} class="sign-in-up-form">
          <div class="rl-form-group">
            <div class="rl-form-group-material">
              <RLInputText
                inputType="text"
                inputFeature="password"
                label={t("password")}
                data={password}
              />
            </div>
          </div>
          <div class="rl-form-group">
            <div class="rl-form-group-material">
              <RLInputText
                inputType="text"
                inputFeature="password"
                label={t("passwordNew")}
                data={newPassword}
              />
            </div>
          </div>
          <div class="rl-form-group">
            <div class="rl-form-group-material">
              <RLInputText
                inputType="text"
                inputFeature="password"
                label={t("passwordNewAgain")}
                data={newPasswordConfirm}
              />
            </div>
            {!isPasswordsMatchq && (
              <div className="error-line">
                <img
                  src="/img/icon-circle-info.svg"
                  alt="Wrong Password"
                  width="24"
                />{" "}
                {Helpers.capitalizeAllFirst(t("password_error"))}
              </div>
            )}
          </div>
          <button class="rl-form-button reset-pass-btn">{t("general_save")}</button>
        </form>
      </div>
      <a
        href=""
        className={props.closeClassName}
        onClick={(event) => {
          event.preventDefault();
          props.onClose(event);
        }}
      >
        x
      </a>
    </Wrapper>
  );
};

export default RLModalPasswordChange;
