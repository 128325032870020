import { useContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLCheckBox from "../../UI/RLCheckBox";
import Wrapper from "../../UI/Wrapper";

const PanelDiaryListItem = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const params = useParams();
  let graveId = params["graveId"];

  const item = props.item;
  const diary = item.value;
  const checkbox = item.checkbox;

  const [isEdit, setIsEdit] = useState(false);

  // const [graveType, setGraveType] = useState(undefined);
  // if (graveType != Helpers.getGraveType()) {
  //   setGraveType(Helpers.getGraveType());
  // }

  const { response, loading, error, request, responseType } = useAxios();

  const submit = (event) => {
    // event.preventDefault();
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => postRemoveDiary,
        },
        {
          label: "No",
          onClick: () => console.log("asdf"),
        },
      ],
    });
  };

  const postRemoveDiary = (event) => {
    event.preventDefault();

    const data = [diary.id];

    request({
      method: "DELETE",
      url: "grave/" + graveId + "/diary/remove",
      data,
    });
  };

  const handleRemoveConfirm = (event) => {
    postRemoveDiary(event);
  };

  const handleEdit = (event) => {
    event.preventDefault();

    setIsEdit(true);
    props.handleEdit(diary)

    window.scrollTo(0, 0)
  }

  const handleRemove = (event) => {
    event.preventDefault();

    const verseDetailModal = {
      type: "confirmationModal",
      show: true,
      params: {
        title: t("diary_modal_remove_title"),
        description: t("diary_modal_remove_desc", { summary: diary.summary }),
        buttonName: t("diary_modal_remove_button"),
      },
      onConfirm: handleRemoveConfirm,
    };
    appCtx.showModal(verseDetailModal);
  };



  useEffect(() => {
    if (error) {
    }
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/diary/remove") {
        props.diaryRemoveHandler();
      }
    }
  }, [response, error]);

  return (
    <Wrapper>
      <tr>
        {/* <td>
          <RLCheckBox input={checkbox} />
        </td> */}
        <td className="fw-600">{Helpers.formatDateMD(diary.created)}</td>
        <td className="rl-table-border-l">{diary.title}</td>
        <td className="w-40 text-right">
          <a
            href=""
            className="rl-table-button blue"
            // onClick={postEditContent}
            onClick={handleEdit}
          >
            {t("general_edit")}
          </a>
          <a href="" className="rl-table-button red" onClick={handleRemove}>
            {t("general_remove")}
          </a>
        </td>
      </tr>
    </Wrapper>
  );
};

export default PanelDiaryListItem;
