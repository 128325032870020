import Wrapper from "./Wrapper";

import classes from "./RLModal/RLModal.module.css";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import Card from "./Card";
import { RotatingLines } from "react-loader-spinner";

const LoadingSpinner = (props) => {
  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return createPortal(
    <CSSTransition
      in={props.loading}
      unmountOnExit
      // timeout={{ enter: 0, exit: 300 }}
    >
      <Wrapper>
        <Card className={`${classes.blocker} `}>
          <div className={classes["loading-background"]}>
            <div style={style}>
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.9"
                width="45"
                visible={true}
              />
            </div>
          </div>
        </Card>
      </Wrapper>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default LoadingSpinner;
