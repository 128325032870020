import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLButton from "../RLButton";
import RLInput from "../RLInput";
import RLInputText from "../RLInputText";
import Wrapper from "../Wrapper";
import RLModal from "./RLModal";

const RLModalForgotPassword = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const modal = props.modal;
  const params = modal.params;

  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "email"],
    onChange: handleEmailChange,
  });

  const { response, loading, error, request } = useAxios();
  const [data, setData] = useState([]);

  function handleEmailChange(value, isValid = false) {
    setEmail({ ...email, value: value, isValid: isValid });
  }

  function isValidForm() {
    let isValidFormEmail = email.isValid;
    if (isValidFormEmail && email.validation.includes("empty")) {
      isValidFormEmail = email.value == "" ? false : true;
      setEmail({ ...email, isValid: isValidFormEmail });
    }
    return isValidFormEmail;
  }

  const postResetPassword = (event) => {
    event.preventDefault();

    if (isValidForm()) {
      const params = {
        email: email.value,
      };

      request(
        {
          method: "POST",
          url: "auth/send-password-reset-link",
          params,
        },
        () => {},
        false
      );
    }
  };

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      setData(response);

      modal.onClose();
      
      const checkMailModal = {
        type: "checkEmail",
        show: true,
        params: {
          onGraveInitation: params?.graveAssignInfo ? params.onLoginSuccess : undefined,
        },
      };
      appCtx.showModal(checkMailModal);

    }
  }, [response, error]);

  const handleClose = (event) => {
    event.preventDefault();
    modal.onClose();
  };

  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">{t("forgatpassword_resetPassword")}</h1>
        <div className="modal-header-desc">
          {t("forgatpassword_desc")}
        </div>
        <form onSubmit={postResetPassword} className="rl-grave-name">
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_email"))} data={email} />
            </div>
          </div>
          <RLButton className="reset-pass-btn" text={t("general_continue")} />
        </form>
      </div>
      {(!params?.graveAssignInfo?.invitedRegistered) && (
      <a
        rel="modal:close"
        className={props.closeClassName}
        onClick={handleClose}
      >
        x
      </a>
      )}
    </Wrapper>
  );
};

export default RLModalForgotPassword;
