import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Wrapper from "../components/UI/Wrapper";
import useAxios from "../hooks/use-axios";
import AppContext from "../utils/app-context";
import Helpers from "../utils/Helpers";
import { useTranslation } from "react-i18next";

const EmailUpdateConfirmPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const appCtx = useContext(AppContext);
  const queryParams = new URLSearchParams(window.location.search);

  const token = queryParams.get("token");

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getEmailUpdateConfirm();
    }

    if ((response !== null && typeof response !== "undefined")) {
      if (responseType == "auth/email-update-confirm") {
        // appCtx.logout();
        // Helpers.removeStorage();
        // history.replace("/?action=login");
        handleEmailUpdateSuccess();
      }
    } else if (error) {
      history.replace("/email-verification-error");
    }
  }, [response, error]);

  const getEmailUpdateConfirm = () => {
    const url = "auth/email-update-confirm";
    setEndpoint(url);

    const params = {
      token: token,
    };

    const data = {
      token: token,
    };

    request(
      {
        method: "POST",
        url: url,
        params,
      },
      () => {},
      false
    );
  };

  function handleEmailUpdateSuccess()  {

    const warninglModal = {
      type: "warningModal",
      show: true,
      params: {
        description: t("warningModal_desc_emailUpdateSuccess"),
      },
      onConfirm: handleEmailUpdateSuccessConfirm
    };
    appCtx.showModal(warninglModal);
  }

  const handleEmailUpdateSuccessConfirm = event => {
    // event.preventDefault();
  
    appCtx.logout();
    Helpers.removeStorage();
    history.replace("/?action=login");
  }

  return <Wrapper></Wrapper>;
};

export default EmailUpdateConfirmPage;
