import Wrapper from "../../../UI/Wrapper";

const BOGraveDetailVerseItem = (props) => {
  const verse = props.item;

  const percent =
    ((verse.displayedMemberCount * 100) / verse.verse.capacity).toString() +
    "%";

  return (
    <Wrapper>
      <tr>
        <td className="fw-600">{verse.verse.verseStatus}</td>
        <td className="rl-table-border-l fw-600">{verse.verse.name}</td>
        <td className="rl-table-border-l">
          <div className="rl-progress-bar">
            {/* <div
              className="rl-progress-bar-fill pink"
              style={{ width: "72%" }}
            ></div> */}
            <div className="rl-progress-bar-fill" style={{width: percent, backgroundColor: verse.verse.color}}></div>
          </div>
        </td>
      </tr>
    </Wrapper>
  );
};

export default BOGraveDetailVerseItem;
