import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import Wrapper from "../Wrapper";
import IFrame from "./IFrame";

import classes from "./RLModal.module.css";
import Card from "../Card";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../utils/app-context";
import { useHistory } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";

const RLModal3DSecure = (props) => {
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const content = props.content;

  let html = "";
  if(content) {
    html = atob(content);
  }
  

  const onCheckoutCompleted = (data) => {
    // console.log("onCheckoutCompleted " + data);
    // props.onClose(data);
    // history.replace("/payment-welcome");

    getProfile();
  };

  const onCheckoutError = (data) => {
    props.onClose(data);
    appCtx.addToast(data, "red");
  };

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {
    if (responseType == "profile") {
      const profile = response.data;
      const user = Helpers.setUser(profile);
      const onboardStatus = Helpers.setOnboardStatus(profile.onboardStatus);
      const onboardType = Helpers.setOnboardType(profile.onboardType);

      appCtx.login(
        localStorage.getItem("token"),
        profile,
      );

      props.onClose(profile);
      history.replace("/payment-welcome?graveId="+props.graveId);
    }

    const onMessage = (event) => {
      console.log("onMessage data " + event.data);
      if (event.data.function === "checkoutCompleted") {
        onCheckoutCompleted(event.data.data);
      } else if (event.data.function === "checkoutError") {
        onCheckoutError(event.data.data);
      }
    };

    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [response, error]);

  function getProfile() {
    const url = "profile";

    request({
      method: "GET",
      url: url,
    });
  }

  return (
    // <IFrame content={html} />
    <iframe
      srcDoc={html}
      style={{ height: "500px", width: "-webkit-fill-available" }}
    />
    // <div dangerouslySetInnerHTML={{ __html: html }}></div>
  );

  //   return ReactDOM.createPortal(
  //     <CSSTransition
  //       in={props.show}
  //       unmountOnExit
  //       timeout={{ enter: 0, exit: 300 }}
  //     >
  //       <Wrapper>
  //         {/* <div className={classes.backdrop} onClick={props.onClose} /> */}
  //         <Card className={classes.blocker} onClick={props.onClose}>
  //           <Card className={`${classes.modal} ${"rememberland-modal"}`}>
  //             <div className="rememberland-modal-body w-100">
  //               <div dangerouslySetInnerHTML={{ __html: html }}></div>
  //               {/* {html} */}
  //             </div>
  //           </Card>
  //         </Card>
  //       </Wrapper>
  //     </CSSTransition>,
  //     document.getElementById("root")
  //   );
};

export default RLModal3DSecure;
