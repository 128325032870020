import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import RLInputSelectStandart from "../RLInputSelectStandart";
import RLInputText from "../RLInputText";
import Wrapper from "../Wrapper";

const RLModalNewCard = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const modal = props.modal;

  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i.toString());
  }

  // cosnt a = Date()
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= 10; i++) {
    years.push((currentYear + i).toString());
  }

  const [name, setName] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleNameChange,
  });

  const [number, setNumber] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "cardNumber"],
    onChange: handleNumberChange,
  });

  const [month, setMonth] = useState({
    value: [""],
    isValid: true,
    validation: ["empty"],
    onChange: handleMonthChange,
    options: months,
  });

  const [year, setYear] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleYearChange,
    options: years,
  });

  const [cvc, setCvc] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "cvc"],
    onChange: handleCvcChange,
  });

  function handleNameChange(value, isValid = false) {
    setName({ ...name, value: value, isValid: isValid });
  }

  function handleNumberChange(value, isValid = false) {
    setNumber({ ...number, value: value, isValid: isValid });
  }

  function handleMonthChange(value, isValid = false) {
    setMonth({ ...month, value: value, isValid: isValid });
  }

  function handleYearChange(value, isValid = false) {
    setYear({ ...year, value: value, isValid: isValid });
  }

  function handleCvcChange(value, isValid = false) {
    setCvc({ ...cvc, value: value, isValid: isValid });
  }

  function isValidForm() {
    let isValidFormName = name.isValid;
    if (isValidFormName && name.validation.includes("empty")) {
      isValidFormName = name.value == "" ? false : true;
      setName({ ...name, isValid: isValidFormName });
    }

    let isValidFormNumber = number.isValid;
    if (isValidFormNumber && number.validation.includes("empty")) {
      isValidFormNumber = number.value == "" ? false : true;
      setNumber({ ...number, isValid: isValidFormNumber });
    }

    let isValidFormMonth = month.isValid;
    if (isValidFormMonth && month.validation.includes("empty")) {
      isValidFormMonth = month.value == "" ? false : true;
      setMonth({ ...month, isValid: isValidFormMonth });
    }

    let isValidFormYear = year.isValid;
    if (isValidFormYear && year.validation.includes("empty")) {
      isValidFormYear = year.value == "" ? false : true;
      setYear({ ...year, isValid: isValidFormYear });
    }

    let isValidFormCvc = cvc.isValid;
    if (isValidFormCvc && cvc.validation.includes("empty")) {
      isValidFormCvc = cvc.value == "" ? false : true;
      setCvc({ ...cvc, isValid: isValidFormCvc });
    }

    return (
      isValidFormName &&
      isValidFormNumber &&
      isValidFormMonth &&
      isValidFormYear &&
      isValidFormCvc
    );
  }

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {
    if (!error && response !== null && typeof response !== "undefined") {
      if (responseType == "payment/card/create") {
        modal.onClose();
        // history.replace("/account");
        window.location.reload(false);
      }
    }
  }, [response, error]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isValidForm()) {
      const data = {
        cardHolderName: name.value,
        cardNumber: number.value,
        expireYear: year.value,
        expireMonth: month.value,
        cvc: cvc.value,
      };

      const url = "payment/card/create";
      request({
        method: "POST",
        url: url,
        data: data,
      });
    }
  };

  return (
    <Wrapper>
      <div class="text-center">
        <h1 class="modal-header">{t("accountSettings_payment_newCard_add")}</h1>
        <div class="modal-header-desc"></div>

        <form onSubmit={handleSubmit} class="sign-in-up-form">

          <div class="rl-form-group">
            <div class="rl-form-group-material">
              <RLInputText
                inputType="text"
                label={t("panelPayment_cardHolderName")}
                data={name}
              />
            </div>
          </div>

          <div class="rl-form-group">
            <div class="rl-form-group-material">
              <RLInputText
                inputType="text"
                inputFeature="number"
                label={t("panelPayment_cardNumber")}
                data={number}
              />
            </div>
          </div>

          <div class="rl-card-expire-info">
            <div class="rl-form-group-material">
              <RLInputSelectStandart
                label={t("panelPayment_month")}
                standart={true}
                onChange={(newValue) => {
                  // setIsPaid(newValue);
                }}
                data={month}
              />
            </div>

            <div class="rl-form-group-material">
              <RLInputSelectStandart
                label={t("panelPayment_year")}
                standart={true}
                onChange={(newValue) => {
                  // setIsPaid(newValue);
                }}
                data={year}
              />
            </div>

            <div class="rl-form-group">
            <div class="rl-form-group-material">
              <RLInputText
                inputType="text"
                label={t("panelPayment_cvc")}
                data={cvc}
              />
            </div>
            </div>

          </div>

          <button class="rl-form-button reset-pass-btn">
            {t("accountSettings_payment_newCard_save")}
          </button>
        </form>
      </div>
      <a
        rel="modal:close"
        className={props.closeClassName}
        onClick={modal.onClose}
      >
        x
      </a>
    </Wrapper>
  );
};

export default RLModalNewCard;
