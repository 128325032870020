import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AppContext from "../../utils/app-context";
import Helpers from "../../utils/Helpers";
import Wrapper from "../UI/Wrapper";

const PaymentWelcomeOwner = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const appCtx = useContext(AppContext);

  const userGraveRoles = Helpers.getUserGraveRoles();
  let canAcces = false;

  const queryParams = new URLSearchParams(window.location.search);
  const graveId_ = queryParams.get("graveId");

  if(userGraveRoles.includes("GUARDIAN")) {

    if (userGraveRoles.includes("OWNER") || userGraveRoles.includes("PURCHASER") || userGraveRoles.includes("ADMIN")) {
      canAcces = true;  
    }

  } else {
    canAcces = true;
  }

  window.gtag('event', 'manual_conversion_SUBSCRIBE_PAID');

  const homepageHandler = (event) => {
    // event.preventDefault();

    // if (Helpers.getOnboardType() != "GUARDIAN") {
    if (canAcces) {
      if (appCtx.isLoggedIn && Helpers.getOnboardStatus() == "COMPLETED") {
        history.replace("/panel/"+graveId_);
      } else {
        history.replace("/");
      }
    }
  };

  return (
    <Wrapper>
      <div className="rl-welcome">
        <img
          src="img/icon-heart-check.svg"
          alt="TEBRİKLER"
          className="img-heart"
        />

        <h2>{t("welcome-ownerPurchaser-congrats")}</h2>

        <p>{t("welcome-ownerPurchaser-desc")}</p>
        
        {canAcces && (
          <a href="" onClick={homepageHandler}>
            {Helpers.capitalizeAll(t("general_homepage"))}
          </a>
        )}
      </div>
    </Wrapper>
  );
};

export default PaymentWelcomeOwner;
