import { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../utils/app-context";
import Wrapper from "../../UI/Wrapper";

const AccountSettingsPaymentMethodItemGrave = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const grave = props.item;

  const graveId = Object.keys(grave)[0];
  const graveName = Object.values(grave)[0];

  const handleChangeCard = (event) => {
    event.preventDefault();

    const changeCardModal = {
      type: "changeCard",
      show: true,
      params: { userCardId: props.id, graveId: graveId },
    };
    appCtx.showModal(changeCardModal);
  };

  return (
    <Wrapper>
      <div className="grave-name">{graveName}</div>
      {props.itemCount > 1 && (
        <a href="" onClick={handleChangeCard} className="rl-change-cc">
          <img src="/img/icon-edit.svg" alt="Change Card" />{" "}
          {t("accountSettings_payment_changeCard")}
        </a>
      )}
    </Wrapper>
  );
};

export default AccountSettingsPaymentMethodItemGrave;
