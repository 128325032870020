import React, { useCallback, useEffect, useState } from "react";
import globals from "../utils/globals";

let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  // user: null,
  // userr: () => {},
  onboardStatus: "",
  login: (token) => {},
  logout: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingTIme = adjExpirationTime - currentTime;

  return remainingTIme;
};

// const test = () => {
//   return user.onboardStatus;
// }

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  const dateTime = Date.now();
  const timestamp = Math.floor(dateTime / 1000);

  if (remainingTime >= timestamp) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    return null;
  }

  return {
    token: storedToken,
    duration: storedExpirationDate - timestamp,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  initialToken = localStorage.getItem("token");
  const initialUser = localStorage.getItem("user");
  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState(initialUser);
  const [onboardStatus, setOnboardStatus] = useState();

  let userIsLoggedIn = !!token;
  // let userData =

  const logoutHandler = useCallback(() => {
    console.log("logoutHandler");
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("onboardStatus");
    // localStorage.removeItem("user");
    // localStorage.removeItem("name");
    // localStorage.removeItem("surname");
    // localStorage.removeItem("email");
    // localStorage.removeItem("onboardStatus");
    // localStorage.removeItem("onboardType");
    // localStorage.removeItem("id");
    setOnboardStatus("")
    localStorage.removeItem("verse");


    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, expirationTime, user) => {
    setToken(token);
    userIsLoggedIn = !!token;
    setUser(user);

    globals.setPropertyValue("user", user)
    setOnboardStatus(user.onboardStatus)

    // sessionStorage.setItem("user", user);
    // setOnboardStatus(user.onboardStatus);
    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expirationTime);
    // localStorage.setItem("user", user);
    // localStorage.setItem("name", user.name);
    // localStorage.setItem("surname", user.surname);
    // localStorage.setItem("email", user.email);
    localStorage.setItem("onboardStatus", user.onboardStatus);
    // localStorage.setItem("onboardType", user.onboardType);
    // localStorage.setItem("id", user.id);
    localStorage.setItem("verse", "FOR_YOU");
    localStorage.setItem("selectedVerse", "");
    localStorage.setItem("selectedGrave", "");

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const userHandler = () => {

    let usr = {
      name: localStorage.getItem("name"),
      surname: localStorage.getItem("surname"),
      email: localStorage.getItem("email"),
      onboardStatus: localStorage.getItem("onboardStatus"),
      onboardType: localStorage.getItem("onboardType"),
      id: localStorage.getItem("id")
    }

    return usr;
  };

  const verseHandler = () => {
    const verse = localStorage.getItem("verse");

    if(verse == "FOR_YOU") {
      return true;
    } else {
      return false;
    }
  }

  const onboardStatusHandler = () => {
    // return onboardStatus;
    return localStorage.getItem("onboardStatus");
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    user: user,
    // getVerse: verseHandler,
    // getUser: userHandler,
    onboardStatus: onboardStatusHandler,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
