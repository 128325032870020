import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "../UI/Wrapper"

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import Helpers from "../../utils/Helpers";
import AppContext from "../../utils/app-context";
import { useHistory, useLocation } from "react-router-dom";
import useAxios from "../../hooks/use-axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { isMobile, isTablet, isDesktop } from ' ';

const HeroNew = props => {

    const { t } = useTranslation();
    const appCtx = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    const [selectedPage, setSelectedPage] = useState("testament");
    const [startLifeType, setStartLifeType] = useState();

    const handlePageChange = (event, type) => {
        event.preventDefault();

        if (selectedPage != type) {
            setSelectedPage(type)
        }
    }

    const handleClickToScroll = (event, id) => {
        event.preventDefault();

        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleReadmore = (event, id) => {
        event.preventDefault();

        handleClickToScroll(event, id)
    }

    const handleDetailForLifeType = (event, type) => {
        event.preventDefault();

        props.handleDetailForLifeType(type, true);

    }

    const handleDetailWatchMore = (event, videoUrl) => {
        event.preventDefault();

        const verseVideoModal = {
            type: "verseVideo",
            show: true,
            params: {
                videoUrl: process.env.REACT_APP_CDN_URL + videoUrl,
                type: "video/mp4",
                blockCloserEnabled: true
            },
            className: "rl-verse-modal",
            // style: {maxWidth: "1280px !important"},
        };
        appCtx.showModal(verseVideoModal);

    }

    function handleCreateGrave(type) {

        if (Helpers.findTopMenu("ADMIN")) {
            history.replace("/payment");
        } else {
            const checkMailModal = {
                type: "createGrave",
                show: true,
                params: { graveType: type, location: location, history: history },
            };
            appCtx.showModal(checkMailModal);
        }
    }

    const handleStartLife = (event, type) => {
        event.preventDefault();

        if (type == "FOR_YOU" || type == "FOR_BE_LOVED") {
            if (appCtx.isLoggedIn) {
                setStartLifeType(type);
                // handleCreateGrave(event, type)
                postGraveListFunnelGraves();
            } else {
                handleLogin(event)
            }
        } else {
            handleClickToScroll(event, "rememberland-landing-for-whom")
        }

    }
    const { response, loading, error, request, responseType } = useAxios();
    useEffect(() => {
        if (response !== null && typeof response !== "undefined") {
            if (responseType == "grave/list-funnel-graves") {
                if (response.data.length == 0) {
                    handleCreateGrave(startLifeType)
                } else {
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].graveType == startLifeType) {
                            history.replace("/funnel/" + response.data[i].id);
                            appCtx.hideLoading();
                            return;
                        }
                    }

                    handleCreateGrave(startLifeType)
                }

                appCtx.hideLoading();
            }
        }
    }, [response]);

    function postGraveListFunnelGraves() {
        appCtx.showLoading();
        const url = "grave/list-funnel-graves";

        const data = {};

        request(
            {
                method: "POST",
                url: url,
                data,
            },
        );
    }

    const handleLogin = event => {

        event.preventDefault();

        const verseDetailModal = {
            type: "register",
            show: true,
            params: {
                onLoginSuccess: handleLoginSuccess
            }
        };
        appCtx.showModal(verseDetailModal);
    }
    const handleLoginSuccess = (url) => {
        history.replace(url);
    }

    return (
        <Wrapper>
            <section className="rememberland-landing-hero-new">

                {/* {(isDesktop) ? ( */}
                    <div className="rememberland-hero-tabs-outer">
                        <div className="rememberland-hero-tabs-images">

                            {/* testament */}
                            {selectedPage == "testament" && (
                                // <img src="img/hero-01-testament-min.jpg" className={selectedPage == "testament" ? "active" : ""} alt="" />
                                <LazyLoadImage src="img/hero-01-testament-min.jpg" className={selectedPage == "testament" ? "active" : ""}/>
                            )}

                            {/* memorial */}
                            {selectedPage == "memorial" && (
                                // <img src="img/hero-02-memorial-min.jpg" className={selectedPage == "memorial" ? "active" : ""} alt="" />
                                <LazyLoadImage src="img/hero-02-memorial-min.jpg" className={selectedPage == "memorial" ? "active" : ""}/>
                            )}

                            {/* graveyard */}
                            {selectedPage == "graveyard" && (
                                // <img src="img/hero-03-graveyard-min.jpg" className={selectedPage == "graveyard" ? "active" : ""} alt="" />
                                <LazyLoadImage src="img/hero-03-graveyard-min.jpg" className={selectedPage == "graveyard" ? "active" : ""}/>
                            )}

                            {/* diary */}
                            {selectedPage == "diary" && (
                                // <img src="img/hero-04-diary-min.jpg" className={selectedPage == "diary" ? "active" : ""} alt="" />
                                <LazyLoadImage src="img/hero-04-diary-min.jpg" className={selectedPage == "diary" ? "active" : ""}/>
                            )}

                            {/* guardian */}
                            {selectedPage == "guardian" && (
                                // <img src="img/hero-05-guardian-min.jpg" className={selectedPage == "guardian" ? "active" : ""} alt="" />
                                <LazyLoadImage src="img/hero-05-guardian-min.jpg" className={selectedPage == "guardian" ? "active" : ""}/>
                            )}

                            {/* verses */}
                            {selectedPage == "verses" && (
                                // <img src="img/hero-06-verse-min.jpeg" className={selectedPage == "verses" ? "active" : ""} alt="" />
                                <LazyLoadImage src="img/hero-06-verse-min.jpeg" className={selectedPage == "verses" ? "active" : ""}/>
                            )}
                        </div>

                        <div className="rememberland-hero-tabs">

                            <div className="rememberland-hero-tabs-left">
                                <div className="rememberland-list-group">

                                    {/* testament */}
                                    <a href="" onClick={event => handlePageChange(event, "testament")} className={`${"rememberland-list-group-item"} ${selectedPage == "testament" ? "active" : ""}`}>
                                        {t("landing_hero_navigation_testament")}
                                    </a>

                                    {/* memorial */}
                                    <a href="" onClick={event => handlePageChange(event, "memorial")} className={`${"rememberland-list-group-item"} ${selectedPage == "memorial" ? "active" : ""}`}>
                                        {t("landing_hero_navigation_memorial")}
                                    </a>

                                    {/* graveyard */}
                                    <a href="" onClick={event => handlePageChange(event, "graveyard")} className={`${"rememberland-list-group-item"} ${selectedPage == "graveyard" ? "active" : ""}`}>
                                        {t("landing_hero_navigation_graveyard")}
                                    </a>

                                    {/* diary */}
                                    <a href="" onClick={event => handlePageChange(event, "diary")} className={`${"rememberland-list-group-item"} ${selectedPage == "diary" ? "active" : ""}`}>
                                        {t("landing_hero_navigation_diary")}
                                    </a>

                                    {/* guardian */}
                                    <a href="" onClick={event => handlePageChange(event, "guardian")} className={`${"rememberland-list-group-item"} ${selectedPage == "guardian" ? "active" : ""}`}>
                                        {t("landing_hero_navigation_guardian")}
                                    </a>

                                    {/* verses */}
                                    <a href="" onClick={event => handlePageChange(event, "verses")} className={`${"rememberland-list-group-item"} ${selectedPage == "verses" ? "active" : ""}`}>
                                        {t("landing_hero_navigation_verses")}
                                    </a>
                                </div>
                            </div>

                            <div className="rememberland-hero-tabs-right">

                                {/* testament */}
                                <div className={`${"rememberland-list-group-item-content"} ${selectedPage == "testament" ? "active" : ""}`}>
                                    <h2>{t("landing_hero_new_testament_title")}</h2>
                                    <p>{t("landing_hero_new_testament_desc")}</p>

                                    <div className="rememberland-list-group-item-content-links">
                                        <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_YOU")} >{Helpers.capitalizeAllFirst(t("landing_hero_button_startWriting"))}</a>
                                        <a href="" className="black-link-img" onClick={event => handleDetailWatchMore(event, t("hero-video-testament"))}>{t("landing_hero_button_watchMore")}
                                            <img src="/img/icon-whatchformore.png" alt="" />
                                        </a>
                                    </div>
                                </div>

                                {/* memorial */}
                                <div className={`${"rememberland-list-group-item-content"} ${selectedPage == "memorial" ? "active" : ""}`}>
                                    <h2>{t("landing_hero_new_memorial_title")}</h2>
                                    <p>{t("landing_hero_new_memorial_desc")}</p>

                                    <div>
                                        <div style={{ marginBottom: "8px" }}><span>{t("landing_hero_new_memorial_button_desc")}</span></div>
                                        <div className="rememberland-list-group-item-content-links">
                                            <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_YOU")}>{Helpers.capitalizeAllFirst(t("landing_hero_new_memorial_button_fu"))}</a>
                                            <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_BE_LOVED")}>{Helpers.capitalizeAllFirst(t("landing_hero_new_memorial_button_fb"))}</a>
                                            {/* <a href="" className="black-link" onClick={event => handleReadmore(event, "rememberland-landing-for-whom")}>{t("landing_hero_button_learnMore")}</a> */}
                                            <a href="" className="black-link-img" onClick={event => handleDetailWatchMore(event, t("hero-video-memorial"))}>{t("landing_hero_button_watchMore")}
                                                <img src="/img/icon-whatchformore.png" alt="" />
                                            </a>
                                        </div>
                                    </div>

                                </div>

                                {/* graveyard */}
                                <div className={`${"rememberland-list-group-item-content"} ${selectedPage == "graveyard" ? "active" : ""}`}>
                                    <h2>{t("landing_hero_new_graveyard_title")}</h2>
                                    <p>{t("landing_hero_new_graveyard_desc")}</p>

                                    <div>
                                        <div style={{ marginBottom: "8px" }}><span>{t("landing_hero_new_graveyard_button_desc")}</span></div>
                                        <div className="rememberland-list-group-item-content-links">
                                            <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_YOU")}>{Helpers.capitalizeAllFirst(t("landing_hero_new_graveyard_button_fu"))}</a>
                                            <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_BE_LOVED")}>{Helpers.capitalizeAllFirst(t("landing_hero_new_graveyard_button_fb"))}</a>
                                            <a href="" className="black-link" onClick={event => handleReadmore(event, "rememberland-landing-gravyeards")} >{t("landing_hero_button_learnMore")}</a>
                                        </div>
                                    </div>
                                </div>

                                {/* diary */}
                                <div className={`${"rememberland-list-group-item-content"} ${selectedPage == "diary" ? "active" : ""}`}>
                                    <h2>{t("landing_hero_new_diary_title")}</h2>
                                    <p>{t("landing_hero_new_diary_desc")}</p>

                                    <div className="rememberland-list-group-item-content-links">
                                        <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_YOU")}>{Helpers.capitalizeAllFirst(t("landing_hero_button_startWriting"))}</a>
                                        <a href="" className="black-link" onClick={event => handleDetailForLifeType(event, "diary")} >{t("landing_hero_button_learnMore")}</a>
                                    </div>
                                </div>

                                {/* guardian */}
                                <div className={`${"rememberland-list-group-item-content"} ${selectedPage == "guardian" ? "active" : ""}`}>
                                    <h2>{t("landing_hero_new_guardian_title")}</h2>
                                    <p>{t("landing_hero_new_guardian_desc")}</p>

                                    <div className="rememberland-list-group-item-content-links">
                                        {/* <a href="" className="black-bttn">{t("landing_hero_navigation_startLife")}</a> */}
                                        <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_YOU")}>{Helpers.capitalizeAllFirst(t("landing_hero_new_guardian_button"))}</a>
                                        {/* <a href="" className="black-link" onClick={event => handleReadmore(event, "rememberland-landing-guardians")} >{t("landing_hero_button_learnMore")}</a> */}
                                        <a href="" className="black-link-img" onClick={event => handleDetailWatchMore(event, t("hero-video-guardian"))}>{t("landing_hero_button_watchMore")}
                                            <img src="/img/icon-whatchformore.png" alt="" />
                                        </a>
                                    </div>
                                </div>

                                {/* verses */}
                                <div className={`${"rememberland-list-group-item-content"} ${selectedPage == "verses" ? "active" : ""}`}>
                                    <h2>{t("landing_hero_new_verses_title")}</h2>
                                    <p>{t("landing_hero_new_verses_desc")}</p>

                                    <div className="rememberland-list-group-item-content-links">
                                        <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_YOU")}>{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife_foryou"))}</a>
                                        <a href="" className="black-bttn" onClick={event => handleStartLife(event, "FOR_BE_LOVED")}>{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife_forbeloved"))}</a>
                                        {/* <a href="" className="black-link" onClick={event => handleReadmore(event, "rememberland-landing-gravyeards")}>{t("landing_readmore")}</a> */}
                                        <a href="" className="black-link-img" onClick={event => handleDetailWatchMore(event, t("hero-video-verse"))}>{t("landing_hero_button_watchMore")}
                                            <img src="/img/icon-whatchformore.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* ) : ( */}
                    <div className="rl-hero-mobile-slider-outer">
                        <Swiper
                            className="swiper rl-hero-mobile-slider"
                            modules={[Navigation, Pagination]}
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                            navigation
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            breakpoints={{
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 80,
                                    centeredSlides: true,
                                },
                            }}
                        >

                            {/* testament */}
                            <SwiperSlide>
                                <div className="rl-hero-mobile-slider-bg-img">
                                    {/* <img src="img/hero-01-testament-min.jpg" alt="" /> */}
                                    <LazyLoadImage src="img/hero-01-testament-min.jpg"/>
                                </div>
                                <div className="rl-hero-mobile-slider-text">
                                    <h2>{Helpers.capitalizeAll(t("landing_hero_navigation_testament"))}</h2>
                                    <p>
                                        {t("landing_hero_new_testament_title")}
                                        <span>{t("landing_hero_new_testament_desc")}</span>
                                    </p>
                                    <a href="" className="btn" onClick={event => handleStartLife(event, "FOR_YOU")}>{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife"))}</a>
                                    <a href="" className="read-more" onClick={event => handleDetailForLifeType(event, "testament")}>{t("landing_readmore")}</a>
                                </div>
                            </SwiperSlide>

                            {/* memorial */}
                            <SwiperSlide>
                                <div className="rl-hero-mobile-slider-bg-img">
                                    {/* <img src="img/hero-02-memorial-min.jpg" alt="" /> */}
                                    <LazyLoadImage src="img/hero-02-memorial-min.jpg"/>
                                </div>
                                <div className="rl-hero-mobile-slider-text">
                                    <h2>{Helpers.capitalizeAll(t("landing_hero_navigation_memorial"))}</h2>
                                    <p>
                                        {t("landing_hero_new_memorial_title")}
                                        <span>{t("landing_hero_new_memorial_desc")}</span>
                                    </p>
                                    <a href="" className="btn" onClick={event => handleStartLife(event, "FOR_YOU")} >{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife_foryou"))}</a>
                                    <a href="" className="btn" onClick={event => handleStartLife(event, "FOR_BE_LOVED")} >{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife_forbeloved"))}</a>
                                    <a href="" className="read-more" onClick={event => handleReadmore(event, "rememberland-landing-for-whom")} >{t("landing_readmore")}</a>
                                </div>
                            </SwiperSlide>

                            {/* graveyard */}
                            <SwiperSlide>
                                <div className="rl-hero-mobile-slider-bg-img">
                                    {/* <img src="img/hero-03-graveyard-min.jpg" alt="" /> */}
                                    <LazyLoadImage src="img/hero-03-graveyard-min.jpg"/>
                                </div>
                                <div className="rl-hero-mobile-slider-text">
                                    <h2>{Helpers.capitalizeAll(t("landing_hero_navigation_graveyard"))}</h2>
                                    <p>
                                        {t("landing_hero_new_graveyard_title")}
                                        <span>{t("landing_hero_new_graveyard_desc")}</span>
                                    </p>
                                    <a href="" className="btn" onClick={event => handleStartLife(event, "FOR_YOU")} >{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife_foryou"))}</a>
                                    <a href="" className="btn" onClick={event => handleStartLife(event, "FOR_BE_LOVED")} >{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife_forbeloved"))}</a>
                                    <a href="" className="read-more" onClick={event => handleReadmore(event, "rememberland-landing-for-whom")} >{t("landing_readmore")}</a>
                                </div>
                            </SwiperSlide>

                            {/* diary */}
                            <SwiperSlide>
                                <div className="rl-hero-mobile-slider-bg-img">
                                    {/* <img src="img/hero-04-diary-min.jpg" alt="" /> */}
                                    <LazyLoadImage src="img/hero-04-diary-min.jpg"/>
                                </div>
                                <div className="rl-hero-mobile-slider-text">
                                    <h2>{Helpers.capitalizeAll(t("landing_hero_navigation_diary"))}</h2>
                                    <p>
                                        {t("landing_hero_new_diary_title")}
                                        <span>{t("landing_hero_new_diary_desc")}</span>
                                    </p>
                                    <a href="" className="btn" onClick={event => handleStartLife(event, "FOR_YOU")} >{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife"))}</a>
                                    <a href="" className="read-more" onClick={event => handleDetailForLifeType(event, "diary")} >{t("landing_readmore")}</a>
                                </div>
                            </SwiperSlide>

                            {/* guardian */}
                            <SwiperSlide>
                                <div className="rl-hero-mobile-slider-bg-img">
                                    {/* <img src="img/hero-05-guardian-min.jpg" alt="" /> */}
                                    <LazyLoadImage src="img/hero-05-guardian-min.jpg"/>
                                </div>
                                <div className="rl-hero-mobile-slider-text">
                                    <h2>{Helpers.capitalizeAll(t("landing_hero_navigation_guardian"))}</h2>
                                    <p>
                                        {t("landing_hero_new_guardian_title")}
                                        <span>{t("landing_hero_new_guardian_desc")}</span>
                                    </p>
                                    <a href="" className="btn" onClick={event => handleStartLife(event, "FOR_YOU")} >{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife_foryou"))}</a>
                                    <a href="" className="read-more" onClick={event => handleReadmore(event, "rememberland-landing-guardians")} >{t("landing_readmore")}</a>
                                </div>
                            </SwiperSlide>

                            {/* verses */}
                            <SwiperSlide>
                                <div className="rl-hero-mobile-slider-bg-img">
                                    {/* <img src="img/hero-06-verse-min.jpeg" alt="" /> */}
                                    <LazyLoadImage src="img/hero-06-verse-min.jpeg"/>
                                </div>
                                <div className="rl-hero-mobile-slider-text">
                                    <h2>{Helpers.capitalizeAll(t("landing_hero_navigation_verses"))}</h2>
                                    <p>
                                        {t("landing_hero_new_verses_title")}
                                        <span>{t("landing_hero_new_verses_desc")}</span>
                                    </p>
                                    <a href="" className="btn" onClick={event => handleStartLife(event, "FOR_YOU")}>{Helpers.capitalizeAllFirst(t("landing_hero_navigation_startLife"))}</a>
                                    <a href="" className="read-more" onClick={event => handleDetailForLifeType(event, "testament")}>{t("landing_readmore")}</a>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                {/* )} */}

            </section>
        </Wrapper>
    )
}

export default HeroNew;