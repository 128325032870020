import { useTranslation } from "react-i18next";
import RLButton from "../RLButton";
import Wrapper from "../Wrapper";

const RLModalCheckEmail = (props) => {
  const { t } = useTranslation();
  const modal = props.modal;
  const params = modal.params;

  const handleContinue = (event) => {
    event.preventDefault();

    if (modal != undefined) {
      if (params?.onGraveInitation != undefined) {
        params.onGraveInitation("/")
        modal.onClose();
      } else if (modal.onConfirm != undefined) {
        modal.onConfirm();
        modal.onClose();
      } else {
        // props.onClose(event);
        modal.onClose();
      }
    } else {
      // props.onClose(event);
      modal.onClose();
    }
  };

  return (
    <Wrapper>
      <div className="text-center">
        <img
          src="img/icon-mail.svg"
          alt="Check your mail"
          class="rl-modal-img-top"
        />
        <h1 class="modal-header">{t("modal_checkmail_title")}</h1>
        <div class="modal-header-desc">
          <p>{t("modal_checkmail_desc1")}</p>

          <p>{t("modal_checkmail_desc2")}</p>
        </div>

        <form onSubmit={handleContinue} className="rl-grave-name">
          <div className="rl-form-group">
            <div className="rl-form-group-material"></div>
          </div>
          <RLButton className="reset-pass-btn" text={t("modal_checkmail_goto")} />
        </form>
      </div>
      {(params?.onGraveInitation == undefined) && (
        <a
          rel="modal:close"
          className={props.closeClassName}
          onClick={handleContinue}
        >
          x
        </a>
      )}

    </Wrapper>
  );
};

export default RLModalCheckEmail;
