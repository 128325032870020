
import PanelHeader from "../components/Panel/PanelHeader";
import Wrapper from "../components/UI/Wrapper";

import "./../css-panel/style.css";
import PanelWrapper from "../components/Panel/PanelWrapper";

const PanelPage = (props) => {

  return (
    <Wrapper>
      <PanelHeader />

      <PanelWrapper />
    </Wrapper>
  );
};

export default PanelPage;
