import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import PanelContentFormFileItem from "../Panel/PanelContent/PanelContentFormFileItem";
import Wrapper from "./Wrapper";

const RLDragAndDrop = props => {

    const { t } = useTranslation();

    var _file = [];
    // props.file != undefined ? _file = props.file : _file = [];
    const [file, setFile] = useState([]);
    if (file != props.file && file.length == 0 && props.file.length > 0) {
        setFile(props.file)
    }
    const [fileList, setFileList] = useState("");
    const [isUploaded, setIsUploaded] = useState();
    if (isUploaded != props.isUploaded) {
        setIsUploaded(props.isUploaded)
    }

    const [isDisabled, setIsDisabled] = useState();
    if (isDisabled != props.isDisabled) {
        setIsDisabled(props.isDisabled)
    }

    const recordedFiles_ = props.recordedFiles;
    const [recordedFiles, setRecordedFiles] = useState();
    useEffect(() => {
        if (recordedFiles_ != recordedFiles) {
            setRecordedFiles(recordedFiles_);
        }
    }, [recordedFiles_]);

    function handleFileChange(file_, merge = true) {

        var isValidFormat = true;
        if (props.formatCheck) {

            file_.forEach(element => {
                var elemPart = element.name.split(".")
                var ext = elemPart.slice(-1)

                if (!(ext == "png" ||
                    ext == "jpg" ||
                    ext == "jpeg" ||
                    ext == "svg" ||
                    ext == "mp4" ||
                    ext == "mov" ||
                    ext == "wmv" ||
                    ext == "avi" ||
                    ext == "mp3" ||
                    ext == "wav" ||
                    ext == "m4a" ||
                    ext == "docx" ||
                    ext == "txt" ||
                    ext == "pdf")) {

                    isValidFormat = false

                    props.onFileFormatWrong(t("content_form_dragdrop_fileformat_error", { ext: ext }))
                    inputRef.current.value = null

                }
            });
        }
        
        if (isValidFormat) {
            if (merge) {
                setFile((prevFile) => [...prevFile, ...file_]);
            } else {
                setFile(file_)
            }
    
            props.onFileChange(file_)
            inputRef.current.value = null
            // resetsFileInput();
        }
        
    }

    const handleFileRemove = (event) => {

        if (event.storage != "local") {
            var fileList_ = file.filter(function (f) {
                return Helpers.fileHashCode(f) != Helpers.fileHashCode(event);
            });

            handleFileChange(fileList_, false);
        } else {
            var fileList_ = recordedFiles.filter(function (f) {
                return Helpers.fileHashCode(f) != Helpers.fileHashCode(event);
            });

            const _testamentStep = Helpers.getTestamentStep();
            _testamentStep[props.selectedGraveId]["files"] = fileList_;
            Helpers.setTestamentStep(_testamentStep);

            setRecordedFiles(fileList_);
        }
    };

    useEffect(() => {
        let fileList_ = [];
        let fileList1_ = {};
        let fileList2_ = {};


        if (file?.length > 0) {

            for (let i = 0; i < file.length; i++) {
                let item = file[i];
                fileList_.push(
                    <PanelContentFormFileItem
                        id={Helpers.fileHashCode(item)}
                        key={Helpers.fileHashCode(item)}
                        item={item}
                        removeFile={handleFileRemove}
                    />
                )
            }
        }

        if (recordedFiles?.length > 0) {

            for (let i = 0; i < recordedFiles.length; i++) {
                let item = recordedFiles[i];
                fileList_.push(
                    <PanelContentFormFileItem
                        id={Helpers.fileHashCode(item)}
                        key={Helpers.fileHashCode(item)}
                        item={item}
                        removeFile={handleFileRemove}
                    />
                )
            }
        }

        setFileList(fileList_);

        props.onFileChange(file)



    }, [file, recordedFiles]);

    useEffect(() => {
        if (isUploaded) {
            setFile([])
            // setFileList("")
        }
    }, [isUploaded]);

    // drag state
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        // console.log("handleDrag " + e.type);
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            if (!isDisabled) {
                setDragActive(true);
            }
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    const handleDragLeave = function (e) {
        // console.log("handleDragOver " + e.type);
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            if (!isDisabled) {
                setDragActive(true);
            }
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    const handleDragOver = function (e) {
        // console.log("handleDragOver " + e.type);
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            if (!isDisabled) {
                setDragActive(true);
            }
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        // console.log("handleDrop " + e.type);
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (!isDisabled) {
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                // setFile((prevFile) => [...prevFile, ...Array.from(e.dataTransfer.files)]);
                if (props.multiple) {
                    handleFileChange(Array.from(e.dataTransfer.files))
                } else {
                    handleFileChange(Array.from(e.dataTransfer.files), false)
                }
            }

        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            let files = Array.from(e.target.files);

            for (var i = 0; i < files.length; i++) {
                files[i]["timeStamp"] = Date.now();
            }

            if (props.multiple) {
                handleFileChange(files)
            } else {
                handleFileChange(files, false);
            }
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = (e) => {
        e.preventDefault();

        if (!isDisabled) {
            inputRef.current.click();
        }
    };

    return (
        <Wrapper>
            <div
                // onDragEnter={handleDrag}
                onDragEnter={handleDrag}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className={`${"rl-content-upload"} ${isUploaded ? "uploaded" : ""} ${isDisabled ? "disabled" : ""}`}
            >
                <img
                    src="/img/icon-upload-cloud.svg"
                    alt="Upload"
                    width="80"
                    height="80"
                />
                <p className="upload-text">
                    {isUploaded
                        ? t("content_form_dragdropsuccess")
                        : t("content_form_dragdrop")}
                </p>
                <div className="rl-form-or">
                    <span>{isUploaded ? t("content_form_dragdropnew") : t("general_or")}</span>
                </div>

                <a href="" className="file-upload">
                    <div className="file-upload__input">
                        <input
                            ref={inputRef}
                            type="file"
                            id="file"
                            name="myfiles[]"
                            multiple={props.multiple}
                            onChange={handleChange}
                        />
                    </div>
                    <div
                        onClick={onButtonClick}
                        className={`${"file-upload__label"}`}
                        style={{ borderRadius: "0px" }}
                    >
                        {t("content_form_dragdropupload")}
                    </div>
                </a>
            </div>

            {fileList.length > 0 && (
                <div className="rl-uploaded-files">
                    <div className="rl-uploaded-files-header">
                        {t("content_form_uploadedFiles")}
                    </div>
                    {fileList}
                </div>
            )}
        </Wrapper>
    );
}

export default RLDragAndDrop;