import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";
import AccountSettingsAvatar from "./AccountSettingsAvatar";
import AccountSettingsForm from "./AccountSettingsForm";
import AccountSettingsSubs from "./AccountSettingsSubs";
import AccountSettingsPaymentMethod from "./AccountSettingsPaymentMethod";
import AccountSettingsSubsInfo from "./AccountSettingsSubsInfo";

const AccountSettings = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const [pageType, setPageType] = useState();

  const handleCancle = (event) => {
    event.preventDefault();

    const cancelSubsModal = {
      type: "cancelSubs",
      show: true,
      params: {},
      onConfirm: handleCancleConfirm,
    };
    appCtx.showModal(cancelSubsModal);
  };

  const handleCancleConfirm = (event) => {
    event.preventDefault();

    console.log("handleCancleConfirm");
    appCtx.hideModal();
  };

  const handlePageType = (type) => {
    setPageType(type);
  }

  return (
    <Wrapper>
      {pageType == undefined && (
        <Wrapper>
          <h1>{t("accountSettings")}</h1>

          <AccountSettingsForm />

          {(Helpers.getUserGraveRoles().includes("OWNER") ||
            Helpers.getUserGraveRoles().includes("PURCHASER")) && (
              <AccountSettingsPaymentMethod id={0} key={0} />
            )}

          <AccountSettingsSubs handlePageType={handlePageType} />
        </Wrapper>
      )}
      {pageType == "paymentInfo" && (
        <AccountSettingsSubsInfo handlePageType={handlePageType} />
      )}

      {((Helpers.getUserGraveRoles().includes("OWNER") ||
            Helpers.getUserGraveRoles().includes("PURCHASER")) && pageType != "paymentInfo") && (
          <a
            href=""
            onClick={handleCancle}
            rel=""
            className="rl-cancel-membership"
          >
            {t("accountSettings_payment_cancelSubs")}
          </a>
        )}
    </Wrapper>
  );
};

export default AccountSettings;
