import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import Wrapper from "../../UI/Wrapper";
import { useTranslation } from "react-i18next";
import PanelSubsList from "./PanelSubsList";
import PanelSubsPaymentMethod from "./PanelSubsPaymentMethod";
import RLEmptyListCard from "../../UI/RLModal/RLEmptyListCard";
import AppContext from "../../../utils/app-context";

const PanelSubs = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const [subsList, setSubsList] = useState({
    paymentMethod: null,
    orders: [],
  });

  const params = useParams();
  // let graveId = params["graveId"];
  let graveId = props.graveId;

  const selectedGrave = props.selectedGrave

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getSubList();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/sub/list") {

        let subs = {
          orders: response.data.orders,
          paymentMethod: response.data.paymentMethod
        };

        if (subs.paymentMethod == null) {
          subs.paymentMethod = {afk: true};
        }

        setSubsList(subs);
        appCtx.hideLoading();
      }
    }
  }, [response, error]);

  useEffect(() => {
    if (endpoint != "first") {
      getSubList();
    }
  }, [graveId]);

  function getSubList() {
    const url = "grave/" + graveId + "/sub/list";
    setEndpoint(url);

    request({
      method: "GET",
      url: url,
    });

    appCtx.showLoading();
  }

  return (
    <Wrapper>
      {/* <section class="rl-admin-content"> */}
        {subsList.paymentMethod && (
          <PanelSubsPaymentMethod item={subsList.paymentMethod} orders={subsList.orders} graveId={graveId}/>
        )}

        {(subsList.orders.length > 0 && subsList.paymentMethod) ? (
          <PanelSubsList items={subsList.orders} paymentMethod={subsList.paymentMethod} graveId={graveId} />
        ) : (
          <RLEmptyListCard />
        )}
      {/* </section> */}
    </Wrapper>
  );
};

export default PanelSubs;
