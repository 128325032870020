import { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper";
import PanelGuardianListItem from "./PanelGuardianListItem";

const PanelGuardianList = props => {
    const { t } = useTranslation();

  const guardians = props.items;
  const [checkbox, setCheckbox] = useState("");

  const checkboxHandler = (event) => {
    event.preventDefault();

    setCheckbox("checked");
  };

  const guardianRemoveHandler = () => {
    props.reloadList();
  };

  let itemList = "";
  if (guardians.length > 0) {
    let first = true;
    itemList = guardians.map((item) => (
      <PanelGuardianListItem
        removeGuardian={guardianRemoveHandler}
        id={item.id}
        key={item.id}
        item={item}
      />
    ));
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                <th className="">{t("general_status")}</th>
                <th className="rl-table-border-l">{t("general_name")}</th>
                <th className="rl-table-border-l">{t("general_email")}</th>
                <th className="w-40 text-right">
                  {/* <a className="rl-table-button disabled">
                    {t("general_remove")}
                  </a> */}
                </th>
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
        </div>
      </div>
    </Wrapper>
    );
}

export default PanelGuardianList;