import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import Wrapper from "../UI/Wrapper"
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import useAxios from "../../hooks/use-axios";
import AppContext from "../../utils/app-context";

const PanelContentTop = props => {
    const location = useLocation();
    const history = useHistory();
    const appCtx = useContext(AppContext);
    const { t } = useTranslation();

    const btnRef = useRef()
    const [disableNextPrev, setDisableNextPrev] = useState({ next: false, prev: false });

    const paths = location.pathname.split("/");

    const rlSideBar = props.rlSideBar
    const itemLength = props.itemLength
    // const testamentStep = props.testamentStep
    const testamentStep = Helpers.getTestamentStep();

    let top = {}
    let title = "";
    let desc = "";


    if (paths[1] == "panel") {
        top.funnel =  { next: {}, prev: {} };

    } else if (paths[1] == "funnel") {
        top.funnel = { next: {}, prev: {} };

        if (paths[3] == "testament") {
            top.funnel.prev = false;
            top.funnel.step = findSelectedGraveFunnelStep("TESTAMENT");

            top.funnel.next.page = "guardian";
            top.funnel.next.id = findSelectedGraveFunnelStep("GUARDIAN").id;

            if (top.funnel.step.status == "IN_PROGRESS" && (testamentStep[rlSideBar.selectedGrave.id] == undefined || testamentStep[rlSideBar.selectedGrave.id]?.stepOneStatus != 'completed') && (props.testaments == undefined || props.testaments?.items?.length == 0)) {
                top.funnel.next.title = "general_skipfornow"
            } else {
                top.funnel.next.title = "general_next"
            }

        } else if (paths[3] == "guardian") {
            top.funnel.prev.title = Helpers.capitalizeAllFirst(t("panel_navigation_testaments"));
            top.funnel.prev.page = "testament";
            top.funnel.prev.id = findSelectedGraveFunnelStep("TESTAMENT").id;

            top.funnel.step = findSelectedGraveFunnelStep("GUARDIAN");

            top.funnel.next = false;
        }
    }

    if (paths[3]) {
        title = Helpers.capitalizeAll(t("panel_content_top_title_" + paths[3]));
        desc = t("panel_content_top_desc_" + paths[3]);
    }

    function findSelectedGraveFunnelStep(stepKey) {
        for (let i = 0; i < rlSideBar.selectedGrave.graveFunnelSteps.length; i++) {
            if (rlSideBar.selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == stepKey) {
                return rlSideBar.selectedGrave.graveFunnelSteps[i]
            }
        }
    }

    const { response, loading, error, request, responseType } = useAxios();

    useEffect(() => {
        if (!error && response !== null && typeof response !== "undefined") {
            if (responseType == "funnel/move-forward-funnel") {
      
              if (rlSideBar.selectedGrave.graveType == "FOR_YOU") {
                history.replace(("/funnel/") + rlSideBar.selectedGrave.id + "/" + top.funnel.next.page);
              }
      
              rlSideBar.funnelMoveForward(rlSideBar, response.data);
      
            } else if (responseType == "funnel/select-funnel-step") {
      
              rlSideBar.funnelMoveForward(rlSideBar, response.data);
              
            }
          } else {
            if(error) {
              btnRef.current?.removeAttribute("disabled");
            }
          }
      
          appCtx.hideLoading();
    }, [response, error]);

    const handleBack = (event) => {
        event.preventDefault();
        
        if(btnRef.current){
            btnRef.current.setAttribute("disabled", "disabled");
        }
    
        setDisableNextPrev({ ...disableNextPrev, prev: true });
    
        history.replace("/funnel/" + rlSideBar.selectedGrave.id + "/" + top.funnel.prev.page);
        postSelectFunnelStep(top.funnel.prev.id)
      }
    const handleSkipForNow = (event) => {
            event.preventDefault();
        
        if(btnRef.current){
            btnRef.current.setAttribute("disabled", "disabled");
        }

        setDisableNextPrev({ ...disableNextPrev, next: true });

        postFunnelMoveForward();
    }

    function postFunnelMoveForward() {

        if (paths[1] == "funnel") {

            let graveId = -1;
            let graveFunnelStepId = -1;
            let skipped = itemLength > 0 ? false : true;

            graveId = top.funnel.step.graveId

            if (top.funnel.step.status == "COMPLETED") {
                history.replace("/funnel/"+ graveId + "/" + top.funnel.next.page);
                postSelectFunnelStep(top.funnel.next.id)
                return;
            }

            const data = {
                graveId: graveId,
                graveFunnelStepId: top.funnel.step.id,
                skipped: skipped,
            };

            request({
                method: "POST",
                url: "funnel/move-forward-funnel",
                data,
            });
        }
    }

    function postSelectFunnelStep(graveFunnelStepId = -1) {
        if (paths[1] == "funnel") {
            let graveId = -1;

            graveId = top.funnel.step.graveId

            const data = {
                graveId: graveId,
                graveFunnelStepId: graveFunnelStepId,
            };

            request({
                method: "POST",
                url: "funnel/select-funnel-step",
                data,
            });
        }
    }

    return (
        <Wrapper>
            <div className="rl-admin-content-top">
                <div className="rl-admin-content-top-nav">

                    {Object.keys(top?.funnel.prev).length ? (
                        <div className="rl-admin-content-top-nav-right">
                            <a href="" onClick={handleBack}><img src="/img/icon-chevron-left.svg" alt="Contents" />{t(top.funnel.prev.title)}</a>
                        </div>
                    ) : (<div></div>)}

                    {Object.keys(top?.funnel.next).length ? (
                        <div className="rl-admin-content-top-nav-left">
                            <a href="" onClick={handleSkipForNow}> {t(top.funnel.next.title)}
                                <img src="/img/icon-chevron-right.svg" alt="Skip for now" />
                            </a>
                        </div>
                    ) : (<div></div>)}
                </div>
                <div className="rl-admin-content-top-text">
                    <div className="rl-admin-content-top-text-header">
                        {title}
                    </div>
                    <div className="rl-admin-content-top-text-p">
                        {desc}
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default PanelContentTop;