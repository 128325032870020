import { useState } from "react";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import RLTextField from "./RLTextField";
import Wrapper from "./Wrapper";

const RLInputDate = (props) => {
  const { t } = useTranslation();

  const [input, setInput] = useState({
    value: props.data.value,
    onChange: props.data.onChange,
  });

  const input_ = props.data;

  function handleChange(event) {
    event.preventDefault();
    const val = event.target.value;

    setInput({
      ...input,
      value: val,
    });

    input.onChange(val);
  }

  var inputLabelProps = {};
  if (props.standart) {
    inputLabelProps = {
      InputLabelProps: {
        shrink: true,
      },
    };
  }

  let inputProps = {};
  if (input_.inputProps) {
    inputProps = input_.inputProps
  }

  let defValue = null;
  if (input_.value != undefined) {
    defValue = input_.value;
  }

  return (
    <Wrapper>
      <RLTextField
        label={Helpers.capitalizeAllFirst(t(props.label))}
        type="date"
        variant="filled"
        fullWidth
        value={defValue}
        defaultValue=''
        onChange={handleChange}
        InputProps={{
          disableUnderline: true,
          // min: "2023-03-15T00:00"
          inputProps : inputProps
        }}
        {...inputLabelProps}
        // {...inputProps}
      />
    </Wrapper>
  );
};

export default RLInputDate;
