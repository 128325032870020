import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../utils/app-context";
import Wrapper from "../../UI/Wrapper";
import Helpers from "../../../utils/Helpers";

const PanelSideBarOwnerTypeSelect = (props) => {

  const appCtx = useContext(AppContext);

  const rlSideBar = props.rlSideBar;
  const [itemGraveType, setItemGraveType] = useState([]);

  const handleGraveTypeChange = (event, graveType) => {
    // event.preventDefault();

    rlSideBar.changeGraveType(rlSideBar, graveType);
    appCtx.setMobileSideBar(false)
  }

  useEffect(() => {
    let _itemGraveType = [];
    const keys = Object.keys(rlSideBar?.sideBar);
    for (const key of keys) {

      let _graveType = key;
      let _class = "";
      let _name = "";
      let _img = "";

      if (_graveType == "FOR_YOU") {
        _class = "-for-you";
        _name = Helpers.capitalizeAll(t("panel_sidebar_foryou"))
        _img = "/img/icon-user.svg";
      } else if (_graveType == "FOR_BE_LOVED") {
        _class = "-for-beloved";
        _name = Helpers.capitalizeAll(t("panel_sidebar_forbeloved"))
        _img = "/img/icon-heart.svg";
      } else if (_graveType == "ADMIN") {
        _class = "-admin";
        _name = Helpers.capitalizeAll(t("panel_sidebar_admin"))
        _img = "/img/icon-heart.svg";
      }

      _itemGraveType.push(
        <li
          className={`${props.selectClass + "_option"}`}
          onClick={(event) => handleGraveTypeChange(event, _graveType)}
          id={_graveType}
          key={_graveType}
        >
          <input
            className={`${props.selectClass + "_input"}`}
            type="radio"
            name={`${props.rlClass}`}
            id={`${props.rlClass + _class}`}
            defaultChecked={rlSideBar.selectedGraveType == _graveType}
          />
          <label
            className={`${props.selectClass + "_label"}`}
            htmlFor={`${props.rlClass + _class}`}
          >
            <img src={_img} alt="" /> {_name}
          </label>
        </li>
      )
    }
    setItemGraveType(_itemGraveType)
  }, [rlSideBar]);


  return (

    <Wrapper>
      {(Object.keys(rlSideBar.selectedGrave).length > 0 && Object.keys(rlSideBar.sideBar).length > 0 && itemGraveType.length > 0) && (
        <ul className={`${"rl-user-select"} ${props.selectClass}`}>
          <li>
            <input
              className={`${props.selectClass + "_close"}`}
              type="radio"
              name=""
              id={`${props.rlClass + "-close"}`}
              value=""
            />
            <span className={`${props.selectClass + "_label"} ${"select_label-placeholder"}`}></span>
          </li>

          <li className={`${props.selectClass + "_items"}`}>
            <input
              style={{ cursor: "pointer" }}
              className={`${props.selectClass + "_expand"}`}
              type="radio"
              name={`${props.rlClass + ""}`}
              id={`${props.rlClass + "-opener"}`}
            />
            <label
              className={`${props.selectClass + "_closeLabel"}`}
              htmlFor={`${props.rlClass + "-close"}`}
            ></label>

            <ul className={`${props.selectClass + "_options"}`}>

              {itemGraveType}

            </ul>

            <label
              className={`${props.selectClass + "_expandLabel"}`}
              htmlFor={`${props.rlClass + "-opener"}`}
            ></label>
          </li>
        </ul>
      )}
    </Wrapper>
  );
};

export default PanelSideBarOwnerTypeSelect;
