import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";
import RLInput from "../../UI/RLInput";
import RLInputText from "../../UI/RLInputText";
import Wrapper from "../../UI/Wrapper";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const PanelGuardianForm = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const isFunnel = Helpers.isFunnel(location);

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave
  let selectedGraveFunnelSteps = undefined;

  const params = useParams();
  let graveId = params["graveId"];
  const guardianCount = props.count;

  let btnRef = useRef();
  const [disableNextPrev, setDisableNextPrev] = useState({next: false, prev: false});

  const [name, setName] = useState({
    value: "",
    isValid: true,
    validation: ["empty","min", "max"],
    onChange: handleNameChange,
  });
  const [surname, setSurname] = useState({
    value: "",
    isValid: true,
    validation: ["empty","min", "max"],
    onChange: handleSurnameChange,
  });
  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "email","min", "max"],
    onChange: handleEmailChange,
  });
  const [message, setMessage] = useState({
    value: "",
    isValid: true,
    validation: ["empty","min"],
    onChange: handleMessageChange,
  });

  useEffect(() => {
    setMessage({ ...message, value: t("guardian_form_defaultMessage")});
  },[t("guardian_form_defaultMessage")])

  function handleNameChange(value, isValid = false) {
    setName({ ...name, value: value, isValid: isValid });
    btnRef.current.removeAttribute("disabled");
  }

  function handleSurnameChange(value, isValid = false) {
    setSurname({ ...surname, value: value, isValid: isValid });
    btnRef.current.removeAttribute("disabled");
  }

  function handleEmailChange(value, isValid = false) {
    setEmail({ ...email, value: value, isValid: isValid });
    btnRef.current.removeAttribute("disabled");
  }

  function handleMessageChange(value, isValid = false) {
    setMessage({ ...message, value: value, isValid: isValid });
    btnRef.current.removeAttribute("disabled");
  }

  function isValidForm() {
    let isValidFormName = name.isValid;
    if (isValidFormName && name.validation.includes("empty")) {
      isValidFormName = name.value == "" ? false : true;
      setName({ ...name, isValid: isValidFormName });
    }

    let isValidFormSurname = surname.isValid;
    if (isValidFormSurname && surname.validation.includes("empty")) {
      isValidFormSurname = surname.value == "" ? false : true;
      setSurname({ ...surname, isValid: isValidFormSurname });
    }

    let isValidFormEmail = email.isValid;
    if (isValidFormEmail && email.validation.includes("empty")) {
      isValidFormEmail = email.value == "" ? false : true;
      setEmail({ ...email, isValid: isValidFormEmail });
    }

    let isValidFormMessage = message.isValid;
    if (isValidFormMessage && message.validation.includes("empty")) {
      isValidFormMessage = message.value == "" ? false : true;
      setMessage({ ...message, isValid: isValidFormMessage });
    }

    return (
      isValidFormName &&
      isValidFormSurname &&
      isValidFormEmail &&
      isValidFormMessage
    );
  }

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (!error && response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/assignment/guardian/assign") {
        setName({ ...name, value: "" });
        setSurname({ ...surname, value: "" });
        setEmail({ ...email, value: "" });
        setMessage({ ...message, value: t("guardian_form_defaultMessage") });

        props.assignGuardian(response.data);
        btnRef.current.removeAttribute("disabled");

      } else if(responseType == "funnel/move-forward-funnel") {
        if(selectedGrave.graveType == "FOR_YOU") {
          history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/kyc");
        } else if(selectedGrave.graveType == "FOR_BE_LOVED") {
        }

        rlSideBar.funnelMoveForward(rlSideBar, response.data);

      } else if (responseType == "funnel/select-funnel-step") {

        rlSideBar.funnelMoveForward(rlSideBar, response.data);

      }

    } else {
      if(error) {
        btnRef.current.removeAttribute("disabled");
      }
    }
  }, [response, error]);

  const postAssignGuardian = (event) => {
    event.preventDefault();

    if(btnRef.current){
      btnRef.current.setAttribute("disabled", "disabled");
    }

    if (isValidForm()) {
      const data = {
        name: name.value,
        surname: surname.value,
        email: email.value,
        message: message.value,
      };

      request({
        method: "POST",
        url: "grave/" + graveId + "/assignment/guardian/assign",
        data,
      });
    }
  };

  const handleBack = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, prev: true });

    history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/admin");
    postSelectFunnelStep("ADMIN")
  }
  const handleSkipForNow = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, next: true });

    postFunnelMoveForward();
  }

  for (let i = 0; i < selectedGrave.graveFunnelSteps.length; i++) {
    if (selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == "GUARDIAN") {
      selectedGraveFunnelSteps = selectedGrave.graveFunnelSteps[i]
    }
  }

  function postFunnelMoveForward() {

    if (isFunnel) {
      history.replace("/panelPayment?graveId=" + graveId + "&graveName=" + selectedGrave.name + "&graveType=" + selectedGrave.graveType);
      return;
    }
  }

  function postSelectFunnelStep(type) {
    if (isFunnel) {
      let graveId = -1;
      let graveFunnelStepId = -1;

      for (let i = 0; i < selectedGrave.graveFunnelSteps.length; i++) {
        if (selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == type) {
          graveId = selectedGrave.graveFunnelSteps[i].graveId
          graveFunnelStepId = selectedGrave.graveFunnelSteps[i].id
        }
      }

      const data = {
        graveId: graveId,
        graveFunnelStepId: graveFunnelStepId,
      };

      request({
        method: "POST",
        url: "funnel/select-funnel-step",
        data,
      });
    }
  }

  const handleCompleteGraveYard = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, next: true });

    postFunnelMoveForward();
  }

  let nextButton = "";
  if(isFunnel) {
    if(selectedGraveFunnelSteps.status == "IN_PROGRESS" && (guardianCount == undefined || guardianCount == 0)) {
      nextButton = "general_skipfornow"
    } else {
      nextButton = "general_next"
    }
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box">
        <form className="rl-admin-add-user-form">
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_name"))} data={name} />
            </div>
          </div>

          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="text"
                label={Helpers.capitalizeFirst(t("general_surname"))}
                data={surname}
              />
            </div>
          </div>

          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText inputType="text" label={Helpers.capitalizeFirst(t("general_email"))} data={email} />
            </div>
          </div>

          <div className="rl-form-group expand-row">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="textArea"
                label={t("admin_form_message")}
                data={message}
              />
            </div>
          </div>

          <div class="expand-row-submit">
            <div class="left"></div>
            <div class="right">
              <button ref={btnRef} className="rl-form-button" onClick={postAssignGuardian}>
                {Helpers.capitalizeFirst(t("general_add"))}
              </button>
              {isFunnel && (
                <a href="" onClick={handleCompleteGraveYard} className="rl-complete-graveyard-button" style={{pointerEvents: disableNextPrev.next ? 'none' : 'auto'}}>{t("panel_navigation_kyc_complete")}</a>
              )}
            </div>
        </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default PanelGuardianForm;
