import { Autocomplete, Chip, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import RLTextField from "./RLTextField";
import RLTextFieldMultiple from "./RLTextFieldMultiple";
import Wrapper from "./Wrapper";

const RLInputSelectStandart = (props) => {
  const { t } = useTranslation();
  const defaultValidation = t("validation_error");

  const input_ = {
    value: props.data.value,
    isValid: props.data.isValid,
    validation: props.data.validation,
    validationMessage: defaultValidation,
    onChange: props.data.onChange,
    onSaveSetting: props.data.onSaveSetting,
    options: props.data.options == undefined ? [] : props.data.options,
  };

  const [input, setInput] = useState(input_);

  let freeSolo = null;
  if (props.standart !== true) {
    freeSolo = true;
  }

  let isMultiple = null;
  if (props.isMultiple === true) {
    isMultiple = true;
  }

  let defaultValue = [];
  if (props.defaultValue != undefined) {
    if (isMultiple) {
      if (props.defaultValue.length > 0) {
        defaultValue = props.defaultValue;
      }
    } else {
      if (props.defaultValue.id && props.defaultValue.name) {
        defaultValue = props.defaultValue;
      }
    }
  }

  function findValidity(val) {
    let isValidVar = true;
    let validMsg = defaultValidation;

    if (input.validation) {
      input.validation.map((validator) => {
        if (validator == "empty") {
          isValidVar = val !== null;

          if (!isValidVar) {
            validMsg = t("form_validation_notempty");
          }

          return { isValidVar: isValidVar, validMsg: validMsg };
        }
      });
    }

    return { isValidVar: isValidVar, validMsg: validMsg };
  }

  function handleChange(event, newValue) {
    event.preventDefault();

    const isValid = findValidity(newValue);

    setInput({
      ...input,
      value: newValue,
      isValid: isValid.isValidVar,
      validationMessage: isValid.validMsg,
    });

    input.onChange(newValue, isValid.isValidVar);
  }

  return (
    <Wrapper>
      <Autocomplete
        id="tags-filled"
        // freeSolo
        freeSolo={freeSolo}
        options={input_.options}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
        // renderInput={(params) => <TextField {...params} label="freeSolo" />}
        renderInput={(params) => (
          <RLTextField {...params} variant="filled" label={props.label} />
        )}
      />
      {!input_.isValid && (
        <div className="error-line">
          <img
            src="../img/icon-circle-info.svg"
            alt={Helpers.capitalizeAllFirst(t("general_email_error"))}
            width="24"
          />
          <span>{input_.validationMessage}</span>
        </div>
      )}
    </Wrapper>
  );
};

export default RLInputSelectStandart;
