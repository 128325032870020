import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";
import PanelSubs from "../../Panel/PanelSubs/PanelSubs";
import RLInputSelect from "../../UI/RLInputSelect";
import Wrapper from "../../UI/Wrapper"

const AccountSettingsSubsInfo = props => {
    const { t } = useTranslation();

    const [graves, setGraves] = useState([]);
    const [selectedGrave, setSelectedGrave] = useState();

    const { response, loading, error, request, responseType } = useAxios();
    const [endpoint, setEndpoint] = useState("first");

    useEffect(() => {
        if (endpoint == "first") {
            getGraveList();
        }

        if (response !== null && typeof response !== "undefined") {
            if (responseType == "grave/list") {

                setGraves(response.data);

                let graveSubs = [];
                let isSelected = false;

                for (const key in response.data) {
                    if (response.data[key].hasSubscription) {
                        graveSubs.push(
                            {
                                id: response.data[key].id,
                                name: response.data[key].name
                            }
                        );

                        if (!isSelected) {
                            isSelected = true;

                            setSelectedGrave(response.data[key])
                        }
                    }
                }

                setGraves(graveSubs);
            }
        }
    }, [response, error]);

    useEffect(() => {

    }, [selectedGrave])

    function getGraveList() {
        const url = "grave/list";
        setEndpoint(url);

        request({
            method: "POST",
            url: url,
        });
    }

    function gravesFilterHandler(value) {
        if (value != null) {
            setSelectedGrave(value);
        }

    }

    const handlePaymentInfo = event => {
        event.preventDefault();
        props.handlePageType(undefined);
    }

    return (
        <Wrapper>

            {graves.length > 0 && (
                <Wrapper>

                    {/* <div style={{ margin: "0 0 40px", padding: "0 0 16px", borderBottom: "1px solid #E9EBF3" }}></div> */}
                    {/* <h2 className="h1">{Helpers.capitalizeAll(t("panel_navigation_subsInfo"))}</h2> */}
                    <h2 className="h1">{t("accountSettings_payment_paymentInfo")}</h2>
                    <div className="column">
                        <div className="rl-form-group">
                            <div className="rl-form-group-material" style={{ width: "300px" }}>
                                <RLInputSelect
                                    label={t("accountSettings_subs_selectGrave")}
                                    defaultValue={selectedGrave}
                                    standart={true}
                                    onChange={gravesFilterHandler}
                                    options={graves}
                                />
                            </div>
                        </div>
                    </div>

                    <PanelSubs graveId={selectedGrave.id} />
                </Wrapper>
            )}
            <div className="left">
                <a
                    href=""
                    onClick={handlePaymentInfo}
                    rel=""
                    className="rl-panel-wizard-btn-skip w-icon left"
                    style={{fontWeight:"bold"}}
                >
                    <img src="/img/icon-chevron-right.svg" alt="" />{t("general_back")}
                </a>
            </div>

        </Wrapper>
    )
}

export default AccountSettingsSubsInfo;