import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const RLTextArea = styled((props) => (
  <TextField InputProps={
    { disableUnderline: true }} {...props} 
    />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #CECED6",
    borderRadius: 0,
    overflow: "hidden",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      backgroundColor: "transparent",
      bottom: "32px",
      color: "#646672",
    },
    color: "#999AA7",
  },
}));

export default RLTextArea;
