import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../../hooks/use-axios";
import AppContext from "../../../../utils/app-context";
import Wrapper from "../../../UI/Wrapper";

const BOGraveDetailKycItem = (props) => {
  const {t} = useTranslation();
  const params = useParams();
  const appCtx = useContext(AppContext);

  const kyc = props.item;

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/kyc/accept/" + kyc.id
      ) {
        props.reloadList();

      } else if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/kyc/reject/" + kyc.id
      ) {
        props.reloadList();

      } else if (responseType == "backOffice/grave/" + params["graveId"] + "/kyc/view/" + kyc.guid) {
        const type = "application/octet-stream";
        const blob = new Blob([response], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = kyc.originalFileName;
        link.click();
      }
    }
  }, [response, error]);

  // const handleReject = (comment) => {

  //   let url = "backOffice/grave/" + params["graveId"] + "/kyc/reject/" + kyc.id;
  //   let data = {
  //     comment: comment
  //   };

  //   request({
  //     method: "POST",
  //     url: url,
  //     data,
  //   });
  // };

  function handleKycReject () {
    const kycRejectModal = {
      type: "kycReject",
      show: true,
      params: {
        title: t("admin_modal_remove_title"),
        description: t("admin_modal_remove_desc"),
        buttonName: t("admin_modal_remove_button"),
        kycId: kyc.id,
        graveId: kyc.graveId,
        reloadList: props.reloadList
      },
      // onConfirm: handleReject,
    };
    appCtx.showModal(kycRejectModal);
  }

  const handleAproveReject = (event, type) => {
    event.preventDefault();

    let url = "";
    let data = {};

    if (type == "APPROVED") {
      url = "backOffice/grave/" + params["graveId"] + "/kyc/accept/" + kyc.id;

      request({
        method: "POST",
        url: url,
        data,
      });
    } else if (type == "DECLINED") {
      // url = "backOffice/grave/" + params["graveId"] + "/kyc/reject/" + kyc.id;
      handleKycReject();
    }

    
  };

  const handleContentOpen = (event) => {
    event.preventDefault();

    request({
      method: "GET",
      url: "backOffice/grave/" + params["graveId"] + "/kyc/view/" + kyc.guid,
      responseType: "blob",
    });
  };

  

  return (
    <Wrapper>
      <tr>
        <td className="fw-600">{kyc.contentType}</td>
        <td className="rl-table-border-l fw-600">{kyc.fileType}</td>
        <td className="rl-table-border-l fw-600">{kyc.kycStatus}</td>
        <td className="w-40 text-right">
          <a href="" onClick={handleContentOpen} className="rl-link-black">
            <img
              src="/img/icon-download.svg"
              alt="Download"
              width="20"
              height="20"
            />{" "}
            {t("general_download")}
          </a>
          {(kyc.kycStatus == "WAITING") && (
            <a
              href=""
              onClick={(event) => handleAproveReject(event, "APPROVED")}
              className="rl-table-button blue"
            >
              {t("general_aprove")}
            </a>
          )}
          {kyc.kycStatus == "WAITING" && (
            <a
              href=""
              onClick={(event) => handleAproveReject(event, "DECLINED")}
              rel="modal:open"
              className="rl-table-button red"
            >
              {t("general_reject")}
            </a>
          )}
        </td>
      </tr>
    </Wrapper>
  );
};

export default BOGraveDetailKycItem;
