import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Wrapper from "../../UI/Wrapper";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Helpers from "../../../utils/Helpers";

const PanelGuardianListItem = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const location = useLocation();

  const params = useParams();
  let graveId = params["graveId"];
  const btnRef = useRef();

  const isFunnel = Helpers.isFunnel(location);
  const guardian = props.item;

  const [checkbox, setCheckbox] = useState("");

  const { response, loading, error, request, responseType } = useAxios();

  const checkboxHandler = (event) => {
    event.preventDefault();

    setCheckbox("checked");
  };

  const postAssignToAdmin = (event) => {
    event.preventDefault();

    request({
      method: "POST",
      url: "grave/" + graveId + "/assignment/admin/assign/" + guardian.id,
    });
  };

  const handleAssignToAdmin = (event) => {
    event.preventDefault();

    const verseDetailModal = {
      type: "confirmationModal",
      show: true,
      params: {
        title: t("guardian_modal_assign_title"),
        description: t("guardian_modal_assign_desc", {
          name: guardian.name + " " + guardian.surname,
        }),
        confirmButton: t("guardian_modal_assign_button"),
      },
      onConfirm: postAssignToAdmin,
    };
    appCtx.showModal(verseDetailModal);
  };

  const postResendGuardian = (event) => {
    event.preventDefault();

    if (btnRef.current.attributes.disabled != undefined) {
      return;
    }

    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }

    request({
      method: "POST",
      url: "grave/" + graveId + "/assignment/" + guardian.id + "/resend",
    });
  };

  const postRemoveGuardian = (event) => {
    event.preventDefault();

    const data = [guardian.id];

    request({
      method: "DELETE",
      url: "grave/" + graveId + "/assignment/guardian/remove",
      data,
    });
  };

  const handleRemoveConfirm = (event) => {
    postRemoveGuardian(event);
  };

  const handleRemove = (event) => {
    event.preventDefault();

    const verseDetailModal = {
      type: "confirmationModal",
      show: true,
      params: {
        title: t("guardian_modal_remove_title"),
        description: t("guardian_modal_remove_desc", {
          name: guardian.name + " " + guardian.surname,
        }),
        confirmButton: t("guardian_modal_remove_button"),
      },
      onConfirm: handleRemoveConfirm,
    };
    appCtx.showModal(verseDetailModal);
  };

  useEffect(() => {
    if (error) {
    } else if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/assignment/guardian/remove") {
        props.removeGuardian();
      } else if (
        responseType ==
        "grave/" + graveId + "/assignment/admin/assign/" + guardian.id
      ) {
        appCtx.addToast(t("guardian_list_add_admin_message"), "green");
        props.removeGuardian();
      } else if (
        responseType ==
        "grave/" + graveId + "/assignment/" + guardian.id + "/resend"
      ) {
        appCtx.addToast(t("general_resent"), "green");
        btnRef.current.removeAttribute("disabled");
      }
    }
  }, [response, error]);

  return (
    <Wrapper>
      <tr>
        {/* <td>
          <input
            onChange={checkboxHandler}
            checked={checkbox}
            type="checkbox"
            className="form-checkbox"
          />
        </td> */}

        <td className="fw-600">{t("general_enum_" + guardian.inviteStatus)}</td>

        <td className="rl-table-border-l fw-600">
          {guardian.name + " " + guardian.surname}
        </td>

        <td className="rl-table-border-l">{guardian.email}</td>

        <td className="w-40 text-right">
          <div class="w-40" style={{ minWidth: "480px" }}>

            {/* {(!guardian.graveAdmin) && (
              <a
                href=""
                className="rl-link-black"
                rel="modal:open"
                onClick={handleAssignToAdmin}
              >
                <img src="/img/icon-plus.svg" alt="Add to Guardian" />{" "}
                {t("guardian_list_add_admin")}
              </a>
            )} */}


            {(guardian.inviteStatus != "ACCEPTED" && !isFunnel) && (
              <a
                href=""
                onClick={postResendGuardian}
                className="rl-table-button blue"
                ref={btnRef}
              >
                {t("general_resend")}
              </a>
            )}

            <a href="" className="rl-table-button red" onClick={handleRemove}>
              {t("general_remove")}
            </a>
          </div>
        </td>
      </tr>
    </Wrapper>
  );
};

export default PanelGuardianListItem;
