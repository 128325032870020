import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import RLEmptyListCard from "../../UI/RLModal/RLEmptyListCard";
import Wrapper from "../../UI/Wrapper";
import PanelContentForm from "./PanelContentForm";
import PanelContentList from "./PanelContentList";
import PanelContentTop from "../PanelContentTop";

const PanelContent = (props) => {
  const params = useParams();

  const [RLVerses, setRLVerses] = useState([]);
  const [data, setData] = useState({
    contents: [],
    RLVerses: [],
    avaliableDiskSize: 0,
  });
  const [reload, setReload] = useState(false);

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (params["graveId"] != undefined) {
      getVerseList();
      getContentList();
    }
  }, [selectedGrave]);

  useEffect(() => {
    // if (endpoint == "first") {
    //   if (params["graveId"] != undefined) {
    //     getVerseList();
    //     getContentList();
    //   }
    // }
    if (response !== null && typeof response !== "undefined") {
      //   setData(response);

      let RLVerses_ = data.RLVerses;
      let contents = data.contents;
      let avaliableDiskSize = data.avaliableDiskSize;

      if (responseType == "grave/" + params["graveId"] + "/content/list") {
        const loadedContents = [];

        const reponseContents = response.data.contents;

        if (reponseContents.length > 0) {
          for (const key in reponseContents) {
            loadedContents[key] = reponseContents[key];
          }
        }

        contents = loadedContents;
        avaliableDiskSize = response.data.availableDiskSize;

        setData({
          contents: contents,
          RLVerses: data.RLVerses,
          avaliableDiskSize: avaliableDiskSize,
        });
      } else if (responseType == "grave/" + params["graveId"] + "/verse/list") {
        if (response.data.length > 0) {
          for (const key in response.data) {
            RLVerses_[key] = {
              id: response.data[key].id,
              name: response.data[key].verse.name,
            };
          }

          // setData({ contents: data.contents, RLVerses: RLVerses, avaliableDiskSize: data.avaliableDiskSize });
          setRLVerses(RLVerses_);
        }
      }

      // setData({ contents: contents, RLVerses: RLVerses, avaliableDiskSize: avaliableDiskSize });
    }
  }, [response, error, params]);

  const getVerseList = (data) => {
    const url = "grave/" + params["graveId"] + "/verse/list";
    setEndpoint(url);

    request({
      method: "GET",
      url: url,
    });
  };

  function getContentList(data = {}) {
    const url = "grave/" + params["graveId"] + "/content/list";
    setEndpoint(url);

    // const filters = {};

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  const listFilterChangeHandler = (data) => {
    let filter = {};
    if (data.verse != undefined) {
      filter = Object.assign(filter, { graveVerseId: data.verse.id });
    }
    if (data.type != undefined) {
      filter = Object.assign(filter, { contentType: data.type.id });
    }

    getContentList(filter);
  };

  return (
    <Wrapper>
      <section className="rl-admin-content">

        <PanelContentTop rlSideBar={rlSideBar} />

        <div className="rl-admin-content-box">
          <div className="rl-upload-storage-left">
            {t("content_form_remainingsize")}
            {": "}
            <span>
              {(data.avaliableDiskSize / (1024 * 1024 * 1024)).toFixed(2)} GB
            </span>
          </div>

          {/* {RLVerses.length > 0 && ( */}
            <PanelContentForm
            RLVerses={RLVerses}
            reloadList={() => {
              getContentList();
            }}
            rlSideBar={rlSideBar}
            count={data.contents.length}
          />
          {/* )} */}
          
        </div>

        <PanelContentList
            reloadList={() => {
              setReload(!reload);
            }}
            RLVerses={data.RLVerses}
            items={data.contents}
            onListFilterChange={listFilterChangeHandler}
          />
      </section>
    </Wrapper>
  );
};

export default PanelContent;
