import { useState } from "react";
import { useTranslation } from "react-i18next";
import RLInputSelect from "../../UI/RLInputSelect";
import Wrapper from "../../UI/Wrapper";
import PanelKycListItem from "./PanelKycListItem";

const PanelKycList = (props) => {
  const { t } = useTranslation();

  // const [documentType, setDocumentType] = useState(null);
  const [listFilters, setlistFilter] = useState({ kyc: null, type: null });

  const kycs = props.items.kycs;
  const kycStatus = props.items.kycStatus;

  let itemList = "";
  if (kycs != undefined) {
    if (kycs.length > 0) {
      itemList = kycs.map((item) => (
        <PanelKycListItem id={item.id} key={item.id} item={item} reloadList={props.reloadList} />
      ));
    }
  }

  function handleDocumentType(value) {
    setlistFilter({
      kyc: value,
      type: listFilters.type,
    });
    props.onListFilterChange({ kyc: value, type: listFilters.type });
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <form
          action=""
          className="rl-bo-users-search-form rl-table-admin-bottom-filters"
        >
          <div className="column">
            <div className="column-2">
              {/* <div className="rl-form-group">
                <div className="rl-form-group-material">

                  <RLInputSelect
                    label={t("kyc_form_document")}
                    defaultValue={listFilters.kyc}
                    standart={true}
                    onChange={handleDocumentType}
                    options={props.documentTypes}
                  />

                </div>
              </div> */}
            </div>
          </div>
          <div className="column"></div> 
          <div className="column">
            <div className="kyc-text-desc">{t("kyc_status")} {t("general_enum_"+kycStatus)}</div>
          </div>
        </form>
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                {/* <th>
                  <input type="checkbox" className="form-checkbox" />
                </th> */}
                <th className="">{t("general_file_type")}</th>
                {/* <th className="w-20 rl-table-border-l">NAME</th> */}
                <th className="rl-table-border-l">{t("kyc_isApproved")}</th>
                <th className="rl-table-border-l">{t("general_link")}</th>
                {/* <th className="w-40 text-right">
                  <a className="rl-table-download-btn disabled">
                    <img
                      src="/img/icon-download.svg"
                      alt="Download"
                      width="20"
                      height="20"
                    />{" "}
                    Download
                  </a>
                </th> */}
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default PanelKycList;
