// import jsPDF from "jspdf";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";

const PanelSubsListItem = (props) => {
  const params = useParams();
  const { t } = useTranslation();

  const sub = props.item;
  const paymentMethod = props.paymentMethod;
  const graveId = props.graveId

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (
      responseType ==
      "grave/" + graveId + "/sub/invoice/" + sub.id
    ) {
      const type = "application/octet-stream";
      const blob = new Blob([response], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "invoice.pdf";
      link.click();
    }
  }, [response, error]);

  const handleContentOpen = (event) => {
    event.preventDefault();

    request({
      method: "GET",
      url: "grave/" + graveId + "/sub/invoice/" + sub.id,
      responseType: "blob",
    });
  };

  return (
    <Wrapper>
      <tr>
        {/* <td>
          <input type="checkbox" className="form-checkbox" />
        </td> */}
        <td className="fw-600">{sub.planName}</td>

        <td className="rl-table-border-l fw-600">{t("panelSub_status_"+sub.orderStatus)}</td>

        <td className="rl-table-border-l fw-600">
          {sub.currency == "TRY" ? (sub.productFinalAmount+"₺") : ("$"+sub.productFinalAmount)}  
        </td>

        <td className="rl-table-border-l fw-600">
          {Helpers.formatDate(sub.orderDate)}
        </td>

        {paymentMethod && paymentMethod.afk && (
          <td className="rl-table-border-l fw-600">
            {t("panelSub_paymentMethod_unRegistered")}
          </td>
        )}
        {paymentMethod && !paymentMethod.afk && (
          <td className="rl-table-border-l fw-600">
            {Helpers.formatCreditCardNumber(sub, "span")}
          </td>
        )}

        <td className="w-30 text-right">
          <a
            href=""
            onClick={handleContentOpen}
            className="rl-link-black"
            rel="modal:open"
          >
            <img
              src="/img/icon-download.svg"
              alt="Download"
              width="20"
              height="20"
            />{" "}
            {t("general_download")}
          </a>
        </td>
      </tr>
    </Wrapper>
  );
};

export default PanelSubsListItem;
