import { t } from "i18next";
import { useState } from "react";
import Wrapper from "../UI/Wrapper";

const PanelPaymentPlan = (props) => {
  const plans = props.items;
  // const planOneTime = plans.filter(function (plan) {
  //   return plan.paymentMethod == "ONE_TIME";
  // });

  const planMonthly = plans.filter(function (plan) {
    return plan.installmentPeriod == "MONTHLY";
  });

  const planYearly = plans.filter(function (plan) {
    return plan.installmentPeriod == "YEARLY";
  });

  const [selectedPlan, setSelectedPlan] = useState("MONTHLY");

  const handlePlan = (event, plan) => {
    event.preventDefault();

    if (plan != selectedPlan) {
      setSelectedPlan(plan);
      props.setPlan(plan);
    }
  };

  return (
    <Wrapper>
      <div className={`${"rl-payment-guardian-select-price"} discounted`}>

        {planMonthly.length > 0 && (
          <a
            href=""
            onClick={(event) => handlePlan(event, "MONTHLY")}
            className={selectedPlan == "MONTHLY" ? "active" : ""}
          >
            
            {planMonthly[0].discount ? 
            (
              <Wrapper>
                <div className="previous-price">
                  {planMonthly[0].currency == "TRY" ? (planMonthly[0].oldPrice+"₺") : ("$"+planMonthly[0].oldPrice)}  
                </div>
                <div className="price">
                  {planMonthly[0].currency == "TRY" ? (planMonthly[0].discount.discountedPrice+"₺") : ("$"+planMonthly[0].discount.discountedPrice)}  
                </div>
              </Wrapper>
            ):(
              <Wrapper>
                <div className="price">
                  {planMonthly[0].currency == "TRY" ? (planMonthly[0].price+"₺") : ("$"+planMonthly[0].price)}  
                </div>
              </Wrapper>
            )}

            <div className="time-frame">
                  {t("panelPayment_monthly")}
            </div>

            <div>
              {/* {planMonthly[0].name} */}
              {t("panelPayment_monthly_desc")}
            </div>

            {selectedPlan == "MONTHLY" ? (
              <img
                src="img/icon-check-circle-active.svg"
                alt="check"
                className="active"
              />
            ) : (
              <img
                src="img/icon-check-circle.svg"
                alt="check"
                className="passive"
              />
            )}
          </a>
        )}  

        {planYearly.length > 0 && (
          <a
            href=""
            onClick={(event) => handlePlan(event, "YEARLY")}
            className={selectedPlan == "YEARLY" ? "active" : ""}
          >
            
            {planMonthly[0].discount ? 
            (
              <Wrapper>
                <div className="previous-price">
                  {planYearly[0].currency == "TRY" ? (planYearly[0].oldPrice+"₺") : ("$"+planYearly[0].oldPrice)}  
                </div>
                <div className="price">
                  {planYearly[0].currency == "TRY" ? (planYearly[0].discount.discountedPrice+"₺") : ("$"+planYearly[0].discount.discountedPrice)}  
                </div>
              </Wrapper>
            ):(
              <Wrapper>
                <div className="previous-price">
                  {planYearly[0].currency == "TRY" ? (planYearly[0].oldPrice+"₺") : ("$"+planYearly[0].oldPrice)}  
                </div>

                <div className="price">
                  {planYearly[0].currency == "TRY" ? (planYearly[0].price+"₺") : ("$"+planYearly[0].price)}  
                </div>
              </Wrapper>
            )}

            <div className="time-frame">
              {t("panelPayment_yearly")}
            </div>

            <div>
              {/* {planYearly[0].name} */}
              {t("panelPayment_yearly_desc")}
            </div>
            
            {selectedPlan == "YEARLY" ? (
              <img
                src="img/icon-check-circle-active.svg"
                alt="check"
                className="active"
              />
            ) : (
              <img
                src="img/icon-check-circle.svg"
                alt="check"
                className="passive"
              />
            )}
          </a>
        )}  
    
      </div>
    </Wrapper>
  );
};

export default PanelPaymentPlan;
