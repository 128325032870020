import { useEffect, useState } from "react";
import Wrapper from "../../UI/Wrapper"
import RLInputSelect from "../../UI/RLInputSelect";
import { useTranslation } from "react-i18next";
import Helpers from "../../../utils/Helpers";

const PanelTestamentStepThree = props => {

    const { t } = useTranslation();

    const rlSideBar = props.rlSideBar
    const selectedGrave = rlSideBar.selectedGrave

    const [shareEmail, setShareEmail] = useState([]);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        const _testamentStep = Helpers.getTestamentStep();

        if (_testamentStep[selectedGrave.id].shareEmail != undefined) {
            setShareEmail(JSON.parse(_testamentStep[selectedGrave.id].shareEmail));
        }

    }, [selectedGrave]);

    function isValidForm() {
        if (shareEmail.length > 0) {
            setIsValid(true);
            return true;
        }

        setIsValid(false);
        return false;
    }

    function handleStepThreeSubmit(event) {
        event.preventDefault();

        if (isValidForm()) {

            const _testamentStep = Helpers.getTestamentStep();

            _testamentStep[selectedGrave.id]["step"] = 4;
            _testamentStep[selectedGrave.id]["shareEmail"] = JSON.stringify(shareEmail);
            _testamentStep[selectedGrave.id]["stepThreeStatus"] = "completed";
            Helpers.setTestamentStep(_testamentStep);

            if(_testamentStep[selectedGrave.id].testamentId) {
                props.submitTestament(true);
            } else {
                props.submitTestament();
            }   
        }
    }

    const handleTestamentCancel = (event) => {
        event.preventDefault();

        props.cancelStep(event);
    }

    const handleShareEmailChange = (newValue) => {

        for(let i = 0; i < newValue.length; i++) {
            if(newValue[i].name.trim() == "") {
                setIsValid(false);
                return false;
            }
        }

        const _testamentStep = Helpers.getTestamentStep();
        _testamentStep[selectedGrave.id]["shareEmail"] = JSON.stringify(newValue);
        Helpers.setTestamentStep(_testamentStep);

        setShareEmail(newValue);
        setIsValid(true);
    }

    return (
        <Wrapper>
            <div className="rl-admin-content-box">
                <form onSubmit={handleStepThreeSubmit} class="rl-new-testament-form">
                    <div class="column flex-1">
                        <div class="rl-form-group">
                            <div class="rl-form-group-material">
                                <RLInputSelect
                                    label={t("testement_form_share")}
                                    defaultValue={shareEmail}
                                    onChange={(newValue) => {
                                        if (newValue != undefined) {
                                            handleShareEmailChange(newValue);
                                        }
                                    }}
                                    options={shareEmail}
                                    isMultiple={true}
                                    isFree={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <button class="rl-form-button">{t("general_complete")}</button>
                    </div>
                    {props.testaments.items.length > 0 && (
                        <div class="column">
                            <button onClick={handleTestamentCancel} class="rl-form-button">{t("general_cancel")}</button>
                        </div>
                    )}
                </form>

                {!isValid && (
                    <div className="rl-form-group">
                    <div className="error-line">
                        <img src="/img/icon-circle-info.svg" width="24" />
                        <span>{t("testement_form_validationMessage_stepthree")}</span>
                    </div>
                    </div>
                )}
            </div>
        </Wrapper>
    )
}

export default PanelTestamentStepThree;