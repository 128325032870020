import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AuthContext from "../../../utils/auth-context";
import RLButton from "../../UI/RLButton";
import Wrapper from "../../UI/Wrapper";
import PanelSideBarOwnerTypeSelect from "./PanelSideBarOwnerTypeSelect";
import PanelSideBarVerseList from "./PanelSideBarVerseList";
import globals from "../../../utils/globals";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import useAxios from "../../../hooks/use-axios";

const PanelSideBar = (props) => {
  const params = useParams();
  const appCtx = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const rlSideBar = props.rlSideBar;
  const type = props.type;

  const user = Helpers.getUser();
  const lang_ = Helpers.getLang();
  const [lang, setLang] = useState(lang_);
  const [showLang, setShowLang] = useState(false);
  const [showRightHeader, setshowRightHeader] = useState(appCtx.isMobileSideBar);

  function cahngeLocalizationHandler(lang) {
    setLang(lang);
    i18n.changeLanguage(lang);
    setShowLang(false);
    Helpers.setLang(lang);

    postProfileUpdateLocale(lang);
  }

  const handleLogout = (event) => {
    event.preventDefault();

    appCtx.setMobileSideBar(false)

    appCtx.logout();
    Helpers.removeStorage();

    history.replace("/");
  };

  const handleSettings = (event) => {
    event.preventDefault();

    history.replace("/account");
  };

  function handleCreateGrave(event, type) {

    event.preventDefault();

    if (hasFunnel(rlSideBar?.selectedGraveType) && rlSideBar?.selectedGraveType != "ADMIN") {
      const warninglModal = {
        type: "warningModal",
        show: true,
        params: {
          description: t("warningModal_desc_createGrave"),
        },
      };
      appCtx.showModal(warninglModal);
    } else if (rlSideBar?.selectedGraveType == "ADMIN") {

      let funnel1 = { graveType: "FOR_YOU" };
      let funnel2 = { graveType: "FOR_BE_LOVED" };

      const funenlChooseModal = {
        type: "funnelChoose",
        show: true,
        params: {
          title: t("panel_sidebar_newGrave"),
          funnel1: funnel1,
          button1Name: t("landing_hero_navigation_startLife_foryou"),
          funnel2: funnel2,
          button2Name: t("landing_hero_navigation_startLife_forbeloved"),
        },
        onConfirm: confirmChooseFunnel,
      };
      appCtx.showModal(funenlChooseModal);

    } else {

      const checkMailModal = {
        type: "createGrave",
        show: true,
        params: { graveType: rlSideBar?.selectedGraveType, location: location, history: history },
        onConfirm: handleOnCreateGrave,
      };
      appCtx.showModal(checkMailModal);
    }
  }

  function hasFunnel(type) {

    // const type_ = Helpers.getNormalizedGraveType(type);
    const type_ = type;

    for (let verseIndex in rlSideBar.sideBar[type_]) {
      let graves = rlSideBar.sideBar[type_][verseIndex].graves

      for (let graveIndex in graves) {
        let hasSubscription = graves[graveIndex].hasSubscription;

        if (!hasSubscription) {
          return true;
        }
      }
    }

    return false;
  }

  function hasSubscriptedGrave () {
    
  }

  const handleOnCreateGrave = (path) => {
    window.location.href = path
  }

  const confirmChooseFunnel = (funnel) => {

    if (hasFunnel(funnel.graveType) && funnel.graveType != "ADMIN") {
      const warninglModal = {
        type: "warningModal",
        show: true,
        params: {
          description: t("warningModal_desc_createGrave"),
        },
      };
      appCtx.showModal(warninglModal);

    } else {
      const checkMailModal = {
        type: "createGrave",
        show: true,
        params: { graveType: funnel.graveType, location: location, history: history },
        onConfirm: handleOnCreateGrave,
      };
      appCtx.showModal(checkMailModal);
    }

  }

  const handleAvatarClick = (event) => {
    event.preventDefault();

    history.replace("/account");
    appCtx.setMobileSideBar(false)
  }

  const landingHandler = (event) => {
    event.preventDefault();

    history.replace("/");
  };

  const showRightHeaderHandler = (event) => {
    event.preventDefault();

    appCtx.setMobileSideBar(!showRightHeader)
    setshowRightHeader(!showRightHeader);
  }

  const myDashboardHandler = (event) => {
    event.preventDefault();

    if (appCtx.isLoggedIn) {
      getGraveList()
    }

    appCtx.setMobileSideBar(false)
  };

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/list") {
        for (var grave in response.data) {
          if (response.data[grave].hasSubscription) {
            history.replace("/panel");
            return
          }
        }

        history.replace("/funnel");
      }
    }
  }, [response]);

  function getGraveList() {
    
    const url = "grave/list";

    const data = {};

    request({
      method: "POST",
      url: url,
      data: data
    });
  }

  function postProfileUpdateLocale(lang) {
    const url = "profile/update";
    // setEndpoint(url);

    const data = {
      locale: lang,
    };

    request(
      {
        method: "POST",
        url: url,
        data,
      }
    );
  }

  const handlePublish = event => {
    event.preventDefault();

    const warninglModal = {
      type: "warningModal",
      show: true,
      params: {
        description: t("panel_sidebar_publish_desc"),
      },
    };
    appCtx.showModal(warninglModal);
  }

  return (
    <Wrapper>
      <aside className="rememberland-panel-sidebar">

        <div className="rememberland-panel-sidebar-inner">
          <div className="rememberland-panel-sidebar-user">
            <img
              src={
                user.profilePhoto
                  ? user.profilePhoto
                  : "/img/avatar-placeholder.png"
              }
              alt="user-name"
              className="rl-user-avatar"
            />

            <div className="rememberland-panel-sidebar-user-name">
              {user.name + " " + user.surname} <br />
              <span>{user.email}</span>
            </div>

            {(type != "account")&& (
              <a
                href=""
                style={{ cursor: "pointer" }}
                onClick={handleSettings}
                className="rl-user-settings"
              >
                <img src="/img/icon-settings.svg" alt="" />
              </a>
            )}

          </div>

          {rlSideBar?.selectedGrave != undefined && (
            <Wrapper>
              <PanelSideBarOwnerTypeSelect
                rlSideBar={rlSideBar}
                selectClass={"select-desktop"}
                rlClass={"rl-desktop"}
              />

              <PanelSideBarVerseList
                rlSideBar={rlSideBar}
              />
            </Wrapper>
          )}

          <div className="rememberland-panel-sidebar-footer">

            <Wrapper>
              {(
                (rlSideBar?.selectedGraveType == "FOR_YOU" ||
                  rlSideBar?.selectedGraveType == "FOR_BE_LOVED" ||
                  rlSideBar?.selectedGraveType == "ADMIN") && !rlSideBar.isAccount
              ) && (
                  <Wrapper>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <a
                        href=""
                        className="read-more"
                        onClick={(event) => handleCreateGrave(event)}
                      ><img src="/img/icon-plus.svg" alt="" style={{width:"24px"}}/>
                        {Helpers.capitalizeAll(t("panel_sidebar_newGrave"))}
                      </a>

                      <div style={{width:"24px"}}></div>

                      <a
                        href=""
                        className={`${"read-more"} ${rlSideBar.selectedGrave.hasSubscription ? "" : "disabled"}`}
                        onClick={(event) => handlePublish(event)}
                      ><img src="/img/icon-globe.svg" alt="" />
                        {Helpers.capitalizeAll(t("panel_sidebar_publish"))}
                      </a>
                    </div>
                  </Wrapper>
                )}
            </Wrapper>

            <a
              style={{ cursor: "pointer" }}
              className="rl-logout"
              onClick={handleLogout}
            >
              <img src="/img/icon-log-in.svg" alt="Log out" /> {t("logout")}
            </a>
            <div></div>
          </div>
        </div>
      </aside>

      <aside className={`${"rememberland-panel-sidebar-mobile"} ${appCtx.isMobileSideBar ? "show" : ""}`}>
        <div className="rememberland-panel-sidebar-mobile-header">
          <a href="" onClick={handleAvatarClick} className="mobile-user-avatar">
            <img src={
              user.profilePhoto
                ? user.profilePhoto
                : "/img/avatar-placeholder.png"
            } alt="user-name" width="40px" />
          </a>

          <a href="" onClick={landingHandler}>
            <img src="/img/logo-black.svg" alt="REMEMBERLAND" className="rememberland-logo-white" />
          </a>

          <a href="" onClick={showRightHeaderHandler} className="close-mobile-hamburger">
            <img src="/img/icon-x-close.svg" alt="Close" />
          </a>
        </div>

        <div className="rememberland-panel-sidebar-mobile-body">

          {(!location.pathname.includes("/panel") && !location.pathname.includes("/funnel") && appCtx.isLoggedIn && Helpers.getOnboardStatus() == "COMPLETED") && (
            <a href=""
              onClick={myDashboardHandler}>
              {t("landing_mydashboard")}
            </a>
          )}

          {(location.pathname.includes("/panel") || location.pathname.includes("/funnel")) && (
            <Wrapper>
              {((rlSideBar.selectedGraveType == "FOR_YOU" || rlSideBar.selectedGraveType == "FOR_BE_LOVED" || rlSideBar.selectedGraveType == "ADMIN")
              ) && (
                  <Wrapper>
                    <a
                      href=""
                      className="read-more"
                      onClick={(event) => handleCreateGrave(event)}
                    ><img src="/img/icon-globe.svg" alt="" />
                      {Helpers.capitalizeAll(t("panel_sidebar_newGrave"))}
                    </a>
                  </Wrapper>
                )}

              {rlSideBar.selectedGrave != undefined && (
                <Wrapper>
                  <PanelSideBarOwnerTypeSelect
                    rlSideBar={rlSideBar}
                    selectClass={"select"}
                    rlClass={"rl"}
                  />

                  <PanelSideBarVerseList
                    rlSideBar={rlSideBar}
                  />
                </Wrapper>
              )}
            </Wrapper>
          )}
        </div>

        <div className="rememberland-panel-sidebar-mobile-footer">
          <ul className="rl-lang">
            <li>
              <a onClick={() => cahngeLocalizationHandler("en")} className={`${lang == "en" ? "active" : ""}`}>ENG</a>
            </li>
            <li>
              <a onClick={() => cahngeLocalizationHandler("tr")} className={`${lang == "tr" ? "active" : ""}`}>TR</a>
            </li>
          </ul>
          <a href="" onClick={handleLogout} className="rl-logout">
            <img src="/img/icon-log-in.svg" alt="Log out" /> {t("logout")}
          </a>
        </div>
      </aside>
    </Wrapper>
  );
};

export default PanelSideBar;
