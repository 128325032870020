import { useContext, useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import CardListNoRecord from "../../UI/CardListNoRecord";
import Wrapper from "../../UI/Wrapper";
import BOUserForm from "./BOUserForm";
import BOUserList from "./BOUserList";

const BOUser = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const [users, setUsers] = useState([]);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (endpoint == "first") {
      getBOUser();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "backOffice/user/list") {
        setUsers(response.data);
        appCtx.addToast(t("list_search_complete", {count: response.data.length}), "green");
        appCtx.hideLoading();
      }
    }
  }, [response, error, reload]);

  function handleSearch(data) {
    getBOUser(data);
  }

  function getBOUser(filter = {}) {
    const url = "backOffice/user/list";
    setEndpoint(url);

    const data = filter;

    appCtx.showLoading();
    request({
      method: "POST",
      url: url,
      data,
    });
  }

  return (
    <Wrapper>
      <section className="rl-admin-content">
        <BOUserForm handleSearch={handleSearch} />

        {users.length > 0 ? <BOUserList items={users} /> : <CardListNoRecord />}
        
      </section>
    </Wrapper>
  );
};

export default BOUser;
