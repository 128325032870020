import { useContext } from "react";
import Wrapper from "../Wrapper";
import { useTranslation } from "react-i18next";
import RLButton from "../RLButton";
import AppContext from "../../../utils/app-context";

const RLModalWarning = props => {

    const appCtx = useContext(AppContext);
    const { t } = useTranslation();

    const modal = props.modal;
    const params = modal.params;

    const handleClose = (event) => {
        event.preventDefault();

        appCtx.hideModal();
    };

    const handleOnConfirm = (event) => {
        event.preventDefault();

        modal.onConfirm();

        appCtx.hideModal();
    };

    return (
        <Wrapper>
            <div className="text-center">

                <div className="modal-header-desc">
                    {" "}
                    {params.description != undefined
                        ? params.description
                        : "warning"}{" "}
                </div>

                <form onSubmit={(event) => (modal.onConfirm ? handleOnConfirm(event) : handleClose(event))} className="rl-grave-name">
                    <div className="rl-form-group">
                        <div className="rl-form-group-material"></div>
                    </div>
                    <RLButton className="reset-pass-btn" text={t("modal_checkmail_goto")} />
                </form>
            </div>
            {(params.enableClose != false) && (
                <a
                    rel="modal:close"
                    className={props.closeClassName}
                    onClick={handleClose}
                >
                    x
                </a>
            )}

        </Wrapper>
    )
}

export default RLModalWarning;