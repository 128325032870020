import Wrapper from "../Wrapper";
import classes from "./RLModal.module.css";
import {isMobile} from 'react-device-detect';

const RLModalVerseVideo = (props) => {
  const modal = props.modal;
  const params = modal.params;

  const handleClose = event => {
    event.preventDefault()
    modal.onClose();
  }

  return (
    <Wrapper>
      {/* <div
        className="modal rememberland-modal rl-verse-modal"
      >
        <div className="rememberland-modal-body w-100"> */}
          <div className="text-center">
            <video autoPlay loop muted playsInline controls style={{width:"100%"}}>
              <source src={params.videoUrl} type={ params.type ? params.type : (isMobile ? "video/mp4" : "video/webm")}/>
              {/* <source src="/img/verse-music.webm" type="video/webm"/> */}
            </video>
          </div>
        {/* </div>
      </div> */}

      <a href="" className={props.closeClassName} onClick={handleClose}>
        x
      </a>
    </Wrapper>
  );
};

export default RLModalVerseVideo;
