import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./utils/i18n";

import "./index.css";
import App from "./App";
import AppProvider from "./utils/AppProvider";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="243113272409-0qgo9milgpqfrrnok17hdv1er1fq3h56.apps.googleusercontent.com">
    <AppProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppProvider>
  </GoogleOAuthProvider>
);
