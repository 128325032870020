import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../hooks/use-axios";
import Helpers from "../../utils/Helpers";
import Wrapper from "../UI/Wrapper";
import PanelMain from "./PanelMain";
import AppContext from "../../utils/app-context";

const PanelNavigation = (props) => {

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const rlSideBar = props.rlSideBar;

  const params = useParams();
  let graveId = params["graveId"];

  let paths = location.pathname.split("/");
  const page = paths[3].toUpperCase();

  const navigationOrder = {
    "FOR_YOU": {
      "PANEL": ["TESTAMENT", "GUARDIAN", "VERSE", "CONTENT", "DIARY"],
      "FUNNEL": ["TESTAMENT", "GUARDIAN"]
    },
    "FOR_BE_LOVED": {
      "PANEL": ["ADMIN", "VERSE", "CONTENT"],
      "FUNNEL": ["ADMIN"]
    },
  };

  let itemList = "";
  let navItemList = {};

  const graveFunnelSteps = rlSideBar.selectedGrave.graveFunnelSteps
  for (const stepIndex in graveFunnelSteps) {
    const stepKey = graveFunnelSteps[stepIndex].funnelStep.stepKey;

    if (stepKey == "ADMIN") {

      navItemList[stepKey] = {
        title: Helpers.capitalizeAll(t("panel_navigation_admins")),
        img: "/img/icon-eye.svg",
      }

    } else if (stepKey == "GUARDIAN") {

      navItemList[stepKey] = {
        title: Helpers.capitalizeAll(t("panel_navigation_guardians")),
        img: "/img/icon-fingerprint.svg",
      }

    } else if (stepKey == "VERSE") {

      navItemList[stepKey] = {
        title: Helpers.capitalizeAll(t("panel_navigation_verses")),
        img: "/img/icon-globe.svg",
      }

    } else if (stepKey == "CONTENT") {

      navItemList[stepKey] = {
        title: Helpers.capitalizeAll(t("panel_navigation_contents")),
        img: "/img/icon-book-open.svg",
      }

    } else if (stepKey == "DIARY") {

      navItemList[stepKey] = {
        title: Helpers.capitalizeAll(t("panel_navigation_diaries")),
        img: "/img/icon-edit-2.svg",
      }

    } else if (stepKey == "TESTAMENT") {

      navItemList[stepKey] = {
        title: Helpers.capitalizeAll(t("panel_navigation_testaments")),
        img: "/img/icon-file.svg",
      }

    } else if (stepKey == "KYC") {

      navItemList[stepKey] = {
        title: Helpers.capitalizeAll(t("panel_navigation_kyc")),
        img: "/img/icon-folder.svg",
      }

    }
  }

  const navClickHandler = (type) => {
    console.log("navClickHandler", type);

    let newPath = ""
    if (!rlSideBar.selectedGrave.hasSubscription) {

      if (!calculateFunnelStatus(type).includes("deactive")) {
        props.handleChangePage(type);
        postSelectFunnelStep(type);
      }


    } else {
      props.handleChangePage(type);
    }
  }

  function calculateFunnelStatus(key) {

    let funnelStatusClass = "";
    if (page == key) {
      funnelStatusClass = "active"
    }

    if (rlSideBar.selectedGrave.hasSubscription) {
      return funnelStatusClass;
    } else {
      for (let i = 0; i < rlSideBar.selectedGrave.graveFunnelSteps.length; i++) {

        if (!navigationOrder[rlSideBar.selectedGrave.graveType]["FUNNEL"].includes(key)) {
          funnelStatusClass = funnelStatusClass + " deactive"
        } else if (rlSideBar.selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == key) {

          if (rlSideBar.selectedGrave.graveFunnelSteps[i].status == "IN_PROGRESS") {
            funnelStatusClass = funnelStatusClass + " completed"
          } else if (rlSideBar.selectedGrave.graveFunnelSteps[i].status == "PENDING") {
            funnelStatusClass = funnelStatusClass + " deactive"
          } else if (rlSideBar.selectedGrave.graveFunnelSteps[i].status == "COMPLETED") {
            funnelStatusClass = funnelStatusClass + " completed"
          }
        }
      }

      return funnelStatusClass;
    }
  }

  const { response, loading, error, request, responseType } = useAxios();

  function postSelectFunnelStep(type) {
    if (!rlSideBar.selectedGrave.hasSubscription) {
      let graveId = -1;
      let graveFunnelStepId = -1;

      for (let i = 0; i < rlSideBar.selectedGrave.graveFunnelSteps.length; i++) {
        if (rlSideBar.selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == type) {
          graveId = rlSideBar.selectedGrave.graveFunnelSteps[i].graveId
          graveFunnelStepId = rlSideBar.selectedGrave.graveFunnelSteps[i].id
        }
      }

      const data = {
        graveId: graveId,
        graveFunnelStepId: graveFunnelStepId,
      };

      request({
        method: "POST",
        url: "funnel/select-funnel-step",
        data,
      });
    }
  }

  return (
    <Wrapper>

      <nav className="rl-admin-tabs rl-custom-scroll">
        <ul>

          {navigationOrder[rlSideBar.selectedGrave.graveType]["PANEL"].map((value) => {
            if (navItemList[value] !== undefined) {
              return (
                <li onClick={() => navClickHandler(value)}>
                  <a href=""
                    onClick={(event) => event.preventDefault()}
                    className={calculateFunnelStatus(value)}>

                    <div>
                      <figure className="left">
                        <img src={navItemList[value].img} alt="" />
                      </figure>

                      {(!navigationOrder[rlSideBar.selectedGrave.graveType]["FUNNEL"].includes(value) && !rlSideBar.selectedGrave.hasSubscription) ? (
                        <figure className="lock right">
                          <img className="lock" src="/img/icon-lock.svg" alt="" />
                        </figure>

                      ) : (<figure></figure>)}
                    </div>

                    <span>{navItemList[value].title}</span>
                  </a>
                </li>
              );
            }
          }
          )}

        </ul>
      </nav>

      {graveFunnelSteps.length > 0 && (
        <PanelMain pageType={page} rlSideBar={rlSideBar} />
      )}

    </Wrapper>
  );
};

export default PanelNavigation;
