import { SplittingJS } from "../UI/Splitting";
import classes from "../../css-panel/style.module.css";
import Image from "../UI/Image";
import Wrapper from "../UI/Wrapper";
import { useContext, useState } from "react";
import AuthContext from "../../utils/auth-context";
import AppContext from "../../utils/app-context";
import Helpers from "../../utils/Helpers";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/use-axios";

const BOHeader = (props) => {
  const appCtx = useContext(AppContext);

  const { i18n } = useTranslation();

  const lang_ = Helpers.getLang();

  const [lang, setLang] = useState(lang_);
  const [showLang, setShowLang] = useState(false);
  const [showRightHeader, setshowRightHeader] = useState(false);

  function showLangHandler() {
    setShowLang(!showLang);
  }

  function cahngeLocalizationHandler(lang) {
    postProfileUpdateLocale(lang)
    
    setLang(lang);
    i18n.changeLanguage(lang);
    setShowLang(false);
    Helpers.setLang(lang)

    console.log("post lang " + lang);
  }

  const { response, loading, error, request } = useAxios();
  function postProfileUpdateLocale(lang) {
    const url = "profile/update";
    // setEndpoint(url);

    const data = {
      locale: lang,
    };

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  function showRightHeaderHandler() {
    setshowRightHeader(!showRightHeader);

    // if(!showRightHeader) {
    //   window.$chatwoot.toggleBubbleVisibility('hide')
    // } else {
    //   window.$chatwoot.toggleBubbleVisibility('show')
    // }
  }

  return (
    <Wrapper>
      <header className={classes["rememberland-header"]}>
        <div className={classes["rl-bo-user-mobile-left"]}></div>
        <a href="">
          <Image
            className={classes["rememberland-logo-white"]}
            src="/img/logo-black.svg"
            alt="Mobile Menu"
          />
        </a>
        <a
          onClick={showRightHeaderHandler}
          className={classes["mobile-hamburger"]}
        >
          <Image src="/img/icon-hamburger.svg" alt="Mobile Menu" />
        </a>
      </header>

      <nav
        className={`${classes["rememberland-nav-top"]} ${classes['rl-bo-users-header']} ${
          showRightHeader ? classes["show"] : ""
        }`}
      >
        <div
          className={`${classes["rememberland-header-right"]} ${
            showRightHeader ? classes["show"] : ""
          }`}
        >
          <div className={classes["rememberland-header-right-mobile-header"]}>
            <a href="" className={classes["mobile-user-avatar"]}>
              <img src="/img/icon-user.svg" alt="User" />
            </a>
            <a href="">
              <img
                src="/img/logo-black.svg"
                alt="REMEMBERLAND"
                className={classes["rememberland-logo-white"]}
              />
            </a>
            <a
              onClick={showRightHeaderHandler}
              className={classes["close-mobile-hamburger"]}
            >
              <img src="/img/icon-x-close.svg" alt="Close" />
            </a>
          </div>
          <div className={`${classes["rl-lang-part"]}`}>
            <a
              className={`${classes["rl-lang"]} ${
                showLang ? classes["open"] : ""
              }`}
              onClick={showLangHandler}
            >
              {lang == "en" ? "ENG" : "TR"}
              <img src="/img/icon-chevron-down.svg" alt="" />
            </a>
            {(showLang || props.showRightHeader) && (
              <ul
                className={`${classes["rl-lang-dropdown"]} ${classes["show"]}`}
              >
                <li>
                  <a
                    onClick={() => cahngeLocalizationHandler("en")}
                    className={`${lang == "en" ? classes["active"] : ""}`}
                  >
                    ENG
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => cahngeLocalizationHandler("tr")}
                    className={`${lang == "tr" ? classes["active"] : ""}`}
                  >
                    TR
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </Wrapper>
  );
};

export default BOHeader;
