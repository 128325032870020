import { useContext, useEffect } from "react";
import Wrapper from "../UI/Wrapper";
import RLButton from "../UI/RLButton";
import { useTranslation } from "react-i18next";
import AppContext from "../../utils/app-context";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Helpers from "../../utils/Helpers";

const PaymentCrateGrave = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const appCtx = useContext(AppContext);

  useEffect(() => { 
    if(Helpers.getOnboardStatus() == "WAITING") {
      window.gtag('event', 'manual_conversion_SIGNUP',
      {
        'email': Helpers.getUser().email,
      });
    }
  }, []);

  function handleCreateGrave (event, type) {
    event.preventDefault();
    const checkMailModal = {
      type: "createGrave",
      show: true,
      params: {graveType: type, location: location, history: history}
    };
    appCtx.showModal(checkMailModal);
  };

  return (
    <Wrapper>
      <section className="rl-select-grave-type">
        <div className="rememberland-landing-for-whom">
          <div className="rememberland-landing-for-whom-wrapper">
            
            <div className="rememberland-landing-for-whom-top">
              <div className="line-1">{Helpers.capitalizeAll(t("payment_create_newGrave"))}</div>
              <div className="line-2">
                {Helpers.capitalizeAll(t("payment_create_graveDesc1"))}
              </div>
              {/* <br />
              <div className="line-2">
                {t("payment_create_graveDesc2")}
              </div> */}
            </div>

            <div className="rememberland-landing-for-whom-bottom">
              
              <div className="rememberland-landing-for-whom-bottom-w-link">
                <img
                  src="img/icon-for-you.svg"
                  alt="For You"
                  className="m-auto"
                />
                <div className="title">{Helpers.capitalizeAll(t("payment_create_fixYou"))}</div>
                <a href="" className="payment" onClick={(event) => handleCreateGrave(event, "FOR_YOU")}>{Helpers.capitalizeAll(t("payment_create_selectSpace"))}</a>
              </div>

              <div className="middle">
                <img src="img/landing-forwhom-seperator.png" alt="" />
              </div>

              <div className="rememberland-landing-for-whom-bottom-w-link">
                <img
                  src="img/icon-for-you-bloved.svg"
                  alt="For You Beloved"
                  className="m-auto"
                />
                <div className="title">{Helpers.capitalizeAll(t("payment_create_fixBeloved"))}</div>
                <a href="" className="payment" onClick={(event) => handleCreateGrave(event, "FOR_BE_LOVED")}>{Helpers.capitalizeAll(t("payment_create_selectSpace"))}</a>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default PaymentCrateGrave;
