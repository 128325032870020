import { useContext } from "react";
import AppContext from "../utils/app-context";
import Helpers from "../utils/Helpers";
import WithCondition from "./WithCondition";

export function WithLoggedIn(Component, onboardStatus = "", onboardTypeRejected = ["GUARDIAN"], acceptedGraveType = []) {
  const appCtx = useContext(AppContext);
  const onboardSts = Helpers.getOnboardStatus();

  let redirect = "/access-denied"
  let condition = false;

  if (appCtx.isLoggedIn && !appCtx.isAdmin && (onboardSts == onboardStatus || onboardStatus == '')) {
    condition = true;
  }

  if (appCtx.isLoggedIn && !appCtx.isAdmin && onboardSts == "WAITING") {
    redirect = "/payment"
  }

  // if (onboardTypeRejected.includes(Helpers.getOnboardType())) {
  //   redirect = "/congrats"
  //   condition = false;
  // }

  const userGraveRoles = Helpers.getUserGraveRoles();
  if(userGraveRoles) {
    if(userGraveRoles.includes(onboardTypeRejected[0])) {
      if (userGraveRoles.includes("OWNER") || userGraveRoles.includes("PURCHASER") || userGraveRoles.includes("ADMIN")) {
        
      } else {
        redirect = "/congrats"
        condition = false;
      }
    }
  }

  // if (acceptedGraveType.length > 0) {
  //   if (!acceptedGraveType.includes(Helpers.getGraveType())) {
  //     redirect = "/access-denied"
  //     condition = false;
  //   }
  // }

  return (
    <WithCondition
      Component={Component}
      condition={condition}
      redirectTo={redirect}
    />
  );
}

export function IfLoggedIn(Component, onboardStatus = "") {
  const appCtx = useContext(AppContext);
  const onboardSts = Helpers.getOnboardStatus();

  if (!appCtx.isLoggedIn) {
    return Component;
  } else {

    let redirect = "/"
    let condition = true;

    if (appCtx.isLoggedIn && !appCtx.isAdmin && onboardSts == "WAITING") {
      redirect = "/payment"
      condition = false;
    }

    return (
      <WithCondition
        Component={Component}
        condition={condition}
        redirectTo={redirect}
      />
    );
  }
}

export function WithAdmin(Component) {
  const appCtx = useContext(AppContext);
  return (
    <WithCondition
      Component={Component}
      condition={appCtx.isLoggedIn && appCtx.isAdmin}
      redirectTo="/access-denied"
    />
  );
}
export const WithLoggedOut = (Component) => {
  const appCtx = useContext(AppContext);
  // return withCondition(Component, !appCtx.isLoggedIn, '/');
  return (
    <WithCondition
      Component={Component}
      condition={!appCtx.isLoggedIn}
      redirectTo="/"
    />
  );
};
