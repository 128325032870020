// import { SplittingJS } from "../UI/Splitting";
import classes from "../../css-landing/style.module.css";
// import "../../css-landing/style.css";
import Image from "../UI/Image";
import Wrapper from "../UI/Wrapper";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RLLang from "../UI/RLLang";
import RLButton from "../UI/RLButton";
import AppContext from "../../utils/app-context";
import Helpers from "../../utils/Helpers";
import useAxios from "../../hooks/use-axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import RLFeatures from "../UI/RLFeatures";

import { isMobile, isTablet } from 'react-device-detect';
import { Height } from "@mui/icons-material";

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const appCtx = useContext(AppContext);
  const { t } = useTranslation();

  const user = Helpers.getUser();

  const [showRightHeader, setshowRightHeader] = useState(false);
  const [listFunnelGrave, setListFunnelGrave] = useState([]);
  const [endpoint, setEndpoint] = useState("first");
  const [startLifeType, setStartLifeType] = useState();

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  // useEffect(() => {
  //   setEndpoint("x")
  // }, [appCtx.isLoggedIn ]);

  const showRightHeaderHandler = (event, show = undefined) => {
    event.preventDefault();

    if (show == undefined) {
      setshowRightHeader(!showRightHeader);

      if (!showRightHeader) {
        // window.$chatwoot.toggleBubbleVisibility('hide')
        document.body.classList.add('body-no-scroll');
      } else {
        // window.$chatwoot.toggleBubbleVisibility('show')
        document.body.classList.remove('body-no-scroll');
      }
    } else {
      setshowRightHeader(show);

      if (!show) {
        // window.$chatwoot.toggleBubbleVisibility('hide')
        document.body.classList.add('body-no-scroll');
      } else {
        // window.$chatwoot.toggleBubbleVisibility('show')
        document.body.classList.remove('body-no-scroll');
      }
    }
  };

  const myDashboardHandler = (event) => {
    event.preventDefault();

    if (appCtx.isAdmin) {
      history.replace("/bo");
      document.body.classList.remove('body-no-scroll');
    } else {
      getGraveList()
    }
  };

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/list-funnel-graves") {

        setListFunnelGrave(response.data)

        if (endpoint == "grave/list") {
          appCtx.hideLoading();
          if (response.data.length == 0) {
            history.replace("/panel");
            document.body.classList.remove('body-no-scroll');
          } else if (response.data.length == 1) {
            history.replace("/funnel/" + response.data[0]?.id)
            document.body.classList.remove('body-no-scroll');
          } else if (response.data.length == 2) {
            handleLoginSuccess("", true, response.data);
          }

        } else if (endpoint == "features") {
          if (response.data.length == 0) {
            handleCreateGrave(startLifeType)
          } else {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].graveType == startLifeType) {
                history.replace("/funnel/" + response.data[i].id);
                appCtx.hideLoading();
                document.body.classList.remove('body-no-scroll');
                return;
              }
            }

            handleCreateGrave(startLifeType)
          }

          appCtx.hideLoading();
        }

      } else if (responseType == "grave/list") {

        if (response.data.length > 0) {

          for (var grave in response.data) {
            if (response.data[grave].hasSubscription) {
              history.replace("/panel");
              document.body.classList.remove('body-no-scroll');
              return
            }
          }

          appCtx.hideLoading();
          postGraveListFunnelGraves()
        }

        appCtx.hideLoading();
      }
    }
  }, [response]);

  function getGraveList() {
    // appCtx.showLoading();
    const url = "grave/list";
    setEndpoint(url);
    const data = {};

    request({
      method: "POST",
      url: url,
      data: data
    });
  }

  function postGraveListFunnelGraves() {
    const url = "grave/list-funnel-graves";

    const data = {};

    request(
      {
        method: "POST",
        url: url,
        data,
      },
    );
  }

  const handleClickToScroll = (event, id) => {
    event.preventDefault();

    var yOffset = -50;
    const element = document.getElementById(id);
    if (element) {
      // var y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      // var y = element.getBoundingClientRect().top;
      // element.scrollIntoView({ top: y, behavior: "smooth" });
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const confirmChooseFunnel = (funnel) => {
    history.replace("/funnel/" + funnel.id);
    document.body.classList.remove('body-no-scroll');
  }

  const handleLoginSuccess = (url, isFUnnel = false, funnels = []) => {
    if (!isFUnnel) {
      history.replace(url);
      document.body.classList.remove('body-no-scroll');
    } else {

      let funnel1 = funnels[0].graveType == "FOR_YOU" ? funnels[0] : funnels[1];
      let funnel2 = funnels[1].graveType == "FOR_BE_LOVED" ? funnels[1] : funnels[0];

      const funenlChooseModal = {
        type: "funnelChoose",
        show: true,
        params: {
          title: t("modal_funnelChoose_title"),
          funnel1: funnel1,
          funnel2: funnel2,
        },
        onConfirm: confirmChooseFunnel,
      };
      appCtx.showModal(funenlChooseModal);
    }

  };
  const handleLogin = (event) => {

    if (event) {
      event.preventDefault();
    }

    const verseDetailModal = {
      type: "login",
      show: true,
      params: {
        onLoginSuccess: handleLoginSuccess,
        location: location
      },
    };
    appCtx.showModal(verseDetailModal);
  };

  const { url } = useRouteMatch();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get("action");
    if (action == "login") {
      handleLogin("");
    } else if (action == "whatisrememberland") {
      const element = document.getElementById("rememberland-what-is");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

    // if (appCtx.isLoggedIn) {
    //   postGraveListFunnelGraves();
    // }

  }, []);

  const onReloadPage = () => {
    history.replace("/");
    document.body.classList.remove('body-no-scroll');
    window.location.reload();
  }

  const handleReadmore = (event) => {
    event.preventDefault();

    if (showRightHeader) {
      // setshowRightHeader(false);
      showRightHeaderHandler(event)
    }

    handleClickToScroll(event, "rememberland-landing-gravyeards")
  }

  const handleWhatIsrememberland = (event) => {
    event.preventDefault();

    if (showRightHeader) {
      // setshowRightHeader(false);
      showRightHeaderHandler(event)
    }

    handleClickToScroll(event, "rememberland-what-is")
  }

  const handleAvatarClick = (event) => {
    event.preventDefault();

    history.replace("/account");
    document.body.classList.remove('body-no-scroll');
  }

  const landingHandler = (event) => {
    event.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLogout = (event) => {
    event.preventDefault();

    if (showRightHeader) {
      // setshowRightHeader(false);
      showRightHeaderHandler(event)
    }

    window.location.reload(false);

    appCtx.logout();
    Helpers.removeStorage();
  };

  const handleFeatureClick = (event, type) => {
    if (event != undefined) {
      event.preventDefault();
    }

    if (type == "FOR_YOU" || type == "FOR_BE_LOVED") {
      if (appCtx.isLoggedIn) {
        setStartLifeType(type);
        setEndpoint("features");
        postGraveListFunnelGraves();
      } else {
        handleLogin(event)
      }
    } else if (type == "GRAVEYARD") {
      handleClickToScroll(event, "rememberland-landing-gravyeards")
      showRightHeaderHandler(event);
    } else {
      handleClickToScroll(event, "rememberland-landing-for-whom")
    }
  }
  const confirmChooseFunnelFeatures = (funnel) => {
    // history.replace("/funnel/" + funnel.id);
    handleFeatureClick(undefined, funnel.graveType)
    setStartLifeType(funnel.graveType)
  }
  const handleChooseFunnel = (event) => {

    event.preventDefault();

    if (appCtx.isLoggedIn) {

      let funnels = [{ 0: {} }, { 1: {} }];
      funnels[0].graveType = "FOR_YOU"
      funnels[1].graveType = "FOR_BE_LOVED"

      const funenlChooseModal = {
        type: "funnelChoose",
        show: true,
        params: {
          title: t("modal_funnelChoose_title"),
          funnel1: funnels[0],
          funnel2: funnels[1],
        },
        onConfirm: confirmChooseFunnelFeatures,
      };
      appCtx.showModal(funenlChooseModal);

    } else {
      handleLogin(event)
    }
  };
  function handleCreateGrave(type) {

    if (Helpers.findTopMenu("ADMIN")) {
      history.replace("/payment");
      document.body.classList.remove('body-no-scroll');
    } else {
      const checkMailModal = {
        type: "createGrave",
        show: true,
        params: { graveType: type, location: location, history: history },
      };
      appCtx.showModal(checkMailModal);
    }
  }

  const handleDetailForLifeType = (event, type) => {
    event.preventDefault();

    props.handleDetailForLifeType(type, true);
    showRightHeaderHandler(event)
  }

  return (
    <Wrapper>
      <header className={"rememberland-header"}>

        <a href="">
          <img src="img/logo-black.svg" onClick={landingHandler} alt="" className={classes["rememberland-logo-white"]} style={{ width: "156px", height: "34px"}} />
        </a>
        <a href="" onClick={showRightHeaderHandler} className={"mobile-hamburger"}>
          <img src="img/icon-hamburger.svg" alt="" style={{ width: "24px", height: "24px"}} />
        </a>
      </header>

      <nav className={`${"rememberland-nav-top wo-anim"} ${showRightHeader ? "show" : ""}`}>

        <div className={`${"rememberland-header-right"} ${showRightHeader ? "show" : ""}`}>

          <div className={"rememberland-header-right-mobile-header"}>
            {(appCtx.isLoggedIn && Object.keys(Helpers.getHasFunnel()).length == 0) && (
              <a href="" onClick={handleAvatarClick} className={"mobile-user-avatar"}>
                <img src={
                  (user.profilePhoto && appCtx.isLoggedIn)
                    ? user.profilePhoto
                    : "/img/avatar-placeholder.png"
                } alt="" width={40} />
              </a>
            )}

            <a href="">
              <img src="img/logo-black.svg" onClick={landingHandler} alt="" className={"rememberland-logo-white"} style={{ width: "156px", height: "34px"}}/>
            </a>
            <a href="" onClick={showRightHeaderHandler} className={"close-mobile-hamburger"}>
              <img src="img/icon-x-close.svg" alt="" />
            </a>
          </div>

          <Wrapper>
            <a href=""
              className="slide-vertical"
              style={{ padding: "" }}
              onClick={!appCtx.isLoggedIn ? handleWhatIsrememberland : handleLogout}
              data-splitting>

              {!appCtx.isLoggedIn ? t("landing_header_whatisrememberland") : t("logout")}

            </a>

            <a href="" onClick={!appCtx.isLoggedIn ? handleLogin : myDashboardHandler} className={`${"login"} ${"slide-vertical"}`} data-splitting>{!appCtx.isLoggedIn ? Helpers.capitalizeAll(t("login")) : t("landing_mydashboard")}</a>
          </Wrapper>

          <a href="" onClick={handleReadmore} className={`${"read-more"} ${"slide-vertical"}`} data-splitting>{t("landing_readmore")}</a>

          {(windowSize.current[0] > 1024) ? (
            <RLFeatures handleDetailForLifeType={props.handleDetailForLifeType} />
          ) : (
            <Wrapper >
              <a className="features-mobile" href="" onClick={event => handleFeatureClick(event, "FOR_YOU")}>{Helpers.capitalizeAll(t("landing_hero_navigation_testament"))}</a>
              <a className="features-mobile" href="" onClick={handleChooseFunnel}>{Helpers.capitalizeAll(t("landing_hero_navigation_memorial"))}</a>
              <a className="features-mobile" href="" onClick={event => handleFeatureClick(event, "GRAVEYARD")}>{Helpers.capitalizeAll(t("landing_hero_navigation_graveyard"))}</a>
              <a className="features-mobile" href="" onClick={event => handleDetailForLifeType(event, "diary")}>{Helpers.capitalizeAll(t("landing_hero_navigation_diary"))}</a>
              <a className="features-mobile" href="" onClick={event => handleFeatureClick(event, "FOR_YOU")}>{Helpers.capitalizeAll(t("landing_hero_navigation_guardian"))}</a>
              <a className="features-mobile" href="" onClick={handleChooseFunnel}>{Helpers.capitalizeAll(t("landing_hero_navigation_verses"))}</a>
            </Wrapper>
          )}

          <RLLang styleClass={classes} showRightHeader={showRightHeader} reloadPage={onReloadPage} />
        </div>
      </nav>
    </Wrapper >
  );
};

export default Header;
