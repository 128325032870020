import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Helpers from "../../../utils/Helpers";
import Wrapper from "../../UI/Wrapper";

const BOGraveListItem = (props) => {

  const history = useHistory();
  const { t } = useTranslation();

  const grave = props.item;

  let verseList = grave.verseList.map((verse) => verse+", ");
  // verseList = verseList.toString().replace(/, /, "");

  const handleDetail = (event) => {
    event.preventDefault();

    history.replace("/bo/grave/"+grave.id)
    // props.history.push({ 
    //   pathname: "bo/grave/"+grave.id,
    //   state: grave
    //  });
  }

  return (
    <Wrapper>
      <tr>
        <td className="fw-600">{grave.graveName}</td>
        <td className="rl-table-border-l fw-600">{Helpers.formatDate(grave.createdDate)}</td>
        <td className="rl-table-border-l fw-600">{verseList}</td>
        <td className="rl-table-border-l fw-600">{grave.paid ? "YES" : "NO"}</td>
        <td className="rl-table-border-l fw-600">{grave.graveType}</td>
        <td className="rl-table-border-l fw-600">{grave.fullName}</td>
        <td className="rl-table-border-l fw-600">{grave.email}</td>
        <td className="rl-table-border-l fw-600">{t("general_enum_"+grave.kycStatus)}</td>
        <td className="w-40 text-right">
          <a href="" onClick={handleDetail} className="rl-table-button blue">
            { t("bo_grave_detail") }
          </a>
        </td>
      </tr>
    </Wrapper>
  );
};

export default BOGraveListItem;