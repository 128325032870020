import { useTranslation } from "react-i18next";
import Wrapper from "./Wrapper";

const CardListNoRecord = (props) => {

const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <tbody>
              <tr>
                <td colSpan="6">
                  <div className="no-content">
                    {t("list_no_record")}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default CardListNoRecord;
