import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import PanelVerseFormItem from "./PanelVerseFormItem";

const PanelVersePopularList = props => {

    const { t } = useTranslation();

    const rlSideBar = props.rlSideBar
    const popularVerses = props.popularVerses
    const graveVerses = props.graveVerses

    let itemList = [];
    if (popularVerses.length > 0) {
        for (const key in popularVerses) {

            const item = popularVerses[key];

            const isSelected = isSelectedVerse(item.id)

            itemList[key] = (
                <PanelVerseFormItem
                    id={item.id}
                    key={item.id}
                    item={item}
                    isSelected={isSelected}
                    reloadList={props.reloadList}
                    type="popular"
                />
            );
        }
    }

    function isSelectedVerse(verseId) {
        let isSelected = false;
        graveVerses?.map((verse) => {
            if (verse.verseId === verseId) {
                isSelected = true
            }
        });
        return isSelected;
    }

    return (
        <Wrapper>
            <div className="rl-admin-content-box mt-16">
                <h3 className="rl-verses-titles">{t("verse_list_popular_header")}</h3>
                <div className="rl-list-all-verses-grid rl-custom-scroll">

                    {itemList}

                </div>
            </div>
        </Wrapper>
    )
}

export default PanelVersePopularList;