import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Wrapper from "../../UI/Wrapper";
import PanelVerseListItem from "./PanelVerseListItem";

const PanelVerseList = (props) => {
  const params = useParams();
  const { t } = useTranslation();

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave
  const graveVerses = props.graveVerses

  const verseRemoveHandler = () => {
    props.onListFilterChange()
  };

  let itemList = "";
  if (graveVerses.length > 0) {
    itemList = graveVerses.map((item) => (
      <PanelVerseListItem
        id={item.id}
        key={item.id}
        graveVerse={item}
        reloadList={props.reloadList}
        rlSideBar={rlSideBar}
      />
    ));
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
          <div className="rl-table-outer rl-custom-scroll">
            <table className="rl-table-admin-bottom">
              <thead>
                <tr>
                  <th className="">{t("general_status")}</th>
                  <th className="rl-table-border-l">{t("verse_form_VerseName")}</th>
                  <th className="w-30 rl-table-border-l">{t("verse_list_capacity")}</th>
                  <th class="w-40 text-right"></th>
                </tr>
              </thead>
              <tbody>
                {itemList}
              </tbody>
            </table>
          </div>
        </div>
    </Wrapper>
  );
};

export default PanelVerseList;
