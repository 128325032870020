import { useTranslation } from "react-i18next";
import Helpers from "../../../utils/Helpers";
import CardListNoRecord from "../../UI/CardListNoRecord";
import Wrapper from "../../UI/Wrapper";
import BOUserListItem from "./BOUserListItem";

const BOUserList = (props) => {

  const {t} = useTranslation();

  const users = props.items;

  let itemList = "";
  if (users.length > 0) {
    itemList = users.map((item) => (
      <BOUserListItem
        id={item.id}
        key={item.id}
        item={item}
      />
    ));
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                <th>{Helpers.capitalizeAll(t("bo_user_form_status"))}</th>
                <th className="rl-table-border-l">{Helpers.capitalizeAll(t("bo_user_form_user"))}</th>
                <th className="rl-table-border-l">{Helpers.capitalizeAll(t("bo_grave_list_email"))}</th>
                <th className="rl-table-border-l">{Helpers.capitalizeAll(t("bo_user_detail_grave_userType"))}</th>
                <th className="rl-table-border-l">{Helpers.capitalizeAll(t("bo_user_list_registerDate"))}</th>
                <th className="w-40 text-right"></th>
              </tr>
            </thead>
            <tbody>
              {itemList}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default BOUserList;
