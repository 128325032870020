import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";
import RLCheckBox from "../../UI/RLCheckBox";
import RLInputSelect from "../../UI/RLInputSelect";
import RLTable from "../../UI/RLTable";
import Wrapper from "../../UI/Wrapper";
import PanelDiaryListItem from "./PanelDiaryListItem";

const PanelDiaryList = (props) => {
  const { t } = useTranslation();

  const [diaries, setDiaries] = useState();
  if (diaries != props.items) {
    setDiaries(props.items);
  }

  const [diariesFilter, setDiariesFilter] = useState([]);
  const [selectedDiariesFilter, setSelectedDiariesFilter] = useState();

  let listCheckboxes_ = [];

  const [listCheckboxes, setListCheckboxes] = useState();
  // const listCheckboxesRef = useRef();
  // listCheckboxesRef.current = listCheckboxes;
  const [itemList, setItemList] = useState();

  const handleListCheckboxClick = (event) => {
    event.preventDefault();
    let isChange = false;
    let checkboxList = {};

    checkboxList = listCheckboxes_;
    // checkboxList = listCheckboxes;

    for (const key in checkboxList) {
      if (
        key === event.target.value &&
        checkboxList[key].isChecked != event.target.checked
      ) {
        checkboxList[key].isChecked = event.target.checked;
        isChange = true;
      }
    }

    if (isChange) {
      listCheckboxes_ = checkboxList;
      setListAndItem(checkboxList);
    }
  };

  const diaryRemoveHandler = () => {
    props.diaryRemoveHandler();
  };

  function handleEdit(diary) {
    console.log("handleEdit list");
    props.handleEdit(diary)
  }

  useEffect(() => {
    let checkboxList = {};
    if (diaries != undefined) {
      if (diaries.length > 0) {
        diaries.forEach((diary) => {
          let isChecked = false;
          if (listCheckboxes != undefined) {
            isChecked = listCheckboxes[diary?.id]?.isChecked;
          }

          checkboxList[diary.id] = {
            value: diary.id,
            isChecked: isChecked,
            onClick: handleListCheckboxClick,
          };
        });
      }
    }

    let diariesFilterLoaded = [];
    const diariesFilter_ = props.diariesFilter
    for (const key in diariesFilter_) {
      diariesFilterLoaded.push(
        {
          id: diariesFilter_[key],
          name: diariesFilter_[key]
        }
      );
    }
    if (diariesFilterLoaded.length > 0) {
      setDiariesFilter(diariesFilterLoaded);
    }


    if (listCheckboxes_ == undefined) {
      listCheckboxes_ = checkboxList;
      setListAndItem(checkboxList);
    } else if (JSON.stringify(checkboxList) != "{}") {
      listCheckboxes_ = checkboxList;
      setListAndItem(checkboxList);
    }
  }, [diaries, listCheckboxes]);

  const setListAndItem = (checkboxList) => {
    // console.log(listCheckboxes_);
    // console.log(listCheckboxes);
    // console.log(itemList);
    if (JSON.stringify(listCheckboxes) != JSON.stringify(checkboxList)) {
      setListCheckboxes(checkboxList);
    }

    let itemList_ = [];
    if (checkboxList != undefined && diaries != undefined) {
      diaries.forEach((diary) => {
        const item = {
          value: diary,
          checkbox: checkboxList[diary.id],
        };

        itemList_.push(
          <PanelDiaryListItem
            diaryRemoveHandler={diaryRemoveHandler}
            id={diary.id}
            key={diary.id}
            item={item}
            handleEdit={handleEdit}
          />
        );
      });

      if (itemList_ != itemList) {
        setItemList(itemList_);
      }
    }
  };

  const tableHeaders = [
    // { className: "", text: "", type: "headerCheckbox" },
    {
      className: "",
      text: t("diary_list_date"),
      type: "header",
    },
    {
      className: "rl-table-border-l",
      text: t("diary_list_text"),
      type: "header",
    }
  ];

  function handleDiaryDateFilter(value) {
    setSelectedDiariesFilter(value);

    if(value != null) {
      props.handleDiaryDateFilter({"diaryDate": value.name});
    } else {
      props.handleDiaryDateFilter();
    }
    
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">

        <form
          action=""
          className="rl-bo-users-search-form rl-table-admin-bottom-filters"
        >
          <div className="column">
            <div className="column">
              <div className="rl-form-group">
                <div className="rl-form-group-material">
                  <RLInputSelect
                    label={Helpers.capitalizeAllFirst(t("diary_list_date"))}
                    defaultValue={selectedDiariesFilter}
                    standart={true}
                    onChange={handleDiaryDateFilter}
                    options={diariesFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                {listCheckboxes != undefined && (
                  <RLTable
                    tableHeaders={tableHeaders}
                    listCheckboxes={listCheckboxes}
                    setListAndItem={setListAndItem}
                  />
                )}
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default PanelDiaryList;
