import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Helpers from "../../utils/Helpers";
import Wrapper from "../UI/Wrapper";

import classes from "../../css-panel/style.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppContext from "../../utils/app-context";
import RLButton from "../UI/RLButton";
import { SplittingJS } from "../UI/Splitting";
import useAxios from "../../hooks/use-axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const PanelPaymentHeader = (props) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  //   const { i18n } = useTranslation();
  const lang_ = Helpers.getLang();
  const user = Helpers.getUser();

  const [lang, setLang] = useState(lang_);
  const [showLang, setShowLang] = useState(false);
  const [showRightHeader, setshowRightHeader] = useState(false);
  const [endpoint, setEndpoint] = useState("first");
  const [listFunnelGrave, setListFunnelGrave] = useState([]);

  const showLangHandler = event => {
    event.preventDefault();
    setShowLang(!showLang);
  }

  function cahngeLocalizationHandler(lang) {
    setLang(lang);
    i18n.changeLanguage(lang);
    setShowLang(false);
    Helpers.setLang(lang);

    postProfileUpdateLocale(lang);
  }

  const myDashboardHandler = (event) => {
    event.preventDefault();

    if (!appCtx.isAdmin) {
      // history.replace("/panel");
      getGraveList()
    }

    appCtx.setMobileSideBar(false)
  };

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/list-funnel-graves") {
        setListFunnelGrave(response.data)

        if (endpoint == "grave/list") {
          appCtx.hideLoading();
          if (response.data.length == 0) {
            history.replace("/panel");
          } else if (response.data.length == 1) {
            history.replace("/funnel/" + response.data[0]?.id)
          } else if (response.data.length == 2) {
            handleLoginSuccess("", true, response.data);
          }
        }
      } else if (responseType == "grave/list") {
        if (response.data.length > 0) {

          for (var grave in response.data) {
            if (response.data[grave].hasSubscription) {
              history.replace("/panel");
              return
            }
          }

          appCtx.hideLoading();
          postGraveListFunnelGraves()
        }

        appCtx.hideLoading();
      }
    }
  }, [response]);

  function postProfileUpdateLocale(lang) {
    const url = "profile/update";
    // setEndpoint(url);

    const data = {
      locale: lang,
    };

    request(
      {
        method: "POST",
        url: url,
        data,
      }
    );
  }

  function getGraveList() {
    // appCtx.showLoading();
    const url = "grave/list";
    setEndpoint(url);
    const data = {};

    request({
      method: "POST",
      url: url,
      data: data
    });
  }

  const confirmChooseFunnel = (funnel) => {
    history.replace("/funnel/" + funnel.id);
  }

  const handleLoginSuccess = (url, isFUnnel = false, funnels = []) => {
    if (!isFUnnel) {
      history.replace(url);
    } else {

      let funnel1 = funnels[0]
      let funnel2 = funnels[1]

      const funenlChooseModal = {
        type: "funnelChoose",
        show: true,
        params: {
          title: t("modal_funnelChoose_title"),
          funnel1: funnel1,
          funnel2: funnel2,
        },
        onConfirm: confirmChooseFunnel,
      };
      appCtx.showModal(funenlChooseModal);
    }

  };

  function postGraveListFunnelGraves() {
    const url = "grave/list-funnel-graves";

    const data = {};

    request(
      {
        method: "POST",
        url: url,
        data,
      },
    );
  }

  const landingHandler = (event) => {
    event.preventDefault();

    history.replace("/");
    appCtx.setMobileSideBar(false)
  };

  const showRightHeaderHandler = (event) => {
    event.preventDefault();
    setshowRightHeader(!showRightHeader);

    appCtx.setMobileSideBar(!showRightHeader)
  }

  const handleAvatarClick = (event) => {
    event.preventDefault();

    history.replace("/account");
    appCtx.setMobileSideBar(false)
  }

  const handleLogout = (event) => {
    event.preventDefault();

    showRightHeaderHandler(event)

    appCtx.logout();
    Helpers.removeStorage();
    history.replace("/");
  };

  const myDashboardHandler2 = (event) => {
    event.preventDefault();

    if (appCtx.isLoggedIn) {
      history.replace("/panel");
    }

    appCtx.setMobileSideBar(false)
  };

  return (
    <Wrapper>
      <header
        className={`${classes["rememberland-header"]} ${classes["rl-select-grave-type-header"]} ${classes["white-header"]}`}
        style={{minHeight:"73px"}}
      >
        {/* <a href="" className={classes["mobile-user-avatar"]}>
          <img src={user.profilePhoto
            ? user.profilePhoto
            : "/img/avatar-placeholder.png"} alt="" width="40" />
        </a>

        <a className={classes["mobile-user-avatar"]}></a> */}

        <a href="" onClick={(event) => event.preventDefault()}>
          <img
            src="img/logo-black.svg"
            onClick={landingHandler}
            alt="REMEMBERLAND"
            className={classes["rememberland-logo-white"]}
          />
        </a>

        <a
          href=""
          onClick={showRightHeaderHandler}
          className={classes["mobile-hamburger"]}
        >
          <img src="img/icon-hamburger.svg" alt="Mobile Menu" />
        </a>
      </header>

      <nav className={classes["rememberland-nav-top"]}>
        <div className={classes["rememberland-header-right"]}>
          <div className={classes["rememberland-header-right-mobile-header"]}>
            {/* <a
              href=""
              onClick={(event) => event.preventDefault()}
              className={classes["mobile-user-avatar"]}
            >
              <img src="img/user-avatar-80.png" alt="User name" width="40" />
            </a> */}
            <a href="" onClick={(event) => event.preventDefault()}>
              <img
                src="img/logo-black.svg"
                onClick={landingHandler}
                alt="REMEMBERLAND"
                className={classes["rememberland-logo-white"]}
              />
            </a>
            <a
              href=""
              onClick={(event) => event.preventDefault()}
              className={classes["close-mobile-hamburger"]}
            >
              <img src="img/icon-x-close.svg" alt="Close" />
            </a>
          </div>

          {/* <a href="" onClick={(event) => event.preventDefault()}>
            {t("landing_header_whatisrememberland")}
          </a> */}

          {Helpers.getOnboardStatus() == "COMPLETED" && (
            <a
              href=""
              onClick={myDashboardHandler}
              className={classes["login"]}
              style={{ marginRight: "20px" }}
            >
              <img src="img/icon-user-2.svg" width="14" alt="MY DASHBOARD" />{" "}
              <span>{t("landing_mydashboard")}</span>
            </a>
          )}

          {/* <a
            href=""
            onClick={(event) => event.preventDefault()}
            className={classes["read-more"]}
          >
            {t("landing_readmore")}
          </a> */}

          <div className={classes["rl-lang-part"]}>
            <a
              href=""
              className={`${classes["rl-lang"]} ${showLang ? classes["open"] : ""
                }`}
              onClick={showLangHandler}
            >
              {lang == "en" ? "ENG" : "TR"}
              <img src="../img/icon-chevron-down.svg" alt="" />
            </a>
            {(showLang || props.showRightHeader) && (
              <ul
                className={`${classes["rl-lang-dropdown"]} ${classes["show"]}`}
              >
                <li>
                  <a
                    href=""
                    onClick={() => cahngeLocalizationHandler("en")}
                    className={`${lang == classes["en"] ? classes["active"] : ""
                      }`}
                  >
                    ENG
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={() => cahngeLocalizationHandler("tr")}
                    className={`${lang == classes["tr"] ? classes["active"] : ""
                      }`}
                  >
                    TR
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>

      <aside className={`${"rememberland-panel-sidebar-mobile"} ${appCtx.isMobileSideBar ? "show" : ""}`}>
        <div className="rememberland-panel-sidebar-mobile-header">
          {/* <a href="" onClick={handleAvatarClick} className="mobile-user-avatar">
            <img src={
              user.profilePhoto
                ? user.profilePhoto
                : "/img/avatar-placeholder.png"
            } alt="user-name" width="40px" />
          </a> */}

          <a href="" onClick={landingHandler}>
            <img src="/img/logo-black.svg" alt="REMEMBERLAND" className="rememberland-logo-white" />
          </a>

          <a href="" onClick={showRightHeaderHandler} className="close-mobile-hamburger">
            <img src="/img/icon-x-close.svg" alt="Close" />
          </a>
        </div>

        <div className="rememberland-panel-sidebar-mobile-body">
          {(appCtx.isLoggedIn && Helpers.getOnboardStatus() == "COMPLETED") && (
            <a href=""
              onClick={myDashboardHandler}>
              {t("landing_mydashboard")}
            </a>
          )}
        </div>

        <div className="rememberland-panel-sidebar-mobile-footer">
          <ul className="rl-lang">
            <li>
              <a onClick={() => cahngeLocalizationHandler("en")} className={`${lang == "en" ? "active" : ""}`}>ENG</a>
            </li>
            <li>
              <a onClick={() => cahngeLocalizationHandler("tr")} className={`${lang == "tr" ? "active" : ""}`}>TR</a>
            </li>
          </ul>
          <a href="" onClick={handleLogout} className="rl-logout">
            <img src="/img/icon-log-in.svg" alt="Log out" /> {t("logout")}
          </a>
        </div>
      </aside>
    </Wrapper>
  );
};

export default PanelPaymentHeader;
