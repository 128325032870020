import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Wrapper from "../components/UI/Wrapper";

const NotFoundPage = props => {
    const history = useHistory();

    return (
        <Wrapper>
            <div className="page-404">
                <img src="/img/404.svg" alt="404" />
                <p>NOT FOUND</p>
                <a onClick={() => { history.replace("/") }}>Homepage</a>
            </div>
        </Wrapper>
    );
}

export default NotFoundPage;