import ChartDoughnut from "../../UI/ChartDoughnut";
import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper";

const PanelGuardianChart = props => {
  const { t } = useTranslation();

    const remainingCount = props.total - props.count

    return (
        <Wrapper>
            <div className="rl-admin-content-box">
            <div className="rl-doughnut-chart">
              <div className="chart-wrapper">
                {/* <canvas id="myDoughnutGraph"></canvas> */}
                <ChartDoughnut count={props.count} total={props.total}/>
                <div className="chart-center">
                  {props.count}<span> / {props.total}</span>
                </div>
              </div>
              <div className="chart-left-count">{t("guardian_chart_desc", {count: props.count})}</div>
            </div>
          </div>
        </Wrapper>
    );
}

export default PanelGuardianChart;