import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BOHeader from "../components/BO/BOHeader";
import BOMain from "../components/BO/BOMain";
import BOSideBar from "../components/BO/BOSideBar";
import Card from "../components/UI/Card";
import Wrapper from "../components/UI/Wrapper";

const BOPage = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [page, setPage] = useState("");

  useEffect(() => {

    // const queryParams = new URLSearchParams(window.location.search);
    // let page_ = props.page;
    // const queryPage = queryParams.get("page");
    // if(queryPage) {
    //   page_ = queryPage;
    // }

    let page_ = props.page;
    page_ = page_==undefined ? "USER" : page_

    if (location.pathname.includes("/bo/users") || location.pathname == "/bo") {
      setPage("USER");
    } else if (location.pathname.includes("/bo/tags")) {
      setPage("TAG");
    } else if (location.pathname.includes("/bo/grave/") || location.pathname.includes("/bo/grave")) {
      setPage("GRAVE");
    } else {
      setPage(page_);
    }
  }, [location]);

  const handlePage = (type) => {
    setPage(type);

    if(type == "USER") {
      history.replace("/bo/users");
    } else if(type == "TAG") {
      history.replace("/bo/tags");
    } else if(type == "GRAVE") {
      history.replace("/bo/grave");
    }

  };

  return (
    <Wrapper>
      <BOHeader />
      <BOSideBar onClickItem={handlePage} page={page} />
      <Card className="rl-admin-panel-content">
        <BOMain pathName={location.pathname} page={page} />
      </Card>
    </Wrapper>
  );
};

export default BOPage;
