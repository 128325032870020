import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AppContext from "../../../utils/app-context";
import Wrapper from "../../UI/Wrapper";
import PanelSideBarGraveList from "./PanelSideBarGraveList";

const PanelSideBarVerseListItem = (props) => {
  const params = useParams();

  const rlSideBar = props.rlSideBar
  const verse = props.verse

  const [verseOpen, setVerseOpen] = useState(true);
  // const [edit, setEdit] = useState(false);

  const clickVerseHandler = (event) => {
    event.preventDefault();
    console.log("clickVerseHandler")

    setVerseOpen(!verseOpen);
  };

  return (
    <Wrapper>
      {verse.id == 0 ? (
        <div
          className={`${"inner"} ${verseOpen ? "show" : ""}`}
          style={{ display: "block" }}
        >
          <PanelSideBarGraveList verse={verse} rlSideBar={rlSideBar} />
        </div>
      ) : (
        <li className={verseOpen ? "active" : ""}>
          <a
            onClick={clickVerseHandler}
            className="rl-accordion-toggle"
            href=""
          >
            {verse.name}
          </a>
          {verseOpen && (
            <div
              className={`${"inner"} ${verseOpen ? "show" : ""}`}
              style={{ display: "block" }}
            >
              <PanelSideBarGraveList verse={verse} rlSideBar={rlSideBar}/>
            </div>
          )}
        </li>
      )}
    </Wrapper>
  );
};

export default PanelSideBarVerseListItem;
