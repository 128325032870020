import Wrapper from "../UI/Wrapper";
import PanelAdmin from "./PanelAdmin/PanelAdmin";
import PanelContent from "./PanelContent/PanelContent";
import PanelDiary from "./PanelDiary/PanelDiary";
import PanelGuardian from "./PanelGuardian/PanelGuardian";
import PanelKyc from "./PanelKyc/PanelKyc";
import PanelSubs from "./PanelSubs/PanelSubs";
import PanelTestament from "./PanelTestament/PanelTestament";
import PanelVerse from "./PanelVerse/PanelVerse";

const PanelMain = props => {

    const pageType = props.pageType;
    const rlSideBar = props.rlSideBar;

    return (
        <Wrapper>
            {pageType === "ADMIN" && (
                <PanelAdmin rlSideBar={rlSideBar}/>
            )}
            {pageType === "GUARDIAN" && (
                <PanelGuardian rlSideBar={rlSideBar}/>
            )}
            {pageType === "CONTENT" && (
                <PanelContent rlSideBar={rlSideBar}/>
            )}
            {pageType === "TESTAMENT" && (
                <PanelTestament rlSideBar={rlSideBar}/>
            )}
            {pageType === "VERSE" && (
                <PanelVerse rlSideBar={rlSideBar}/>
            )}
            {/* {pageType === "SUBS" && (
                // (<p>asdf asdf</p>)
                <PanelSubs selectedGrave={selectedGrave}/>
            )} */}
            {pageType === "KYC" && (
                <PanelKyc rlSideBar={rlSideBar}/>
            )}
            {pageType === "DIARY" && (
                <PanelDiary rlSideBar={rlSideBar}/>
            )}
        </Wrapper>
    );    
}

export default PanelMain;

