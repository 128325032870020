import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import RLEmptyListCard from "../../UI/RLModal/RLEmptyListCard";
import Wrapper from "../../UI/Wrapper";
import PanelAdminChart from "./PanelAdminChart";
import PanelAdminForm from "./PanelAdminForm";
import PanelAdminList from "./PanelAdminList";
import PanelContentTop from "../PanelContentTop";

const PanelAdmin = (props) => {
  const { t } = useTranslation();

  const params = useParams();
  let graveId = params["graveId"];

  const [admins, setAdmins] = useState([]);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");
  const [reload, setReload] = useState(false);

  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave

  useEffect(() => {
    getAdminList();
  }, [graveId]);

  useEffect(() => {

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/assignment/admin/list") {
        const loadedAdmins = [];

        const reponseAdmins = response.data;

        if (reponseAdmins.length > 0) {
          for (const key in reponseAdmins) {
            loadedAdmins[key] = reponseAdmins[key];
          }
        }

        setAdmins(loadedAdmins);
      }
    }
  }, [response, error, reload, graveId]);

  function getAdminList() {
    const url = "grave/" + graveId + "/assignment/admin/list";
    if (endpoint == "first") {
      setEndpoint(url);
    }

    const data = {};

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  const adminAssignHandler = (admin) => {
    getAdminList();
  };

  const adminRemoveHandler = (admin) => {
    getAdminList();
  };

  return (
    <Wrapper>
      <section className="rl-admin-content">

        <PanelContentTop rlSideBar={rlSideBar} itemLength={admins.length} />

        <div className="rl-admin-content-7-3">
          <PanelAdminForm assignAdmin={adminAssignHandler} rlSideBar={rlSideBar} count={admins.length} />
          <PanelAdminChart count={admins.length} total={3} />
        </div>
        {admins.length > 0 ? (
          <PanelAdminList
            // reloadList={() => {
            //   setReload(!reload);
            // }}
            adminRemoveHandler={adminRemoveHandler}
            items={admins}
          />
        ) : (
          // <RLEmptyListCard />
          <div></div>
        )}
      </section>
    </Wrapper>
  );
};

export default PanelAdmin;
