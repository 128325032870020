import Wrapper from "../../../UI/Wrapper";
import BOUserDetailGrave from "./BOUserDetailGrave";
import BOUserDetailPaymentInfo from "./BOUserDetailPaymentInfo";

const BOUserDetailMain = props => {
    return (
        <Wrapper>
            {props.pageType === "PAYMENT_INFO" && (
                <BOUserDetailPaymentInfo />
            )}
            {props.pageType === "GRAVE" && (
                <BOUserDetailGrave />
            )}
        </Wrapper>
    );
}

export default BOUserDetailMain;