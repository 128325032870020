import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../../../hooks/use-axios";
import RLInputSelect from "../../../UI/RLInputSelect";
import Wrapper from "../../../UI/Wrapper";
import BOGraveDetailTestamentItem from "./BOGraveDetailTestamentItem";

const BOGraveDetailTestament = (props) => {
  const { t } = useTranslation();
  const params = useParams();

  const [testaments, setTestaments] = useState([]);
  const [RLInputs, setRLInputs] = useState({
    RLTestaments: [],
    RLEmails: [],
  });
  const [listFilters, setlistFilter] = useState({
    testament: null,
    mail: null,
  });
  const [itemList, setItemList] = useState("");

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getTestamentList();
    }

    if (response !== null && typeof response !== "undefined") {
      if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/testament/list"
      ) {
        if (response.data.items != undefined) {
          if (response.data.items.length > 0) {
            let itemList_ = "";
            itemList_ = response.data.items.map((item) => (
              <BOGraveDetailTestamentItem
                id={item.id + "#" + item.testamentFileId}
                key={item.id + "#" + item.testamentFileId}
                item={item}
              />
            ));

            setItemList(itemList_);
          }
        }

        let RLTestaments_ = [];
        for (const key in response.data.items) {
          RLTestaments_[key] = {
            id: response.data.items[key].id,
            name: response.data.items[key].name,
          };
        }

        let RLEmails_ = [];
        for (const key in response.data.items) {
          let emails = response.data.items[key].emails;
          emails = emails.split(",");

          for (const key in emails) {
            RLEmails_[emails[key]] = {};
            RLEmails_[emails[key]] = { id: emails[key], name: emails[key] };
          }
        }

        let RLEmails__ = [];
        for (const key in RLEmails_) {
          RLEmails__ = RLEmails__.concat({
            id: RLEmails_[key].id,
            name: RLEmails_[key].name,
          });
        }

        setTestaments(response.data);
        setRLInputs({
          RLTestaments: RLTestaments_,
          RLEmails: RLEmails__,
        });
      }
    }
  }, [response, error]);

  function getTestamentList(filter) {
    const url = "backOffice/grave/" + params["graveId"] + "/testament/list";
    setEndpoint(url);

    let data = {};
    if (filter != undefined) {
      data = filter;
    }

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  function testamentFilterHandler(value) {
    setlistFilter({
      testament: value,
      mail: listFilters.mail,
    });
  }

  function mailFilterHandler(value) {
    setlistFilter({
      testament: listFilters.testament,
      mail: value,
    });
  }

  const handleSearch = (event) => {
    event.preventDefault();

    let filter = {};
    if (listFilters.testament != undefined) {
      filter = Object.assign(filter, { testamentId: listFilters.testament.id });
    }
    // if (listFilters.mail != undefined) {
    //     filter = Object.assign(filter, { testamentId: listFilters.testament.id});
    //   }

    getTestamentList(filter);
  };

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <form action="" className="rl-bo-users-search-form">
          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("testement_form_name")}
                  standart={true}
                  onChange={testamentFilterHandler}
                  options={RLInputs.RLTestaments}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={t("Email")}
                  standart={true}
                  onChange={mailFilterHandler}
                  options={RLInputs.RLEmails}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <button onClick={handleSearch} className="rl-form-button">
              Search
            </button>
          </div>
        </form>
      </div>

      <div className="rl-admin-content-box mt-16">
        <div className="rl-table-outer rl-custom-scroll">
          <table className="rl-table-admin-bottom">
            <thead>
              <tr>
                <th>yÜKLE zAMANI</th>
                <th className="rl-table-border-l">Testament name</th>
              </tr>
            </thead>
            <tbody>
              {itemList}
              {/* <tr>
                <td className="fw-600">04.12.2202</td>
                <td className="rl-table-border-l">
                  <a target="_blank" className="rl-content-link">
                    <div className="rl-content-icon video">
                      <img src="img/icon-play-circle.svg" alt="Video" />
                    </div>{" "}
                    youtube/poasmdjs.com
                  </a>
                </td>
              </tr>
              <tr>
                <td className="fw-600">04.12.2202</td>
                <td className="rl-table-border-l">
                  <a target="_blank" className="rl-content-link">
                    <div className="rl-content-icon music">
                      <img src="img/icon-music-note.svg" alt="music" />
                    </div>{" "}
                    audipısoksdl.mp3
                  </a>
                </td>
              </tr>
              <tr>
                <td className="fw-600">04.12.2202</td>
                <td className="rl-table-border-l">
                  <a target="_blank" className="rl-content-link">
                    <div className="rl-content-icon text">
                      <img src="img/icon-type.svg" alt="Text" />
                    </div>{" "}
                    loremipsum.txt
                  </a>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default BOGraveDetailTestament;
