import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/use-axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../utils/app-context";
import Wrapper from "../UI/Wrapper";
import Helpers from "../../utils/Helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { isMobile, isSafari } from 'react-device-detect';
import RenderIfVisible from 'react-render-if-visible'
import { color } from "@mui/system";

const Gravyeards = (props) => {

  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  const [data, setData] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getVerse();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "public/verse") {
        setData(response.data);
      }
    }
  }, [response, error]);

  function getVerse() {
    const url = "public/verse";
    setEndpoint(url);

    request(
      {
        method: "GET",
        url: url,
        data,
      },
      () => { },
      false
    );
  }

  const handleDiscover = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const graveyard = data[slideIndex];

    if (graveyard.videoUrl === null) {
      const verseDetailModal = {
        type: "verseDetail",
        show: true,
        params: {
          versePopular: graveyard,
          from: "landing",
          blockCloserEnabled: true
        },
        className: "rl-verse-modal",
      };
      appCtx.showModal(verseDetailModal);
    } else {
      const verseVideoModal = {
        type: "verseVideo",
        show: true,
        params: {
          videoUrl: isMobile ? graveyard.mobileVideoUrl : graveyard.videoUrl,
          blockCloserEnabled: true
        },
        className: "rl-verse-modal",
        // style: {maxWidth: "1280px !important"},
      };
      appCtx.showModal(verseVideoModal);
    }
  };

  useEffect(() => {
    let itemList_ = [];

    if (itemList.length == 0) {
      data.forEach((graveyard) => {
        const percent =
          ((graveyard.count * 100) / graveyard.capacity).toString() + "%";

        itemList_.push(
          <SwiperSlide id={graveyard.id} key={graveyard.id}>

            {(isMobile || isSafari) ? (
              graveyard.mobileThumbnailUrl != null ? (

                <div style={{ width: "100%", height: "100%" }}>
                  <RenderIfVisible >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                      <defs>
                        <mask id="MASK">

                          <image xlinkHref={graveyard.mobileThumbnailAlphaChannelUrl}
                            height="100%"
                            width="100%" />
                        </mask>
                      </defs>

                      <image mask="url(#MASK)" x="0" y="0%" class="space" xlinkHref={graveyard.mobileThumbnailUrl}
                        height="100%" width="100%" />
                    </svg>
                  </RenderIfVisible>
                </div>

              ) : (
                // <img src={graveyard.thumbnailUrl} alt="" />
                <div className="mobile-p-35px" style={{display:"flex", alignContent: "center", justifyContent: "flex-end", width: "100%", height: "100%", padding: "4rem"}}>
                  <LazyLoadImage
                  src={graveyard.thumbnailUrl}
                />
                </div>
                
              )
            ) : (
              graveyard.thumbnailUrl?.includes(".webm") ?
                (
                  <RenderIfVisible>
                    <video autoPlay loop muted playsInline>
                      <source src={graveyard.thumbnailUrl} type="video/webm" />
                    </video>
                  </RenderIfVisible>

                ) : (
                  // <img src={graveyard.thumbnailUrl} alt="" />
                  <LazyLoadImage
                    src={graveyard.thumbnailUrl}
                  />
                )
            )
            }

            <div className="rl-grave-fill">
              <div className="rl-grave-fill-top">
                <div className="name">{graveyard.name}</div>
                <div className="numbers">
                  {graveyard.count}/{graveyard.capacity}
                </div>
              </div>
              <div className="rl-grave-fill-bottom">
                <div
                  className="rl-grave-fill-bottom-filled"
                  style={{ width: percent }}
                ></div>
              </div>
            </div>

            {graveyard.publishAt && (
              <div className="rl-grave-publish-date">
                {t("landing_graveyards_desc", { date: Helpers.formatDateMY(graveyard.publishAt) })}
              </div>
            )}
          </SwiperSlide>
        );
      });

      setItemList(itemList_)
    }

  }, [data])

  const handleSlideChange = event => {
    setSlideIndex(event.realIndex);
  }

  return (
    <Wrapper>
      {/* <div id="rememberland-landing-gravyeards"></div> */}

      {itemList.length > 0 && (
        <section
          className="rememberland-landing-gravyeards" id="rememberland-landing-gravyeards">

          {/* <div id="rememberland-landing-gravyeards" style={{position:"absolute", top: "-100px", left: "0", width:"20px", height:"20px", color:"#123123"}}></div> */}

          <div className="title">{t("landing_graveyards_title")}</div>
          <p className="desc">{t("landing_graveyards_desc2")}</p>

          <Swiper
            className="swiper rl-graveyards-carousel"
            // install Swiper modules
            modules={[Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            preloadImages={false}
            lazy={true}
            preventInteractionOnTransition={true}
            onSlideChange={handleSlideChange}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 80,
                centeredSlides: true,
              },
            }}
          >
            {itemList}
          </Swiper>

          <a
            href=""
            onClick={(event) => handleDiscover(event)}
            className="rl-animated-btn slide-vertical"
            data-splitting
            style={{ lineHeight: "1", padding: "22px 48px 18px" }}
          >
            {t("landing_graveyards_discover")}
          </a>

          {/* <a href="" className="rl-animated-btn slide-vertical" onClick={event => handleDiscover(event)} data-splitting>{t("landing_hero_navigation_startLife")}</a> */}
        </section>

      )}
    </Wrapper>
  );
};

export default Gravyeards;
