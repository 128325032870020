import { useState } from "react";
import { useTranslation } from "react-i18next";
import Helpers from "../../../../utils/Helpers";
import Wrapper from "../../../UI/Wrapper";
import BOGraveDetailMain from "./BOGraveDetailMain";

const BOGraveDetailNavigation = (props) => {

  const { t } = useTranslation();

  const [page, setPage] = useState("ADMIN");

  const navClickHandler = (event, type) => {
    event.preventDefault();
    setPage(type);
  };

  return (
    <Wrapper>
      <ul className="rl-user-info-tabs grave rl-custom-scroll">
        <li onClick={(event) => navClickHandler(event, "ADMIN")}>
          <a href="" className={page === "ADMIN" ? "active" : ""}>
            <img src="/img/icon-eye.svg" alt="Admins" /> {Helpers.capitalizeFirst(t("panel_navigation_admins"))}
          </a>
        </li>
        <li onClick={(event) => navClickHandler(event, "GUARDIAN")}>
          <a href="" className={page === "GUARDIAN" ? "active" : ""}>
            <img src="/img/icon-fingerprint.svg" alt="Guardians" />{" "}
            {Helpers.capitalizeFirst(t("panel_navigation_guardians"))}
          </a>
        </li>
        <li onClick={(event) => navClickHandler(event, "CONTENT")}>
          <a href="" className={page === "CONTENT" ? "active" : ""}>
            <img src="/img/icon-book-open.svg" alt="Contents" /> {Helpers.capitalizeFirst(t("panel_navigation_contents"))}
          </a>
        </li>
        <li onClick={(event) => navClickHandler(event, "TESTAMENT")}>
          <a href="" className={page === "TESTAMENT" ? "active" : ""}>
            <img src="/img/icon-file.svg" alt="Testaments" /> {Helpers.capitalizeFirst(t("panel_navigation_testaments"))}
          </a>
        </li>
        <li onClick={(event) => navClickHandler(event, "VERSE")}>
          <a href="" className={page === "VERSE" ? "active" : ""}>
            <img src="/img/icon-globe.svg" alt="Verse" /> {Helpers.capitalizeFirst(t("panel_navigation_verses"))}
          </a>
        </li>
        <li onClick={(event) => navClickHandler(event, "SUBS")}>
          <a href="" className={page === "SUBS" ? "active" : ""}>
            <img src="/img/icon-wallet.svg" alt="Sub Info" /> {Helpers.capitalizeFirst(t("panel_navigation_subsInfo"))}
          </a>
        </li>
        <li onClick={(event) => navClickHandler(event, "KYC")}>
          <a href="" className={page === "KYC" ? "active" : ""}>
            <img src="/img/icon-folder.svg" alt="KYC" /> {Helpers.capitalizeAll(t("panel_navigation_kyc"))}
          </a>
        </li>
      </ul>
      <BOGraveDetailMain pageType={page} />
    </Wrapper>
  );
};

export default BOGraveDetailNavigation;
