import React, { useCallback, useState } from "react";
import { useReducer } from "react";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import RLModalRouter from "../components/UI/RLModal/RLModalRouter";
import ToastContainer from "../components/UI/Toast/ToastContainer";
import AppContext from "./app-context";

const defaultAppState = {
  token: "",
  isLoggedIn: false,
  isAdmin: false,
  isMobileSideBar: false,
  onboardStatus: "",
  onboardType: "",
  user: [],
  sideBar: [],
};

const AppReducer = (state, action) => {
  const history = useHistory();

  if (action.type === "login") {
    localStorage.setItem("token", action.token);
    localStorage.setItem("isLoggedIn", true);

    let user = action.user;

    localStorage.setItem("onboardStatus", user.onboardStatus);
    localStorage.setItem("onboardType", user.onboardType);

    localStorage.setItem("user", JSON.stringify(user));

    const updatedIsAdmin = user.roles.includes("ROLE_ADMIN");
    localStorage.setItem("isAdmin", updatedIsAdmin);

    const updatedToken = action.token;
    const updatedIsLoggedIn = true;

    const updatedOnboardStatus = user.onboardStatus;
    const updatedOnboardType = user.onboardType;

    const updatedIsMobileSideBar = false;
    localStorage.setItem("isMobileSideBar", updatedIsMobileSideBar);

    return {
      token: updatedToken,
      isLoggedIn: updatedIsLoggedIn,
      isAdmin: updatedIsAdmin,
      isMobileSideBar: updatedIsMobileSideBar,
      onboardStatus: updatedOnboardStatus,
      onboardType: updatedOnboardType
    };
  }

  if (action.type === "logout") {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("isMobileSideBar");
    localStorage.removeItem("onboardStatus");
    localStorage.removeItem("onboardType");
    localStorage.removeItem("user");
    localStorage.removeItem("topMenu");

    return {
      token: defaultAppState.token,
      isLoggedIn: defaultAppState.isLoggedIn,
      isAdmin: defaultAppState.isAdmin,
      isMobileSideBar: defaultAppState.isMobileSideBar,
      onboardStatus: defaultAppState.onboardStatus,
      onboardType: defaultAppState.onboardType,
    };
  }

  if (action.type === "hasRole") {
    const user = defaultAppState.user;
    const foundedRole = user.roles.find((role) => role == action.role);

    if (foundedRole) {
      return true;
    } else {
      return false;
    }
  }

  if (action.type === "setUser") {
    let user = action.user;
    user.roles = action.roles;
    user.profilePhoto = action.profilePhoto;
    localStorage.setItem("user", JSON.stringify(user));

    return {
      token: defaultAppState.token,
      isLoggedIn: defaultAppState.isLoggedIn,
      isAdmin: defaultAppState.isAdmin,
      isMobileSideBar: defaultAppState.isMobileSideBar,
      onboardStatus: defaultAppState.onboardStatus,
      user: action.user,
      sideBar: action.sideBar,
    };
  }

  if (action.type === "updateProfile") {

    let user = action.user;

    defaultAppState.token = localStorage.getItem("token") ?? "";

    localStorage.setItem("user", JSON.stringify(user));

    const updatedIsAdmin = user.roles.includes("ROLE_ADMIN");
    localStorage.setItem("isAdmin", updatedIsAdmin);

    const updatedOnboardStatus = user.onboardStatus;
    const updatedOnboardType = user.onboardType;

    const updatedIsMobileSideBar = false;
    localStorage.setItem("isMobileSideBar", updatedIsMobileSideBar);

    return {
      token: defaultAppState.token,
      isLoggedIn: defaultAppState.isLoggedIn,
      isAdmin: updatedIsAdmin,
      isMobileSideBar: defaultAppState.isMobileSideBar,
      onboardStatus: defaultAppState.onboardStatus,
      onboardType: defaultAppState.onboardType
    };
  }

  if (action.type === "setMobileSideBar") {
    let isMobileSideBar = action.isMobileSideBar;
    localStorage.setItem("isMobileSideBar", JSON.stringify(isMobileSideBar));

    // if(isMobileSideBar) {
    //   window.$chatwoot.toggleBubbleVisibility('hide')
    // } else {
    //   window.$chatwoot.toggleBubbleVisibility('show')
    // }

    defaultAppState.isMobileSideBar = isMobileSideBar

    return {
      token: defaultAppState.token,
      isLoggedIn: defaultAppState.isLoggedIn,
      isAdmin: defaultAppState.isAdmin,
      isMobileSideBar: isMobileSideBar,
      onboardStatus: defaultAppState.onboardStatus,
      user: defaultAppState.user,
    };
  }

  if (action.type === "REFRESH") {
    return defaultAppState;
  }

  return defaultAppState;
};

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const remainingTIme = expirationTime - currentTime / 1000;

  return remainingTIme;
};

const AppProvider = (props) => {
  let logoutTimer;

  defaultAppState.token = localStorage.getItem("token") ?? "";
  defaultAppState.isLoggedIn =
    JSON.parse(localStorage.getItem("isLoggedIn")) ?? false;
  defaultAppState.isAdmin =
    JSON.parse(localStorage.getItem("isAdmin")) ?? false;
  defaultAppState.isMobileSideBar =
    JSON.parse(localStorage.getItem("isMobileSideBar")) ?? false;
  defaultAppState.onboardStatus = localStorage.getItem("onboardStatus") ?? "";
  defaultAppState.user = JSON.parse(localStorage.getItem("user")) ?? [];
  defaultAppState.sideBar = JSON.parse(localStorage.getItem("sideBar")) ?? [];

  const [appState, dispatchAppAction] = useReducer(AppReducer, defaultAppState);

  const login = (token, user) => {
    dispatchAppAction({
      type: "login",
      token: token,
      user: user,
    });

    // let tokenPayload = jwtDecode(token);
    // const remainingTime = calculateRemainingTime(tokenPayload.exp);

    // logoutTimer = setTimeout(logout, remainingTime);
  };

  const logout = () => {
    dispatchAppAction({ type: "logout" });

    // if (logoutTimer) {
    //   // clearTimeout(logoutTimer);
    // }
  };

  const hasRole = (role) => {
    dispatchAppAction({ type: "hasRole", role: role });
  };

  const setUser = (user, roles) => {
    dispatchAppAction({ type: "setUser", user: user, roles: roles });
  };

  const updateProfile = (user) => {
    dispatchAppAction({ type: "updateProfile", user: user });
  };

  const setSideBar = (sideBar, owner) => {
    dispatchAppAction({ type: "setSideBar", sideBar: sideBar, owner: owner });
  };

  const setMobileSideBar = (isMobileSideBar) => {
    dispatchAppAction({ type: "setMobileSideBar", isMobileSideBar: isMobileSideBar});
  };

  // const setSelected = (owner, verse, grave) => {
  const setSelected = (selectType, selected, sideBar = null) => {
    // dispatchAppAction({ type: "setSelected", owner: owner, verse: verse, grave: grave });
    dispatchAppAction({
      type: "setSelected",
      selectType: selectType,
      selected: selected,
      sideBar: sideBar,
    });
  };

  const [toasts, setToasts] = useState([]);
  let id = 1;
  const addToast = useCallback(
    (content, type) => {
      setToasts((toasts) => [
        ...toasts,
        {
          id: id++,
          content,
          type,
        },
      ]);
    },
    [setToasts]
  );
  const removeToast = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts]
  );

  const [loading, setLoading] = useState(false);
  const showLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);
  const hideLoading = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  let modal_ = [];
  const [modal, setModal] = useState(false);
  const showModal = useCallback((modal) => {
    modal_=modal
    setModal(modal);
  }, [setModal]);
  const hideModal = useCallback(() => {
    setModal({...modal, show: false});
  }, [setModal]);

  const appContext = {
    token: appState.token,
    isLoggedIn: appState.isLoggedIn,
    isAdmin: appState.isAdmin,
    isMobileSideBar: appState.isMobileSideBar,
    onboardStatus: appState.onboardStatus,
    user: appState.user,
    sideBar: appState.sideBar,
    login: login,
    logout: logout,
    hasRole: hasRole,
    setUser: setUser,
    updateProfile: updateProfile, 
    setSideBar: setSideBar,
    setMobileSideBar: setMobileSideBar,
    setSelected: setSelected,
    addToast,
    removeToast,
    showLoading,
    hideLoading,
    showModal,
    hideModal,
  };

  return (
    <AppContext.Provider value={appContext}>
      {toasts.length > 0 && <ToastContainer toasts={toasts} />}

      {loading != undefined && <LoadingSpinner loading={loading} />}
      {modal != undefined && <RLModalRouter modal={modal} />}

      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
