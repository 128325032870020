import Wrapper from "../UI/Wrapper";
import AccountSettings from "./AccountSettings/AccountSettings";

const AccountMain = (props) => {
  return (
    <Wrapper>
      <section className="rl-admin-content">
        <div className="rl-admin-content-box">

            <AccountSettings />

        </div>
      </section>
    </Wrapper>
  );
};

export default AccountMain;
