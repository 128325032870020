import { ClassNames } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import RLCheckBox from "./RLCheckBox";
import Wrapper from "./Wrapper";

const RLTable = (props) => {
  const { t } = useTranslation();

  const handleMainCheckboxClick = (event) => {
    // setCount(() => count+1)

    setMainCheckbox({
      ...mainCheckbox,
      isChecked: event.target.checked,
    });

    let isChange = false;
    let checkboxes = {};
    // checkboxes = props.listCheckboxes;
    checkboxes = listCheckboxes;
    // console.log(listCheckboxes);
    // console.log(mainCheckbox);
    // checkboxes = listCheckboxesRef.current;

    if (event.target.checked) {
      for (const key in checkboxes) {
        if (checkboxes[key].isChecked != true) {
          checkboxes[key].isChecked = true;
          isChange = true;
        }
      }
    } else {
      for (const key in checkboxes) {
        if (checkboxes[key].isChecked == true) {
          checkboxes[key].isChecked = false;
          isChange = true;
        }
      }
    }

    if (isChange) {
      //   listCheckboxes_ = checkboxes;
      setListAndItem(checkboxes);
    }

    // event.preventDefault();
  };

  const [mainCheckbox, setMainCheckbox] = useState({
    value: "main",
    isChecked: false,
    onClick: handleMainCheckboxClick,
  });

  const [listCheckboxes, setListCheckboxes] = useState();

  const [tableHeaders, setTableHeaders] = useState([]);
  const tableHeaders_ = [];

  useEffect(() => {
    if (listCheckboxes != props.listCheckboxes) {
      setListCheckboxes(props.listCheckboxes);
    }

    let isListCheckboxesChecked = mainCheckbox.isChecked;

    if (!isListCheckboxesChecked) {
      for (const key in props.listCheckboxes) {
        if (props.listCheckboxes[key].isChecked == true) {
          isListCheckboxesChecked = true;
        }
      }
    } else {
    }

    if (mainCheckbox.isChecked != isListCheckboxesChecked) {
      setMainCheckbox({
        ...mainCheckbox,
        isChecked: isListCheckboxesChecked,
      });
    }
    let mainCheckbox_ = mainCheckbox;
    mainCheckbox_.isChecked = isListCheckboxesChecked;

    if (props.tableHeaders != undefined) {
      props.tableHeaders.forEach((item) => {
        if (item.type == "headerCheckbox") {
          tableHeaders_.push(
            <th id={item.type + item.text} key={item.type + item.text}>
              <RLCheckBox input={mainCheckbox_} />
            </th>
          );
        } else if (item.type == "headerRemove") {
          tableHeaders_.push(
            <th className={item.className} id={item.type + item.text} key={item.type + item.text}>
              <a
                href=""
                onClick={(event) => handleRemove(event)}
                className={`${"rl-table-button"} ${
                  mainCheckbox_.isChecked ? "red" : "disabled"
                }`}
              >
                {t("general_remove")}
              </a>
            </th>
          );
        } else if (item.type == "header") {
          tableHeaders_.push(<th className={item.className} id={item.type + item.text} key={item.type + item.text}>{item.text}</th>);
        }
      });

      if (tableHeaders[0] != undefined && 1==2) {
        if (
          tableHeaders[0].props.children.props.input.isChecked !=
          tableHeaders_[0].props.children.props.input.isChecked
        ) {
          setTableHeaders(tableHeaders_);
        }
      } else {
        setTableHeaders(tableHeaders_);
      }
    }
  }, [listCheckboxes]);

  //   function handleMainCheckboxClick(event) {
  //     // setCount(() => count+1)

  //     setMainCheckbox({
  //       ...mainCheckbox,
  //       isChecked: event.target.checked,
  //     });

  //     let isChange = false;
  //     let checkboxes = {};
  //     // checkboxes = props.listCheckboxes;
  //     console.log(listCheckboxes);
  //     console.log(mainCheckbox);
  //     // checkboxes = listCheckboxesRef.current;

  //     if (event.target.checked) {
  //       for (const key in checkboxes) {
  //         if (checkboxes[key].isChecked != true) {
  //           checkboxes[key].isChecked = true;
  //           isChange = true;
  //         }
  //       }
  //     } else {
  //       for (const key in checkboxes) {
  //         if (checkboxes[key].isChecked == true) {
  //           checkboxes[key].isChecked = false;
  //           isChange = true;
  //         }
  //       }
  //     }

  //     if (isChange) {
  //       //   listCheckboxes_ = checkboxes;
  //       setListAndItem(checkboxes);
  //     }

  //     // event.preventDefault();
  //   }

  function handleRemove(event) {
    event.preventDefault();

    if (mainCheckbox.isChecked) {
      let ids = [];
      let listCheckboxes_ = listCheckboxes;
      for (const key in listCheckboxes_) {
        const checkbox = listCheckboxes_[key];
        if (checkbox.isChecked == true) {
          ids.push(checkbox.value);
        }
      }

      if (ids.length > 0) {
        // console.log(ids);
      }
    }
  }

  function setListAndItem(checkboxes) {
    props.setListAndItem(checkboxes);
  }

  return <Wrapper>{tableHeaders}</Wrapper>;
};

export default RLTable;
