import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AppContext from "../../utils/app-context";
import Wrapper from "../UI/Wrapper";
import Helpers from "../../utils/Helpers";
import RLModal from "../UI/RLModal/RLModal";
import { t } from "i18next";

const BOSideBar = (props) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const handlePageClick = (event, type) => {
    event.preventDefault();

    if (type == "CHANGEPASS") {
      setShowPasswordModal(true);
    } else {
      props.onClickItem(type);
    }
  };

  const handleLogout = (event) => {
    event.preventDefault();

    appCtx.logout();
    Helpers.removeStorage();
    history.replace("/");
  };

  const closeModalHandler = (event) => {
    if (event.target === event.currentTarget) {
      setShowPasswordModal(false);
    }
  };

  return (
    <Wrapper>
      <aside className="rememberland-panel-sidebar bo-user">
        <div className="rememberland-panel-sidebar-inner">
          <ul className="bo-user-menu">
            <li>
              <a
                href=""
                onClick={(event) => handlePageClick(event, "USER")}
                className={props.page === "USER" ? "active" : ""}
              >
                {t("bo_sidebar_users")}
              </a>
            </li>
            {/* <li>
              <a
                href=""
                onClick={(event) => handlePageClick(event, "TAG")}
                className={props.page === "TAG" ? "active" : ""}
              >
                {t("bo_sidebar_tags")}
              </a>
            </li> */}
            <li>
              <a
                href=""
                onClick={(event) => handlePageClick(event, "GRAVE")}
                className={props.page === "GRAVE" ? "active" : ""}
              >
                {t("bo_sidebar_graves")}
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={(event) => handlePageClick(event, "CHANGEPASS")}
                className={props.page === "CHANGEPASS" ? "active" : ""}
              >
                {t("bo_sidebar_changePassword")}
              </a>
            </li>
          </ul>

          <div className="rememberland-panel-sidebar-footer">
            <a href="" onClick={handleLogout} className="rl-logout">
              <img src="/img/icon-log-in.svg" alt="Log out" /> {t("logout")}
            </a>
            <div></div>
          </div>
        </div>
      </aside>

      <aside className="rememberland-panel-sidebar-mobile">
        <div className="rememberland-panel-sidebar-mobile-header">
          <div className="rl-bo-user-mobile-left"></div>
          <a>
            <img
              src="img/logo-black.svg"
              alt="REMEMBERLAND"
              className="rememberland-logo-white"
            />
          </a>
          <a className="close-mobile-hamburger">
            <img src="img/icon-x-close.svg" alt="Close" />
          </a>
        </div>
        <div className="rememberland-panel-sidebar-mobile-body">
          <ul className="bo-user-menu">
            <li>
              <a className="active">Users</a>
            </li>
            <li>
              <a>Tags</a>
            </li>
            <li>
              <a>Graves</a>
            </li>
          </ul>
        </div>
        <div className="rememberland-panel-sidebar-mobile-footer" />
        <ul className="rl-lang">
          <li>
            <a className="active">ENG</a>
          </li>
          <li>
            <a>TR</a>
          </li>
        </ul>
        <a onClick={handleLogout} className="rl-logout">
          <img src="img/icon-log-in.svg" alt="Log out" /> Log out
        </a>
        {/* </div> */}
      </aside>
      <RLModal
        title="My Modal"
        onClose={closeModalHandler}
        show={showPasswordModal}
        type={"changePassword"}
      />
    </Wrapper>
  );
};

export default BOSideBar;
