import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLInput from "../../UI/RLInput";
import RLInputText from "../../UI/RLInputText";
import RLModal from "../../UI/RLModal/RLModal";
import Wrapper from "../../UI/Wrapper";
import { isMobile } from 'react-device-detect';

const AccountSettingsForm = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);

  // const profile = props.profile;
  // const [profile, setProfile] = useState("");
  // const [name, setName] = useState(profile.name);

  const [profile, setProfile] = useState("");

  const [profilePhoto, setProfilePhoto] = useState(profile.profilePhoto);
  const [newProfilePhoto, setNewProfilePhoto] = useState(null);
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);

  const [name, setName] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleName,
    onSaveSetting: handleUpdateName,
  });
  const [surname, setSurName] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleSurname,
    onSaveSetting: handleUpdateSurname,
  });
  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    validation: ["empty", "email"],
    disabled: false,
    onChange: handleEmail,
    onSaveSetting: handleUpdateEmail,
  });
  const [password, setPassword] = useState({
    value: "******",
    isValid: true,
  });
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");
  const [reload, setReload] = useState(false);

  function handleUpdateName(value) {
    const data = { name: value };
    postProfileUpdate(data);
  }
  function handleName(value) {
    setName({ ...name, value: value });
  }

  function handleUpdateSurname(value) {
    const data = { surname: value };
    postProfileUpdate(data);
  }
  function handleSurname(value) {
    setSurName({ ...surname, value: value });
  }

  function handleUpdateEmail(value) {
    const data = { email: value };
    postProfileUpdate(data);
  }
  function handleEmail(value) {
    setEmail({ ...email, value: value });
  }

  const closeModalHandler = (event) => {
    if (event.target === event.currentTarget) {
      setShowPasswordModal(false);
    }
  };

  function closeModal() {
    setShowPasswordModal(false);
  }

  function openModal() {
    setShowPasswordModal(true);
  }

  function handleResendEmail() {
    const url = "profile/resend-mail-update-mail";
    setEndpoint(url);

    request({
      method: "POST",
      url: url,
      // data,
    });
  }

  useEffect(() => {
    if (endpoint == "first") {
      getProfile();
    }

    if (response !== null && typeof response !== "undefined") {
      if (responseType == "profile") {
        setProfile(response.data);

        if (response.data.socialLogin) {
          setName({
            ...name,
            value: response.data.name,
            disabled: true,
          });
          setSurName({
            ...surname,
            value: response.data.surname,
            disabled: true,
          });
        } else {
          setName({
            ...name,
            value: response.data.name,
          });
          setSurName({
            ...surname,
            value: response.data.surname,
          });
        }

        if (
          response.data.waitingUpdateMail != null ||
          response.data.socialLogin
        ) {
          setEmail({
            ...email,
            value: response.data.email,
            disabled: true,
          });
        } else {
          setEmail({
            ...email,
            value: response.data.email,
          });
        }

        Helpers.setUser(response.data);
      } else if (responseType == "profile/update") {
        appCtx.addToast(t("accountSettings_updatedMessage"), "green");
        Helpers.setUser(response.data);
        getProfile();
      } else if (responseType == "profile/profilePhoto/delete") {
        getProfile();
        appCtx.addToast(t("accountSettings_deletedMessage"), "green");
      } else if (responseType == "profile/profilePhoto/upload") {
        getProfile();
        appCtx.addToast(t("accountSettings_updatedMessage"), "green");
      } else if (responseType == "profile/resend-mail-update-mail") {
        getProfile();
        appCtx.addToast(t("accountSettings_updatedMessage"), "green");
      }
    } else if (response == undefined && error != "") {
      if (endpoint == "profile/update") {
        getProfile();
      }
    }
  }, [response, error, reload]);

  function postProfileUpdate(data) {
    const url = "profile/update";
    setEndpoint(url);

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  function getProfile() {
    const url = "profile";
    setEndpoint(url);

    request({
      method: "GET",
      url: url,
    });
  }

  const handleDeletePhoto = (event) => {
    event.preventDefault();

    const url = "profile/profilePhoto/delete";
    setEndpoint(url);

    request({
      method: "DELETE",
      url: url,
    });
  };

  function postUploadProfilePhoto(file) {
    const url = "profile/profilePhoto/upload";
    setEndpoint(url);

    const data = { file: file };

    request(
      {
        method: "POST",
        url: url,
        data,
      },
      () => { },
      false,
      true
    );
  }

  const handleChange = function (e) {
    e.preventDefault();
    console.log("handleChange")
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      postUploadProfilePhoto(file);
    }
  };
  const handleUploadPhoto = (e) => {
    // e.preventDefault();
    console.log("handleUploadPhoto")
    inputRef.current.click();
  };

  var emailInputFeature = "";
  if (profile.waitingUpdateMail === null && profile.socialLogin != true) {
    emailInputFeature = "accountSettings";
  } else if (profile.waitingUpdateMail != null && Helpers.minutesBetweenDates(profile.waitingUpdateMailAt) > 30) {
    emailInputFeature = "accountSettingsResend";
  }

  return (
    <Wrapper>
      <div className="rl-account-avatar">

        <div className="rememberland-panel-sidebar-user">
          <figure>
            <img
              src={
                profile.profilePhoto
                  ? profile.profilePhoto
                  : "/img/avatar-placeholder.png"
              }
              className="rl-user-avatar"
            />
          </figure>
        </div>

        {isMobile ? (
          <div
          className="file-upload-mobile rl-gray-square-btn"
          style={{ cursor: "pointer" }}
        >
          <div className="file-upload__input">
            <input
              // onClick={handleUploadPhoto}
              ref={inputRef}
              type="file"
              id="file"
              onChange={handleChange}
            />
          </div>
          <img src="img/icon-change.svg" alt="Change" />

        </div>
        ) : (
          <div
          onClick={handleUploadPhoto}
          className="file-upload-mobile rl-gray-square-btn"
          style={{ cursor: "pointer" }}
        >
          <div className="file-upload__input">
            <input
              // onClick={handleUploadPhoto}
              ref={inputRef}
              type="file"
              id="file"
              onChange={handleChange}
            />
          </div>

          <img src="img/icon-change.svg" alt="Change" />

        </div>

        )}
        

        {profile.profilePhoto !== null && (
          <a
            href=""
            onClick={handleDeletePhoto}
            title=""
            className="rl-gray-square-btn"
          >
            <div
              onClick={handleDeletePhoto}
            >
              <img src="img/icon-trash.svg" alt="Delete" />
            </div>
          </a>
        )}
      </div>

      <form action="" className="rl-account-details-form">
        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText
              inputType="text"
              inputFeature={profile.socialLogin ? "" : "accountSettings"}
              label={Helpers.capitalizeFirst(t("general_name"))}
              data={name}
              standart={true}
            />
          </div>
        </div>

        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText
              inputType="text"
              inputFeature={profile.socialLogin ? "" : "accountSettings"}
              label={Helpers.capitalizeFirst(t("general_surname"))}
              data={surname}
            />
          </div>
        </div>

        <div className="rl-form-group">
          <div className="rl-form-group-material">
            <RLInputText
              inputType="text"
              inputFeature={emailInputFeature}
              label={Helpers.capitalizeFirst(t("general_email"))}
              data={email}
              resendEmail={handleResendEmail}
            />
            {profile.waitingUpdateMail !== null && (
              <div className="error-line">
                <img
                  src="/img/icon-circle-info.svg"
                  alt={Helpers.capitalizeAllFirst(t("general_email_error"))}
                  width="24"
                />

                <span>
                  {t("accountSettings_profile_email_update", {
                    email: profile.waitingUpdateMail,
                  })}
                </span>
              </div>
            )}
          </div>
        </div>

        {!profile.socialLogin && (
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="text"
                inputFeature="accountSettingsPassword"
                openModal={openModal}
                // label={t("password")}
                label={Helpers.capitalizeFirst(t("password"))}
                data={password}
              />
            </div>
          </div>
        )}
      </form>
      <RLModal
        title="My Modal"
        openModal={openModal}
        onClose={closeModalHandler}
        show={showPasswordModal}
        type={"changePassword"}
      />
    </Wrapper>
  );
};

export default AccountSettingsForm;
