
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const RLTextFieldMultiple = styled((props) => (
    <TextField {...props} />
  ))(({ theme }) => ({
    
    "& .MuiFilledInput-root": {
      border: "1px solid #CECED6",
      borderRadius: 0,
      // height: "58px",
      overflow: "hidden",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiInputLabel-root": {
      "&.Mui-focused": {
        color: "#646672",
      },
      fontSize: "14px",
      fontWeight: 400,
      color: "#999AA7",
    },
    "& .MuiChip-root": {
      backgroundColor: "#eaebf2",
      height: 23,
      fontSize: "12px",
      fontWeight: 400,
      borderRadius: 5,
    },
    "$ .MuiChip-deleteIcon": {
      height: "10px"
    }
    
  }));

  export default RLTextFieldMultiple;