import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import RLInputText from "../RLInputText";
import Wrapper from "../Wrapper";

const RLModalKycReject = (props) => {

  const { t } = useTranslation();

  const modal = props.modal;
  const params = modal.params;

  const [comment, setComment] = useState({
    value: "",
    isValid: true,
    validation: ["empty"],
    onChange: handleChangeComment,
  });


  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (
        responseType ==
        "backOffice/grave/" + params["graveId"] + "/kyc/reject/" + params["kycId"]
      ) {

        modal.onClose();
        params.reloadList();
      }
    }
  }, [response, error]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValidComment = comment.isValid;
    if (isValidComment && comment.validation.includes("empty")) {
      isValidComment = comment.value == "" ? false : true;
      setComment({ ...comment, isValid: isValidComment });
    }

    if (!isValidComment) {
      return;
    }

    let url = "backOffice/grave/" + params["graveId"] + "/kyc/reject/" + params["kycId"];

    let data = {
      comment: comment.value,
    };

    request({
      method: "POST",
      url: url,
      data,
    });
  };

  function handleChangeComment(value, isValid = false) {
    setComment({ ...comment, value: value, isValid: isValid });
  }

  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="modal-header">{t("general_reject")}</h1>
        <div className="modal-header-desc"></div>
        <div className="modal-header-desc">
          <div className="rl-form-group">
            <div className="rl-form-group-material">
              <RLInputText
                inputType="text"
                label={t("bo_grave_kyc_list_rejectReason")}
                data={comment}
              />
            </div>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="rl-form-button reset-pass-btn"
          data-modal=""
        >
          {t("general_reject")}
        </button>
      </div>
      <a
        rel="modal:close"
        className={props.closeClassName}
        onClick={modal.onClose}
      >
        x
      </a>
    </Wrapper>
  );
};

export default RLModalKycReject;
