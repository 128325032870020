import { useContext, useEffect, useState } from "react";
import Wrapper from "../../UI/Wrapper"
import { useTranslation } from "react-i18next";
import AppContext from "../../../utils/app-context";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../../hooks/use-axios";
import Helpers from "../../../utils/Helpers";
import { useIndexedDB } from "react-indexed-db";

const PanelTestamentListItem = (props) => {
    const { getByID, update, getByIndex, deleteRecord } = useIndexedDB('testament_step_files');
    const { t } = useTranslation();
    const appCtx = useContext(AppContext);
    const params = useParams();

    const rlSideBar = props.rlSideBar
    const selectedGrave = rlSideBar.selectedGrave
    const testament = props.item;

    const [fileList, setFileList] = useState("");
    const [selectedFile, setSelectedFile] = useState();

    useEffect(() => {

        if (testament != undefined) {
            let _fileList = "";
            if (testament.files.length > 0) {
                _fileList = testament.files.map((file) => (
                    <Wrapper>
                        <a href="" onClick={event => handleContentOpen(event, file.id)} className="rl-content-link">
                            {Helpers.getFileIcon(file.file.contentType)}{" "}
                            {file.file.originalFileName}
                        </a>
                        <div style={{ height: "8px" }}></div>
                    </Wrapper>
                ));
            }

            setFileList(_fileList);
        }

        setSelectedFile();

    }, [testament]);

    const handleEdit = async (event) => {
        event.preventDefault();

        appCtx.showLoading();

        request({
            method: "GET",
            url:
                "grave/" +
                params["graveId"] +
                "/testament/detail/" +
                testament.id
        });

    };

    const handleRemoveConfirm = (event) => {
        event.preventDefault();

        let data = {};

        request({
            method: "DELETE",
            url:
                "grave/" + params["graveId"] + "/testament/" + testament.id + "/delete",
            data: data,
        });
    };

    const handleRemove = (event) => {
        event.preventDefault();

        const verseDetailModal = {
            type: "confirmationModal",
            show: true,
            params: {
                title: t("confirmation_remove_title"),
                description: t("confirmation_remove_desc"),
                buttonName: "Remove",
            },
            onConfirm: handleRemoveConfirm,
        };
        appCtx.showModal(verseDetailModal);
    };

    const handleContentOpen = (event, fileId) => {
        event.preventDefault();

        let selectedFile_ = getSelectedFileById(fileId);
        setSelectedFile(selectedFile_);

        request({
            method: "GET",
            url:
                "grave/" +
                params["graveId"] +
                "/testament/" +
                testament.id +
                "/file/" +
                selectedFile_.id +
                "/view/" +
                selectedFile_.file.guid,
            responseType: "blob",
        });
    };

    const { response, loading, error, request, responseType } = useAxios();
    useEffect(() => {
        if (response !== null && typeof response !== "undefined") {
            if (
                responseType ==
                "grave/" + params["graveId"] + "/testament/" + testament.id + "/delete"
            ) {
                props.reloadList();

            } else if (responseType == "grave/" + params["graveId"] + "/testament/" +
                testament.id + "/file/" + selectedFile?.id + "/view/" + selectedFile?.file.guid) {

                const type = "application/octet-stream";
                const blob = new Blob([response], { type: type, encoding: "UTF-8" });

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = selectedFile.file.originalFileName;
                link.click();

            } else if (responseType == "grave/" + params["graveId"] + "/testament/detail/" + testament.id) {
                let userId = Helpers.getUser().id;
                let graveId = selectedGrave.id;
                let id = userId + "_" + graveId;

                let testamentDetail = response.data;

                const emails = testament.emails.split(",");
                let emails_ = [];
                for (const key in emails) {
                    emails_.push({ id: emails[key], name: emails[key] });
                }

                getByIndex('rlId', id).then(
                    async testamentStepFiles => {
                        if (testamentStepFiles != undefined) {
                            deleteRecord(testamentStepFiles.id).then(
                                event => {

                                    let files = [];
                                    for (let i = 0; i < testamentDetail.files.length; i++) {
                                        files.push({
                                            id: testamentDetail.files[i].id,
                                            name: testamentDetail.files[i].file.originalFileName,
                                            type: testamentDetail.files[i].file.contentType
                                        }
                                        );
                                    }

                                    var obj = { userId: {} }
                                    obj = {
                                        [selectedGrave.id]: {
                                            "step": 1,
                                            "testamentId": testamentDetail.id,
                                            "testamentFileId": testament.testamentFileId,
                                            "files": files,
                                            "stepOneStatus": "pending",
                                            "stepTwoStatus": "notStarted",
                                            "stepThreeStatus": "notStarted",
                                            "name": testamentDetail.name,
                                            "shareEmail": JSON.stringify(emails_),
                                        }
                                    };

                                    var _testamentStep = {};
                                    Object.assign(_testamentStep, obj);
                                    Helpers.setTestamentStep(_testamentStep);

                                    props.reloadList();

                                    appCtx.hideLoading();
                                },
                                error => {
                                    console.log(error);
                                    appCtx.hideLoading();
                                }
                            );
                        } else {

                            let files = [];
                            for (let i = 0; i < testamentDetail.files.length; i++) {
                                files.push({
                                    id: testamentDetail.files[i].id,
                                    name: testamentDetail.files[i].file.originalFileName,
                                    type: testamentDetail.files[i].file.contentType,
                                    storage: "local"
                                }
                                );
                            }

                            var obj = { userId: {} }
                            obj = {
                                [selectedGrave.id]: {
                                    "step": 1,
                                    "testamentId": testamentDetail.id,
                                    "testamentFileId": testament.testamentFileId,
                                    "files": files,
                                    "stepOneStatus": "pending",
                                    "stepTwoStatus": "notStarted",
                                    "stepThreeStatus": "notStarted",
                                    "name": testamentDetail.name,
                                    "shareEmail": JSON.stringify(emails_),
                                }
                            };

                            var _testamentStep = {};
                            Object.assign(_testamentStep, obj);
                            Helpers.setTestamentStep(_testamentStep);

                            props.reloadList();
                            appCtx.hideLoading();
                        }

                    },
                    error => {
                        console.log(error);
                    }
                );
            }
        }
    }, [response, error]);

    function getSelectedFileById(fileId) {
        const selectedFile_ = testament.files.filter(function (file) {
            return file.id == fileId;
        });

        return selectedFile_[0];
    }

    return (
        <Wrapper>
            <tr>
                <td className="fw-600">
                    {testament.name}
                </td>

                <td className="rl-table-border-l fw-600">
                    {testament.emails}
                </td>

                <td className="rl-table-border-l">
                    {fileList}
                </td>

                {
                    <td className="w-40 text-right">
                        <a
                            href=""
                            onClick={handleEdit}
                            className="rl-table-button blue"
                        >
                            {t("general_edit")}
                        </a>

                        <a
                            href=""
                            onClick={handleRemove}
                            className="rl-table-button red"
                        >
                            {t("general_remove")}
                        </a>
                    </td>
                }
            </tr>
        </Wrapper>
    )
}

export default PanelTestamentListItem;