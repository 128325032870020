import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAxios from "../../../../hooks/use-axios";
import AppContext from "../../../../utils/app-context";
import Helpers from "../../../../utils/Helpers";
import CardListNoRecord from "../../../UI/CardListNoRecord";
import RLInputSelect from "../../../UI/RLInputSelect";
import Wrapper from "../../../UI/Wrapper";

const BOUserDetailPaymentInfo = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const appCtx = useContext(AppContext);

  const [filters, setFilters] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [itemList, setItemList] = useState("");

  const [RLInputs, setRLInputs] = useState({
    graveDTOList: [],
    userCardDTOList: [],
  });
  const [listFilters, setlistFilter] = useState({
    graveDTOList: null,
    userCardDTOList: null,
  });

  const { response, loading, error, request, responseType } = useAxios();
  const [endpoint, setEndpoint] = useState("first");

  useEffect(() => {
    if (endpoint == "first") {
      getPaymentInfoFilter();
      postUserDetailPaymentInfo();
    }

    if (response !== null && typeof response !== "undefined") {
      if (
        responseType ==
        "backOffice/user/" + params["userId"] + "/detail/paymentInfo/filter"
      ) {
        setFilters(response.data);

        let graveDTOList_ = [];
        graveDTOList_ = response.data.graveDTOList.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });

        let userCardDTOList_ = [];
        userCardDTOList_ = response.data.userCardDTOList.map((item) => {
          return {
            id: item.id,
            name: Helpers.formatCreditCardNumber(item),
          };
        });

        setRLInputs({
          graveDTOList: graveDTOList_,
          userCardDTOList: userCardDTOList_,
        });
      } else if (
        responseType ==
        "backOffice/user/" + params["userId"] + "/detail/paymentInfo"
      ) {
        setPaymentInfo(response.data);

        appCtx.addToast(
          t("list_search_complete", { count: response.data.length }),
          "green"
        );

        let itemList_ = "";
        if (response.data.length > 0) {
          itemList_ = response.data.map((item) => (
            // <BOGraveListItem id={item.id} key={item.id} item={item} />
            <tr>
              <td className="fw-600">{Helpers.formatCreditCardNumber(item)}</td>
              <td className="rl-table-border-l fw-600">{item.graveName}</td>
              <td className="rl-table-border-l fw-600">
                {item.remainingAmount}
              </td>
            </tr>
          ));

          setItemList(itemList_);
        } else {
          setItemList([]);
        }
      }
    }
  }, [response, error]);

  function getPaymentInfoFilter() {
    const url =
      "backOffice/user/" + params["userId"] + "/detail/paymentInfo/filter";
    setEndpoint(url);

    const data = {};

    request({
      method: "GET",
      url: url,
      data,
    });
  }

  function postUserDetailPaymentInfo() {
    const url = "backOffice/user/" + params["userId"] + "/detail/paymentInfo";
    setEndpoint(url);

    var data = {};
    if (listFilters.graveDTOList != null) {
      data["graveId"] = listFilters.graveDTOList.id;
    }
    if (listFilters.userCardDTOList != null) {
      data["cardId"] = listFilters.userCardDTOList.id;
    }

    request({
      method: "POST",
      url: url,
      data,
    });
  }

  function graveFilterHandler(value) {
    setlistFilter({
      graveDTOList: value,
      userCardDTOList: listFilters.userCardDTOList,
    });
  }

  function cardFilterHandler(value) {
    setlistFilter({
      graveDTOList: listFilters.graveDTOList,
      userCardDTOList: value,
    });
  }

  const handleSearch = (event) => {
    event.preventDefault();

    postUserDetailPaymentInfo();
  };

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <form action="" className="rl-bo-users-search-form">
          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={Helpers.capitalizeFirst(t("bo_grave_form_grave"))}
                  defaultValue={listFilters.graveDTOList}
                  standart={true}
                  onChange={graveFilterHandler}
                  options={RLInputs.graveDTOList}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="rl-form-group">
              <div className="rl-form-group-material">
                <RLInputSelect
                  label={Helpers.capitalizeFirst(t("general_card"))}
                  defaultValue={listFilters.userCardDTOList}
                  standart={true}
                  onChange={cardFilterHandler}
                  options={RLInputs.userCardDTOList}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <button onClick={handleSearch} className="rl-form-button">
              {t("bo_grave_sub_filterSearch")}
            </button>
          </div>
        </form>
      </div>

      {paymentInfo.length > 0 ? (
        <div className="rl-admin-content-box mt-16">
          <div className="rl-table-outer rl-custom-scroll">
            <table className="rl-table-admin-bottom">
              <thead>
                <tr>
                  <th>{t("general_card")}</th>
                  <th className="rl-table-border-l">
                    {t("bo_grave_form_grave")}
                  </th>
                  <th className="rl-table-border-l">
                    {t("panelSub_paymentMethod_remainingPayment")}
                  </th>
                </tr>
              </thead>
              <tbody>{itemList}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <CardListNoRecord />
      )}
    </Wrapper>
  );
};

export default BOUserDetailPaymentInfo;
