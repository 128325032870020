import { useTranslation } from "react-i18next";
import Wrapper from "../../UI/Wrapper"
import PanelVerseFormItem from "./PanelVerseFormItem"

const PanelVerseSuggestedList = props => {

  const { t } = useTranslation();

  const rlSideBar = props.rlSideBar
  const sugestedVerses = props.sugestedVerses
  const graveVerses = props.graveVerses

  let itemList = [];
  if (sugestedVerses.length > 0) {
    for (const key in sugestedVerses) {

      const item = sugestedVerses[key];

      const isSelected = isSelectedVerse(item.id)

      itemList[key] = (
        <PanelVerseFormItem
          id={item.id}
          key={item.id}
          item={item}
          isSelected={isSelected}
          reloadList={props.reloadList}
          type="suggested"
        />
      );
    }
  }

  function isSelectedVerse(verseId) {
    let isSelected = false;
    graveVerses?.map((verse) => {
      if (verse.verseId === verseId) {
        isSelected = true
      }
    });
    return isSelected;
  }

  return (
    <Wrapper>
      <div className="rl-admin-content-box mt-16">
        <h3 className="rl-verses-titles">{t("verse_list_suggested_header")}</h3>
        <div className="rl-list-all-verses-grid rl-custom-scroll">

          {itemList}
        </div>
      </div>
    </Wrapper>
  )
}

export default PanelVerseSuggestedList;