import React from "react";

const AppContext = React.createContext({
  token: "",
  isLoggedIn: false,
  isAdmin: false,
  onboardStatus: "",
  user: [],
  sideBar: [],
  login: (token) => {},
  logout: () => {},
  updateUser: () => {},
  updateProfile: () => {},
  addToast: () => {},
  removeToast: () => {},
  showLoading: () => {},
  hideLoading: () => {},
  showModal: () => {},
  hideModal: () => {},
});

export default AppContext;