import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import Wrapper from "../Wrapper";
import classes from "./RLModal.module.css";
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from "react-lazy-load-image-component";

const RLModalVerseDetail = (props) => {
  const { t } = useTranslation();

  const modal = props.modal;
  const params = modal.params

  const percent = ((params.versePopular.count * 100) / params.versePopular.capacity).toString() + "%";

  const { response, loading, error, request, responseType } = useAxios();
  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {

      if (responseType == "grave/" + params.graveId + "/verse/add") {
        params.reloadList();
        modal.onClose();
      }
    }
  }, [response, error]);

  const handleAddVerse = (event) => {
    event.preventDefault();
    postAddVerse()
  }

  function postAddVerse() {
    const url = "grave/" + params.graveId + "/verse/add";

    const data = {
      verseId: params.versePopular.id
    }

    request({
      method: "POST",
      url: url,
      data
    });
  }

  const handleClose = event => {
    event.preventDefault()
    modal.onClose();
  }

  function createContent() {
    if (params.versePopular.videoUrl === null) {

      if(isMobile) {

        if(params.versePopular.mobileThumbnailUrl != null) {
          return (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="30 30 300 300">
                    <defs>
                      <mask id="MASK">
                        <image xlinkHref={params.versePopular.mobileThumbnailAlphaChannelUrl}
                          className="verse-img" style={{ width: "360px" }} />
                      </mask>
                    </defs>

                    <image mask="url(#MASK)" style={{ width: "360px" }} xlinkHref={params.versePopular.mobileThumbnailUrl} />
                  </svg>
                );

        } else {
          return (
            <img src={params.versePopular.thumbnailUrl} className="verse-img" style={{ width: "360px" }}></img>
          );

        }

      } else {

        if(params.versePopular.thumbnailUrl?.includes(".webm")) {
          return(
            <video autoPlay loop muted playsInline className="verse-img" style={{ width: "360px" }}>
              <source src={params.versePopular.thumbnailUrl} type="video/webm" />
            </video>
          );

        } else {
          return (
            <img src={params.versePopular.thumbnailUrl} className="verse-img" style={{ width: "360px" }}></img>
          );
        }
      }

    } else {
      return (
        <video autoPlay loop muted playsInline controls style={{width:"100%", paddingBottom: "40px"}}>
            <source src={isMobile ? params.versePopular.mobileVideoUrl : params.versePopular.videoUrl} type={isMobile ? "video/mp4" : "video/webm"}/>
        </video>
      );
    }
  }

  return (
    <Wrapper>
      <div className={`${classes["text-center"]}`}>

        {createContent()}

        <h1 className="modal-header">{params.versePopular.name}</h1>

        <div className="numbers">
          <span>
            {params.versePopular.count}/<span>{params.versePopular.capacity}</span>
          </span>
          <div className="rl-progress-bar">
            <div
              className="rl-progress-bar-fill"
              style={{ width: percent, backgroundColor: "#0D1854" }}
            ></div>
          </div>
        </div>

        <div className="modal-header-desc">
          {params.versePopular.description != null && (
            <p>{params.versePopular.description}</p>
          )}

          {params.versePopular.description != null && (
            <p>{params.versePopular.shortDescription}</p>
          )}
        </div>

        {params.from != "landing" && (
          <a href="" onClick={handleAddVerse} className="rl-btn-filled black w-220">
            {t("general_add")}
          </a>
        )}

      </div>

      <a href="" className={props.closeClassName} onClick={handleClose}>
        x
      </a>
    </Wrapper>
  );
};

export default RLModalVerseDetail;
