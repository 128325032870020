import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/use-axios";
import AppContext from "../../../utils/app-context";
import Helpers from "../../../utils/Helpers";
import RLDragAndDrop from "../../UI/RLDragAndDrop";
import RLInput from "../../UI/RLInput";
import RLInputSelect from "../../UI/RLInputSelect";
import Wrapper from "../../UI/Wrapper";
import PanelContentFormFileItem from "../PanelContent/PanelContentFormFileItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const PanelKycForm = (props) => {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  let graveId = params["graveId"];
  const isFunnel = Helpers.isFunnel(location);
  const rlSideBar = props.rlSideBar
  const selectedGrave = rlSideBar.selectedGrave
  const uploadable = props.uploadable;
  let selectedGraveFunnelSteps = undefined;
  const kycCount = props.count;

  const [disableNextPrev, setDisableNextPrev] = useState({next: false, prev: false});

  const [file, setFile] = useState([]);
  const [documentType, setDocumentType] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const { response, loading, error, request, responseType } = useAxios();

  useEffect(() => {
    if (response !== null && typeof response !== "undefined") {
      if (responseType == "grave/" + graveId + "/kyc/create") {
        props.reloadList();
        setIsUploaded(true);
        setTimeout(() => setIsUploaded(false), 3000);

        setFile([]);
        setDocumentType(null);

      } else if (responseType == "funnel/move-forward-funnel") {
        history.replace("/panelPayment?graveId=" + graveId + "&graveName=" + selectedGrave.name + "&graveType=" + selectedGrave.graveType);

        rlSideBar.funnelMoveForward(rlSideBar, response.data);

      } else if (responseType == "funnel/select-funnel-step") {

        rlSideBar.funnelMoveForward(rlSideBar, response.data);
        
      }
    }

    appCtx.hideLoading();
  }, [response, error]);

  function handleDocumentType(value) {
    setDocumentType(value);
  }

  function isValidForm() {
    if (file.length > 0 && documentType != null) {
      setIsValid(true);
      return true;
    }

    setIsValid(false);
    setIsUploaded(false);
    return false;
  }

  const addContentHandler = function (event) {
    event.preventDefault();

    if (isValidForm()) {
      const data = {
        file: file,
        kycFileType: documentType.id,
      };

      appCtx.showLoading();

      request(
        {
          method: "POST",
          url: "grave/" + graveId + "/kyc/create",
          data,
        },
        () => { },
        false,
        true
      );
    }
  };

  for (let i = 0; i < selectedGrave.graveFunnelSteps.length; i++) {
    if (selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == "KYC") {
      selectedGraveFunnelSteps = selectedGrave.graveFunnelSteps[i]
    }
  }

  const handleBack = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, prev: true });

    history.replace((isFunnel ? "/funnel/" : "/panel/") + graveId + "/testament");
    postSelectFunnelStep("TESTAMENT")
  }
  const handleCompleteGraveYard = (event) => {
    event.preventDefault();

    setDisableNextPrev({ ...disableNextPrev, next: true });

    postFunnelMoveForward();
  }
  function postFunnelMoveForward() {

    if (isFunnel) {

      let graveId = -1;
      let graveFunnelStepId = -1;
      let skipped = kycCount > 0 ? false : true;

      graveId = selectedGraveFunnelSteps.graveId
      graveFunnelStepId = selectedGraveFunnelSteps.id

      history.replace("/panelPayment?graveId=" + graveId + "&graveName=" + selectedGrave.name + "&graveType=" + selectedGrave.graveType);
      return;

      if (selectedGraveFunnelSteps.status == "COMPLETED") {
        history.replace("/panelPayment?graveId=" + graveId + "&graveName=" + selectedGrave.name + "&graveType=" + selectedGrave.graveType);
        return;
      }

      const data = {
        graveId: graveId,
        graveFunnelStepId: graveFunnelStepId,
        skipped: skipped,
      };

      request({
        method: "POST",
        url: "funnel/move-forward-funnel",
        data,
      });
    }
  }

  function postSelectFunnelStep(type) {
    if (isFunnel) {
      let graveId = -1;
      let graveFunnelStepId = -1;

      for (let i = 0; i < selectedGrave.graveFunnelSteps.length; i++) {
        if (selectedGrave.graveFunnelSteps[i].funnelStep.stepKey == type) {
          graveId = selectedGrave.graveFunnelSteps[i].graveId
          graveFunnelStepId = selectedGrave.graveFunnelSteps[i].id
        }
      }

      const data = {
        graveId: graveId,
        graveFunnelStepId: graveFunnelStepId,
      };

      request({
        method: "POST",
        url: "funnel/select-funnel-step",
        data,
      });
    }
  }

  let backButton = "";
  if (isFunnel) {
    if (selectedGrave.graveType == "FOR_YOU") {
      backButton = "panel_navigation_testaments"
    } else if (selectedGrave.graveType == "FOR_BE_LOVED") {
    }
  }

  function handleSetFile(file) {
    setFile(file)
    // btnRef.current.removeAttribute("disabled");
  }

  return (
    <Wrapper>

      {uploadable &&(
        <RLDragAndDrop file={file} isUploaded={isUploaded} onFileChange={(file) => handleSetFile(file)} multiple={false} />
      )}

      <form
        onSubmit={addContentHandler}
        className="rl-bo-users-search-form for-kyc"
      >

        {isFunnel && (
          <div className="column">
            <a href="" onClick={handleBack} class="rl-panel-wizard-btn-skip w-icon left" style={{ fontWeight: "bold", pointerEvents: disableNextPrev.prev ? 'none' : 'auto' }}>
              <img src="/img/icon-chevron-right.svg" alt="Testaments" /> {Helpers.capitalizeAllFirst(t("panel_navigation_testaments"))}
            </a>
          </div>
        )}

        {uploadable ? (
          <Wrapper>
            {!isFunnel ? (<div style={{marginRight: "-1rem;"}}></div>) : (<Wrapper></Wrapper>)}
            
            <div className="column">
              <div className="rl-form-group">
                <div className="rl-form-group-material">
                  <RLInputSelect
                    label={t("kyc_form_document")}
                    defaultValue={documentType}
                    standart={true}
                    onChange={handleDocumentType}
                    options={props.documentTypes}
                  />
                </div>
              </div>
            </div>
            
            <div className="column">
              <button className="rl-form-button">{t("general_add")}</button>
            </div>
          </Wrapper>
        ) : (
          <div class="column"></div>
        )}

        {isFunnel && (
          <div className="column">
            <a href="" onClick={handleCompleteGraveYard} className="rl-complete-graveyard-button" style={{pointerEvents: disableNextPrev.next ? 'none' : 'auto'}}>{t("panel_navigation_kyc_complete")}</a>
          </div>
        )}
      </form>
      {!isValid && (
        <div className="rl-form-group">
          <div className="error-line">
            <img src="/img/icon-circle-info.svg" width="24" />
            <span>{t("kyc_form_documentError")}</span>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default PanelKycForm;
